import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import {Input, Button, Steps, Card, Descriptions, Timeline} from 'antd'
import Request from '../../request'
import styles from './styles.less'
import {Calendar, Views, momentLocalizer} from "react-big-calendar";

import "react-big-calendar/lib/css/react-big-calendar.css";

import moment from 'moment'

const localizer = momentLocalizer(moment)
const now = new Date()
let allViews = Object.keys({DAY: "day"}).map(k => {
    return Views[k]
})

const ColoredDateCellWrapper = ({children}) =>
    React.cloneElement(React.Children.only(children), {
        style: {
            backgroundColor: 'white',
        },
    })


class CalendarLayout extends PureComponent {

    constructor(props) {
        super(props)

        this.state = {
            message: '',
        }


    }


    toDate = (dateItem) => {
        let newDate = moment(dateItem.followUpDate).format('YYYY-MM-DD')
        let time = new Date(`${newDate} ${dateItem && dateItem.followUpTime}`)
        return time
    }

    fromDate = (dateItem) => {
        let newDate = moment(dateItem.followUpDate).format('YYYY-MM-DD')
        let time = new Date(`${newDate} ${dateItem && dateItem.followUpTime}`)
        time = moment(time).subtract(15, 'minutes').format()
        return new Date(time)
    }


    onChange = (time) => {
        let {handleCancel} = this.props
        handleCancel({
            startRange: moment(time).startOf('day').format('YYYY-MM-DD'),
            lastRange: moment(time).endOf('day').format('YYYY-MM-DDTHH:MM:ss'),
        })
    }


    async componentDidMount() {


    }


    render() {
        let {events} = this.props
        const colors = [
            'gold',
            'lime',
            '#617eb7'
        ];


        events = events && events.map((item) => {
            return {
                start: this.fromDate(item),
                end: this.toDate(item),
                title: item && item.leadId && item.leadId.title,
                leadId: item && item.leadId && item.leadId._id
            }
        });


        return (
            <Card className={styles.calendar}>
                {events && <Calendar
                    events={events}
                    views={allViews}
                    step={15}
                    timeslots={2}
                    min={new Date(now.getFullYear(), now.getMonth(), now.getDate(), 8)}
                    max={new Date(now.getFullYear(), now.getMonth(), now.getDate(), 18)}
                    showMultiDayTimes
                    defaultDate={new Date()}

                    defaultView={'day'}
                    onView={(val) => {
                        console.log(val)
                    }}
                    localizer={localizer}
                    eventPropGetter={(av) => {
                        return {
                            style: {
                                color: 'white',
                                border: '1px white solid'
                                //backgroundColor: colors[Math.floor(Math.random() * colors.length)]
                            }
                        }

                    }}
                    components={{
                        timeSlotWrapper: ColoredDateCellWrapper
                    }}
                    onSelectEvent={(val) => {
                        window.open(`/lead/detail?id=${val && val.leadId}`, '')
                    }}

                    onNavigate={(val) => this.onChange(val)}


                />}

            </Card>
        )

    }

}

const mapStateToProps = ({counter, router}) => ({
    count: counter.count,
    isIncrementing: counter.isIncrementing,
    isDecrementing: counter.isDecrementing,
    search: router.location.search,
    btnLoading: counter.btnLoading

})

const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CalendarLayout)
