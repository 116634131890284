import React, {Component} from 'react'
import {Button, Card, Tabs, Tooltip, Tag} from 'antd'
import moment from 'moment'
import Request from '../../request'
import Emitter from '../../emitter'
import {TableComp, FormUtils as GetAllFormFields} from 'sz-react-utils'

import {getUrlPushWrapper} from "../../routes";
import Dashboard from "./index"
import Stock from "../stock/product/productList"

const {TabPane} = Tabs;


class MainDashboard extends Component {


    constructor(props) {
        super(props)
        this.table = React.createRef()
        this.state = {
            activeKey: "1",
            currentPage: 1
        }
    }
    apiRequest = (params) => {
        if (params.page) {
          this.state.currentPage = params.page
        }
        let currentUser = JSON.parse(window.localStorage.getItem('user'))
    
        if (currentUser && currentUser.userType === 'Dispatch') {
          params.filledBy = currentUser._id
        }
        if (!params.sortOrder) {
          params.sortField = 'createdAt'
          params.sortOrder = 'desc'
        }
    
        this.setState({ filters: { ...params } })
    
        return new Promise(async (resolve) => {
          let data = await Request.getAllStockList({
            ...params,
            // regExFilters: ['product'],
          })
        
          this.setState({ count: data.total })
    
          resolve(data)
        })
      }
      downloadDispatch = async () => {
        this.setState({ fileDownloadLoader: true })
        let date = moment().format('lll')
        console.log(date, '----')
        await Request.downloadStock(date)
        this.setState({ fileDownloadLoader: false })
      }
    // apiRequest = (params) => {
    //     if (params.page) {
    //         this.state.currentPage = params.page
    //     }

    //     return new Promise(async (resolve) => {
    //         if (!params.sortField) {
    //             params.sortField = 'stock'
    //             params.sortOrder = 'ascend'
    //         }

    //         let data = await Request.getAllStockProducts({
    //             ...params, regExFilters: ['name']
    //         })

    //         this.setState({count: data.total})
    //         resolve(data);
    //     })


    // }


    async componentDidMount() {
        let {data: categoryList} = await Request.loadCategories();

        this.setState({categoryList: categoryList})

    }


    render() {

        const {dispatch} = this.props
        const {currentPage} = this.state;

        const categoriesArray = this.state.categoryList && this.state.categoryList.map((item) => {
            return {
                text: item.category,
                value: item._id
            }
        });
        const columns = [
            {
              title: 'S.No',
              dataIndex: 'sno',
              width: 50,
              key: 'sno',
              fixed: 'left',
              render: (value, item, index) => (currentPage - 1) * 10 + index + 1,
            },
      
            {
              title: 'Products',
              dataIndex: 'product',
              key: 'product',
              // searchTextName: 'product',
              width: 200,
            },
            {
              title: 'Total Inward Qty',
              dataIndex: 'inwardQty',
              key: 'inwardQty',
            },
            {
              title: 'Total  Outward Qty',
              dataIndex: 'outwardQty',
              key: 'outwardQty',
            },
            {
              title: 'Stock',
              dataIndex: 'stock',
              key: 'stock',
      
              width: 200,
              render:(val,record) => {
               const {stock,criticalValue,criticalValueTwo} = record
               if(stock>criticalValue && stock>criticalValueTwo){
                return <Tag color='green'>{stock}</Tag>
               }
               if(stock<criticalValue && stock>criticalValueTwo){
                return <Tag color='yellow'>{stock}</Tag>
               }
                
               if(stock<criticalValue && stock<criticalValueTwo){
                return <Tag color='red'>{stock}</Tag>
               }
                
                
              }
            },
          ]
        // const columns = [
        //     {
        //         title: 'S.No',
        //         dataIndex: 'sno',
        //         width: 50,
        //         key: 'sno',
        //         render: (value, item, index) => (currentPage - 1) * 10 + index + 1
        //     },
        //     {
        //         title: 'Product Name',
        //         key: 'name',
        //         width: 150,
        //         dataIndex: 'name',
        //         searchTextName: 'name'


        //     },
        //     {
        //         title: 'Category',
        //         width: 100,
        //         key: 'category',
        //         filters: categoriesArray,

        //         render: (val, record) => {
        //             return <div>
        //                 {record && record.category && record.category.category}
        //             </div>
        //         }

        //     },


        //     {
        //         title: 'Inward Total',
        //         dataIndex: 'inwardCount',
        //         width: 100,
        //         searchTextName: 'inwardCount',

        //     }, {
        //         title: 'Outward Total',
        //         dataIndex: 'outwardCount',
        //         width: 100,
        //         searchTextName: 'outwardCount',

        //     },
        //     {
        //         title: 'Net Stock',
        //         dataIndex: 'stock',
        //         width: 80,
        //         render: (val, record) => {
        //             if ((record && record.stock) <= (record && record.criticalValue)) {
        //                 return <Tag color={'red'}>{record.stock}</Tag>
        //             } else {
        //                 return <Tag>{record.stock}</Tag>
        //             }
        //         }

        //     },
        //     {
        //         title: 'Critical Value',
        //         width: 120,
        //         searchTextName: 'criticalValue',
        //         dataIndex: 'criticalValue'

        //     },

        //     {
        //         title: 'Created At',
        //         width: 120,
        //         key: 'createdAt',
        //         dataIndex: 'createdAt',
        //         sorter: true,
        //         render: (text, record) => {
        //             return (
        //                 <div>
        //                     {text ? moment(text).format('lll') : ''}
        //                 </div>
        //             )
        //         }
        //     },
        //     {
        //         key: 'actions',
        //         width: 40,
        //         title: 'Actions',
        //         render: (text, record) => {
        //             return <React.Fragment>
        //                 <Tooltip title="View Ledger">
        //                     <a href={`/stock/view/ledger?id=${record._id}`} target={'_blank'}>
        //                         <Button style={{marginLeft: '5px'}} size="small" icon={'eye'} shape={'circle'}/>
        //                     </a>
        //                 </Tooltip>
        //             </React.Fragment>
        //         }
        //     }


        // ]

        return (
            <div>
                <Tabs activeKey={this.state.activeKey} onChange={(i) => {
                    this.setState({activeKey: i})
                    if (i === "1") {
                        this.setState({key: Date.now()})
                    } else {
                        this.table.current.reload()
                    }


                }}>
                    <TabPane tab="Dashboard" key="1">
                        <Dashboard key={this.state.key}/>
                    </TabPane>

                    <TabPane tab="Stock" key="2" forceRender={true}>
                        <Card>
                        <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Tooltip title="Download">
              <Button
                type="warning"
                style={{ marginTop: '6px' }}
                loading={this.state.fileDownloadLoader}
                onClick={() => {
                  this.downloadDispatch()
                }}
                icon="download">
                Download
              </Button>{' '}
            </Tooltip>
          </div>
                        <TableComp
            ref={this.table}
            columns={columns}
            apiRequest={this.apiRequest}
            pagination={{
              ...this.state.pagination,
              defaultPageSize: 20,
              pageSizeOptions: ['10', '20', '50', '100'],
              showSizeChanger: true,
              ...this.props.pagination,
            }}
          />
                            {/* <TableComp ref={this.table}
                                       columns={columns}
                                       apiRequest={this.apiRequest}
                                       pagination={{
                                           ...this.state.pagination,
                                           defaultPageSize: 10,
                                           pageSizeOptions: ['10', '25', '50', '100'],
                                           showSizeChanger: true,
                                           ...this.props.pagination
                                       }}/> */}
                        </Card>
                    </TabPane>
                </Tabs>

            </div>
        )
    }

}

export default MainDashboard

