import React, {Component} from 'react'
import {
    Card,
    Col,
    Row,
    Icon,
    Table,
    List,
    Radio,
    Descriptions,
    Tooltip,
    Select,
    Spin,
    DatePicker,
    Badge,
    Input,
    Button
} from 'antd'
import styles from './styles.less'
import moment from 'moment'
import _ from 'lodash'

import Highlighter from 'react-highlight-words'

import Request, {API_URL} from '../../../request'
import {getUrlPushWrapper} from '../../../routes'
import {connect} from "react-redux";
import FormatNumber from "indian-number-format";

const {Option} = Select

class ConflictsLeads extends Component {
    loadApisRequest = () => {
        this.setState({startLoading: true})
        Request.ConflictsLeads({}).then(({data}) => {

            data = _.reject(data, (ii) => {
                return ii._id === null
            })
            console.log(data)
            this.setState({
                activitiesCountDataByRO: data,
                startLoading: false
            })
        })

    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
            <div style={{padding: 8}}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{width: 188, marginBottom: 8, display: 'block'}}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon="search"
                    size="small"
                    style={{width: 90, marginRight: 8}}
                >
                    Search
                </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{width: 90}}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{color: filtered ? '#1890ff' : undefined}}/>
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({searchText: ''});
    };


    handleCancel = e => {
        this.setState({
            visible: false,
        });
    };

    constructor(props) {
        super(props)
        this.state = {currentPage:1}
    }

    async componentDidMount() {
        this.loadApisRequest()

    }


    render() {

        let {userList, startLoading,currentPage} = this.state
        const {dispatch} = this.props
        const handleChangeDebut = (range) => {
            console.log("range is", range);
            if (!range.length) {
                this.setState({
                    startRange: moment().startOf('month').toDate(),
                    lastRange: moment().endOf('month').toDate(),
                })
            }
            else {
                const valueOfInput1 = moment(range[0]).startOf('day').format();
                const valueOfInput2 = moment(range[1]).endOf('day').format();
                this.setState({...this.state, startRange: valueOfInput1, lastRange: valueOfInput2});
            }
            console.log("state changed")
            setTimeout(() => {
                this.loadApisRequest()

            }, 500);

        }

        const columns = [
           
            {
                title: 'Contact Id',
                dataIndex: 'id',
                key: 'id',
                ...this.getColumnSearchProps('id'),

            },
            {
                title: 'Contact Name',
                // dataIndex: 'contactName',
                key: 'contactName',
                render: (val, record) => {
                    return <div>
                        <div>
                            {record && record.contactName}
                        </div>
                        <div>

                            <a href={`/contact/history?id=${record.contactId}`}
                               target={'_blank'}>{record && record.mobile}
                            </a>
                        </div>
                    </div>
                },
                //  ...this.getColumnSearchProps('contactName'),

            },
            {
                title: 'Leads',
                key: 'lead',
                render: (val, record) => {
                    return _.map(record && record.leads, (ii) => {
                        return <div><a href={`/lead/detail?id=${ii && ii._id}`}
                                       target={'_blank'}>
                            {ii.title ? ii.title : ''}</a> <b>RO: {ii.officer && ii.officer.name}</b>
                        </div>
                    })
                }

            },

        ];

        return (
            <div>

                <Spin spinning={startLoading}>

                    <div className="site-card-wrapper">

                        <Card> <Table size={'small'} dataSource={this.state.activitiesCountDataByRO} columns={columns}
                                      bordered={true}
                                      pagination={true}/></Card>


                    </div>
                </Spin>
            </div>
        )
    }

}

const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}
export default connect(
    mapDispatchToProps
)(ConflictsLeads)

