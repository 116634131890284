import React, {PureComponent} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
    Form,
    Input,
    notification,
    Anchor,
    Spin,
    Select,
    Tag,
    Button,
    Table,
    Card,
    Typography,
    Row,
    Col,
    Icon, Tooltip, Popconfirm, Avatar, Checkbox, AutoComplete, Radio, Drawer
} from 'antd'
import _ from 'lodash'
import { FormUtils as GetAllFormFields, TableComp} from 'sz-react-utils'
import moment from 'moment'


import {API_URL} from '../../../request'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {hideLoader, showLoader} from '../../../modules/actions'
import Request from '../../../request'
//import styles from './styles.less'
import {connect} from 'react-redux'
import {createMatchSelector, goBack} from 'connected-react-router'
import {getUrlPushWrapper} from "../../../routes";
import styles from "../all/styles.less";
import {pureFinalPropsSelectorFactory} from "react-redux/lib/connect/selectorFactory";

const FormItem = Form.Item
const {Option} = Select
const {Text, Paragraph} = Typography;
const {TextArea} = Input

@Form.create()
class AddOutwardForm extends PureComponent {

    handleSubmit = e => {
        const {dispatch, form, currentUser} = this.props
        let {totalData} = this.state

        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let values = _.clone(valData)

                if (values.replacementType === 'Inward Pending') {
                    values.replacementList = []
                }
                console.log(values, "values")


                if (totalData && totalData.length == 1) {
                    return notification.error({
                        message: 'Atleast add one product'
                    })
                }

                let foundFlag = false

                let tempArr = []
                let errorIds = []

                _.each(this.state.totalData, (item) => {

                    if (item.productIds && item.productIds.length != item.quantity) {
                        foundFlag = true
                    }
                    _.each(item.productIds, (val) => {
                        if (!tempArr.includes(val)) {
                            tempArr.push(val)

                        } else {
                            errorIds.push(val)

                        }
                    })


                })

                if (errorIds.length) {
                    return notification.error({message: `Duplicate Product Ids ${errorIds}.`})

                }
               

                totalData = _.reject(this.state.totalData, (x) => x.isLast == true)
                values.products = totalData

                // if (!values.invoiceNumber && !values.invoiceReason) {
                //
                //     return notification.error({
                //         message: 'Fill Invoice Details'
                //     })
                // }

                values.type = 'Outward'
                let x = null
                dispatch(showLoader())
                console.log(values)

                if (this.state.id) {
                    values._id = this.state.id

                    x = await Request.updateInwardOutwardForm(values)
                } else {
                    x = await Request.addInwardOutwardForm(values)
                }

                dispatch(hideLoader())


                if (!x.error) {

                    notification.success({
                        message: this.state.id
                            ? x.message
                            : x.message
                    })

                    if (!this.state.id) {
                        this.props.form.resetFields()

                        this.props.form.setFieldsValue({date: moment(), filledBy: currentUser && currentUser._id})

                        Request.getNextInwardOutwardFormNo({type: 'Outward'}).then((data) => {
                            if (!data.error) {
                                this.props.form.setFieldsValue({serialNumber: data.data})
                            }
                        })
                        this.setState({
                            totalData: [{isLast: true}], replacementType: undefined,
                            purposeValue: undefined,
                            selectedRowKeys: []
                        })
                        this.loadOutwardPendingList({
                            replacementType: 'Outward Pending',
                            purpose: 'Replacement'
                        })
                        this.loadOutwardPendingList({type: 'Inward', purpose: 'Repair'})


                    }

                    if (this.state.id) {
                        dispatch(goBack())

                    }


                } else {
                    notification.error({
                        message: 'Error Saving',
                        description: x.message
                    })
                }

            }
        })
    }

    constructor(props) {
        super(props)
        this.state = {
            visible: false,
            productList: [],
            totalData: [],
            newOption:null,
            time: Date.now(),
            carriersData: ['Fedex', 'DHL', 'UPS', 'USPS', 'BlueDart', 'DTDC'],
            loader: true,
            outwardPendingList: [],
            selectedRowKeys: [],
            repairList: []
        }
        this.AddData = _.debounce(this.addData, 1000, true)


    }

    handleCancel = e => {

        this.props.form.setFieldsValue({
            quantity: null,
            productName: undefined,
        })
        this.setState({
            modalVisible: false,
            productName: '',
            quantity: ''


        })
    }


    addData = () => {


        let tt = _.cloneDeep(this.state.totalData)
        let found = _.find(tt, (i) => {
            if (i && i.isLast != true) {
                return (i.productName == this.state.productName) || (i.productName._id == this.state.productName)

            }
        })

        if (found) {
            this.setState({showText: false})
            this.props.form.setFieldsValue({

                productName: undefined,
                quantity: null,

            })

            return notification.error({
                message: 'Product Already Exists.'
            })
        }
        let comp = tt.pop()
        tt.push({

            quantity: this.state.quantity,
            productName: this.state.productName,
            isLast: false,
            productIds: [],
            subjectedToStock: this.state.subjectedToStock ? this.state.subjectedToStock : false,
            productId: this.state.productId

        })
        tt.push(comp)
        this.props.form.setFieldsValue({
            quantity: null,
            totalData: tt,
            productName: undefined,

        })
        this.setState({
            quantity: null,
            totalData: tt,
            productName: undefined,
            subjectedToStock: undefined,
            productId: null


        }, () => {

        })

    }
    handleProductSearch = async (test) => {
        if (test == '' || test == undefined) {
            this.setState({productList: this.state.productList})

            return false
        } else {
            let tt = _.cloneDeep(this.state.totalData)

            this.setState({
                totalData: tt,
                productName: test,
                quantity: 1,


            }, () => {
                this.addData()
            })
        }


    }

    onProductSelect = (val) => {
        let selectedQuote = this.state.productList.find((obj) => obj.name === val)
        if (selectedQuote) {
            this.props.form.setFieldsValue({
                productName: selectedQuote.name,

            })
            if (this.state.subjectedToStock) {
                this.setState({productId: selectedQuote && selectedQuote._id})
            }

            this.setState({
                productName: selectedQuote.name,
                quantity: 1

            }, () => {
                this.addData()
            })
        }
        else{
            this.handleProductSearch(val)
        }

    }


    getOptionsData = async () => {
     
        let currentUser = JSON.parse(window.localStorage.getItem('user'))

        let filter = {
            userType: {$in: ['Admin', 'RO', 'Dispatch', 'Technician', 'Lead Executive', 'Caller']},
            results: 10000
        }
        this.props.form.setFieldsValue({
            filledBy: currentUser._id
        })


        // Request.getAllProducts({results: 2000}).then(({data: productList}) => {
        //     this.setState({productList: productList || []}, () => {
        //
        //     })
        // })
        Request.getAllHistoryProducts({
            results: 2000
        }).then((data) => {
            this.setState({productList: data || []}, () => {

            })
        })
        Request.loadUser(filter).then(({data: userList}) => {
            this.setState({userList: userList || [],})
        })


    }
    loadOutwardPendingList = async (obj) => {
        Request.inwardOutwardListDispatch({
            ...obj,
            results: 100000000000000000
        }).then(({data}) => {
            if (obj && obj.type === 'Inward') {
                this.setState({repairList: data || []})

            } else {
                this.setState({outwardPendingList: data || []})

            }

        })

    }

    async componentDidMount() {
        let searchParams = new URLSearchParams(this.props.search);
        let dispatchId = searchParams.get('id')

        this.getOptionsData()


        if (dispatchId) {

            Request.getDispatchInwardOutwardById({_id: dispatchId}).then(({data: dispatchData, error, message}) => {
                if (!error) {
                    let selectedInwards
                    if (dispatchData && dispatchData.replacementList && dispatchData.replacementList.length) {
                        selectedInwards = _.map(dispatchData.replacementList, (ii) => {
                            return ii._id
                        })
                    }
                    if (dispatchData && dispatchData.repairList && dispatchData.repairList.length) {
                        selectedInwards = _.map(dispatchData.repairList, (ii) => {
                            return ii._id
                        })
                    }

                    this.setState({
                        id: dispatchData && dispatchData._id,
                        loader: false,
                        totalData: [...dispatchData.products, {isLast: true}],
                        replacementType: dispatchData.replacementType,
                        purposeValue: dispatchData && dispatchData.purpose,
                        outwardPendingList: dispatchData.replacementList,
                        repairList: dispatchData.repairList,
                        selectedRowKeys: selectedInwards,


                    })
                    this.props.form.setFieldsValue({

                        serialNumber: dispatchData && dispatchData.serialNumber,
                        department: dispatchData && dispatchData.department,
                        productType: dispatchData && dispatchData.productType,
                        challanNumber: dispatchData && dispatchData.challanNumber,
                        filledBy: dispatchData && dispatchData.filledBy,
                        checkedBy: dispatchData && dispatchData.checkedBy,
                        date: dispatchData && moment(dispatchData.date),
                        invoiceNumber: dispatchData && dispatchData.invoiceNumber,
                        invoiceReason: dispatchData && dispatchData.invoiceReason,
                        orderAuthority: dispatchData && dispatchData.orderAuthority,
                        consignee: dispatchData && dispatchData.consignee,
                        packingDoneBy: dispatchData && dispatchData.packingDoneBy,
                        carrier: dispatchData && dispatchData.carrier,
                        purpose: dispatchData && dispatchData.purpose,
                        carrierDetails: dispatchData && dispatchData.carrierDetails,
                        shipTo: dispatchData && dispatchData.shipTo,
                        contactNumber: dispatchData && dispatchData.contactNumber,
                        contactPerson: dispatchData && dispatchData.contactPerson,
                        totalBox: dispatchData && dispatchData.totalBox,
                        boxNumber: dispatchData && dispatchData.boxNumber,
                        address: dispatchData && dispatchData.address,
                        city: dispatchData && dispatchData.city,
                        state: dispatchData && dispatchData.state,
                        pincode: dispatchData && dispatchData.pincode,
                        replacementType: dispatchData.replacementType,
                        replacementList: dispatchData.replacementList,
                        repairList: dispatchData.repairList


                    });
                } else {
                    this.setState({
                        loader: false
                    })
                    notification.error({
                        message: 'Error Getting Data'
                    })
                }
            })
        } else {
            Request.getNextInwardOutwardFormNo({type: 'Outward'}).then((data) => {
                if (!data.error) {
                    this.props.form.setFieldsValue({serialNumber: data.data, date: moment()})
                }
            })
            this.loadOutwardPendingList({
                replacementType: 'Outward Pending',
                purpose: 'Replacement'
            })
            this.loadOutwardPendingList({type: 'Inward', purpose: 'Repair',})

            this.setState({
                loader: false
            })
            this.state.totalData.push({isLast: true})

        }


    }

    showDrawer = () => {
        this.setState({
            visible: true,
            key: Date.now()
        })
    }

    onSelect = (data) => {
        console.log('onSelect', data);
    };

    render() {
        const {
            form,
            form: {getFieldDecorator, getFieldValue}
        } = this.props

        const {
            productList,
            carriersData,
            id,
            userList,
            purposeValue,
            replacementType,
            outwardPendingList,
            selectedRowKeys,
            singleRecord,
            repairList,
            repairListKey,
            outwardPendingListKey
        } = this.state


        const inputTypes = {
            fields: [
                {
                    label: 'Serial No.',
                    key: 'serialNumber',
                    customProps: {disabled: true},
                    required: true,

                },
                {
                    label: 'Department',
                    key: 'department',
                    type: 'select',
                    required: true,
                    options: ['AMCU', 'Glassware'],
                    onChange: department => {
                        this.props.form.setFieldsValue({
                            department: department
                        })

                    }


                },
                {
                    label: 'Date',
                    key: 'date',
                    type: 'date',
                    required: true

                },
                {
                    label: 'Product Type',
                    key: 'productType',
                    type: 'select',
                    required: true,
                    options: ['Finish Goods', 'Packing Material', 'Electronic Components', 'Raw Material'],
                    onChange: productType => {
                        this.props.form.setFieldsValue({
                            productType: productType
                        })

                    }


                },
                {
                    label: 'Invoice Number',
                    key: 'invoiceNumber',


                },
                {
                    label: 'Invoice Reason',
                    key: 'invoiceReason',
                    type: 'textArea'


                },
                {
                    label: 'Challan Number',
                    key: 'challanNumber',
                    required: true,

                },
                {
                    label: 'State',
                    key: 'state',
                    // required: true
                },
                {
                    label: 'Address',
                    key: 'address',
                    // required: true,
                    type: 'textArea'

                },
                {
                    label: 'Total Boxes',
                    key: 'totalBox',
                    required: true,
                    type: 'number'

                },
                {
                    label: 'Box Number',
                    key: 'boxNumber',
                    required: true,
                    type: 'number'

                },

                {
                    label: 'Purpose',
                    key: 'purpose',
                    type: 'select',
                    required: true,
                    options: ['Sale', 'Repair', 'Purchase', 'Replacement', 'Receiving'],
                    onChange: purpose => {
                        this.setState({
                            purposeValue: purpose,
                            replacementType: undefined,
                            selectedRowKeys: []
                        })

                        this.props.form.setFieldsValue({
                            purpose: purpose,
                            replacementType: undefined,
                            replacementList: [],
                            repairList: []

                        })

                    }


                },

            ]
        }
        const inputTypes1 = {
            fields: [
                {
                    label: 'Order Authority',
                    key: 'orderAuthority',
                    // disabled: true,
                    showSearch: true,
                    required: true,
                    keyAccessor: x => x._id,
                    valueAccessor: x => `${x.name} ${x.mobile} (${x.userType})`,
                    type: 'select',
                    options: userList || [],
                    onChange: orderAuthority => {
                        this.props.form.setFieldsValue({
                            orderAuthority: orderAuthority
                        })

                    },
                    customProps: {
                        dropdownMatchSelectWidth: false
                    }


                },
                {
                    label: 'Consignee',
                    key: 'consignee',
                    required: true,


                },

                {
                    label: 'Packing Done By',
                    key: 'packingDoneBy',
                    required: true,


                },
                {
                    label: 'Filled By',
                    key: 'filledBy',
                    showSearch: true,
                    disabled: true,
                    required: true,
                    keyAccessor: x => x._id,
                    valueAccessor: x => `${x.name} ${x.mobile} (${x.userType})`,
                    type: 'select',
                    options: userList || [],
                    onChange: filledBy => {
                        this.props.form.setFieldsValue({
                            filledBy: filledBy
                        })

                    },

                },

                {
                    label: 'Checked By',
                    key: 'checkedBy',
                    showSearch: true,
                    required: true,
                    keyAccessor: x => x._id,
                    valueAccessor: x => `${x.name} ${x.mobile} (${x.userType})`,
                    type: 'select',
                    options: userList || [],
                    onChange: checkedBy => {
                        this.props.form.setFieldsValue({
                            checkedBy: checkedBy
                        })

                    },
                    customProps: {
                        dropdownMatchSelectWidth: false
                    }
                },
                {
                    label: 'Ship To',
                    key: 'shipTo',
                    type: 'select',
                    required: true,
                    options: ['ASC', 'SAME AS BILL TO', 'PARTY ADDRESS'],
                    onChange: shipTo => {
                        this.props.form.setFieldsValue({
                            shipTo: shipTo
                        })

                    }


                }, {
                    label: 'City',
                    key: 'city',


                },
                {
                    label: 'Pincode',
                    key: 'pincode',

                },
                {
                    label: 'Contact Person',
                    key: 'contactPerson',
                    // required: true,
                },
                {
                    label: 'Contact Number',
                    key: 'contactNumber',
                    // required: true,
                },

            ]
        }


        const refColumns = [

            {
                title: 'S.No',
                dataIndex: 'sno',
                key: 'sno',
                width: 70,
                render: (value, item, index) => {
                    if (item && item.isLast != true) {
                        return index + 1
                    }
                }
            },
            {
                title: 'Subjected to Stock',
                dataIndex: 'subjectedToStock',
                key: 'subjectedToStock',
                width: 150,
                render: (value, item, index) => {
                    if (item && item.isLast == true) {
                        return <Checkbox onChange={(val) => {
                            this.setState({subjectedToStock: val.target.checked})
                        }}/>
                    } else {
                        return <Tag>{value ? 'Yes' : 'No'}</Tag>
                    }
                }
            },

            {
                title: 'Product',
                dataIndex: 'productName',
                key: 'productName',
                width: 200,
                render: (x, item, index) => {
                    if (item && item.isLast == true) {

                        return (<div style={{width: '300px'}}>

{getFieldDecorator('productName', {})(
                                 <AutoComplete
                                    notFoundContent={this.state.showSpin && <Spin/>}
                                    autoComplete={'none'}
                                    dropdownMatchSelectWidth={false}
                                    onSelect={this.onProductSelect}
                                    placeholder={'Product Name'}
                                    // onBlur={(val) => {
                                    //     this.handleProductSearch(val)

                                    // }}
                                    onSearch={(val) => {
                                        this.setState({newOption: val})
                                    }}
                                >
                                {this.state.newOption &&
                                        <Option value={this.state.newOption || ''}>{this.state.newOption}</Option>}
                                {productList.map((val, item) => (

                                  
                                    <Option value={val._id}>
                                        <>
                                            <div><b>{val._id}</b></div>
                                        </>
                                    </Option>
                                ))}

                                </AutoComplete>)}


                        </div>)

                    } else {
                        return <div style={{marginBottom: '5px'}}> {x}</div>
                    }


                }


            },
            {
                title: 'ProductIds',
                key: 'productIds',
                dataIndex: 'productIds',
                render: (x, item, key) => {
                    if (item && item.isLast !== true) {
                        return <div>
                            <Select
                                defaultValue={item && item.productIds}
                                style={{width: 200}}
                                placeholder={'Enter Product Id'}
                                mode={'tags'}
                                onChange={(e) => {
                                    let array = []
                                    _.each(e, (i) => {
                                        array.push(i.toLowerCase())
                                    })
                                    item.productIds = array


                                }}
                            >
                            </Select>
                        </div>
                    }
                }


            },


            {
                title: 'Quantity',
                key: 'quantity',
                //width: 50,
                dataIndex: 'quantity',
                render: (y, item, index) => {
                    if (item && item.isLast != true) {
                        return <div><Text editable={{
                            onChange: (val) => {

                                if (!isNaN(parseInt(val))) {
                                    let tt = this.state.totalData
                                    item.quantity = val ? Math.abs(val) : tt[index].quantity
                                    this.setState({totalData: tt})
                                    this.props.form.setFieldsValue({
                                        quantity: val
                                    })
                                }


                            }
                        }}> {y}</Text>


                        </div>
                    }


                }

            },


            {
                title: 'Action',
                key: 'operation',
                width: 80,
                //  fixed: 'right',
                render: (item, record) => {
                    if (record && record.isLast != true) {
                        return <React.Fragment>

                            <Tooltip title="Delete Product">
                                <Popconfirm
                                    title="Are you sure delete this Product?"
                                    onConfirm={() => {
                                        let tt = this.state.totalData
                                        tt = _.reject(tt, item)
                                        this.setState({totalData: tt}, () => {
                                        })


                                    }}
                                    onCancel={() => {
                                        console.log()
                                    }}
                                    okText="Yes"
                                    cancelText="No">
                                    <Button
                                        shape="circle"
                                        size="small"
                                        type={'danger'}
                                        icon="delete"
                                    />
                                </Popconfirm>

                            </Tooltip>

                        </React.Fragment>

                    }
                }
            }
        ]
        const column = [
            {
                title: 'Id',
                dataIndex: 'serialNumber',
                width: 80,
                fixed: 'left',
                key: 'serialNumber',
                searchTextName: 'serialNumber'

            },
            {
                title: 'Type',
                dataIndex: 'type',
                key: 'type',
                width: 100,
                fixed: 'left',
                render: (val) => {
                    return <Tag color={val === 'Inward' ? 'blue' : 'red'}>{val}</Tag>
                }

            },
            {
                title: 'Products',
                dataIndex: 'products',
                key: 'products',
                width: 200,
                render: (val, record, index) => {
                    return <React.Fragment>
                        <div>
                            <ul style={{paddingLeft: '0px', listStyle: 'none'}}>
                                {val && val.length && val.map((item, key) => {

                                    return <li key={key}>{item.productName}</li>
                                })}

                            </ul>

                        </div>
                    </React.Fragment>

                }

            },
            {
                title: 'Purpose',
                dataIndex: 'purpose',
                width: 120,
                key: 'purpose',

            },
            {
                title: 'Challan Number',
                dataIndex: 'challanNumber',
                width: 150,
                key: 'challanNumber',
                searchTextName: 'challanNumber',
            },
            {
                title: 'Invoice Number',
                dataIndex: 'invoiceNumber',
                width: 150,
                key: 'invoiceNumber',
                searchTextName: 'invoiceNumber',
            },
            {
                title: 'Order Authority',
                dataIndex: 'orderAuthority',
                width: 150,
                key: 'orderAuthority',
                render: (val, record) => {
                    return (<div>
                        <div>{record.orderAuthority ? record.orderAuthority.name : ''}</div>
                    </div>)
                }
            },


            {
                title: 'Consignee Name',
                dataIndex: 'consignee',
                width: 150,
                key: 'consignee',
                searchTextName: 'consignee'
            },
            {
                title: 'Packing Done By',
                width: 150,
                dataIndex: 'packingDoneBy',
                key: 'packingDoneBy',
                searchTextName: 'packingDoneBy'

            },

            {
                title: 'Date',
                dataIndex: 'date',
                key: 'date',
                width: 180,
                searchDateName: 'date',
                render: (text, record) => {
                    return (
                        <Tag>
                            {text ? moment(text).format('lll') : ''}
                        </Tag>
                    )
                },
            },
            {
                title: 'Created At',
                dataIndex: 'createdAt',
                key: 'createdAt',
                width: 180,
                searchDateName: 'createdAt',
                render: (text, record) => {
                    return (
                        <Tag>
                            {text ? moment(text).format('lll') : ''}
                        </Tag>
                    )
                },
            },
            {
                key: 'actions',
                title: 'Actions',
                fixed: 'right',
                width: 80,
                render: (val, record, key) => {
                    return (<React.Fragment>
                        <Tooltip title="View Products">
                            <Button
                                style={{marginRight: '6px', marginTop: '6px'}}
                                size="small"
                                shape="circle"
                                onClick={() => {
                                    this.setState({
                                        singleRecord: record
                                    }, () => {
                                        this.showDrawer()

                                    })

                                }}
                                icon="eye"
                            />
                        </Tooltip>
                    </React.Fragment>)
                }
            }

        ]

        const rowSelection = {
            selectedRowKeys: selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({selectedRowKeys})
                if (getFieldValue('purpose') === 'Replacement') {
                    this.props.form.setFieldsValue({replacementList: selectedRows})

                } else if (getFieldValue('purpose') === 'Repair') {
                    this.props.form.setFieldsValue({repairList: selectedRows})

                }

            },
            getCheckboxProps: record => ({
                _id: record._id
            })
        }


        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
                md: {span: 8}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
                md: {span: 12}
            }
        }


        return (
            <PageHeaderWrapper title={this.state.id ? 'Update Outward Form' : 'Add Outward Form'}
            >

                <Form onSubmit={this.handleSubmit} hideRequiredMark={false} style={{marginTop: 8}}>

                    <Spin spinning={this.state.loader}>
                        <Card bordered={true}>

                            <Row>
                                <Col span={12}>
                                    <GetAllFormFields apiurl={API_URL}
                                                      inputSchema={inputTypes}
                                                      formItemLayout={formItemLayout}
                                                      getFieldDecorator={getFieldDecorator}/>
                                    {(purposeValue == 'Replacement') ?
                                        <Form.Item label={'Replacement Type'} {...formItemLayout}>
                                            {getFieldDecorator('replacementType', {
                                                rules: [{
                                                    required: true,
                                                }],
                                            })(
                                                <Radio.Group disabled={this.state.id ? true : false}
                                                             options={['Inward Pending', 'Already Inward']}
                                                             className={styles.inwardRadioButton}
                                                             onChange={(checkedValues) => {
                                                                 this.setState({replacementType: checkedValues.target.value})
                                                             }} value={this.state.replacementType}>


                                                </Radio.Group>
                                            )}
                                        </Form.Item> : ''}

                                </Col>
                                <Col span={12}>
                                    <GetAllFormFields apiurl={API_URL}
                                                      inputSchema={inputTypes1}
                                                      formItemLayout={formItemLayout}
                                                      getFieldDecorator={getFieldDecorator}/>
                                    <Form.Item
                                        label='Carrier'
                                        {...formItemLayout}
                                    >{getFieldDecorator('carrier', {
                                        rules: [{
                                            required: true,
                                            message: 'This is a Mandatory Field',
                                        }]
                                    })(
                                        <AutoComplete
                                            autoComplete={'none'}
                                            onSelect={this.onSelect}
                                            placeholder={'Delivery Carrier'}

                                        >
                                            {carriersData.map((val, item) => (
                                                <Option value={val}>
                                                    {val}
                                                </Option>
                                            ))}
                                        </AutoComplete>
                                    )}
                                    </Form.Item>


                                    <Form.Item
                                        label='Carrier Details'
                                        {...formItemLayout}
                                    >{getFieldDecorator('carrierDetails', {
                                        rules: [{
                                            required: true,
                                            message: 'This is a Mandatory Field',
                                        }]
                                    })(
                                        <TextArea></TextArea>
                                    )}
                                    </Form.Item>


                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    {((replacementType === 'Already Inward') && (purposeValue === 'Replacement')) ?
                                        <Form.Item label={'Outward Pending'}>

                                            {getFieldDecorator('replacementList', {
                                                rules: [{
                                                    required: true,
                                                    message: 'This is a Mandatory Field',
                                                }]
                                            })(
                                                <Table
                                                    scroll={{y: 350, x: 'max-content'}}
                                                    bordered={true}
                                                    rowKey={x => x._id}
                                                    columns={column}
                                                    dataSource={outwardPendingList || []}
                                                    pagination={false}
                                                    rowSelection={this.state.id ? '' : rowSelection}
                                                />
                                            )}
                                        </Form.Item> : ''}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {(purposeValue === 'Repair') ?
                                        <Form.Item label={'Inward Repair List'}>

                                            {getFieldDecorator('repairList', {
                                                rules: [{
                                                    required: true,
                                                    message: 'This is a Mandatory Field',
                                                }]
                                            })(
                                                <Table
                                                    scroll={{y: 350, x: 'max-content'}}
                                                    bordered={true}
                                                    rowKey={x => x._id}
                                                    columns={column}
                                                    dataSource={repairList || []}
                                                    pagination={false}
                                                    rowSelection={this.state.id ? '' : rowSelection}
                                                />
                                            )}
                                        </Form.Item> : ''}
                                </Col>
                            </Row>

                            <Row> <Col span={24}>
                                {<Table
                                    bordered={true}
                                    rowKey={x => x.productName}
                                    columns={refColumns}
                                    dataSource={this.state.totalData || []}
                                    pagination={false}
                                />}

                            </Col>
                            </Row>
                            <Form.Item style={{marginTop: 32, textAlign: 'center'}}>

                                <Button size="large" type='primary'
                                        loading={this.props.loading}
                                        htmlType='submit'
                                        style={{width: '15%', marginLeft: '1em'}}>{id ? `Update` : `Save`}
                                </Button>


                            </Form.Item>
                        </Card>
                    </Spin>


                </Form>
                <Drawer
                    title=""
                    placement="right"
                    closable={true}
                    onClose={() => {
                        this.setState({
                            visible: false,
                        })
                    }}
                    visible={this.state.visible}
                    width={600}>
                    <Card className={styles.drawerCard}>

                        <table style={{width: '100%'}} className={styles.ProcessTable}>
                            <thead>
                            <tr>
                                <th>Product Name</th>
                                <th>Product Ids</th>
                                <th>Quantity Recieved</th>
                                <th>Quantity Accepted</th>
                                <th>Quantity Rejected</th>
                            </tr>
                            </thead>

                            <tbody> {_.map(singleRecord && singleRecord.products, (item) => {
                                return <tr>
                                    <td colSpan="1">{item && item.productName}</td>
                                    <td>{item && item.productIds.join(' , ')}</td>
                                    <td colSpan="1">{item && item.quantityRecieved}</td>
                                    <td colSpan="1"> {item && item.quantityAccepted}</td>
                                    <td colSpan="1">{item && item.quantityRejected}</td>

                                </tr>
                            })}</tbody>


                        </table>

                    </Card>

                </Drawer>

            </PageHeaderWrapper>
        )
    }
}

const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search,
    currentUser: global.currentUser

})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddOutwardForm)
