import React, {PureComponent} from 'react'
import PageHeaderWrapper from '../../components/PageHeaderWrapper'
import {
    Form,
    Button, Table,
    Card, Select, AutoComplete, Tag,
    Icon
} from 'antd'
import _ from 'lodash'
import async from 'async'
import moment from 'moment'
import {FormUtils as GetAllFormFields} from 'sz-react-utils'
import 'react-quill/dist/quill.snow.css'
import {notification} from 'antd/lib/index'
import {hideLoader, showLoader} from '../../modules/actions'
import Request from '../../request'
import {API_URL} from '../../request'
import {connect} from 'react-redux';
import {followUpTime} from './helpers';
import styles from './styles.less'

const {Option} = Select;

@Form.create()
class AddFollowup extends PureComponent {

    handleSubmit = e => {
        const {dispatch, form, handleAddFollowup, leadid, fromCalendar, followUpDate} = this.props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let values = _.clone(valData);
                values.followUpTime = this.state.followUpTime;
                if (followUpDate) {
                    values.followUpDate = followUpDate;
                }
                dispatch(showLoader())
                if (fromCalendar) {
                    values.leadId = this.state.leadid;
                } else {
                    if (!leadid) {
                        notification.error({
                            message: "Lead doesn't exist."
                        })
                        dispatch(hideLoader());
                        return;
                    }
                    values.leadId = leadid._id;
                }
                let x = null
                x = await Request.addfollowup(values)

                dispatch(hideLoader())

                if (!x.error) {
                    notification.success({
                        message: x.message
                    })
                    if (handleAddFollowup) {
                        handleAddFollowup()
                    }
                    this.props.form.resetFields()

                } else {
                    notification.error({
                        message: 'Error Saving',
                        description: x.message
                    })
                }
            }
        })
    }

    constructor(props) {
        super(props)
        this.state = {
            allLeads: [], followUpTime: '',
            leadNames: [],
            leadNamesFiltered: [],
        }
    }
    getTimeOptions = (date) => {
        let afterDate = date || moment()
        console.log(afterDate, "afafafafafaf", date)
        let timeOptionsArr = []
        if (afterDate.format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')) {
            async.forEach(followUpTime, (doc, cb) => {

                let compareTime = moment(doc, ['h:mma'])

                if (moment().add('hour', 1).isBefore(compareTime)) {
                    timeOptionsArr.push(doc)
                }
                cb()
            }, () => {
                this.setState({
                    timeOptionsArr
                })
            })
        } else {
            this.setState({
                timeOptionsArr: followUpTime
            })
        }
    }


    async componentDidMount() {
        let currentUser = JSON.parse(window.localStorage.getItem('user'))
        // let { data } = await Request.getAllLeads({results:10000000});
        // console.log("all leads data is", data);
        // this.setState({...this.state, allLeads:data});
        // console.log(this.state);
        this.getTimeOptions()
        Request.getAllLeads(currentUser && currentUser.userType === 'RO' ? {
            officer: currentUser._id,
            status: "Open",
            results: 1000000
        } : {results: 1000000}).then(({data: leadNames}) => {
            this.setState({leadNames: leadNames || [], leadNamesFiltered: leadNames || []})
        })
    }

    render() {
        const {leadid} = this.props
        const {leadNamesFiltered,timeOptionsArr} = this.state

        const {submitting, dispatch, hideTitle, fromCalendar} = this.props
        const {
            form: {getFieldDecorator, getFieldValue}
        } = this.props

        const {editorState, followsData} = this.state;
        const inputTypes = {
            fields: [
                // {
                //   label: 'FollowUp Date',
                //   key: 'followUpDate',
                //   type: 'date',
                //   required: true,
                //   rightComp: <Select
                //   style={{
                //     width: 125,
                //     marginLeft: '3px', marginBottom: "13px"
                //   }}
                //   allowClear
                //   placeholder="Select Time"
                //   onChange={time => {
                //     this.setState({ followUpTime: time }, () => {
                //       // this.table.current.reload()
                //     })
                //   }}
                // >
                //   {followUpTime &&
                //     followUpTime.map((val, idn) => {
                //       return (
                //         <Option key={idn} value={val}>
                //           {val}
                //         </Option>
                //       )
                //     })}
                // </Select>


                // },
                {
                    label: 'Description',
                    key: 'description',
                    type: 'textArea',
                    rows: 10,
                    required: true
                },
                // {
                //   label: 'Status',
                //   key: 'status',
                //   type: 'select',
                //   required: true,
                //   options: ['Started', 'Not Started', 'In Progress'],
                //   placeholder: 'Select status',
                //   onChange: v => {
                //     this.props.form.setFieldsValue({
                //       status: v
                //     })
                //   }
                // },

                // {
                //   label: 'Follow Ups Type',
                //   key: 'followuptype',
                //   type: 'select',
                //   required: true,
                //   options: ['Cold Sales Follow Up', 'Product Sale', 'Warm Sales Follow Up', 'Free Trial Follow Up'],
                //   placeholder: 'Select followUp Type',
                //   onChange: v => {
                //     this.props.form.setFieldsValue({
                //       followuptype: v
                //     })
                //   }
                // }

            ]
        }

        if (this.props.fromCalendar) {
            inputTypes.fields.unshift({
                label: 'FollowUp Time',
                key: 'followUpTime',
                type: 'select',
                required: true,
                options: followUpTime,
                //  onChange: v=>{  this.props.form.setFieldsValue({
                //   followUpTime: v
                //   })
                // }
                onChange: v => {
                    this.props.form.setFieldsValue({
                        followUpTime: v
                    })
                    this.setState({followUpTime: v})
                }

            })
        } else {
            inputTypes.fields.unshift({
                label: 'FollowUp Date',
                key: 'followUpDate',
                type: 'date',
                required: true,
                rightComp: <Select
                    style={{
                        width: 125,
                        marginLeft: '3px', marginBottom: "13px"
                    }}
                    allowClear
                    placeholder="Select Time"
                    onChange={time => {
                        this.setState({followUpTime: time}, () => {
                            // this.table.current.reload()
                        })
                    }}
                >
                    {timeOptionsArr &&
                    timeOptionsArr.map((val, idn) => {
                        return (
                            <Option key={idn} value={val}>
                                {val}
                            </Option>
                        )
                    })}
                </Select>,
                customProps: {
                    onChange: (val) => {
                        this.getTimeOptions(val)
                    },
                    disabledDate: (d) => !d || d.isBefore(moment().add(-1, 'day'))

                },



            },)
        }

        const handleDealsSearch = value => {
            var filteredNames = this.state.leadNames.filter((x) => {
                return x.title.toLowerCase().includes(value.toLowerCase())
            })
            this.setState({leadNamesFiltered: filteredNames})
        };

        const onDealSelect = (val) => {
            let selectedLead = this.state.leadNames.find((obj) => obj._id === val)
            if (selectedLead) {
                this.props.form.setFieldsValue({
                    dealName: selectedLead.title,
                })
                this.setState({isEdited: true})
            }
        }


        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
                md: {span: 6}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
                md: {span: 18}
            }
        }
        const submitFormLayout = {
            wrapperCol: {
                xs: {span: 24, offset: 0},
                sm: {span: 10, offset: 7},
                md: {span: 12, offset: 8}
            }
        }


        return (
            <div>
                <React.Fragment>

                    <Card bordered={true}>
                        <Form onSubmit={this.handleSubmit} hideRequiredMark style={{marginTop: 8}}>

                            <GetAllFormFields apiurl={API_URL} inputSchema={inputTypes} formItemLayout={formItemLayout}
                                              getFieldDecorator={getFieldDecorator}/>
                            {this.props.fromCalendar && <Form.Item
                                label='Lead Name'
                                {...formItemLayout}
                            >{getFieldDecorator('dealName')(
                                <AutoComplete
                                    autoComplete={'none'}
                                    onSelect={onDealSelect}
                                    onSearch={handleDealsSearch}
                                    placeholder={'Lead Name'}
                                    onChange={(val) => {
                                        this.setState({leadid: val})

                                    }}
                                >
                                    {leadNamesFiltered.map((val, item) => (
                                        <Option value={val._id}>
                                            <>
                                                <div><b>{`${val.title} (${val.id})`}</b></div>
                                                <div className={styles.autoCompleteList}
                                                     style={{marginTop: '0px'}}>{`${val.contact.email}`}</div>
                                                {/* <div className={styles.autoCompleteList}>{`${val.contact.mobile}`}</div> */}
                                                <div
                                                    className={styles.autoCompleteList}>{`${val.contact.firstName} ${val.contact.lastName} (${val.contact.mobile})`}</div>
                                                <div
                                                    className={styles.autoCompleteList}>{`Company: ${val.company || ''}`}</div>
                                                <Tag>{`${moment(val.createdAt).format('DD-MM-YYYY HH:mm')}`}</Tag>
                                                {/* <a style={{ color: '#0e87af' }} href={`/quote/edit?id=${val._id}`} title="View Quote" target="_blank">View Quote <Icon type='right' /></a> */}
                                            </>
                                        </Option>
                                    ))}
                                </AutoComplete>
                            )}
                            </Form.Item>
                            }

                            <Form.Item {...submitFormLayout} style={{marginTop: 32}}>
                                <Button type="primary" htmlType="submit" loading={this.props.loading}>
                                    SAVE
                                </Button>
                            </Form.Item>

                        </Form>
                    </Card>
                </React.Fragment>
            </div>

            //   <React.Fragment>{!hideTitle ? <PageHeaderWrapper>{form}</PageHeaderWrapper> : form}</React.Fragment>
        )
    }
}

const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    pathname: router.location.pathname
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddFollowup)
