export default {}

export const pageTitle = 'HT SALES CRM'
export const subTitle = 'Powered by Scizers.com'
//export const apiUrl = `http://kbn.scizers.com`
export const pdfUrl = 'https://htindiatech.com/quote/?quoteId='

let mainURL = 'http://localhost:8343'
// let mainURL = 'https://sales-r.ht-india.com'
if (process.env.NODE_ENV == 'production') {
  mainURL = 'https://sales-r.scizers.com'
}

console.log(process.env.REACT_APP_CUSTOM_NODE_ENV)

if (process.env.REACT_APP_CUSTOM_NODE_ENV == 'staging') {

  mainURL = 'https://devsales-r.ht-india.com'
}

export const apiUrl = mainURL

