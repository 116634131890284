import React, {Component} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {TableComp} from 'sz-react-utils'
import moment from 'moment'
import {
    Card,
    Select,


} from 'antd'
import Request from '../../../request'
import {connect} from 'react-redux'


const {Option} = Select

class ServiceCenters extends Component {

    constructor(props) {
        super(props)
        this.table = React.createRef()
        this.state = {visible: false, key: Date.now(), currentPage: 1}

    }


    apiRequest = (params) => {
        if (params.page) {
            this.state.currentPage = params.page
        }

        return new Promise(async (resolve) => {
            delete params.results
            delete params.count
            delete params.page


            let data = await Request.getAllServiceCenters({
                ...params
            })

            this.setState({count: data && data.data.length})
            resolve(data);
        })
    }


    async componentDidMount() {


    }

    render() {
        const {submitting, dispatch} = this.props
        const {userList, currentPage} = this.state;


        const columns = [
            {
                title: 'S.No',
                dataIndex: 'sno',
                width: 50,
                key: 'sno',
                render: (value, item, index) => (currentPage - 1) * 10 + index + 1
            },

            {
                title: 'Name',
                dataIndex: 'name',
                width: 180,
                searchTextName: 'name'
            }, {
                title: 'Mobile',
                dataIndex: 'mobile',
                width: 180,
                searchTextName: 'mobile'
            },
            {
                title: 'Company Name',
                dataIndex: 'companyName',
                width: 180,
                key: 'companyName',
                searchTextName: 'companyName',

            },


            {
                title: ' Address',
                searchTextName: 'state/city/pincode/address',
                key: 'stateData',
                render: (val, record) => {
                    let arr = []
                    if (record) {
                        if (record.address) {
                            arr.push(record.address)
                        }
                        if (record.state) {
                            arr.push(record.state)
                        }
                        if (record.city) {
                            arr.push(record.city)
                        }
                        if (record.pincode) {
                            arr.push(record.pincode)
                        }

                    }

                    return (<div>
                        {arr.join(', ')}
                    </div>)
                }
            }


        ]


        return (
            <PageHeaderWrapper
                title={this.state.count ? `Service Centers: ${this.state.count}` : `Service Centers: 0`}>

                <Card bordered={true}>
                    <TableComp ref={this.table}
                               columns={columns}
                               apiRequest={this.apiRequest}
                               pagination={{
                                   ...this.state.pagination,
                                   defaultPageSize: 10,
                                   pageSizeOptions: ['10', '25', '50', '100'],
                                   showSizeChanger: true,
                                   ...this.props.pagination
                               }}/>
                </Card>

            </PageHeaderWrapper>)

    }
}


const mapStateToProps = ({global}) => ({
    categories: global.categories,
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ServiceCenters)
