import React, { Component } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { TableComp, FormUtils as GetAllFormFields } from 'sz-react-utils'
import moment from 'moment'
import {
  Card,
  Tooltip,
  Popconfirm,
  notification,
  Button,
  Form,
  Drawer,
  Row,
  Col,
  Tag,
  Select,
  Tabs,
  Modal,
} from 'antd'
import Request, { API_URL } from '../../../request'
import _ from 'lodash'
import { connect } from 'react-redux'
import { getUrlPushWrapper } from '../../../routes'
import styles from './styles.less'
import InwardComp from '../add/inwardListComponent'

const Option = { Select }
const { TabPane } = Tabs

@Form.create()
class ReplacementList extends Component {
  delete = async (data) => {
    this.setState({ loading: true })
    let x = await Request.deleteInwardOutward(data)
    this.setState({ loading: false })

    this.table.current.reload()
    if (!x.error) {
      notification.success({
        message: x.message,
        duration: 20,
        key: `${data._id}-close`,
      })
    } else {
      notification.error({
        message: x.message,
        duration: 20,
        key: `${data._id}-close`,
      })
    }
  }

  apiRequest = (params) => {
    let { activeTab } = this.state
    if (params.page) {
      this.state.currentPage = params.page
    }
    let currentUser = JSON.parse(window.localStorage.getItem('user'))

    if (currentUser && currentUser.userType === 'Dispatch') {
      params.filledBy = currentUser._id
    }
    if (!params.sortOrder) {
      params.sortField = 'createdAt'
      params.sortOrder = 'desc'
    }

    if (this.state.productName) {
      params['products.productName'] = this.state.productName
    }

    if (this.state.selectedProductId) {
      params['products.productIds'] = this.state.selectedProductId
    }
    let obj = {}
    params.purpose = 'Replacement'
    if (activeTab == '1') {
      params.replacementType = ['Already Inward', 'Already Outward']
      obj.replacementType = ['Already Inward', 'Already Outward']
    } else if (activeTab == '2') {
      params.replacementType = 'Inward Pending'
      obj.replacementType = 'Inward Pending'
    } else {
      params.replacementType = 'Outward Pending'
      obj.replacementType = 'Outward Pending'
    }

    this.setState({ filters: { ...params } })

    return new Promise(async (resolve) => {
      let data = await Request.inwardOutwardListDispatch({
        ...params,
        regExFilters: [
          'mobile',
          'name',
          'email',
          'challanNumber',
          'carrierDetails',
          'packingDoneBy',
          'consignee',
          'invoiceNumber',
        ],
      })

      if (activeTab === '1') {
        this.setState({ count: data.total })
      } else if (activeTab === '2') {
        this.setState({ inwardPendingCount: data.total })
      } else {
        this.setState({ outwardPendingCount: data.total })
      }

      Request.inwardOutwardProducts({ ...obj, purpose: 'Replacement' }).then(
        ({ data, productIds }) => {
          this.setState({
            productsData: data || [],
            productIds: productIds || [],
          })
        }
      )

      console.log(data, 'fkfkfkfkkfkkkkkkkkkkkkkkkkkkkkkkkkk')

      resolve(data)
    })
  }
  showDrawer = () => {
    this.setState({
      visible: true,
      key: Date.now(),
    })
  }
  showDrawer1 = () => {
    this.setState({
      visible1: true,
      key1: Date.now(),
    })
  }

  constructor(props) {
    super(props)
    this.table = React.createRef()
    this.table2 = React.createRef()
    this.table3 = React.createRef()

    this.state = {
      currentPage: 1,
      visible: false,
      visible1: false,
      modalVisible: false,
      userType: ['Inward', 'Outward'],
      productType: [
        'Finish Goods',
        'Packing Material',
        'Electronic Components',
        'Raw Material',
      ],
      purpose: ['Sale', 'Repair', 'Purchase', 'Replacement', 'Receiving'],
      carriersData: ['Fedex', 'DHL', 'UPS', 'USPS', 'BlueDart', 'DTDC'],
      fileDownloadLoader: [],
      activeTab: '1',
    }
  }

  exportData = async () => {
    console.log(this.state.filters)

    this.setState({ startLoading: true })
    let data = await Request.inwardOutwardListDispatch({
      ...this.state.filters,
      export: true,
      regExFilters: [
        'mobile',
        'name',
        'email',
        'challanNumber',
        'carrierDetails',
        'packingDoneBy',
        'consignee',
        'invoiceNumber',
      ],
    })
    this.setState({ startLoading: false })
    console.log(data.url, data)
    if (!data.error) {
      window.open(data.url)
    } else {
      return notification.error({ message: 'Error in Export File' })
    }
  }

  setLoaderForFile = (key, value) => {
    let { fileDownloadLoader } = this.state
    let newLoading = _.clone(fileDownloadLoader)
    newLoading[key] = value
    this.setState({ fileDownloadLoader: newLoading })
  }

  componentDidMount() {
    Request.loadUser({
      userType: ['RO', 'Admin', 'Dispatch', 'Technician'],
      results: 200,
    }).then(({ data: userList }) => {
      this.setState({ userList: userList || [] })
    })
  }

  render() {
    const { dispatch } = this.props

    let {
      singleRecord,
      userType,
      currentPage,
      userList,
      productsData,
      purpose,
      productIds,
      activeTab,
      count,
      modalVisible,
      modalKey,
      outwardPendingCount,
      inwardPendingCount,
    } = this.state

    const RofilterArray =
      userList &&
      userList.map((user) => {
        return {
          text: user.name,
          value: user._id,
        }
      })

    const columns = [
      {
        title: 'S.No',
        dataIndex: 'sno',
        width: 50,
        key: 'sno',
        fixed: 'left',
        render: (value, item, index) => (currentPage - 1) * 10 + index + 1,
      },
      {
        title: 'Id',
        dataIndex: 'serialNumber',
        width: 50,
        fixed: 'left',
        key: 'serialNumber',
        searchTextName: 'serialNumber',
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        fixed: 'left',
        filters: userType && userType.map((x) => ({ value: x, text: x })),
        render: (val) => {
          return <Tag color={val === 'Inward' ? 'blue' : 'red'}>{val}</Tag>
        },
      },
      {
        title: 'Products',
        dataIndex: 'products',
        key: 'products',
        width: 200,
        render: (val, record, index) => {
          return (
            <React.Fragment>
              <div>
                <ul style={{ paddingLeft: '0px', listStyle: 'none' }}>
                  {val &&
                    val.length &&
                    val.map((item, key) => {
                      return <li key={key}>{item.productName}</li>
                    })}
                </ul>
              </div>
            </React.Fragment>
          )
        },
      },
      {
        title: 'Purpose',
        dataIndex: 'purpose',
        key: 'purpose',
        filters: purpose && purpose.map((x) => ({ value: x, text: x })),
      },
      {
        title: 'Challan Number',
        dataIndex: 'challanNumber',
        key: 'challanNumber',
        searchTextName: 'challanNumber',
      },
      {
        title: 'Replacement Type',
        dataIndex: 'replacementType',
        key: 'replacementType',
        render: (val) => {
          return <Tag>{val}</Tag>
        },
      },
      {
        title: 'Order Authority',
        dataIndex: 'orderAuthority',
        key: 'orderAuthority',
        filters: RofilterArray,
        render: (val, record) => {
          return (
            <div>
              <div>
                {record.orderAuthority ? record.orderAuthority.name : ''}
              </div>
            </div>
          )
        },
      },

      {
        title: 'Consignee Name',
        dataIndex: 'consignee',
        width: 150,
        key: 'consignee',
        searchTextName: 'consignee',
      },
      {
        title: 'Packing Done By',
        dataIndex: 'packingDoneBy',
        key: 'packingDoneBy',
        searchTextName: 'packingDoneBy',
      },

      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        searchDateName: 'date',
        render: (text, record) => {
          return <Tag>{text ? moment(text).format('lll') : ''}</Tag>
        },
        sorter: true,
      },
      {
        title: 'Created At',
        dataIndex: 'createdAt',
        key: 'createdAt',
        searchDateName: 'createdAt',
        render: (text, record) => {
          return <Tag>{text ? moment(text).format('lll') : ''}</Tag>
        },
        sorter: true,
      },
      {
        key: 'actions',
        title: 'Actions',
        fixed: 'right',
        width: 100,
        render: (val, record, key) => {
          return (
            <React.Fragment>
              <Tooltip title="Edit Dispatch">
                <Button
                  shape="circle"
                  style={{ marginRight: 6 }}
                  size="small"
                  onClick={() => {
                    if (record && record.type === 'Inward') {
                      dispatch(
                        getUrlPushWrapper('dispatch.editInwardForm', {
                          id: record._id,
                        })
                      )
                    } else {
                      dispatch(
                        getUrlPushWrapper('dispatch.editOutwardForm', {
                          id: record._id,
                        })
                      )
                    }
                  }}
                  icon="edit"
                />
              </Tooltip>
              <Tooltip title="Delete">
                <Popconfirm
                  title="Are you sure to delete?"
                  onConfirm={() => {
                    this.delete({ _id: record._id })
                  }}
                  onCancel={() => {
                    console.log()
                  }}
                  okText="Yes"
                  cancelText="No">
                  <Button
                    style={{ marginRight: '6px' }}
                    shape="circle"
                    size="small"
                    icon="delete"
                  />
                </Popconfirm>
              </Tooltip>
              <Tooltip title="View Products">
                <Button
                  style={{ marginRight: '6px', marginTop: '6px' }}
                  size="small"
                  shape="circle"
                  onClick={() => {
                    this.setState(
                      {
                        singleRecord: record,
                      },
                      () => {
                        this.showDrawer()
                      }
                    )
                  }}
                  icon="eye"
                />
              </Tooltip>

              {
                record &&
                (record.replacementType === 'Already Inward' ||
                  record.replacementType === 'Already Outward') ? (
                  <Tooltip title="View Replacement">
                    <Button
                      style={{ marginRight: '6px', marginTop: '6px' }}
                      size="small"
                      shape="circle"
                      onClick={() => {
                        this.setState(
                          {
                            singleRecord: record,
                          },
                          () => {
                            this.showDrawer1()
                          }
                        )
                      }}
                      icon="eye"
                    />
                  </Tooltip>
                ) : (
                  ''
                )
                // <Tooltip title="Add Replacement">
                //     <Button
                //         style={{marginRight: '6px', marginTop: '6px'}}
                //         size="small"
                //         shape="circle"
                //         onClick={() => {
                //             this.setState({
                //                 modalVisible: true,
                //                 modalKey: Date.now(),
                //                 inwardId: record && record._id,
                //                 type: record && record.type
                //             })
                //         }}
                //         icon="plus"
                //     />
                // </Tooltip>
              }
            </React.Fragment>
          )
        },
      },
    ]
    return (
      <PageHeaderWrapper title={'Replacement List'}>
        <Card>
          <Row>
            <Col span={6}>
              <h4 style={{ display: 'inline-block', marginRight: '10px' }}>
                Search Product
              </h4>
              <Select
                showSearch
                allowClear
                optionFilterProp="children"
                style={{
                  width: 225,
                }}
                placeholder="Search Product"
                onChange={(productName) => {
                  this.setState({ productName: productName }, () => {
                    if (activeTab === '1') {
                      this.table2.current.reload()
                    } else if (activeTab === '2') {
                      this.table.current.reload()
                    } else {
                      this.table3.current.reload()
                    }
                  })
                }}>
                {productsData &&
                  productsData.map((val, idn) => {
                    return (
                      <Option key={idn} value={val}>
                        {val}
                      </Option>
                    )
                  })}
              </Select>
            </Col>
            <Col span={6}>
              <h4 style={{ display: 'inline-block', marginRight: '10px' }}>
                Search Product Id
              </h4>
              <Select
                showSearch
                allowClear
                optionFilterProp="children"
                style={{
                  width: 225,
                }}
                placeholder="Search Product"
                onChange={(selectedProductId) => {
                  this.setState(
                    { selectedProductId: selectedProductId },
                    () => {
                      if (activeTab === '1') {
                        this.table2.current.reload()
                      } else if (activeTab === '2') {
                        this.table.current.reload()
                      } else {
                        this.table3.current.reload()
                      }
                    }
                  )
                }}>
                {productIds &&
                  productIds.map((val, idn) => {
                    return (
                      <Option key={idn} value={val}>
                        {val}
                      </Option>
                    )
                  })}
              </Select>
            </Col>

            <Col span={12} style={{ textAlign: 'right' }}>
              <Button
                style={{ marginBottom: 10, marginLeft: 10 }}
                type="default"
                onClick={() => {
                  this.exportData()
                }}
                loading={this.state.startLoading}>
                Export Excel
              </Button>
            </Col>
          </Row>
        </Card>

        <Card>
          <Tabs
            activeKey={activeTab}
            onChange={(key) => {
              this.setState({ activeTab: key }, () => {
                console.log(key, 'key')
                if (key === '1') {
                  this.table2.current.reload()
                } else if (key === '2') {
                  this.table.current.reload()
                } else {
                  this.table3.current.reload()
                }
              })
            }}>
            <TabPane tab={`Replacement List (${count || 0})`} key="1">
              <Card>
                <TableComp
                  ref={this.table2}
                  columns={columns}
                  apiRequest={this.apiRequest}
                  extraProps={{ scroll: { x: 1600 } }}
                  pagination={{
                    ...this.state.pagination,
                    defaultPageSize: 10,
                    pageSizeOptions: ['10', '25', '50', '100'],
                    showSizeChanger: true,
                    ...this.props.pagination,
                  }}
                />
              </Card>
            </TabPane>

            <TabPane
              tab={`Replacement Inward Pending List (${
                inwardPendingCount || 0
              })`}
              key="2">
              <Card bordered={true}>
                <TableComp
                  ref={this.table}
                  columns={columns}
                  apiRequest={this.apiRequest}
                  extraProps={{ scroll: { x: 1600 } }}
                  pagination={{
                    ...this.state.pagination,
                    defaultPageSize: 10,
                    pageSizeOptions: ['10', '25', '50', '100'],
                    showSizeChanger: true,
                    ...this.props.pagination,
                  }}
                />
              </Card>
            </TabPane>
            <TabPane
              tab={`Replacement Outward Pending List (${
                outwardPendingCount || 0
              })`}
              key="3">
              <Card bordered={true}>
                <TableComp
                  ref={this.table3}
                  columns={columns}
                  apiRequest={this.apiRequest}
                  extraProps={{ scroll: { x: 1600 } }}
                  pagination={{
                    ...this.state.pagination,
                    defaultPageSize: 10,
                    pageSizeOptions: ['10', '25', '50', '100'],
                    showSizeChanger: true,
                    ...this.props.pagination,
                  }}
                />
              </Card>
            </TabPane>
          </Tabs>
        </Card>

        <Drawer
          placement="right"
          closable={true}
          onClose={() => {
            this.setState({
              visible: false,
            })
          }}
          visible={this.state.visible}
          width={600}>
          <Card className={styles.drawerCard}>
            <table style={{ width: '100%' }} className={styles.ProcessTable}>
              {singleRecord && singleRecord.type === 'Inward' ? (
                <thead>
                  <tr>
                    <th>Product Name</th>
                    <th>Product Ids</th>
                    <th>Quantity Recieved</th>
                    <th>Quantity Accepted</th>
                    <th>Quantity Rejected</th>
                  </tr>
                </thead>
              ) : (
                <thead>
                  <tr>
                    <th>Product Name</th>
                    <th>Quantity</th>
                    <th>Product Ids</th>
                  </tr>
                </thead>
              )}

              <tbody>
                {' '}
                {_.map(singleRecord && singleRecord.products, (item) => {
                  if (singleRecord && singleRecord.type === 'Inward') {
                    return (
                      <tr>
                        <td colSpan="1">{item && item.productName}</td>
                        <td>{item && item.productIds.join(' , ')}</td>
                        <td colSpan="1">{item && item.quantityRecieved}</td>
                        <td colSpan="1"> {item && item.quantityAccepted}</td>
                        <td colSpan="1">{item && item.quantityRejected}</td>
                      </tr>
                    )
                  } else {
                    return (
                      <tr>
                        <td colSpan="1">{item && item.productName}</td>
                        <td colSpan="1">{item && item.quantity}</td>
                        <td>{item && item.productIds.join(' , ')}</td>
                      </tr>
                    )
                  }
                })}
              </tbody>
            </table>
          </Card>
        </Drawer>

        <Drawer
          title={'View Replacement'}
          placement="right"
          closable={true}
          onClose={() => {
            this.setState({
              visible1: false,
            })
          }}
          visible={this.state.visible1}
          width={1000}>
          <Card className={styles.drawerCard}>
            <table style={{ width: '100%' }} className={styles.ProcessTable}>
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Carrier</th>
                  <th>Department</th>
                  <th>Challan Number</th>
                  <th>Created At</th>
                </tr>
              </thead>

              <tbody>
                {_.map(singleRecord && singleRecord.replacementList, (item) => {
                  return (
                    <tr>
                      <td colSpan="1">{item && item.serialNumber}</td>
                      <td colSpan="1">{item && item.carrier}</td>
                      <td colSpan="1"> {item && item.department}</td>
                      <td colSpan="1">{item && item.challanNumber}</td>
                      <td colSpan="1">
                        {item && moment(item.createdAt).format('lll')}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </Card>
        </Drawer>
      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global }) => ({
  categories: global.categories,
  loading: global.buttonLoading,
})
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReplacementList)
