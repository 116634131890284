import React, {Component} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {TableComp} from 'sz-react-utils'
import moment from 'moment'
import {
    Modal,
    Card,
    Button, Tooltip,
    Select,
    Descriptions

} from 'antd'
import Request from '../../../request'
import _ from 'lodash'
import styles from '../../lead/all/styles.less'
import {connect} from 'react-redux'
import {getUrlPushWrapper} from '../../../routes'
import {apiUrl} from "../../../settings";

const {Option} = Select

class SMS extends Component {

    constructor(props) {
        super(props)
        this.table = React.createRef()
        this.state = {visible: false, key: Date.now(), currentPage: 1}

    }


    apiRequest = (params) => {
        if (params.page) {
            this.state.currentPage = params.page
        }
        let user = {...JSON.parse(localStorage.getItem('user'))};
        return new Promise(async (resolve) => {

            let data = await Request.getAllCommunicationSMS({
                ...params, regExFilters: ['message']
            })

            console.log(data, "smsData")
            this.setState({count: data.total})
            resolve(data);
        })


    }
    showModal = () => {
        this.setState({
            visible: true
        })
    }

    async componentDidMount() {

    }

    render() {
        const {submitting, dispatch} = this.props
        const {visible, singleSMS, currentPage} = this.state;


        const columns = [
            {
                title: 'S.No',
                dataIndex: 'sno',
                width: 50,
                key: 'sno',
                render: (value, item, index) => (currentPage - 1) * 10 + index + 1
            },

            {
                title: 'Message',
                dataIndex: 'message',
                key: 'message',
                width: 80,
                render: (item, record) => (
                    <React.Fragment>
                        <Tooltip title={'View'}>
                            <Button icon={'eye'}
                                    onClick={() => {
                                        this.setState({singleSMS: record}, () => {
                                            this.showModal()
                                        })

                                    }}>View Message</Button>
                        </Tooltip>

                    </React.Fragment>
                )
            },

            {
                title: 'User',
                dataIndex: 'user',
                key: 'user',
                width: 80
            },
            {
                title: 'To',
                dataIndex: 'textNumber',
                key: 'textNumber',
                searchTextName: 'textNumber',
                width: 100,
                render: (val, record) => {
                    return (<div>
                        <div>{record && record.contactName ? record.contactName :
                            <span style={{color: 'red'}}>NO CONTACT</span>}</div>
                        <div>

                            {record && record.textNumber}

                        </div>
                        <div>{record && record.contactEmail}</div>

                    </div>)
                }
            },

            {
                title: 'Date',
                width: 120,
                key: 'date',
                dataIndex: 'date',
                sorter: true,
                searchDateName: 'date',
                render: (text, record) => {
                    return (
                        <div>
                            {text ? moment(text).format('lll') : ''}
                        </div>
                    )
                }
            }

        ]


        return (
            <PageHeaderWrapper
                title={this.state.count ? `All SMS: ${this.state.count}` : `All SMS: 0`}>

                <Card bordered={true}>

                    <TableComp ref={this.table}
                               columns={columns}
                               apiRequest={this.apiRequest}
                               pagination={{
                                   ...this.state.pagination,
                                   defaultPageSize: 10,
                                   pageSizeOptions: ['10', '25', '50', '100'],
                                   showSizeChanger: true,
                                   ...this.props.pagination
                               }}/>
                </Card>


                <Modal
                    visible={visible}
                    title="Sms"
                    onCancel={() => this.setState({visible: false})}
                    cancelButtonProps={{style: {display: 'none'}}}
                    okButtonProps={{style: {display: 'none'}}}
                >
                    <Descriptions>
                        <Descriptions.Item className={styles.antddescription}><b style={{fontSize: "16px"}}>Receiver
                            :</b> <br/>
                            <div style={{
                                border: "1px solid #DAE0E2",
                                margin: "5px",
                                backgroundColor: "#DAE0E2",
                                width: "100%",
                                padding: "3px"
                            }}>{singleSMS && singleSMS.textNumber}<br/></div>

                            <b style={{fontSize: "16px"}}>Text :</b><br/>
                            <div style={{
                                border: "1px solid #DAE0E2",
                                margin: "5px",
                                backgroundColor: "#DAE0E2",
                                width: "100%",
                                padding: "3px"
                            }}>{singleSMS && singleSMS.message}<br/></div>

                        </Descriptions.Item>
                    </Descriptions>
                </Modal>


            </PageHeaderWrapper>)

    }
}


const mapStateToProps = ({global}) => ({
    categories: global.categories,
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SMS)
