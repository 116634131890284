import React, { Component } from 'react'
import PageHeaderWrapper from '../../components/PageHeaderWrapper'
import { TableComp } from 'sz-react-utils'
import moment from 'moment'
import {
    Table,
    Badge,
    Card,
    Spin,
    Tooltip,
    Input, Button, Icon
} from 'antd'
import Request from '../../request'
import _ from 'lodash'
import styles from './styles.less'
import { connect } from 'react-redux'
import { hideLoader, showLoader } from '../../modules/actions';
import { Calendar } from 'antd';
import { addFollowUpModal, followUpModal } from './helpers'

class FollowupCalendar extends Component {

    // apiRequest = (params) => {
    //     let user = { ...JSON.parse(localStorage.getItem('user')) };
    //     if (user.userType == 'RO') {
    //         params.populateOfficer = { officer: user._id }
    //         return new Promise(async (resolve) => {
    //             let data = await Request.getAllFollowupsByLeadId({
    //                 ...params,
    //                 customQuery: { followUpDate: { $gte: this.state.startRange, $lte: this.state.lastRange }, active: true },
    //                 regExFilters: ['description']
    //             });
    //             this.setState({ count: data.total })
    //             resolve(data)
    //         })
    //     }
    //     else {
    //         return new Promise(async (resolve) => {

    //             let data = await Request.getAllFollowupsByLeadId({
    //                 ...params,
    //                 customQuery: { followUpDate: { $gte: this.state.startRange, $lte: this.state.lastRange }, active: true },
    //                 regExFilters: ['description']
    //             });
    //             this.setState({ count: data.total })
    //             resolve(data)
    //         })
    //     }
    // }


    constructor(props) {
        super(props)
        this.table = React.createRef()
        this.state = {
            visible: false,
            startRange: moment('1990-01-01').format('YYYY-MM-DD'),
            lastRange: moment().endOf('day').format('YYYY-MM-DDTHH:MM:ss'),
            leadId: {},
            currentFollowup: {},
            loader:true,
            addFollowup:false,
            listClicked: false

        }
        this.getCalendarData = this.getCalendarData.bind(this);
    }

    async componentDidMount() {
       this.getCalendarData();
    }
     getCalendarData = async() =>{
        let params = {};
        let user = { ...JSON.parse(localStorage.getItem('user')) };
        if (user.userType == 'RO') {
            params.populateOfficer = { officer: user._id }
            params.calendarView = true;
            let data = await Request.getAllFollowupsByLeadId({
                ...params,
                customQuery: { active: true, },
            });
            console.log("followup data is", data)
            this.setState({ count: data.total, followUps: data.data,loader:false })

        }
    }

    

    render() {
        const colors = [
            'pink',
            'red',
            'yellow',
            'orange',
            'cyan',
            'green',
            'blue',
            'purple',
            'geekblue',
            'magenta',
            'volcano',
            'gold',
            'lime',
          ];
          const getBadgeColor = () =>{
            var randomItem = colors[Math.floor(Math.random()*colors.length)];
            return randomItem
          }
        const { submitting, dispatch, currentUser } = this.props;
        console.log("this.stte.followUps is", this.state.followUps)
        const setFollowupModal = (item) => {
            this.setState({
                visible: true,
                currentFollowup: item
            })
        }
        

        const getFollowUpByDate = (value) => {
            let followup = this.state.followUps ? this.state.followUps.filter((ele) => {
                let startRange = moment(value).startOf('day');
                let endRange = moment(value).endOf('day');
                return moment(ele.followUpDate).isBetween(startRange, endRange);
            }) : [];
            return followup;
        }

        const onPanelChange = (value, mode) => {
            console.log(value.format('YYYY-MM-DD'), mode);
        }

        const dateCellRender = (value) => {
            let followUps = getFollowUpByDate(value);
            return (
                <ul className={styles.events}>
                    {followUps.map(item => (
                        <li onClick={() => {
                            console.log(item._id);
                            this.state.listClicked = true;
                            setFollowupModal(item);
                        }} key={item._id}>
                        {/* <Tooltip title="Tap to view"> */}
                            <a><Badge color={getBadgeColor()} text={item.description} /></a>
                            {/* </Tooltip> */}
                        </li>
                    ))}
                </ul>
            );

        }

        const onCancel = () => {
            this.setState({
                visible: false,
                addFollowup: false
            })
        }
         const onSelect = value => {
             console.log("selected value is", value);
             console.log("state is", this.state);
             this.state.followUpDate = value;
             if(moment().startOf('day').diff(moment(value).startOf('day')) > 0){
                    return;
             }
             if(!this.state.listClicked){
            this.setState({
                addFollowup: true,
            });
        }
          };
        const setListClicked=()=>{
            this.state.listClicked = false;
        }


        return (
            <Spin spinning={this.state.loader}><PageHeaderWrapper
                title={this.state.count ? `All FollowUp : ${this.state.count}` : `All FollowUp: 0`}>
                <Card bordered={true}>
                    <Calendar onSelect={onSelect}  dateCellRender={dateCellRender} onPanelChange={onPanelChange} />
                </Card>
                {this.state.visible && followUpModal({ onCancel, setListClicked, followup: this.state.currentFollowup, dispatch, state: { ...this.state } })}
                {this.state.addFollowup && addFollowUpModal({onCancel, getCalendarData: this.getCalendarData ,dispatch, state: { ...this.state } })}
            </PageHeaderWrapper> </Spin>)

    }
}


const mapStateToProps = ({ global }) => ({
    categories: global.categories,
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FollowupCalendar)
