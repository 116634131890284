import React, {PureComponent} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
    Form,
    Input,
    Spin,
    Button,
    Card,
    Row, Col, Checkbox, Upload, Icon,
    Select
} from 'antd'
import _ from 'lodash'
import moment from 'moment'
//import styles from './styles.less'

import {FormUtils as GetAllFormFields} from 'sz-react-utils'

import {API_URL} from '../../../request'

import 'react-quill/dist/quill.snow.css'
import {notification} from 'antd/lib/index'
import {hideLoader, showLoader} from '../../../modules/actions'
import Request from '../../../request'
import {connect} from 'react-redux'
import {createMatchSelector, goBack} from 'connected-react-router'

const {TextArea} = Input
const {Option} = Select


const Categories = ['Milk Testing Equipment', 'Glassware', 'IOT']


@Form.create()
class AddState extends PureComponent {

    handleSubmit = e => {
        const {dispatch, form} = this.props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let values = _.clone(valData)

                dispatch(showLoader())

                let x = null


                if (this.state.id) {
                    values._id = this.state.id
                    x = await Request.updateState(values)
                }
                else {
                    x = await Request.addState(values)

                }

                dispatch(hideLoader())

                if (!x.error) {

                    notification.success({
                        message: this.state.id
                            ? x.message
                            : x.message
                    })

                    form.resetFields()

                    this.props.form.setFieldsValue({})
                    if (this.state.id) {
                        dispatch(goBack())
                    }

                } else {
                    notification.error({
                        message: 'Error Saving',
                        description: x.message
                    })
                }

            }
        })
    }

    constructor(props) {
        super(props)
        this.state = {countryList: []}

    }

    async componentDidMount() {
        let {data: allCountry} = await Request.loadCountry();

        this.setState({countryList: allCountry})

    }

    render() {

        const {} = this.props
        const {
            form: {getFieldDecorator, getFieldValue}
        } = this.props

        let {countryList} = this.state
        const inputType = {
            fields: [
                {
                    label: 'Select Country',
                    key: 'countryId',
                    type: 'select',
                    showSearch: true,
                    required: true,
                    keyAccessor: x => x._id,
                    valueAccessor: x => x.countryName ? _.startCase(x.countryName) : '',
                    options: countryList || [],
                    onChange: async (countryId) => {
                        this.props.form.setFieldsValue({
                            countryId: countryId,

                        })

                    }

                },

                {
                    label: 'State Name',
                    key: 'stateName',
                    required: true

                }
            ]
        }


        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
                md: {span: 8}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
                md: {span: 12}
            }
        }
        const formItemLayout1 = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
                md: {span: 5}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
                md: {span: 12}
            }
        }


        return (
            <PageHeaderWrapper
                title={this.state.id ? 'Update State' : 'Add State'}
            >
                <Card bordered={true}>
                    <Form onSubmit={this.handleSubmit} hideRequiredMark={false} style={{marginTop: 8}}>

                        <Row>

                            <Col>
                                <GetAllFormFields apiurl={API_URL}
                                                  inputSchema={inputType}
                                                  formItemLayout={formItemLayout}
                                                  getFieldDecorator={getFieldDecorator}/>
                            </Col>


                        </Row>

                        <Form.Item style={{marginTop: 32, textAlign: 'center'}}>
                            <Button size="large" type="primary" htmlType="submit" loading={this.props.loading}
                                    style={{width: '15%'}}>
                                {this.state.id ? `UPDATE` : `SAVE`}

                            </Button>
                        </Form.Item>

                    </Form>
                </Card>
            </PageHeaderWrapper>
        )
    }
}

const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddState)
