import React, { PureComponent } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
    Form,
    Input,
    DatePicker,
    Select,
    Button,
    Card,
    Icon
} from 'antd'
import { ChromePicker } from 'react-color'
import _ from 'lodash'
import moment from 'moment'
import { FormUtils as GetAllFormFields } from 'sz-react-utils'
import { API_URL } from '../../../request'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { notification } from 'antd/lib/index'
import { hideLoader, showLoader } from '../../../modules/actions'
import Request from '../../../request'
import { connect } from 'react-redux'
import { createMatchSelector } from 'connected-react-router'
import StateData from '../../../states'

const FormItem = Form.Item
const { Option } = Select

const State = ['Andaman and Nicobar Islands',
    'Andhra Pradesh',
    'Arunachal Pradesh',
    'Assam',
    'Bihar',
    'Chandigarh',
    'Chhattisgarh', 'Dadra and Nagar Haveli', 'Daman and Diu', 'Delhi', 'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jammu', 'Jharkhand', 'Karnataka', 'Kashmir', 'Kerala', 'Ladakh', 'Lakshadweep', 'Madhya Pradesh', 'Maharashtra', 'Manipur', 'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha', 'Puducherry', 'Punjab', 'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura', 'Uttarakhand', 'Uttar Pradesh', 'West Bengal']


@Form.create()
class AddLead extends PureComponent {

    handleSubmit = e => {
        const { dispatch, form } = this.props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let values = _.clone(valData)
                let dateRange = {
                    to: values.to,
                    from: values.from
                }

                values.dateRange = dateRange
                if (values.photos && values.photos.length) {
                    values.photos = _.map(values.photos, (item) => {
                        return item.response ? item.response : item
                    })
                }
                _.each(values.photos, (item) => {
                    item.order = values.order
                })


                dispatch(showLoader())

                let x = null
                if (this.state.id) {
                    values._id = this.state.id
                    x = await Request.updateAdvertisement(values)
                } else {
                    x = await Request.addAdvertisement(values)
                    this.props.form.resetFields()
                }

                dispatch(hideLoader())

                if (!x.error) {

                    notification.success({
                        message: this.state.id
                            ? x.message
                            : x.message
                    })

                    this.props.form.setFieldsValue({})

                } else {
                    notification.error({
                        message: 'Error Saving',
                        description: x.message
                    })
                }

            }
        })
    }

    setFormValues = async (slug) => {

    }

    constructor(props) {
        super(props)
        this.state = {
            major: ''
        }

    }

    async componentDidMount() {
        let searchParams = new URLSearchParams(this.props.search)

        let advertisementId = searchParams.get('id')
        if (advertisementId) {
            Request.getAdvertisementById({ _id: advertisementId }).then(({ data, error, message }) => {
                if (!error) {
                    this.setState({
                        id: advertisementId
                    })
                    _.each(data.photos, (item) => {
                        this.props.form.setFieldsValue({
                            order: item.order,
                        })
                    })
                    this.props.form.setFieldsValue({
                        position: data && data.position,
                        pageType: data && data.pageType,
                        from: data && moment(data.dateRange.from),
                        to: data && moment(data.dateRange.to),
                        clientName: data && data.clientName,
                        redirectUrl: data && data.redirectUrl,
                        photos: data && data.photos,

                    })
                } else {
                    notification.error({
                        message: 'Error Getting Data'
                    })
                }
            })
        }

    }

    render() {

        const { submitting } = this.props
        const {
            form: { getFieldDecorator, getFieldValue }
        } = this.props

        const { editorState } = this.state


        const inputTypes = {
            fields: [
                {
                    label: 'Lead Owner ',
                    key: 'leadOwner',
                    required: true,
                    type: 'select', options: ['Payal', "Poonam", "Anoop"],
                    onChange: v => {
                        this.props.form.setFieldsValue({ leadOwner: v })
                    }

                },
                {
                    label: 'Company',
                    key: 'company',
                    required: true,


                },
                {
                    label: 'Lead Name',
                    key: 'leadName',
                    required: true

                },
                {
                    label: 'Mobile',
                    key: 'mobile',
                    required: true
                },
                {
                    label: 'Email',
                    key: 'email',
                },
                {
                    label: 'Lead Source',
                    key: 'leadSource',
                    required: true
                },


                {
                    label: 'Lead Status ', key: 'leadStatus',
                    required: true,
                    type: 'select',
                    options: ['Contact In Future', 'Not Contacted', 'Attempted To Contacted',
                        'Contacted', 'Junk Lead', 'Lost Lead', 'Pre Qualified', 'Not Qualified'],
                    onChange: v => {
                        this.props.form.setFieldsValue({ leadStatus: v })
                    }

                },
                {
                    label: 'No Of Employees', key: 'noOfEmployees',
                    type: 'number',
                    required: true
                },
                {
                    label: 'Description', key: 'description',
                    required: true
                },
                // {
                //     label: 'Country', key: 'country',
                //     required: true
                // },
               
                {
                    label: 'State',
                    type: 'select',
                    key: 'state',
                    showSearch: true,
                    required: true,
                    keyAccessor: x => x,
                    valueAccessor: x => x,
                    options: State || [],
                    onChange: state => {
                        let city = _.filter(StateData, (item) => {
                            return item.state == state
                        })
                        this.setState({ cityList: city })
                        this.props.form.setFieldsValue({
                            state: state,
                            city: ''
                        })

                    }
                },
                {
                    label: 'City',
                    type: 'select',
                    key: 'city',
                    required: true,
                    showSearch: true,
                    disabled: this.state.cityList ? false : true,
                    keyAccessor: x => x.name,
                    valueAccessor: x => x.name,
                    options: this.state.cityList || [],
                    onChange: city => {
                        this.props.form.setFieldsValue({
                            city: city
                        })

                    }
                },

                {
                    label: 'Street', key: 'street',
                    required: true
                },
                {
                    label: 'Pincode', key: 'pincode',
                    required: true
                },
               
                {
                    label: 'Title', key: 'title',
                    required: true
                },
               

            ]
        }

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
                md: { span: 8 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
                md: { span: 12 }
            }
        }

        const submitFormLayout = {
            wrapperCol: {
                xs: { span: 24, offset: 0 },
                sm: { span: 10, offset: 7 },
                md: { span: 12, offset: 8 }
            }
        }

        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: { span: 24, offset: 0 },
                sm: { span: 20, offset: 4 }
            }
        }
        const handleChange = () => {

        }

        return (
            <PageHeaderWrapper
                title={this.state.id ? 'Update Lead' : 'Add Lead'}
            >

                <Card bordered={true}>
                    <Form onSubmit={this.handleSubmit} hideRequiredMark style={{ marginTop: 8 }}>

                        <GetAllFormFields apiurl={API_URL}
                            inputSchema={inputTypes} formItemLayout={formItemLayout}
                            getFieldDecorator={getFieldDecorator} />

                        {/* <Form.Item {...submitFormLayout} style={{ marginTop: 32 }}>
                            <Button type="primary" htmlType="submit" loading={this.props.loading}>
                                SAVE
                            </Button>
                        </Form.Item> */}

                    </Form>
                </Card>

            </PageHeaderWrapper>
        )
    }
}

const mapStateToProps = ({ global, router }) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddLead)
