import React, {PureComponent} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
    Form,
    Button,
    Card,
    Input,
    Tooltip,
    Popconfirm,
    Tag,
    Switch
} from 'antd'
import _ from 'lodash'
import {FormUtils as GetAllFormFields, TableComp} from 'sz-react-utils'
import {API_URL} from '../../../request'
import 'react-quill/dist/quill.snow.css'
import {notification} from 'antd/lib/index'
import {hideLoader, showLoader} from '../../../modules/actions'
import Request from '../../../request'
import {connect} from 'react-redux'
import {createMatchSelector} from 'connected-react-router'
import moment from "moment";

const {TextArea} = Input

@Form.create()
class AddHoliday extends PureComponent {


    handleSubmit = e => {
        const {dispatch, form} = this.props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let values = _.clone(valData)

                values.date = values.date.replace(/\n/g, '');

                let holidayData = values.date.split(',')

                holidayData = _.uniq(holidayData)

                holidayData = _.map(holidayData, (ii) => moment(ii, 'DD/MM/YYYY').format('YYYY-MM-DD'))

                if (holidayData.includes('Invalid date')) {
                    return notification.error({
                        message: 'Invalid Date'
                    })

                }
                dispatch(showLoader())

                console.log(holidayData)
                let x = await Request.addHoliday(holidayData)


                dispatch(hideLoader())


                if (!x.error) {

                    notification.success({
                        message: this.state.id
                            ? x.message
                            : x.message
                    })

                    if (!this.state.id) {
                        this.props.form.resetFields()
                        this.table.current.reload()


                    }


                } else {
                    notification.error({
                        message: 'Error Saving',
                        description: x.message
                    })
                }

            }
        })
    }


    constructor(props) {
        super(props)
        this.table = React.createRef()

        this.state = {}

    }

    async componentDidMount() {
        let searchParams = new URLSearchParams(this.props.search);
        let userId = searchParams.get('id')

    }


    apiRequest = (params) => {

        return new Promise(async (resolve) => {


            let data = await Request.getAllHolidays({
                ...params, regExFilters: []
            })

            console.log(data)
            this.setState({count: data.total})
            resolve(data);
        })
    }
    deleteHoliday = async data => {
        this.setState({loading: true})
        let x = await Request.deleteHoliday(data)
        this.setState({loading: false})

        this.table.current.reload()
        if (!x.error) {
            notification.success({
                message: x.message,
                duration: 20,
                key: `${data._id}-close`
            })

        }
        else {
            notification.error({
                message: x.message,
                duration: 20,
                key: `${data._id}-close`
            })
        }


    }

    render() {


        const {
            form: {getFieldDecorator, getFieldValue}
        } = this.props;


        const columns = [
            {
                title: 'Date',
                key: 'date',
                dataIndex: 'date',
                sorter: true,
                render: (text, record) => {
                    return (
                        <Tag> {text ? moment(text).format('DD-MM-YYYY') : ''}</Tag>
                    )
                }
            },
            {
                title: 'Created At',
                key: 'createdAt',
                dataIndex: 'createdAt',
                sorter: true,
                render: (text, record) => {
                    return (
                        <Tag> {text ? moment(text).format('lll') : ''}</Tag>
                    )
                }
            },
            {
                key: 'actions',
                title: 'Actions',
                render: (text, record) => {
                    return <React.Fragment>

                        <Tooltip title="Delete Holiday">
                            <Popconfirm
                                title="Are you sure to delete this Holiday?"
                                onConfirm={() => {
                                    this.deleteHoliday({_id: record._id})
                                }}
                                onCancel={() => {
                                    console.log()
                                }}
                                okText="Yes"
                                cancelText="No">
                                <Button
                                    shape="circle"
                                    size="small"

                                    icon="delete"
                                />
                            </Popconfirm>

                        </Tooltip>
                    </React.Fragment>
                }
            }


        ]


        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
                md: {span: 8}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
                md: {span: 12}
            }
        }

        const submitFormLayout = {
            wrapperCol: {
                xs: {span: 24, offset: 0},
                sm: {span: 10, offset: 7},
                md: {span: 12, offset: 8}
            }
        }


        return (
            <PageHeaderWrapper
                title={this.state.id ? 'Update Holiday' : 'Add Holiday'}
            >

                <Card bordered={true}>
                    <Form onSubmit={this.handleSubmit} hideRequiredMark>

                        <Form.Item  {...formItemLayout} label={'Add Holiday'}>
                            {getFieldDecorator('date', {
                                rules: [
                                    {
                                        required: true,
                                        message: `Please input Date`
                                    }
                                ]
                            })(<TextArea rows={5}>

                                </TextArea>)}
                        </Form.Item>

                        <Form.Item style={{textAlign: 'center'}}>
                            <Button size="large" type="primary" htmlType="submit" loading={this.props.loading}
                                    style={{width: '15%'}}>
                                ADD

                            </Button>
                        </Form.Item>


                    </Form>


                </Card>
                <Card bordered={true}>
                    <h3>{this.state.count ? `All Holidays: ${this.state.count}` : `All Holidays: 0`}</h3>

                    <TableComp ref={this.table}
                               columns={columns}
                               apiRequest={this.apiRequest}
                               pagination={{
                                   ...this.state.pagination,
                                   defaultPageSize: 10,
                                   pageSizeOptions: ['10', '25', '50', '100'],
                                   showSizeChanger: true,
                                   ...this.props.pagination
                               }}/>
                </Card>

            </PageHeaderWrapper>
        )
    }
}

const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddHoliday)
