import React from 'react'
import {
    Input, Select, DatePicker, Modal,
    Form, notification,Drawer
} from 'antd';
import moment from 'moment';
// import Request from '../../../request';
import { Timeline, Descriptions, Button,Icon} from 'antd';
// import ReactQuill from 'react-quill'
import styles from './styles.less';
import { getUrlPushWrapper } from '../../routes'
import AddFollowup from './addDirect'


export const followUpTime = [
    '08:00 AM',
    '08:15 AM',
    '08:30 AM',
    '08:45 AM',
    '09:00 AM',
    '09:15 AM',
    '09:30 AM',
    '09:45 AM',
    '10:00 AM',
    '10:15 AM',
    '10:30 AM',
    '10:45 AM',
    '11:00 AM',
    '11:15 AM',
    '11:30 AM',
    '11:45 AM',
    '12:00 PM',
    '12:15 PM',
    '12:30 PM',
    '12:45 PM',
    '01:00 PM',
    '01:15 PM',
    '01:30 PM',
    '01:45 PM',
    '02:00 PM',
    '02:15 PM',
    '02:30 PM',
    '02:45 PM',
    '03:00 PM',
    '03:15 PM',
    '03:30 PM',
    '03:45 PM',
    '04:00 PM',
    '04:15 PM',
    '04:30 PM',
    '04:45 PM',
    '05:00 PM',
    '05:15 PM',
    '05:30 PM',
    '05:45 PM',
    '06:00 PM',
    '06:15 PM',
    '06:30 PM',
    '06:45 PM',
    '07:00 PM',
    '07:15 PM',
    '07:30 PM',
    '07:45 PM',
    '08:00 PM',
    '08:15 PM',
    '08:30 PM',
    '08:45 PM',
    '09:00 PM',
    '09:15 PM',
    '09:30 PM',
    '09:45 PM',
    '10:00 PM',
];



export const followUpModal = (props) => {

    let user = { ...JSON.parse(localStorage.getItem('user')) };
    const { onCancel, updateTheLead, followup, setListClicked } = props;
    if(setListClicked){
        setListClicked();
    }
    return <Modal
        visible={props.state.visible}
        title="Followup"
        onCancel={onCancel}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
    >   
        <Button size="small" type="primary" ghost onClick={() => {
                  props.dispatch(getUrlPushWrapper('lead.viewLead', {
                    id: followup.leadId._id
                  }))
                }}><Icon type="link"/>View Lead</Button>
        <Descriptions>
            <Descriptions.Item className={styles.antddescription}>
                <b style={{ fontSize: "16px" }}><span>Lead : 
                </span></b> <br />

           <div style={{border:"1px solid #DAE0E2", margin:"5px", backgroundColor:"#DAE0E2", width:"100%", padding:"3px"}}>{followup.leadId.title}<br /></div>
            
                <b style={{ fontSize: "16px" }}>Date :</b> <br />
           <div style={{border:"1px solid #DAE0E2", margin:"5px", backgroundColor:"#DAE0E2", width:"100%", padding:"3px"}}>{moment(followup.followUpDate).format('ll')}<br /></div>
            
                <b style={{ fontSize: "16px" }}>Time :</b><br />
            <div style={{border:"1px solid #DAE0E2", margin:"5px", backgroundColor:"#DAE0E2", width:"100%", padding:"3px"}}>{followup.followUpTime}<br /></div>
                
                <b style={{ fontSize: "16px" }}> Description :</b>
                <div style={{border:"1px solid #DAE0E2", margin:"5px", backgroundColor:"#DAE0E2", width:"100%", padding:"3px"}} >{followup.description}</div></Descriptions.Item>
        </Descriptions>
    </Modal>

}
export const addFollowUpModal = (props) => {

        let user = { ...JSON.parse(localStorage.getItem('user')) };
        const { getFieldDecorator, onCancel, updateTheLead,state,getCalendarData } = props;
        
        // return <Modal
        //     visible={props.state.addFollowup}
        //     title="Add Followup for this day"
        //     okText="Add"
        //     cancelText="Cancel"
        //     onCancel={onCancel}
        //     cancelButtonProps={{ style: { display: 'none' } }}
        //     okButtonProps={{ style: { display: 'none' } }}
            
        // >
        //    <AddFollowup fromCalendar="true" />
        // </Modal>
       return <Drawer
        width={780}
        onCancel={onCancel}
        onClose={onCancel}
        visible={props.state.addFollowup}
        title={`Add Followup for ${moment(state.followUpDate).format('ll')}`}
        bodyStyle={{ paddingBottom: 80 }}>
        <AddFollowup fromCalendar="true" followUpDate={state.followUpDate}
          handleAddFollowup={() => {
            onCancel();
            getCalendarData();
          }}
        />
      </Drawer>
    
}