import React, {Component} from 'react'
import Board from 'react-trello'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {TableComp} from 'sz-react-utils'

import moment from 'moment'
import {
    Card,
    Tooltip,
    Popconfirm,
    notification,
    Modal,
    Switch,
    Avatar,
    Row,
    Col,
    Popover,
    AutoComplete,
    Spin,
    Tag,
    Input,
    Button,
    Icon,
    Select,
    Drawer,
    Form,
    Checkbox,
    Badge,
    Radio,
} from 'antd'
import Request, {getLeadStageColor, getStatusStyle, getLeadTypeColor} from '../../../request'
import _ from 'lodash'
import styles from './styles.less'
import {connect} from 'react-redux'
import {getUrlPushWrapper, getUrlParams} from '../../../routes'
import AddLead from '../add/modalIndex'
import UpdateLead from '../add/editLead'
import {Typography} from 'antd'
import {stateFilter} from '../../../states'
import {addLeadActivity} from './helpers' // CommonJs : require('react-on-screen').default

const {Paragraph} = Typography
const {TextArea} = Input
const {Option} = Select
let lanes = [
    {
        id: 'New (Untouched)',
        title: 'New (Untouched)',
        style: {width: 300, backgroundColor: '#f4f5f7', color: '#5243aa'},
        cardStyle: {maxWidth: 290},
        cards: [],
    },
    {
        id: 'Contacted',
        title: 'Contacted',
        style: {width: 300, backgroundColor: '#f4f5f7', color: '#2196f3'},
        cardStyle: {maxWidth: 290},
        cards: [],
    },
    {
        id: 'Qualified',
        title: 'Qualified',
        style: {width: 300, backgroundColor: '#f4f5f7', color: '#3f51b5'},
        cardStyle: {maxWidth: 290},
        cards: [],
    },
    {
        id: 'Proposal Presented',
        title: 'Proposal Presented',
        style: {width: 300, backgroundColor: '#f4f5f7', color: '#673ab7'},
        cardStyle: {maxWidth: 290},
        cards: [],
    },
    {
        id: 'In Negotiation',
        title: 'In Negotiation',
        style: {width: 300, backgroundColor: '#f4f5f7', color: '#9c27b0'},
        cardStyle: {maxWidth: 290},
        cards: [],
    },
]

class AllLeads extends Component {
    constructor(props) {
        super(props)
        this.table = React.createRef()
        const {dispatch} = props
        this.state = {
            leadStatuseArr: ['Open', 'won', 'Unqualified', 'Lost'],
            allContacts: [],
            showBoard: false,
            showTable: true,
            currentPage: 1,
            leadStage: [
                'New (Untouched)',
                'Contacted',
                'Proposal Presented',
                'Followup (New Untouched)',
                'Contacted Followup',
                'Overdue Followup',
                'Today Followup',
                'Qualified', 'In Negotiation'
            ],
            boardData: {lanes: []},
            key: Math.random(),
            RoList: [],
            mobileList: [],
            selectedRowKeys: [],
            selectedRO: undefined,
            msgBody: '',
            msgType: 'SEND',
            lockUnlockLead: [],
            statusKey: '',
            stageKey: '',
            transferLeads: false,
        }
        let searchParams = getUrlParams('countUrl', window.location.pathname)
        let statesLeadsUrl = getUrlParams(
            'statesLeadsUrl',
            window.location.pathname
        )
        let officerStatesUrl = getUrlParams(
            'officerStatesUrl',
            window.location.pathname
        )
        let statusUrl = getUrlParams('statusUrl', window.location.pathname)

        if (statusUrl) {
            let {value, gte, lte} = statusUrl
            let key = ''
            if (value === 'won' || value === 'Lost' || value === 'Unqualified') {
                this.state.status = value
                this.state.gte = gte
                this.state.lte = lte

                switch (value) {
                    case 'won':
                        key = 'wonDate'
                        break
                    case 'Lost':
                        key = 'lostDate'
                        break
                    case 'Unqualified':
                        key = 'unqualifiedDate'
                        break
                }

                this.state.statusKey = key
            }
            if (
                value === 'NewUntouched' ||
                value === 'Contacted' ||
                value === 'Proposal Presented' ||
                value === 'In Negotiation'
            ) {
                this.state.stage = value === 'NewUntouched' ? 'New (Untouched)' : value
                this.state.gte = gte
                this.state.lte = lte

                if (gte == 'null') {
                    this.state.lte = moment().endOf('day').toDate()
                    delete this.state.gte
                }

                switch (value) {
                    case 'NewUntouched':
                        key = 'newUntouchedDate'
                        break
                    case 'Contacted':
                        key = 'contactedDate'
                        break
                    case 'Proposal Presented':
                        key = 'proposalPresentedDate'
                        break
                    case 'In Negotiation':
                        key = 'inNegotiationDate'
                        break
                }
                this.state.stageKey = key
            }
        }

        if (searchParams) {
            let {value, gte, lte, officerId} = searchParams
            console.log(value, gte, lte, officerId)
            if (value && value !== '0') {
                let key = ''
                if (value === 'won' || value === 'Lost') {
                    this.state.status = value
                    this.state.gte = gte
                    this.state.lte = lte

                    switch (value) {
                        case 'won':
                            key = 'wonDate'
                            break
                        case 'Lost':
                            key = 'lostDate'
                            break
                    }

                    this.state.statusKey = key
                }
                if (
                    value === 'Contacted' ||
                    value === 'Proposal Presented' ||
                    value === 'In Negotiation'
                ) {
                    this.state.stage = value
                    this.state.gte = gte
                    this.state.lte = lte

                    switch (value) {
                        case 'Contacted':
                            key = 'contactedDate'
                            break
                        case 'Proposal Presented':
                            key = 'proposalPresentedDate'
                            break
                        case 'In Negotiation':
                            key = 'inNegotiationDate'
                            break
                    }
                    this.state.stageKey = key
                }
            }

            if (officerId) {
                this.state.officerId = officerId
                this.state.gte = gte
                this.state.lte = lte
            }
        }
        if (statesLeadsUrl) {
            let {gte, lte, value, stateName} = statesLeadsUrl
            let key = ''
            if (value === 'won' || value === 'Open' || value === 'Lost') {
                this.state.status = value
                switch (value) {
                    case 'won':
                        key = 'wonDate'
                        break
                    case 'Lost':
                        key = 'lostDate'
                        break
                    case 'Open':
                        key = 'createdAt'
                        break
                }
            }
            this.state.statusKey = key
            this.state.gte = gte
            this.state.lte = lte
            console.log(stateName)
            this.state.stateName = stateName
        }
        if (officerStatesUrl) {
            let {gte, lte, value, stateName, officerId} = officerStatesUrl
            if (value === 'won' || value === 'Open' || value === 'Lost') {
                this.state.status = value
            } else if (value === 'all') {
                this.state.status = ['won', 'Open', 'Lost']
            }
            if (officerId) {
                this.state.officerId = officerId
            }

            this.state.gte = gte
            this.state.lte = lte
            this.state.stateName = stateName
        }

        this.searchContact = _.debounce(this.handleContactSearch, 1000, true)
    }

    deleteLead = async (data) => {
        this.setState({loading: true})
        let x = await Request.deleteLead(data)
        this.setState({loading: false})

        this.table.current.reload()
        if (!x.error) {
            notification.success({
                message: x.message,
                duration: 20,
                key: `${data._id}-close`,
            })
        } else {
            notification.error({
                message: x.message,
                duration: 20,
                key: `${data._id}-close`,
            })
        }
    }

    copyContent = ''

    content = async (cardId) => {
        let x = await Request.getLeadById({_id: cardId})
        if (!x.error) {
            this.setState({
                leadData: x.data,
            })
            if (!x.data.contact) {
                //  return "No number found";
                this.copyContent = 'No number found'
            } else {
                // return <div>
                //   <Paragraph copyable>{x.data.contact.mobile}</Paragraph>
                // </div>
                this.copyContent = (
                    <div>
                        <Paragraph copyable>{x.data.contact.mobile}</Paragraph>
                    </div>
                )
            }
        }
    }

    apiRequest = (params) => {
        if (params.page) {
            this.state.currentPage = params.page
        }
        let {contactNameId} = this.state

        if (!params.stage || !params.stage.length) {
            params.stage = this.state.leadStage

        }

        if (this.state.selectedLeadStage) {
            params.stage = this.state.selectedLeadStage
        }


        if (!params.sortOrder) {
            params.sortField = 'createdAt'
            params.sortOrder = 'desc'
        }

        let user = {...JSON.parse(localStorage.getItem('user'))}
        let {status, statusKey, stageKey, stage, stateName} = this.state
        return new Promise(async (resolve) => {
            if (status && statusKey && !stateName) {
                params.status = status
                params[statusKey] = {
                    $gte: this.state.gte,
                    $lte: this.state.lte,
                }
            }
            if (stage && stageKey) {
                params.stage = stage
                params[stageKey] = {
                    $gte: this.state.gte,
                    $lte: this.state.lte,
                }
            }

            if (!params.status || !params.status.length) {
                params.status = ['Open', 'won', 'Lost', 'Unqualified']
            }

            if (stateName) {
                params.state = {
                    $in: [stateName.toLowerCase(), _.startCase(stateName)],
                }

                params.state =
                    stateName && stateName == 'Jammu Kashmir'
                        ? 'Jammu & Kashmir'
                        : stateName

                params[statusKey] = {
                    $gte: this.state.gte,
                    $lte: this.state.lte,
                }
                params.status = status
            }

            if (this.state.unassignedLeads) {
                params.officer = {$in: [null]}
            }
            if (params.results && params.page) {
                this.setState({lockUnlockLead: []})
            }

            if (user && user.userType == 'Manager' && !this.state.officerId) {
                if (!params.officer || !params.officer.length) {
                    params.officer = user && user.roList
                }
            }

            if (this.state.transferLeads) {
                params.isTransferred = true
            }

            this.setState({filters: {...params}})
            let data = await Request.getAllLeads({
                ...params,
                customQuery: {
                    contact: contactNameId ? contactNameId : undefined,
                    officer: this.state.officerId ? this.state.officerId : undefined,
                    $or: [{leadApprovedByAdmin: {$in: [true]}}, {leadApprovedByAdmin: {$exists: false}}]

                },
                regExFilters: ['title', 'company', 'contact'],
            })
            _.each(data.data, (item, i) => {
                if (item.isLocked) {
                    this.setLockUnlockLead(i, item.isLocked)
                }
            })
            resolve(data)
            this.setState({leadData: data, count: data.total})
        })
    }

    getTodos = async (stageArr) => {
        let newCards = []
        let {contactNameId} = this.state
        if (this.state.selectedLeadStage) {
            this.state.filters.stage = this.state.selectedLeadStage
        }

        let {data: data, total: totalCount} = await Request.getAllLeads({
            ...this.state.filters,
            customQuery: {
                contact: contactNameId ? contactNameId : undefined,
                officer: this.state.officerId ? this.state.officerId : undefined,
            },
        })

        this.setState({count: totalCount})

        _.each(data, (item, key) => {
            let found = _.find(lanes, (lane) => lane.title == item.stage)
            let description = (
                <div>
                    {item.contact &&
                        `${item.contact.firstName} ${
                            item.contact.lastName ? item.contact.lastName : ''
                        }`}
                    <br/>
                    {item.company}
                    <br/>
                    <div style={{display: 'block'}}>
            <span>
              <Tooltip title={'Send an Email'}>
                <Button
                    icon={'mail'}
                    type={'link'}
                    className={styles.leadbtn}
                />
              </Tooltip>
              <Tooltip title={'Number'}>
                {/* <Popover content={this.copyContent} trigger="click"> */}
                  <Button
                      style={{zIndex: 100}}
                      icon={'phone'}
                      type={'link'}
                      onClick={(e) => {
                          this.content(this.state.cardId)
                          this.setState({visible: false, updatelead: false})
                      }}
                      className={styles.leadbtn}
                  />
                  {/* </Popover> */}
              </Tooltip>
              <Tooltip style={{position: 'absolute'}} title={'Activity'}>
                <Button
                    icon={'info-circle'}
                    type={'link'}
                    className={styles.leadbtn}
                />
              </Tooltip>
              <Tooltip title={'View'}>
                <Button
                    icon={'eye'}
                    type={'link'}
                    onClick={() => {
                        this.props.dispatch(
                            getUrlPushWrapper('lead.viewLead', {
                                id: item._id,
                            })
                        )
                    }}
                    className={styles.leadbtn}
                />
              </Tooltip>
            </span>
                    </div>
                </div>
            )
            let label = (
                <div>
                    <Tooltip title={item.contact ? item.contact.firstName : ''}>
                        <Avatar
                            style={{
                                backgroundColor: '#03a9f4',
                            }}>
                            {item && item.contact && item.contact.firstName
                                ? item.contact.firstName.charAt(0).toUpperCase()
                                : '?'}
                        </Avatar>
                    </Tooltip>
                </div>
            )
            if (found) {
                let bb = {}
                bb.id = item._id.toString()
                bb.title = item.title
                bb.description = description
                bb.label = label
                found.cards.push(bb)
                newCards.push(bb)
                found.label = found.cards.length
            } else {
                let aa = {}
                aa.id = Math.random()
                aa.title = item.stage
                aa.style = {width: 280}
                aa.cards = [
                    {
                        id: item._id.toString(),
                        title: item.title,
                        description: description,
                        label: label,
                    },
                ]
                aa.label = aa.cards.length
                lanes.push(aa)
            }
        })

        let data1 = {}
        data1.lanes = lanes
        this.setState({boardData: data1})
    }

    showModal = () => {
        this.setState({
            visible: true,
            btnLoader: false,
        })
    }
    waSave = async () => {
        const {msgType, msgBody, selectedRows, selectedRowKeys, waMobile} =
            this.state
        console.log(msgBody)
        if (!msgBody) {
            return notification.error({message: 'Enter Message'})
        }
        if (!waMobile.length) {
            return notification.error({message: 'Select Number'})
        }
        let obj = {
            type: msgType,
            messageBody: msgBody,
            waMobile: waMobile
        }
        this.setState({waLoader: true})


        let {data, error, message} = await Request.waDetailSave(obj)

        this.setState({waLoader: false})

        if (!error) {
            this.setState({
                waMobile: [],
                whatsappData: false,
                selectedRows: undefined,
                msgBody: '',
                msgType: 'SEND',
                selectedRowKeys: [],
            })
            notification.success({message: message})
            this.table.current.reload()
        } else {
            notification.error({message: message})
        }
    }
    assignLeads = async () => {
        const {selectedRowKeys, selectedRO} = this.state
        if (selectedRowKeys.length === 0 || !selectedRO) {
            return
        }
        this.setState({assignLoader: true})
        let {data, error, message} = await Request.assignLeads(selectedRowKeys, {
            officer: selectedRO,
        })
        this.setState({assignLoader: false})

        if (!error) {
            let foundRO = _.find(this.state.RoList, (item) => {
                return item._id === selectedRO
            })
            if (foundRO) {
                _.each(selectedRowKeys, async (item) => {
                    await addLeadActivity({
                        values: {
                            message: `Lead assign to ${foundRO.name}`,
                            leadId: item,
                            user: this.props.currentUser.name,
                            type: 'info',
                        },
                    })
                })
            }

            notification.success({message: message})
            this.table.current.reload()
            this.setState({
                selectedRO: undefined,
                assignLeadDrawer: false,
                selectedRowKeys: [],
            })
        } else {
            notification.error({message: message})
        }
    }

    changeLeadStatus = async () => {
        const {selectedRowKeys, selectedStatus} = this.state
        if (selectedRowKeys.length === 0 || !selectedStatus) {
            return
        }
        this.setState({statusLoader: true})
        let {data, error, message} = await Request.updateMultipleLeadStatus(
            selectedRowKeys,
            {status: selectedStatus}
        )
        this.setState({statusLoader: false})

        if (!error) {
            notification.success({message: message})
            this.table.current.reload()
            this.setState({
                selectedStatus: undefined,
                statusChangeDrawer: false,
                selectedRowKeys: [],
            })
        } else {
            return notification.error({message: message})
        }
    }
    transferLeadToExistingRO = async () => {
        const {selectedRowKeys, selectedStatus} = this.state
        if (selectedRowKeys.length === 0) {
            return
        }
        this.setState({transferLoader: true})
        let {data, error, message} = await Request.transferLeadToExistingRO(
            selectedRowKeys
        )
        this.setState({transferLoader: false})

        if (!error) {
            notification.success({message: message})
            this.table.current.reload()
            this.setState({selectedRowKeys: []})
        } else {
            return notification.error({message: message})
        }
    }

    async componentDidMount() {
        this.props.dispatch({type: 'TOGGLE_SIDEBAR_COLLAPSED'})
        //        let {data: allContacts} = await Request.getAllContacts();
        //this.getTodos()
        // this.setState({allContacts, sale: 'Sales'})

        let user = {...JSON.parse(localStorage.getItem('user'))}

        if (user && user.userType == 'Manager') {
            let {data: allContacts} = await Request.getAllContacts({
                contactOwner: {$in: user.roList},
            })

            Request.loadUser(
                {_id: {$in: user.roList}},
                {results: 1000000000000}
            ).then(({data: userList}) => {
                this.setState({RoList: userList, allContacts})
            })
        } else {
            let {data: allContacts} = await Request.getAllContacts()

            let {data: userList} = await Request.getAllROList({
                customQuery: {userType: 'RO'},
            })
            this.setState({RoList: userList, allContacts})
        }
        this.getTodos()
    }


    handleContactSearch = async (test) => {
        console.log(test)
        let filter = {}
        // this.setState({​​​​​​​​showSpin: true}​​​​​​​​)
        this.setState({selectedContact: test})
        if (test == '') {
            console.log('empty')
            this.setState(
                {
                    allContacts: this.state.allContacts,
                    selectedContact: test,
                    contactNameId: null,
                },
                () => this.table.current.reload()
            )
            return false
        }

        let user = {...JSON.parse(localStorage.getItem('user'))}

        if (user && user.userType == 'Manager') {
            filter.roList = user && user.roList
        }

        let {data, error, message} = await Request.contactSearch({
            text: test,
            type: 'list',
            ...filter,
        })
        if (!error) {
            this.setState({allContacts: data}, () => {
                this.setState({showSpin: false})
            })
        }
    }

    onContactSelect = (val) => {
        let selectedContact = this.state.allContacts.find((obj) => obj._id === val)
        if (selectedContact) {
            this.setState(
                {
                    contactNameId: selectedContact._id,
                    selectedContact: `${selectedContact.firstName} (${selectedContact.mobile})`,
                },
                () => {
                    this.table.current && this.table.current.reload()
                }
            )
        }
    }

    setLockUnlockLead = (key, value, leadId) => {
        let {lockUnlockLead} = this.state
        let newValue = _.clone(lockUnlockLead)
        newValue[key] = value
        this.setState({lockUnlockLead: newValue}, () => {
            if (leadId) this.updateTheLead({_id: leadId, isLocked: value})
        })
    }

    updateTheLead = async (values) => {
        let x = await Request.updateLead(values)
        if (!x.error) {
            notification.success({
                message: x.message,
            })
        } else {
            console.log('err is', x.error)
            notification.error({
                message: 'Error Saving',
                description: x.message,
            })
        }
    }
    exportData = async () => {
        console.log(this.state.filters)
        let {contactNameId} = this.state


        if (this.state.selectedLeadStage) {
            this.filters.stage = this.state.selectedLeadStage
        }

        this.setState({startLoading: true})
        let data = await Request.getAllLeads({
            ...this.state.filters,
            export: true,
            customQuery: {
                contact: contactNameId ? contactNameId : undefined,
                officer: this.state.officerId ? this.state.officerId : undefined,
            },
        })
        this.setState({startLoading: false})
        if (!data.error) {
            return notification.success({message: 'Mail Sent'})
        } else {
            return notification.error({message: 'Error'})
        }

    }

    render() {
        const RofilterArray =
            this.state.RoList &&
            this.state.RoList.map((user) => {
                return {
                    text: user.name,
                    value: user._id,
                }
            })

        const {submitting, currentUser, dispatch} = this.props
        const {
            leadStatuseArr,
            allContacts,
            showTable,
            showBoard,
            leadStage,
            boardData,
            selectedRowKeys,
            RoList,
            assignLeadDrawer,
            whatsappData,
            selectedRO,
            msgBody,
            msgType,
            assignLoader,
            waLoader,
            currentPage,
            selectedStatus,
            waMobile,
            selectedRows,
            mobileList,
            waMessage,
            statusLoader,
        } = this.state

        const columns = [
            {
                title: 'S.No',
                dataIndex: 'sno',
                width: 50,
                key: 'sno',
                fixed: 'left',
                render: (value, item, index) => (currentPage - 1) * 10 + index + 1,
            },

            {
                key: 'actions',
                title: 'View',
                fixed: 'left',
                width: 50,
                render: (text, record) => {
                    return (
                        <React.Fragment>
                            <Tooltip title="View">
                                <a
                                    href={`/lead/detail?id=${record && record._id}`}
                                    target={'_blank'}>
                                    <Button
                                        style={{marginLeft: '5px'}}
                                        size="small"
                                        icon={'eye'}
                                        shape={'circle'}
                                    />
                                </a>
                            </Tooltip>
                        </React.Fragment>
                    )
                },
            },
            {
                title: 'Id',
                dataIndex: 'id',
                key: 'id',
                fixed: 'left',
                searchTextName: 'id',
                width: 100,
            },

            {
                title: 'Lead',
                dataIndex: 'title',
                key: 'title',
                searchTextName: 'title',
                render: (text, record) => (
                    <span>
            <a
                onClick={() => {
                    this.setState({
                        ...this.state,
                        cardId: record._id,
                        updatelead: true,
                    })
                }}>
              {record.title}
            </a>
          </span>
                ),
                // width: 150
            },

            {
                title: 'Contact',
                dataIndex: 'contact.firstName',
                key: 'contact.firstName',
                render: (text, record) => (
                    <span>
            <span>{record.contact ? record.contact.firstName : ''}</span>
            <br/>
            <span>{record.contact ? record.contact.mobile : ''}</span>
          </span>
                ),
                // width: 160
            },
            {
                title: 'Company',
                dataIndex: 'company',
                key: 'company',
                searchTextName: 'company',
                // width: 100
            },
            {
                title: 'Relationship Officer',
                dataIndex: 'officer',
                key: 'officer',
                filters: RofilterArray,
                render: (val, record) => {
                    return (
                        <div>
                            <div>{val && val.name}</div>
                        </div>
                    )
                },
                // width: 100
            },

            {
                title: 'Stage',
                //  dataIndex: 'stage',
                key: 'stage',
                render: (text, record) => {
                    return (
                        <div>
                            {record && record.stage ? (
                                <div
                                    className={styles.stageColor}
                                    style={{
                                        backgroundColor: getLeadStageColor(record.stage),
                                    }}>
                                    {record.stage}
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    )
                },
                filters: _.map(this.state.leadStage, (ii) => ({text: ii, value: ii}))
            },
            {
                title: 'Stage Date',
                key: 'contactedDate',
                width: 130,
                render: (text, record) => {
                    if (record && record.stage == 'Contacted') {
                        return (
                            <div>
                                {record && record.contactedDate
                                    ? moment(record.contactedDate).format('lll')
                                    : ''}
                            </div>
                        )
                    } else if (record && record.stage == 'Proposal Presented') {
                        return (
                            <div>
                                {record && record.proposalPresentedDate
                                    ? moment(record.proposalPresentedDate).format('lll')
                                    : ''}
                            </div>
                        )
                    } else if (record && record.stage == 'In Negotiation') {
                        return (
                            <div>
                                {record && record.inNegotiationDate
                                    ? moment(record.inNegotiationDate).format('lll')
                                    : ''}
                            </div>
                        )
                    } else if (record && record.stage == 'New (Untouched)') {
                        return (
                            <div>
                                {record && record.newUntouchedDate
                                    ? moment(record.newUntouchedDate).format('lll')
                                    : ''}
                            </div>
                        )
                    }
                },
            },
            {
                title: 'Lead Type',
                dataIndex: 'leadType',
                key: 'leadType',
                filters: [
                    {text: 'IndiaMART Api', value: 'IndiaMART Api'},
                    {text: 'Inbound', value: 'Inbound'},
                    {text: 'Aajjo', value: 'aajjo'},
                ],
                render: (val, record) => {
                    return (
                        <div>
                            {record.leadType ? (
                                <div style={getLeadTypeColor(record.leadType)}>
                                    {record.leadType}
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    )
                },
                // width: 100
            },
            {
                title: 'Total Call Logs',
                dataIndex: 'callLogs',
                key: 'callLogs',
                render: (val, record) => {
                    return <div>
                        <div>
                            <small>Outgoing: {val && ((val.outgoingCalls || 0) + (val.outgoingRejectCalls || 0))}</small>
                        </div>
                        <div><small>Incoming: {val && val.incomingCalls || 0}</small></div>
                        <div><small>Missed: {val && val.missedCalls || 0}</small></div>
                        <div><small>Attended: {val && val.totalAttended || 0}</small></div>
                    </div>
                }
                // width: 100
            },
            {
                title: 'State',
                dataIndex: 'state',
                key: 'state',
                filters: stateFilter,
                // width: 100
            },

            {
                title: 'Status',
                key: 'status',
                filters:
                    leadStatuseArr && leadStatuseArr.map((x) => ({value: x, text: x})),
                // width: 130,
                render: (text, record) => {
                    return (
                        <div>
                            {record.status ? (
                                <div style={getStatusStyle(record.status)}>{record.status}</div>
                            ) : (
                                ''
                            )}
                        </div>
                    )
                },
            },
            {
                title: 'Status Date',
                key: 'wonDate',
                width: 130,
                render: (text, record) => {
                    if (record && record.status == 'won') {
                        return (
                            <div>
                                {record && record.wonDate
                                    ? moment(record.wonDate).format('lll')
                                    : ''}
                            </div>
                        )
                    } else if (record && record.status == 'Lost') {
                        return (
                            <div>
                                {record && record.lostDate
                                    ? moment(record.lostDate).format('lll')
                                    : ''}
                            </div>
                        )
                    } else if (record && record.status == 'Unqualified') {
                        return (
                            <div>
                                {record && record.unqualifiedDate
                                    ? moment(record.unqualifiedDate).format('lll')
                                    : ''}
                            </div>
                        )
                    }
                },
            },
            {
                title: 'Tags',
                key: 'tags',
                // width: 120,
                render: (text, record) => {
                    if (record.tags && record.tags.length) {
                        return _.map(record.tags, (ii) => {
                            return <Tag style={{marginBottom: '2px'}}>{ii}</Tag>
                        })
                    }
                },
                filters: [{text: 'No FollowUp', value: 'No FollowUp'}],
            },

            {
                title: 'Latest Followup',
                dataIndex: 'latestFollowup',
                key: 'latestFollowup',
                render: (val, record) => {
                    return (
                        <div>
                            <div>
                                {record && record.description ? record.description : ''}
                            </div>
                            <div>
                                {record && record.overdueCount ? (
                                    <Tag
                                        style={{
                                            backgroundColor: 'red',
                                            color: 'white',
                                        }}>{`Overdue (${record.overdueCount})`}</Tag>
                                ) : (
                                    ''
                                )}
                            </div>
                            <div>
                                {record && record.followUpDate
                                    ? moment(record.followUpDate).format('ll')
                                    : ''}
                            </div>
                        </div>
                    )
                },
                width: 180,
            },

            {
                title: 'Created At',
                dataIndex: 'createdAt',
                key: 'createdAt',
                sorter: true,
                searchDateName: 'createdAt',
                render: (val, record) => {
                    return (
                        <div>
                            <div>{moment(record.createdAt).format('lll')}</div>
                        </div>
                    )
                },
                // width:100
            },
            {
                title: 'Updated At',
                dataIndex: 'updatedAt',
                key: 'updatedAt',
                sorter: true,
                searchDateName: 'updatedAt',
                render: (val, record) => {
                    return (
                        <div>
                            <div>{moment(record.updatedAt).format('lll')}</div>
                        </div>
                    )
                },
                // width:100
            },
            {
                title: 'Lead Received Date',
                dataIndex: 'leadReceivedDate',
                key: 'leadReceivedDate',
                searchDateName: 'leadReceivedDate',
                sorter: true,
                fixed: 'right',
                render: (val, record) => {
                    return (
                        <div>
                            <div>
                                {' '}
                                {record && record.leadReceivedDate
                                    ? moment(record.leadReceivedDate).format('lll')
                                    : ''}{' '}
                            </div>
                        </div>
                    )
                },
                width: 150,
            },
            {
                title: 'Lock/Unlock',
                dataIndex: 'isLocked',
                key: 'isLocked',
                fixed: 'right',
                filters: [
                    {text: 'Locked', value: true},
                    {text: 'UnLocked', value: false},
                ],

                render: (val, record, key) => {
                    return (
                        <Switch
                            checked={this.state.lockUnlockLead[key]}
                            onChange={(vall) => {
                                this.setLockUnlockLead(key, vall, record && record._id)
                            }}
                        />
                    )
                },
                width: 80,
            },
        ]

        const rowSelection = {
            selectedRowKeys: selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({selectedRowKeys})
                this.setState({selectedRows})

                let mobArr = selectedRows.map((item, ii) => {
                    return (
                        <Option key={ii} value={item.contact.mobile}>
                            {item.contact.mobile}
                        </Option>
                    )
                })
                this.setState({
                    waMobile: selectedRows.map((item) => item.contact.mobile),
                })
                this.setState({mobileList: mobArr})
            },
            getCheckboxProps: (record) => ({
                _id: record._id,
            }),
        }

        const unassignedLeadsFilterChange = (e) => {
            if (e.target.checked) {
                this.setState({unassignedLeads: true}, () => {
                    this.table.current.reload()
                })
            } else {
                this.state.unassignedLeads = false
                this.table.current.reload()
            }
        }

        const TransferLeadsFilterSet = (e) => {
            if (e.target.checked) {
                this.setState({transferLeads: true}, () => {
                    this.table.current.reload()
                })
            } else {
                this.state.transferLeads = false
                this.table.current.reload()
            }
        }
        if (this.state.transferLeads) {
            let dataItem = {
                title: 'Existing RO',
                key: 'existingRO',
                render: (text, record) => {
                    return (
                        <div>
                            <div>{record && record.existingRO && record.existingRO.name}</div>
                            <small>
                                {record && record.existingRO && record.existingRO.email}
                            </small>
                            <small>
                                {record && record.existingRO && record.existingRO.mobile}
                            </small>
                        </div>
                    )
                },
            }
            columns.splice(7, 0, dataItem)
        }

        return (
            <PageHeaderWrapper
                title={
                    this.state.count ? `All Leads : ${this.state.count}` : `All Leads: 0`
                }>
                <div style={{margin: '-24px', padding: '0px'}}>
                    <Card>
                        <Row>
                            <Col span={5}>
                                <Select
                                    showSearch
                                    allowClear
                                    optionFilterProp="children"
                                    style={{width: 200}}
                                    value={this.state.selectedLeadStage}
                                    onChange={(id) => {
                                        if (id == undefined) {
                                            this.setState({selectedLeadStage: ''}, () => {
                                                this.getTodos()
                                                this.table.current
                                                    ? this.table.current.reload()
                                                    : console.log('')
                                            })
                                        } else {
                                            this.setState({selectedLeadStage: id}, () => {
                                                this.getTodos([id])
                                                this.table.current
                                                    ? this.table.current.reload()
                                                    : console.log('')
                                            })
                                        }
                                    }}>
                                    {leadStage &&
                                        leadStage.map((val, idn) => {
                                            return (
                                                <Option key={idn} value={val}>
                                                    {val}
                                                </Option>
                                            )
                                        })}
                                </Select>
                            </Col>

                            <Col span={7}>
                                <AutoComplete
                                    value={this.state.selectedContact}
                                    notFoundContent={this.state.showSpin && <Spin/>}
                                    autoComplete={'none'}
                                    onSelect={this.onContactSelect}
                                    onSearch={this.searchContact}
                                    onChange={(val) => {
                                        this.setState({selectedContact: val})
                                    }}
                                    placeholder={'Search Contact'}
                                    style={{width: 300}}>
                                    {allContacts &&
                                        allContacts.map((val, item) => (
                                            <Option value={val._id}>
                                                <>
                                                    <div>
                                                        <b>{`${val.firstName} (${val.mobile})`}</b>
                                                    </div>
                                                </>
                                            </Option>
                                        ))}
                                </AutoComplete>
                            </Col>

                            <Col span={4}>
                                <Checkbox onChange={unassignedLeadsFilterChange}>
                                    Unassigned Leads
                                </Checkbox>
                            </Col>
                            <Col span={2}>
                                <Checkbox onChange={TransferLeadsFilterSet}>
                                    Transferred Leads
                                </Checkbox>
                            </Col>

                            <Col span={6} style={{textAlign: 'end'}}>
                                <Tooltip title={'List'}>
                                    <Button
                                        type={'default'}
                                        className={styles.defaultBtn}
                                        onClick={() => {
                                            this.setState({showTable: true, showBoard: false})
                                        }}>
                                        List
                                    </Button>
                                </Tooltip>
                                <Tooltip title={'Board'}>
                                    <Button
                                        type={'primary'}
                                        className={styles.btnPrimary}
                                        onClick={() => {
                                            this.setState({showBoard: true, showTable: false})
                                        }}>
                                        Board
                                    </Button>
                                </Tooltip>
                                <Button
                                    type={'primary'}
                                    onClick={() => {
                                        this.showModal()
                                    }}>
                                    <Icon type={'plus'}/>
                                    Add Lead
                                </Button>
                            </Col>
                        </Row>
                    </Card>

                    <Card className={styles.outerLeadCard}>
                        {showTable && (
                            <>
                                <div className={styles.exportDiv}>
                                    <div className={styles.assignBtn}>
                                        <Button
                                            disabled={selectedRowKeys.length === 0}
                                            onClick={() => this.setState({whatsappData: true})}>
                                            Whatsapp <Icon type="right"/>
                                        </Button>
                                    </div>
                                    <div className={styles.assignBtn}>
                                        <Button
                                            loading={this.state.transferLoader}
                                            style={{marginRight: '10px', marginLeft: '10px'}}
                                            onClick={async () => {
                                                this.setState({transferLoader: true})
                                                let {data, error, message} =
                                                    await Request.transferLeadToExistingRO()
                                                this.setState({transferLoader: false})
                                                if (!error) {
                                                    notification.success({message: message})
                                                    this.table.current.reload()
                                                } else {
                                                    return notification.error({message: message})
                                                }
                                            }}>
                                            Transfer All Leads <Icon type="right"/>
                                        </Button>
                                    </div>

                                    <div className={styles.assignBtn}>
                                        <Button
                                            disabled={selectedRowKeys.length === 0}
                                            onClick={() => this.setState({assignLeadDrawer: true})}>
                                            Assign To <Icon type="right"/>
                                        </Button>
                                    </div>
                                    <div className={styles.assignBtn}>
                                        <Button
                                            style={{marginLeft: '10px'}}
                                            disabled={selectedRowKeys.length === 0}
                                            onClick={() =>
                                                this.setState({statusChangeDrawer: true})
                                            }>
                                            Change Status <Icon type="right"/>
                                        </Button>
                                    </div>
                                    <div className={styles.assignBtn}>
                                        <Button
                                            loading={this.state.transferLoader}
                                            style={{marginLeft: '10px'}}
                                            disabled={
                                                this.state.transferLeads && selectedRowKeys.length != 0
                                                    ? false
                                                    : true
                                            }
                                            onClick={() => this.transferLeadToExistingRO()}>
                                            Transfer To Existing RO <Icon type="right"/>
                                        </Button>
                                    </div>
                                    <div className={styles.assignBtn}>
                                        <Button
                                            style={{marginBottom: 10, marginLeft: 10}}
                                            type="default"
                                            onClick={() => {
                                                this.exportData()
                                            }}
                                            loading={this.state.startLoading}>
                                            Export Excel
                                        </Button>
                                    </div>
                                </div>
                                <div style={{backgroundColor: 'white'}}>
                                    <TableComp
                                        ref={this.table}
                                        columns={columns}
                                        apiRequest={this.apiRequest}
                                        extraProps={{
                                            scroll: {x: 2400},
                                            rowSelection: {...rowSelection},
                                            rowClassName: (record, index) => {
                                                if (record && record.status == 'won') {
                                                    return styles.wonColor
                                                } else if (record && record.status == 'Lost') {
                                                    return styles.lostColor
                                                } else if (record && record.status == 'Unqualified') {
                                                    return styles.unqualifiedColor
                                                } else {
                                                    return styles.openColor
                                                }
                                            },
                                        }}
                                        id="leadList"
                                        pagination={{
                                            ...this.state.pagination,
                                            defaultPageSize: 10,
                                            pageSizeOptions: ['10', '25', '50', '100'],
                                            showSizeChanger: true,
                                            ...this.props.pagination,
                                        }}
                                    />
                                </div>
                            </>
                        )}

                        {showBoard && (
                            <Board
                                data={boardData}
                                laneDraggable={false}
                                className={styles.board}
                                cardDraggable
                                style={{backgroundColor: '#f4f5f7'}}
                                key={this.state.key}
                                onCardClick={(cardId, metadata, laneId) => {
                                    this.setState({
                                        updatelead: true,
                                        cardId,
                                        drawerKey: Date.now(),
                                    })
                                }}
                                handleDragEnd={async (
                                    cardId,
                                    sourceLaneId,
                                    targetLaneId,
                                    position,
                                    cardDetails
                                ) => {
                                    let obj = {_id: cardId, stage: targetLaneId}
                                    let x = await Request.changeLeadStage(obj)
                                    if (!x.error) {
                                        notification.success({message: x.message})
                                    } else {
                                        notification.error({message: x.message || 'Error Saving'})
                                    }
                                }}
                            />
                        )}
                    </Card>
                </div>
                <Modal
                    width={1000}
                    visible={this.state.visible}
                    onCancel={() => this.setState({visible: false})}
                    maskClosable={true}
                    centered={true}
                    footer={null}
                    keyboard={true}
                    title={'Add New Lead'}
                    // icon={<QuestionCircle />}
                >
                    <AddLead
                        allContacts={allContacts}
                        handleAddLead={() => {
                            this.setState({visible: false})
                            this.table.current.reload()
                            this.getTodos()
                        }}
                    />
                </Modal>
                <Drawer
                    title=""
                    placement="right"
                    closable={true}
                    onClose={async () => {
                        this.setState({
                            ...this.state,
                            updatelead: false,
                            cardId: '',
                        })
                    }}
                    visible={this.state.updatelead}
                    width={800}>
                    {/* cardId={cardId} */}
                    <UpdateLead
                        cardId={this.state.cardId}
                        allContacts={allContacts}
                        leadOwner={currentUser}
                        key={this.state.drawerKey ? this.state.drawerKey : Date.now()}
                        handleUpdateLead={() => {
                            this.setState({updatelead: false, cardId: ''})
                            this.getTodos()
                        }}
                    />
                </Drawer>
                <Modal
                    title="Whatsapp Data"
                    onCancel={async () => {


                        this.setState({
                            whatsappData: false,
                            msgBody: null
                        })
                    }}
                    visible={whatsappData}
                    okText="SAVE"
                    onOk={this.waSave}
                    okButtonProps={{
                        loading: waLoader,
                    }}>
                    <div style={{textAlign: 'center'}}>
                        <Form layout="inline">
                            <Row>
                                <Form.Item label="Message">
                                    <TextArea
                                        rows={4}
                                        style={{width: 210}}
                                        onChange={(e) =>
                                            this.setState({
                                                msgBody: e.target.value,
                                            })
                                        }
                                        value={msgBody}
                                        placeholder="Write Message"
                                        required
                                    />
                                </Form.Item>
                            </Row>
                        </Form>
                        <Form layout="inline">
                            <Row>
                                <Col>
                                    <Form.Item label="Mobile No." style={{width: '100%'}}>
                                        <Select
                                            mode="tags"
                                            style={{width: 250}}
                                            placeholder="Mobile"
                                            onChange={(value) => {
                                                this.setState({
                                                    waMobile: value,
                                                })
                                            }}
                                            value={waMobile}>
                                            {this.state.mobileList}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Modal>
                <Modal
                    title="Assign Leads"
                    onCancel={async () => {
                        this.setState({
                            assignLeadDrawer: false,
                            selectedRO: undefined,
                        })
                    }}
                    visible={assignLeadDrawer}
                    okText="Assign"
                    onOk={this.assignLeads}
                    okButtonProps={{
                        loading: assignLoader,
                    }}>
                    <div style={{textAlign: 'center'}}>
                        <Form layout="inline">
                            <Form.Item label="RO Officer">
                                <Select
                                    showSearch
                                    allowClear
                                    optionFilterProp="children"
                                    style={{width: 200}}
                                    value={selectedRO}
                                    onChange={(selectedRO) => {
                                        this.setState({selectedRO})
                                    }}
                                    placeholder="Select RO Officer">
                                    {RoList &&
                                        RoList.map((val, id) => {
                                            return (
                                                <Option
                                                    key={val._id}
                                                    value={
                                                        val._id
                                                    }>{`${val.name} (${val.email})`}</Option>
                                            )
                                        })}
                                </Select>
                            </Form.Item>
                        </Form>
                    </div>
                </Modal>
                <Modal
                    title="Change Leads Status"
                    onCancel={async () => {
                        this.setState({
                            statusChangeDrawer: false,
                            selectedStatus: undefined,
                        })
                    }}
                    visible={this.state.statusChangeDrawer}
                    okText="Change"
                    onOk={this.changeLeadStatus}
                    okButtonProps={{
                        loading: statusLoader,
                    }}>
                    <div style={{textAlign: 'center'}}>
                        <Form layout="inline">
                            <Form.Item label="Status">
                                <Select
                                    showSearch
                                    // allowClear
                                    optionFilterProp="children"
                                    style={{width: 200}}
                                    value={selectedStatus}
                                    onChange={(selectedStatus) => {
                                        this.setState({selectedStatus})
                                    }}
                                    placeholder="Select Status">
                                    {leadStatuseArr &&
                                        leadStatuseArr.map((val, id) => {
                                            return (
                                                <Option key={val} value={val}>
                                                    {val}
                                                </Option>
                                            )
                                        })}
                                </Select>
                            </Form.Item>
                        </Form>
                    </div>
                </Modal>
            </PageHeaderWrapper>
        )
    }
}

const mapStateToProps = ({global, router}) => ({
    categories: global.categories,
    currentUser: global.currentUser,
    search: router.location.search,
})
const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AllLeads)
