import React, { PureComponent } from 'react'
import {
  Divider,
  Button, Input,
  Card, Form, Select
} from 'antd'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { notification } from 'antd/lib/index'
import Request from '../../../request'
import { connect } from 'react-redux'
import { complaintStatus } from '../../../request'

const { Option } = Select
const { TextArea } = Input

class AddCommentOrStatus extends PureComponent {

  changeStatus = async (status) => {
    const { complaintId, isCommentModal, handleComment } = this.props
    let { selectedStatus, comment } = this.state

    if (isCommentModal) {
      if (!comment) {
        return notification.error({ message: 'Comment Required' })

      }
    } else {
      if (!selectedStatus) {
        return notification.error({ message: 'Select Status' })

      }
    }

    this.setState({ statusLoader: true })
    let x = null
    if (isCommentModal) {
      x = await Request.addComplaintComment({
        complaintId: complaintId,
        comment: comment
      })
    } else {
      x = await Request.changeComplaintStatus({
        complaintId: complaintId,
        status: selectedStatus
      })
    }


    this.setState({ statusLoader: false })


    if (!x.error) {
      notification.success({ message: x.message })
      this.setState({ comment: '', selectedStatus: null })

      if (handleComment) {
        handleComment()
      }


    } else {
      return notification.error({ message: x.message })
    }

  }


  constructor(props) {
    super(props)
    this.state = { statusLoader: false }
  }

  async componentDidMount() {
  }

  render() {

    const { isCommentModal, onCancel } = this.props
    let { comment, selectedStatus, statusLoader } = this.state

    return (
      <React.Fragment>
        <Form layout='inline'>
          {isCommentModal ? <Form.Item label='Comment'>
            <TextArea value={comment} style={{ width: '350px' }} rows={4} onChange={(val) => {
              this.setState({ comment: val.target.value })
            }} />

          </Form.Item> : <Form.Item label='Status'>
            <Select
              showSearch
              optionFilterProp="children"
              style={{ width: 200 }}
              value={selectedStatus}
              onChange={selectedStatus => {
                this.setState({ selectedStatus })
              }}
              placeholder='Select Status'
            >
              {complaintStatus && complaintStatus.map((val, id) => {
                return (
                  <Option key={val} value={val}>{val}</Option>
                )
              })}
            </Select>
          </Form.Item>}

          <div style={{ textAlign: 'right', marginTop: '20px', width: '90%' }}>

            <Button onClick={() => {
              onCancel()
              this.setState({ comment: '', selectedStatus: null })
            }}>
              CANCEL
            </Button>
            <Button style={{ marginLeft: '10px' }} type="primary" onClick={() => {
              this.changeStatus()
            }} loading={statusLoader}>
              SAVE
            </Button>

          </div>


        </Form>


      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  categories: global.categories,
  pathname: router.location.pathname

})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddCommentOrStatus)
