import React, {Component} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {TableComp, FormUtils as GetAllFormFields} from 'sz-react-utils'
import moment from 'moment'
import {
    Table,
    Badge,
    Card,
    Tooltip,
    Popconfirm,
    notification,
    Switch,
    Input, Button, Icon,
    Form,
    Drawer, Row, Col, Tag, Select
} from 'antd'
import Request from '../../../request'
import Color from 'color'
import _ from 'lodash'
import Highlighter from 'react-highlight-words'
import styles from './styles.less'
import {connect} from 'react-redux'
import memoizeOne from 'memoize-one'
import update from 'immutability-helper'
import {getUrlPushWrapper} from '../../../routes'
import {hideLoader, showLoader} from '../../../modules/actions'

const {Option} = Select

@Form.create()
class DairyContactList extends Component {


    apiRequest = (params) => {
        if (params.page) {
            this.state.currentPage = params.page
        }

        if (!params.sortOrder) {
            params.sortField = 'createdAt';
            params.sortOrder = 'desc';
        }
        let currentUser = JSON.parse(window.localStorage.getItem("user"))

        if (currentUser && currentUser.userType === "RO") {
            this.state.ownerId = currentUser._id
        }
        if (currentUser && currentUser.userType == 'Manager') {
            params.officer = currentUser && currentUser.roList
        }

        return new Promise(async (resolve) => {
            if (this.state.ownerId) {
                params.officer = this.state.ownerId
            }

            let data = await Request.dairyContactList({
                ...params, regExFilters: ['contactNumber', 'name', 'email']
            })
            this.setState({count: data.total})

            resolve(data)
        })
    }


    constructor(props) {
        super(props)
        this.table = React.createRef()
        this.state = {
            currentPage: 1,
            visible: false,


        }

    }

    componentDidMount() {

        let user = {...JSON.parse(localStorage.getItem('user'))}

        if (user && user.userType == 'Manager') {
            Request.loadUser({_id: {$in: user.roList}}, {results: 1000000000000}).then(({data: userList}) => {
                this.setState({userList: userList})
            })

        } else {
            Request.loadUser({userType: ['RO', 'Admin']}, {results: 1000000000000}).then(({data: userList}) => {
                this.setState({userList: userList})
            })
        }

    }

    render() {
        const {dispatch} = this.props

        let {currentPage, userList} = this.state
        let user = JSON.parse(window.localStorage.getItem("user"))

        const columns = [
            {
                title: 'S.No',
                dataIndex: 'sno',
                width: 50,
                key: 'sno',
                fixed: 'left',
                render: (value, item, index) => (currentPage - 1) * 10 + index + 1
            },
            {
                title: 'Name',
                dataIndex: 'name',
                width: 50,
                fixed: 'left',
                key: 'name',
                searchTextName: 'name'

            },
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',


            },
            {
                title: 'Contact Number',
                dataIndex: 'contactNumber',
                key: 'contactNumber',

            },
            {
                title: 'Officer',
                key: 'officer',
                render: (val, record) => {
                    return (<div>
                        <div>{record.officer ? record.officer.name : ''}</div>
                        <div>{record.officer ? record.officer.email : ''}</div>
                        <div>{record.officer ? record.officer.mobile : ''}</div>
                    </div>)
                }

            },
            {
                title: 'Organization Name',
                dataIndex: 'organizationName',
                key: 'organizationName',

            },
            {
                title: 'Designation',
                dataIndex: 'designation',
                key: 'designation'
            },
            {
                title: 'Milk Plants Location',
                dataIndex: 'milkPlantsLocation',
                key: 'milkPlantsLocation',
                searchTextName: 'milkPlantsLocation',
            },

            {
                title: 'Created At',
                dataIndex: 'createdAt',
                key: 'createdAt',
                searchDateName: 'createdAt',
                render: (text, record) => {
                    return (
                        <Tag>
                            {text ? moment(text).format('lll') : ''}
                        </Tag>
                    )
                },
                sorter: true
            },
            {
                key: 'actions',
                title: 'Actions',
                fixed: 'right',
                width: 100,
                render: (val, record) => {
                    return (<React.Fragment>
                        <Tooltip title="Edit Dairy Contact">
                            <Button
                                shape="circle"
                                style={{marginRight: 6}}
                                size="small"
                                onClick={() => {
                                    dispatch(
                                        getUrlPushWrapper('dairyContact.editDairyContact', {
                                            id: record._id
                                        })
                                    )
                                }}
                                icon="edit"
                            />
                        </Tooltip>
                    </React.Fragment>)
                }
            }

        ]
        if (user && user.userType === "RO") {
            columns.splice(4, 1);
        }

        return (
            <PageHeaderWrapper
                title={this.state.count ? `Milk Procurement List: ${this.state.count}` : `Milk Procurement List: 0`}>

                <Card bordered={true}>

                    <Row>
                        <Col span={8}>
                            <h4 style={{display: 'inline-block', marginRight: '10px'}}>Select Officer</h4>
                            <Select
                                showSearch
                                allowClear
                                optionFilterProp="children"
                                style={{
                                    width: 225

                                }}
                                disabled={user && user.userType === 'RO'}
                                value={(user && user.userType === 'RO') ? (user && user._id) : this.state.ownerId}
                                placeholder="Select Officer"
                                onChange={id => {
                                    this.setState({ownerId: id}, () => {
                                        this.table.current.reload()
                                    })
                                }}
                            >
                                {userList &&
                                userList.map((val, idn) => {
                                    return (
                                        <Option key={idn} value={val._id}>
                                            {val.name} ({val.mobile})
                                        </Option>
                                    )
                                })}
                            </Select>
                        </Col>
                    </Row>


                    <TableComp ref={this.table}
                               columns={columns}
                               apiRequest={this.apiRequest}
                               extraProps={{scroll: {x: 1600}}}
                               pagination={{
                                   ...this.state.pagination,
                                   defaultPageSize: 10,
                                   pageSizeOptions: ['10', '25', '50', '100'],
                                   showSizeChanger: true,
                                   ...this.props.pagination
                               }}/>
                </Card>

            </PageHeaderWrapper>)

    }
}


const mapStateToProps = ({global}) => ({
    categories: global.categories,
    loading: global.buttonLoading,

})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DairyContactList)
