import React, {Component} from 'react'
import PageHeaderWrapper from '../../components/PageHeaderWrapper/index'
import {TableComp} from 'sz-react-utils'
import moment from 'moment'
import {
    Table,
    Badge,
    Card,
    Tooltip,
    Popconfirm,
    notification,
    Row, Col,
    Select,
    Drawer,
    Switch,
    Input, Button, Icon,
    Tag
} from 'antd'
import Request from '../../request'
import _ from 'lodash'
import {connect} from 'react-redux'
import {getUrlParams, getUrlPushWrapper} from '../../routes'
import {hideLoader, showLoader} from '../../modules/actions';
import {DatePicker, Space} from 'antd';

const {Option} = Select

class AllFollowUps extends Component {

    apiRequest = (params) => {
        let user = {...JSON.parse(localStorage.getItem('user'))}

        if (params.page) {
            this.state.currentPage = params.page
        }

        return new Promise(async (resolve) => {

            if (user && user.userType == 'Manager') {
                if (!params.addedBy || !params.officer.length) {
                    params.addedBy = user && user.roList
                }

            }

            if (this.state.addedBy) {
                params.addedBy = this.state.addedBy
            }
            console.log(this.state.customQuery, "customQuerycustomQuery", params)

            let data = await Request.getAllFollowupsByLeadId({
                ...params,
                customQuery: this.state.customQuery,
                regExFilters: ['description']
            });
            console.log(data)
            this.setState({count: data.total})
            resolve(data)
        })

    }


    constructor(props) {

        super(props)
        let searchParams = new URLSearchParams(this.props.search)


        this.table = React.createRef()
        this.state = {
            visible: false, key: Date.now(),
            currentPage: 1,
            startRange: moment().startOf('day').format('YYYY-MM-DDTHH:MM:ss'),
            lastRange: moment().endOf('day').format('YYYY-MM-DDTHH:MM:ss'),
            leadId: {},
            leadDataVisible: false
        }

        let followUpsUrl = getUrlParams('followUpsUrl', window.location.pathname)
        if (followUpsUrl) {
            let {gte, lte, value} = followUpsUrl
            this.state.startRange = gte
            this.state.lastRange = lte
            this.onChange(value)
            this.state.filterValue = value
        } else {
            this.onChange('Total')
        }


    }

    async componentDidMount() {
        let user = {...JSON.parse(localStorage.getItem('user'))}

        if (user && user.userType == 'Manager') {
            Request.loadUser({_id: {$in: user.roList}}, {results: 1000000000000}).then(({data: userList}) => {
                this.setState({userList: userList})
            })

        } else {
            let {data: userList} = await Request.getAllROList({customQuery: {userType: {$eq: "RO"}}});
            this.setState({userList: userList})
        }


    }


    onChange = (e) => {
        console.log(e, "valuauauauauauua")
        this.setState({filterValue: e})
        let filter = {}


        switch (e) {
            case 'Completed':
                filter.customQuery = {
                    $and: [{
                        followUpDate: {
                            $gte: this.state.startRange,
                            $lte: this.state.lastRange
                        }, active: false
                    }
                        // {
                        // updatedAt: {
                        //     $gte: this.state.startRange,
                        //     $lte: this.state.lastRange
                        // }
                        //}
                    ]
                }
                break
            case 'Remaining':
                filter.customQuery = {
                    $and: [{
                        followUpDate: {
                            $gte: this.state.startRange,
                            $lte: this.state.lastRange
                        }, active: true
                    }]
                }
                break
            default:
                filter.customQuery = {
                    $and: [{
                        followUpDate: {
                            $gte: this.state.startRange,
                            $lte: this.state.lastRange
                        }
                    }]
                }
        }


        this.state.customQuery = filter.customQuery

    };


    render() {
        const {submitting, dispatch, currentUser} = this.props;
        let {userList, currentPage} = this.state
        const columns = [
            {
                title: 'S.No',
                dataIndex: 'sno',
                width: 50,
                key: 'sno',
                render: (value, item, index) => (currentPage - 1) * 10 + index + 1
            },
            {
                title: 'Date',
                dataIndex: 'followUpDate',
                key: 'followUpDate',
                sorter: true,
                render: (text, record) => {
                    let date = moment(text).isBefore(moment(), 'day')
                    return (
                        <div>
                            <Tag color={date ? 'red' : ''}>
                                {text ? moment(text).format('ll') : ''} ({record.followUpTime ? record.followUpTime : ''})

                            </Tag>
                        </div>
                    )

                }

            },

            {
                title: 'Description',
                key: 'description',
                // width: 150,
                dataIndex: 'description',
                searchTextName: 'description'
            },
            {
                title: 'Overdue Count',
                key: 'overdueCount',
                // width: 150,
                dataIndex: 'overdueCount',
                render: (val) => {
                    return (val ? <Badge
                        count={val}
                        showZero
                        style={{backgroundColor: 'red'}}
                    /> : '')
                }
            },

            {
                title: 'Lead',
                key: 'leadId',
                render: (val, record) => {
                    return (<div>
                        <a style={{color: 'blue', textDecoration: 'underline'}}
                           href={`/lead/detail?id=${record && record.leadId && record.leadId._id}`} target={'_blank'}>
                            {record.leadId ? record.leadId.title : ''}</a>


                    </div>)
                }

            },
            {
                title: 'Contact',
                key: 'contact',
                render: (text, record) => {
                    return (<div>
                        <div>{record && record.contactName}</div>
                        <div>{record && record.contactEmail}</div>
                        <div>{record && record.contactMobile}</div>

                    </div>)
                }
            }
        ]


        if (currentUser && currentUser.userType === 'Admin' || (currentUser.userType === 'Manager')) {

            let dataItem = {
                title: 'RO Details',
                key: 'addedBy',
                // width: 180,
                render: (text, record) => {

                    return (<div>
                        <div>{record && record.addedBy && record.addedBy.name}</div>
                        <small>{record && record.addedBy && record.addedBy.email}</small>
                        <small>{record && record.addedBy && record.addedBy.mobile}</small>

                    </div>)
                }
            }
            columns.splice(2, 0, dataItem)
        }


        const handleChangeDebut = (range) => {
            console.log("range is", range);
            if (!range.length) {
                this.state = {
                    ...this.state,
                    startRange: moment().subtract(2, 'months').format('YYYY-MM-DD'),
                    lastRange: moment().add(1, 'months').format('YYYY-MM-DD'),

                }
                if (this.state.overDueRange) {
                    this.setState({
                        startRange: null,
                        lastRange: this.state.overDueRange
                    })


                }
            }
            else {
                const valueOfInput1 = moment(range[0]).startOf('day').format();
                const valueOfInput2 = moment(range[1]).endOf('day').format();
                this.setState({...this.state, startRange: valueOfInput1, lastRange: valueOfInput2});
            }
            console.log("state changed");
            setTimeout(() => {
                this.table.current.reload();
            }, 500);

        }


        return (
            <PageHeaderWrapper
                title={this.state.count ? `All FollowUp : ${this.state.count}` : `All FollowUp: 0`}>

                <Card bordered={true}>

                    <Row>
                        <Col span={8}>
                            {/*<h4 style={{display: 'inline-block', marginRight: '10px'}}>Select Date Range</h4>*/}
                            <DatePicker.RangePicker
                                style={{width: "100%"}}
                                getPopupContainer={trigger => trigger.parentNode}
                                value={[moment(this.state.startRange), moment(this.state.lastRange)]}
                                onChange={(value, dateString) => handleChangeDebut(value)}
                            />
                        </Col>

                        {currentUser && currentUser.userType !== 'RO' && <Col span={8}>
                            {/*<h4 style={{display: 'inline-block', marginLeft: '10px'}}>Select Relationship Officer</h4>*/}
                            <Select
                                showSearch
                                allowClear
                                optionFilterProp="children"
                                style={{
                                    width: 225,
                                    marginLeft: '15px'

                                }}
                                placeholder="Select Relationship Officer"
                                onChange={id => {
                                    this.setState({addedBy: id}, () => {
                                        this.table.current.reload()
                                    })
                                }}
                            >
                                {userList &&
                                userList.map((val, idn) => {
                                    return (
                                        <Option key={idn} value={val._id}>
                                            {val.name} ({val.mobile})
                                        </Option>
                                    )
                                })}
                            </Select>
                        </Col>}


                        <Col span={8}>
                            {/*<h4 style={{display: 'inline-block', marginRight: '10px'}}>Select Filter</h4>*/}

                            <Select
                                showSearch
                                allowClear
                                optionFilterProp="children"
                                placeholder={'Select Filter'}
                                style={{width: 200, marginLeft: '10px'}}
                                value={this.state.filterValue}
                                onChange={id => {
                                    this.setState({filterValue: id}, () => {
                                        this.onChange(this.state.filterValue)
                                        this.table.current.reload()

                                    })


                                }}
                            >
                                <Option key={'Completed'} value={'Completed'}>Completed</Option>
                                <Option key={'Remaining'} value={'Remaining'}>Remaining</Option>
                            </Select>
                        </Col>

                    </Row>
                    <TableComp ref={this.table}
                               columns={columns}
                               apiRequest={this.apiRequest}
                               pagination={{
                                   ...this.state.pagination,
                                   defaultPageSize: 10,
                                   pageSizeOptions: ['10', '25', '50', '100'],
                                   showSizeChanger: true,
                                   ...this.props.pagination
                               }}/>
                </Card>

            </PageHeaderWrapper>)

    }
}


const mapStateToProps = ({global, router}) => ({
    categories: global.categories,
    currentUser: global.currentUser,
    search: router.location.search
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllFollowUps)
