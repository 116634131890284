import React, {PureComponent} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
    Form,
    Input,
    DatePicker,
    Select,
    Button,
    Card,
    Row, Col,
    Icon,
    Popover,
    InputNumber,
    Tag,
    Timeline,
    Spin
} from 'antd'
import _ from 'lodash'
import moment from 'moment'
import {FormUtils as GetAllFormFields} from 'sz-react-utils'
import {API_URL} from '../../../request'
import styles from '../all/styles.less'
import 'react-quill/dist/quill.snow.css'
import {notification} from 'antd/lib/index'
import {hideLoader, showLoader} from '../../../modules/actions'
import Request from '../../../request'
import {connect} from 'react-redux'
import {createMatchSelector} from 'connected-react-router'
import {apiUrl} from "../../../settings";
import {
    DealActivityModal, EmailActivityModal, FollowUpActivityModal, LostModal, NoteActivityModal, TextActivityModal,
    WhatsAppActivityModal,
    WinModal
} from "../all/helpers";
import ReactPlayer from 'react-player/lazy'
import S from "string";

const FormItem = Form.Item
const {Option} = Select

@Form.create()
class AddLead extends PureComponent {

    handleSubmit = e => {
        const {dispatch, form} = this.props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let values = _.clone(valData)

                if (!this.state.showContactForm && !this.state.showLead) {
                    return notification.error({
                        message: 'Error Saving'
                    })
                }
                dispatch(showLoader())

                let x = null
                let y = null
                let info = {
                    title: values.title,
                    state: values.state,
                    description: values.description,
                    contact: this.state.contactId,
                    closeDate: values.closeDate,
                    stage: values.stage,
                    source: values.source,
                    contactExists: true
                }


                if (this.state.showContactForm) {
                    let user = {...JSON.parse(localStorage.getItem('user'))}
                    if (user.userType === "RO") {
                        values.contactOwner = user._id;
                    }

                    if (user.userType === 'Lead Executive') {
                        values.contactOwner = null
                    }
                    console.log(values, "jijijij")
                    if (user.userType == 'Admin' && !values.contactOwner) {
                        values.contactOwner = null
                    }

                    x = await Request.addContact({
                        firstName: values.firstName,
                        mobile: values.mobile,
                        contactOwner: values.contactOwner,
                        email: values.email
                    })
                    dispatch(hideLoader())

                    if (!x.error) {
                        info.contact = x && x.data && x.data._id
                        info.contactExists = false
                        info.officer = values.contactOwner

                    } else {
                        return notification.error({
                            message: 'Error Saving',
                            description: x.message
                        })
                    }

                }
                y = await Request.addLead(info)

                dispatch(hideLoader())

                if (!y.error) {
                    notification.success({
                        message: y.message

                    })
                    this.setState({
                        showLead: false,
                        showContactForm: false,
                        contactMobile: '',
                        contactDetails: '',
                        leadData: [],
                        roName: ''
                    })
                    this.props.form.setFieldsValue({mobileNumber: '', state: this.state.stateList})

                } else {
                    notification.error({
                        message: 'Error Saving',
                        description: y.message
                    })
                }

            }
        })
    }


    constructor(props) {
        super(props)
        this.state = {showLead: false, showContactForm: false, leadDta: [], showPlayer: [], spinLoader: false}

    }

    handleVisibleChange = (key, value) => {
        let newData = _.clone(this.state.showPlayer)
        newData[key] = value
        this.setState({showPlayer: newData})
    }

    async componentDidMount() {
        let searchParams = new URLSearchParams(this.props.search)

        let leadId = searchParams.get('id')

        let {data: stateList} = await Request.loadState({countryId: '5fca34a100c10219d4c564de'})
        this.setState({stateList: stateList})
        Request.loadUser({userType: 'RO'}, {results: 1000000000000}).then(({data: userList}) => {
            this.setState({userList: userList})
        })

    }

    render() {


        const {submitting, currentUser} = this.props
        let {userList, stateList, contactDetails, leadData, showPlayer} = this.state
        const {
            form: {getFieldDecorator, getFieldValue}
        } = this.props;

        const leftInputTypes = {
            fields: [

                {
                    label: 'First Name',
                    key: 'firstName',
                    required: true
                },
                {
                    label: 'Mobile No',
                    key: 'mobile',
                    required: true,
                    customProps: {disabled: true}

                },
                {
                    label: 'Email',
                    key: 'email',
                    required: true,

                },
                {
                    label: 'Contact Owner',
                    key: 'contactOwner',
                    // required: true,
                    showSearch: true,
                    customProps: {
                        allowClear: true,
                    },
                    hidden: currentUser && currentUser.userType !== 'Admin',
                    keyAccessor: x => x._id,
                    valueAccessor: x => `${x.name} (${x && x.id}) (${x.mobile}) `,
                    type: 'select',
                    options: userList || [],
                    onChange: contactOwner => {
                        this.props.form.setFieldsValue({
                            contactOwner: contactOwner
                        })


                    }

                }]
        }

        const rightInputTypes = {
            fields: [
                {
                    label: 'Title', key: 'title',
                    required: true
                },
                {
                    label: 'Stage',
                    key: 'stage',
                    required: true,
                    type: 'select',
                    options: currentUser && currentUser.userType === 'Lead Executive' ? ['New (Untouched)'] : ['New (Untouched)', 'Contacted'],
                    onChange: v => {
                        this.props.form.setFieldsValue({stage: v})
                    }
                },
                {
                    label: 'Source',
                    key: 'source',
                    required: true,
                    type: 'select', options: ['Purchased', 'Call', 'Direct', 'Facebook'],
                    onChange: v => {
                        this.props.form.setFieldsValue({source: v})
                    }
                },
                {
                    label: 'State',
                    type: 'select',
                    key: 'state',
                    required: true,
                    showSearch: true,
                    keyAccessor: x => _.startCase(x.stateName),
                    valueAccessor: x => x.stateName && _.startCase(x.stateName),
                    options: stateList || [],
                    onChange: (state) => {
                        this.props.form.setFieldsValue({
                            state: state
                        })
                    }


                },
                {
                    label: 'Description', key: 'description', type: 'textArea', required: true
                },
                {
                    label: 'Estimated Close Date',
                    key: 'closeDate',
                    type: 'date',

                },
            ]
        }

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
                md: {span: 8}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
                md: {span: 14}
            }
        }
        const onCancel = () => {
            this.setState({
                ...this.state, lost: false, win: false
            })
        }
        const getCallColor = (callType, duration) => {
            if (callType === 'outgoing' && duration == 0) {
                return {

                    color: "#FBB917",
                    fontWeight: 'bold'

                }
            } else if (callType === 'outgoing' && duration > 0) {
                return {
                    color: "#43BE31",
                    fontWeight: 'bold'

                }
            } else if (callType === 'incoming') {
                return {

                    color: "#1287A5",
                    fontWeight: 'bold'

                }
            } else {
                return {
                    color: "red",
                    fontWeight: 'bold'

                }
            }
        }
        const cancelActivityModal = () => {
            this.setState({
                noteModal: false,
                emailModal: false,
                followUpModal: false,
                dealActivityModal: false,
                whatsAppModal: false,
                textActivityModal: false,
            })
        }
        const submitFormLayout = {
            wrapperCol: {
                xs: {span: 24, offset: 0},
                sm: {span: 10, offset: 7},
                md: {span: 12, offset: 8}
            }
        }


        return (
            <PageHeaderWrapper
                title={this.state.id ? 'Update Lead' : 'Add Lead'}
            >


                <Form onSubmit={this.handleSubmit} hideRequiredMark={false} style={{marginTop: 8}}>


                    <Card bordered={true}>


                        <Row>
                            <Col span={12}>

                                <Card bordered={true}>
                                    <Row>
                                        <Col>
                                            <Form.Item label='Enter Contact Mob. No'{...formItemLayout}>
                                                {getFieldDecorator('mobileNumber')(<InputNumber style={{width: 200}}
                                                                                                onBlur={(e) => {
                                                                                                    this.setState({contactMobile: e.target.value})


                                                                                                }}/>)
                                                }
                                                <Button style={{marginLeft: '2px'}} type="primary"
                                                        onClick={async () => {

                                                            if (!this.state.contactMobile) {

                                                                this.setState({
                                                                    showContactForm: false,
                                                                    showLead: false,
                                                                    contactDetails: '',
                                                                    leadData: []
                                                                })
                                                                return notification.error({
                                                                    message: 'Enter Lead Mobile Number'
                                                                })
                                                            }

                                                            let filter
                                                            if (this.state.contactMobile.length == 10) {
                                                                filter = {
                                                                    mobile: [this.state.contactMobile, `91${this.state.contactMobile}`, `+91${this.state.contactMobile}`]
                                                                }
                                                            } else {
                                                                filter = {mobile: this.state.contactMobile}
                                                            }
                                                            let {data: singleContact} = await Request.getSingleContactByMobile({...filter})


                                                            this.setState({spinLoader: true})
                                                            if (singleContact) {
                                                                let {data: leadData} = await Request.getAllLeads({
                                                                    contact: singleContact && singleContact._id,
                                                                    results: 1,
                                                                    sortField: 'createdAt',
                                                                    sortOrder: 'desc'

                                                                })
                                                                let {data: activities} = await Request.getLeadById({
                                                                    _id: leadData && leadData[0] && leadData[0]._id

                                                                })

                                                                this.setState({
                                                                    spinLoader: false,
                                                                    showLead: true,
                                                                    showContactForm: false,
                                                                    contactId: singleContact._id,
                                                                    contactDetails: singleContact,
                                                                    roName: singleContact && singleContact.contactOwner && singleContact.contactOwner.name,
                                                                    leadData: activities && activities.activities && _.orderBy(activities.activities, ['createdAt'], ['desc'])
                                                                })
                                                                this.props.form.setFieldsValue({
                                                                    closeDate: moment().endOf('month'),
                                                                    state: singleContact && singleContact.billingAddress && singleContact.billingAddress.state
                                                                })

                                                            } else {

                                                                this.setState({
                                                                    spinLoader: false,
                                                                    showContactForm: true,
                                                                    showLead: true,
                                                                    contactDetails: '',
                                                                    roName: '',
                                                                    leadData: []
                                                                })
                                                                this.props.form.setFieldsValue({mobile: this.state.contactMobile})
                                                                this.props.form.setFieldsValue({
                                                                    closeDate: moment().endOf('month')
                                                                })


                                                            }
                                                        }}>{<Icon type="search"/>}</Button>

                                            </Form.Item>
                                        </Col>

                                    </Row>
                                </Card>
                                {
                                    (contactDetails || this.state.spinLoader) && <Card loading={this.state.spinLoader}>
                                        <Card bordered={true} style={{marginBottom: '10px'}}><Row>
                                            <Col>
                                                <Row>
                                                    <Col span={12}>
                                                        <h2 style={{marginBottom: '0px'}}> {contactDetails && contactDetails.firstName} {contactDetails && contactDetails.lastName ? contactDetails.lastName : ''}
                                                        </h2>
                                                        <span> {contactDetails && contactDetails.mobile}</span> <br/>
                                                        <span>{contactDetails && contactDetails.email}</span>
                                                    </Col>
                                                    {/*<Col span={12}>*/}
                                                    {/*    <h2 style={{textAlign: 'right'}}>{this.state.roName ? this.state.roName : ''}</h2>*/}
                                                    {/*</Col>*/}
                                                </Row>
                                            </Col>

                                        </Row></Card>

                                        {/*<Card>*/}
                                        {/*    <h4>Activities</h4>*/}

                                        {/*    <Timeline mode="left">*/}
                                        {/*        {leadData && leadData.length && leadData.map((activity, key) => {*/}

                                        {/*                if (key <= 9) {*/}
                                        {/*                    let newKey = activity._id*/}
                                        {/*                    if (activity.type === "value" || activity.type === "status" || activity.type === "stage" || activity.type === "closeDate")*/}
                                        {/*                        return <Timeline.Item*/}
                                        {/*                            key={newKey}>{activity.message} by <b>{activity.user}</b> on <b>{moment(activity.createdAt).format("lll")}</b></Timeline.Item>*/}
                                        {/*                    else if (activity.type === "notes")*/}
                                        {/*                        return <Timeline.Item key={newKey}>Note :*/}
                                        {/*                            "{S(activity.noteMessage).stripTags().s}" added*/}
                                        {/*                            at <b>{moment(activity.createdAt).format('lll')}</b></Timeline.Item>*/}
                                        {/*                    else if (activity.type === "email")*/}
                                        {/*                        return <Timeline.Item key={newKey}><a onClick={() => {*/}
                                        {/*                            this.setState({*/}
                                        {/*                                emailModal: true,*/}
                                        {/*                                emailActivity: activity*/}
                                        {/*                            });*/}
                                        {/*                        }}>{activity.message} by <b>{activity.user}</b> on <b>{moment(activity.createdAt).format("lll")}</b></a>*/}
                                        {/*                        </Timeline.Item>*/}
                                        {/*                    // else if (activity.type == "file")*/}
                                        {/*                    else if (activity.type === "whatsApp")*/}
                                        {/*                        return <Timeline.Item key={newKey}><a onClick={() => {*/}
                                        {/*                            this.setState({*/}
                                        {/*                                whatsAppModal: true,*/}
                                        {/*                                whatsAppActivity: activity*/}
                                        {/*                            });*/}
                                        {/*                        }}>{activity.message} by <b>{activity.user}</b> on <b>{moment(activity.createdAt).format("lll")}</b></a>*/}
                                        {/*                        </Timeline.Item>*/}
                                        {/*                    else if (activity.type === "file")*/}
                                        {/*                        return <Timeline.Item*/}
                                        {/*                            key={newKey}>{activity.message} by <b>{activity.user}</b> on <b>{moment(activity.createdAt).format("lll")}</b><a*/}
                                        {/*                            style={{marginLeft: "10px"}}*/}
                                        {/*                            href={apiUrl + `${activity.fileUrl}`}*/}
                                        {/*                            target="_blank">*/}
                                        {/*                            <mark>{activity.fileName}</mark>*/}
                                        {/*                        </a></Timeline.Item>*/}
                                        {/*                    else if (activity.type === "followUp")*/}
                                        {/*                        return <Timeline.Item key={newKey}><a onClick={() => {*/}
                                        {/*                            this.setState({*/}
                                        {/*                                followUpModal: true,*/}
                                        {/*                                followupActivity: activity*/}
                                        {/*                            });*/}
                                        {/*                        }}>{activity.message} by <b>{activity.user}</b> on <b>{moment(activity.createdAt).format("lll")}</b></a>*/}
                                        {/*                        </Timeline.Item>*/}

                                        {/*                    else if (activity.type === "message")*/}
                                        {/*                        return <Timeline.Item key={newKey}><a onClick={() => {*/}
                                        {/*                            this.setState({*/}
                                        {/*                                textActivityModal: true,*/}
                                        {/*                                textActivity: activity*/}
                                        {/*                            });*/}
                                        {/*                        }}>{activity.message} by <b>{activity.user}</b> on <b>{moment(activity.createdAt).format("lll")}</b></a>*/}
                                        {/*                        </Timeline.Item>*/}

                                        {/*                    else if (activity.type === "activity")*/}
                                        {/*                        return <Timeline.Item key={newKey}><a onClick={() => {*/}
                                        {/*                            this.setState({*/}
                                        {/*                                dealActivityModal: true,*/}
                                        {/*                                dealActivity: activity*/}
                                        {/*                            });*/}
                                        {/*                        }}>{activity.message} by <b>{activity.user}</b> on <b>{moment(activity.createdAt).format("lll")}</b></a>*/}
                                        {/*                        </Timeline.Item>*/}
                                        {/*                    else if (activity.type === "recording")*/}
                                        {/*                        return <Timeline.Item key={newKey}>*/}
                                        {/*                            <span>{activity.message}</span> by <b>{activity.user}</b> on <b>{moment(activity.createdAt).format("lll")}</b>*/}
                                        {/*                            <Popover*/}
                                        {/*                                content={<ReactPlayer*/}
                                        {/*                                    url={`${apiUrl}/recordingStream?path=${activity.filePath}&isPath=true`}*/}
                                        {/*                                    controls*/}
                                        {/*                                    height={50}*/}
                                        {/*                                    className={styles.player}*/}
                                        {/*                                    width={400}*/}
                                        {/*                                />}*/}
                                        {/*                                placement={'bottomRight'}*/}
                                        {/*                                trigger="click"*/}
                                        {/*                                destroyTooltipOnHide*/}
                                        {/*                                visible={showPlayer[key]}*/}
                                        {/*                                onVisibleChange={(val) => this.handleVisibleChange(key, val)}*/}
                                        {/*                            >*/}
                                        {/*                                <Icon className={styles.playBtn} size='small'*/}
                                        {/*                                      type='play-circle'/>*/}
                                        {/*                            </Popover>*/}

                                        {/*                            <Icon className={styles.playBtn} size='small'*/}
                                        {/*                                  type='download'*/}
                                        {/*                                  onClick={() => {*/}
                                        {/*                                      Request.downloadWithPath(activity.filePath)*/}
                                        {/*                                  }}/>*/}
                                        {/*                        </Timeline.Item>*/}

                                        {/*                    else if (activity.type === "callLog") {*/}
                                        {/*                        let recordId = activity && activity.callLogId && activity.callLogId.recording*/}
                                        {/*                        return <Timeline.Item key={newKey}>*/}
                                        {/*    <span>{activity && activity.callLogId ? <Tag*/}
                                        {/*        style={getCallColor(activity.callLogId.callType, activity.callDuration)}>{_.startCase(activity.callLogId.callType)}</Tag> : ''}</span> Call*/}
                                        {/*                            of*/}
                                        {/*                            duration <span>{moment.utc(activity.callDuration * 1000).format('HH:mm:ss')} </span>*/}
                                        {/*                            of <b>{activity.user}</b> on <b>{moment(activity.followUpDate).format("lll")}</b>*/}
                                        {/*                            {recordId && <>*/}
                                        {/*                                <Popover*/}
                                        {/*                                    content={<ReactPlayer*/}
                                        {/*                                        url={`${apiUrl}/recordingStream?id=${recordId}`}*/}
                                        {/*                                        controls*/}
                                        {/*                                        height={50}*/}
                                        {/*                                        className={styles.player}*/}
                                        {/*                                        width={400}*/}
                                        {/*                                    />}*/}
                                        {/*                                    placement={'bottomRight'}*/}
                                        {/*                                    trigger="click"*/}
                                        {/*                                    destroyTooltipOnHide*/}
                                        {/*                                    visible={showPlayer[key]}*/}
                                        {/*                                    onVisibleChange={(val) => this.handleVisibleChange(key, val)}*/}
                                        {/*                                >*/}
                                        {/*                                    <Icon className={styles.playBtn} size='small'*/}
                                        {/*                                          type='play-circle'/>*/}
                                        {/*                                </Popover>*/}
                                        {/*                                <Icon className={styles.playBtn} size='small'*/}
                                        {/*                                      type='download'*/}
                                        {/*                                      onClick={() => {*/}
                                        {/*                                          Request.downloadWithPath(`recordings/${recordId}.mp3`)*/}
                                        {/*                                      }}/>*/}

                                        {/*                            </>}*/}

                                        {/*                        </Timeline.Item>*/}
                                        {/*                    } else if (activity.type === "info")*/}
                                        {/*                        return <Timeline.Item*/}
                                        {/*                            key={newKey}><a>{activity.message} by <b>{activity.user}</b> on <b>{moment(activity.createdAt).format("lll")}</b></a>*/}
                                        {/*                        </Timeline.Item>*/}
                                        {/*                }*/}

                                        {/*            }*/}
                                        {/*        )}*/}

                                        {/*    </Timeline>*/}
                                        {/*</Card>*/}


                                    </Card>
                                }

                            </Col>
                            <Col span={1}></Col>
                            <Col span={11}>

                                {(this.state.showLead || this.state.spinLoader) &&
                                    <Card loading={this.state.spinLoader} title={'Lead Details'}
                                          style={{marginBottom: '10px'}} bordered={true}>
                                        <Row>

                                            <Col>
                                                <GetAllFormFields apiurl={API_URL}
                                                                  inputSchema={rightInputTypes}
                                                                  formItemLayout={formItemLayout}
                                                                  getFieldDecorator={getFieldDecorator}/>

                                            </Col>
                                        </Row>
                                    </Card>}
                                {(this.state.showContactForm || this.state.spinLoader) &&
                                    <Card loading={this.state.spinLoader} bordered={true} title={'Contact Details'}>
                                        <Row>
                                            <Col>
                                                <GetAllFormFields apiurl={API_URL}
                                                                  inputSchema={leftInputTypes}
                                                                  formItemLayout={formItemLayout}
                                                                  getFieldDecorator={getFieldDecorator}/>
                                            </Col>
                                        </Row>
                                    </Card>}

                            </Col>


                        </Row>


                        <div>


                        </div>
                        {
                            this.state.showLead && <Form.Item {...submitFormLayout} style={{marginTop: 32}}>
                                <Button type="primary" htmlType="submit" loading={this.props.loading}>
                                    SAVE
                                </Button>
                            </Form.Item>
                        }
                        {this.state.win && WinModal(
                            {onCancel, state: {...this.state}, form: {...this.props.form}})}
                        {this.state.lost && LostModal(
                            {onCancel, state: {...this.state}, form: {...this.props.form}})}
                        {this.state.noteModal && NoteActivityModal(
                            {cancelActivityModal, state: {...this.state},})}
                        {this.state.emailModal && EmailActivityModal(
                            {cancelActivityModal, state: {...this.state}})}
                        {this.state.followUpModal && FollowUpActivityModal(
                            {cancelActivityModal, state: {...this.state},})}
                        {this.state.dealActivityModal && DealActivityModal(
                            {cancelActivityModal, state: {...this.state},})}
                        {this.state.whatsAppModal && WhatsAppActivityModal(
                            {cancelActivityModal, state: {...this.state},})}
                        {this.state.textActivityModal && TextActivityModal(
                            {cancelActivityModal, state: {...this.state},})}
                    </Card>
                </Form>


            </PageHeaderWrapper>
        )
    }
}

const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search,
    currentUser: global.currentUser,

})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddLead)
