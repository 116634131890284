import React, { Component } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { TableComp } from 'sz-react-utils'
import moment from 'moment'
import {
  Table,
  Badge,
  Card,
  Tooltip,
  Popconfirm,
  notification,
  Row, Col,
  Select,
  Drawer,
  Switch, AutoComplete, Spin,
  Input, Button, Icon, Tag
} from 'antd'
import Request from '../../../request'
import _ from 'lodash'
//import styles from './styles.less'
import { connect } from 'react-redux'
import { getUrlPushWrapper } from '../../../routes'
import { hideLoader, showLoader } from '../../../modules/actions'
import InvoiceForm from '../add/invoiceForm'
import { FaFileInvoice } from 'react-icons/fa'


const { Option } = Select

class AllQuote extends Component {

  deleteQuote = async data => {
    this.setState({ loading: true })
    let x = await Request.deleteOutsideQuote(data)
    this.setState({ loading: false })

    this.table.current.reload()
    if (!x.error) {
      notification.success({
        message: x.message,
        duration: 20,
        key: `${data._id}-close`
      })

    } else {
      notification.error({
        message: x.message || 'Try Again',
        duration: 20,
        key: `${data._id}-close`
      })
    }


  }
  apiRequest = (params) => {

    if (params.page) {
      this.state.currentPage = params.page
    }

    let currentUser = JSON.parse(window.localStorage.getItem('user'))

    if (currentUser && currentUser.userType == 'Manager') {
      params.quoteOwner = currentUser && currentUser.roList
    }
    if (currentUser && currentUser.userType == 'RO') {
      this.state.ownerId = currentUser._id
    }
    return new Promise(async (resolve) => {

      if (this.state.ownerId) {
        params.quoteOwner = this.state.ownerId
      }
      if (this.state.contactNameId) {
        params.contactName = this.state.contactNameId
      }
      params.sortField = 'createdAt'
      params.sortOrder = 'desc'
      let data = await Request.getAllOutsideQuotes({
        ...params, regExFilters: ['dealName', 'carrier']
      })
      this.setState({ count: data.total })
      resolve(data)
    })
  }
  downloadQuoteSheet = async (item, key) => {
    this.setLoaderForFile(key, true)

    let resp = await Request.downloadOutsideQuoteSheet({ _id: item })
    this.setLoaderForFile(key, false)
  }
  showDrawer = () => {
    this.setState({
      visible: true,
      key: Date.now()
    })
  }
  handleContactSearch = async (test) => {
    console.log(test)
    let filter = {}
    // this.setState({​​​​​​​​showSpin: true}​​​​​​​​)
    this.setState({ selectedContact: test })
    if (test == '') {
      console.log('empty')
      this.setState({
        allContacts: this.state.allContacts,
        selectedContact: test,
        contactNameId: null
      }, () => this.table.current.reload())
      //   , () => {
      //   this.setState({
      //     showSpin: false,
      //   })
      // })
      return false
    }
    let user = { ...JSON.parse(localStorage.getItem('user')) }

    if (user && user.userType == 'Manager') {
      filter.roList = user && user.roList
    }
    let { data, error, message } = await Request.contactSearch({ text: test, ...filter })
    if (!error) {
      this.setState({ allContacts: data }, () => {
        this.setState({ showSpin: false })
      })
    }
  }

  onContactSelect = (val) => {
    let selectedContact = this.state.allContacts.find((obj) => obj._id === val)
    if (selectedContact) {
      this.setState({
        contactNameId: selectedContact._id,
        selectedContact: `${selectedContact.firstName} (${selectedContact.mobile})`
      }, () => {
        this.table.current && this.table.current.reload()
      })
    }
  }

  constructor(props) {
    super(props)
    this.table = React.createRef()
    this.state = {
      visible: false,
      currentPage: 1,
      key: Date.now(),
      fileDownloadLoader: [],
      carrier: ['Fedex', 'DHL', 'UPS', 'USPS', 'BlueDart']
    }

  }

  setLoaderForFile = (key, value) => {
    let { fileDownloadLoader } = this.state
    let newLoading = _.clone(fileDownloadLoader)
    newLoading[key] = value
    this.setState({ fileDownloadLoader: newLoading })
  }

  getOptionsData = async () => {
    // const { currentUser } = this.props
    let currentUser = JSON.parse(window.localStorage.getItem('user'))
    let filter = {}
    let params = {}
    if (currentUser && currentUser.userType == 'RO') {
      filter._id = currentUser._id
      this.setState({ isRO: true, roOfficer: currentUser._id })
    }
    let roQuery = currentUser && currentUser.userType == 'RO' ? { contactOwner: currentUser && currentUser._id } : {}


    if (currentUser && currentUser.userType == 'Manager') {
      Request.loadUser({ _id: { $in: currentUser.roList } }, { results: 1000000000000 }).then(({ data: userList }) => {
        this.setState({ userList: userList })
      })

    } else {
      Request.getAllROList(filter).then(({ data: userList }) => {
        this.setState({ userList: userList || [] })
      })
    }

    if (currentUser && currentUser.userType == 'RO') {
      params.contactOwner = currentUser._id
      let { data } = await Request.getAllContacts({
        ...params
      })
      this.setState({ allContacts: data || [] })
    } else if (currentUser && currentUser.userType == 'Manager') {
      let { data } = await Request.getAllContacts({ contactOwner: { $in: currentUser.roList } })
      this.setState({ allContacts: data || [] })
    } else {
      Request.getAllContacts().then(({ data: allContacts }) => {
        this.setState({ allContacts: allContacts || [] })
      })
    }

  }

  async componentDidMount() {
    this.getOptionsData()
  }

  render() {
    const getStatusStyle = (status) => {
      switch (status) {
        case 'Approved':
          return {
            backgroundColor: '#6ab04c',
            textAlign: 'center',
            color: 'white',
            padding: '2px 2px',
            borderRadius: '2px',
            minWidth: '30px'
          }
          break
        case 'Unapproved':
          return {
            backgroundColor: '#F3B431',
            textAlign: 'center',
            color: 'white',
            padding: '2px 2px',
            borderRadius: '2px',
            minWidth: '30px'
          }
          break
        default:
          return {
            backgroundColor: '#F3B431',
            color: 'white',
            textAlign: 'center',
            padding: '2px 2px',
            borderRadius: '2px',
            minWidth: '30px'
          }
      }
    }
    const { submitting, dispatch, currentUser } = this.props
    const { userList, allContacts, isRO, roOfficer, currentPage, fileDownloadLoader, carrier, visible, leadId, quoteData, quoteId } = this.state
    let user = JSON.parse(window.localStorage.getItem('user'))


    const columns = [
      {
        title: 'S.No',
        dataIndex: 'sno',
        width: 50,
        key: 'sno',
        fixed: 'left',
        render: (value, item, index) => (currentPage - 1) * 10 + index + 1
      },
      {
        title: 'Invoice Number',
        dataIndex: 'invoiceNumber',
        fixed: 'left',
        width: 100,
        key: 'invoiceNumber',
        searchTextName: 'invoiceNumber'

      },

      {
        title: 'IEC Number',
        key: 'iecNumber',
        searchTextName: 'iecNumber'

      },
      {
        title: 'AD Code',
        key: 'code',
        searchTextName: 'code'

      },
      {
        title: 'Consignee',
        key: 'consignee'
      },
      {
        title: 'Place Of Receipt',
        key: 'receiptPlace'
      },
      {
        title: 'Destination',
        key: 'destination'
      },
      {
        title: 'Payment',
        key: 'payment'
      },
      {
        title: 'Port Of Discharge',
        key: 'dischargePort'
      },
      {
        title: 'NO. Boxes',
        key: 'noOfBoxes'
      },
      {
        title: 'Net Weight',
        key: 'netWeight'
      },
      {
        title: 'Status',
        key: 'status',
        dataIndex: 'status',
        filters: [{ text: 'Approved', value: 'Approved' },
          { text: 'Unapproved', value: 'Unapproved' }],
        // width: 150,
        render: (val, record) => {
          return <div>{record.status ?
            <div style={getStatusStyle(record.status)}>{record.status}</div> : ''}</div>

        }

      },

      {
        title: 'Date',
        key: 'date',
        dataIndex: 'date',
        width: 150,
        render: (text, record) => {
          return (
            <Tag>
              {text ? moment(text).format('lll') : ''}
            </Tag>
          )
        },
        sorter: true
      },

      {
        key: 'actions',
        title: 'Actions',
        fixed: 'right',
        width: 120,
        render: (text, record, key) => {

          return <React.Fragment>
            <Tooltip title="Edit Quote">
              <Button
                shape="circle"
                style={{ marginRight: 6 }}
                size="small"
                onClick={() => {
                  dispatch(
                    getUrlPushWrapper('quote.editOutsideQuotes', {
                      id: record._id
                    })
                  )
                }}
                icon="edit"
              />
            </Tooltip>
            <Tooltip title="Delete Quote">
              <Popconfirm
                title="Are you sure delete this Quote?"
                onConfirm={() => {
                  this.deleteQuote({ _id: record._id })
                }}
                onCancel={() => {
                  console.log()
                }}
                okText="Yes"
                cancelText="No">
                <Button
                  shape="circle"
                  size="small"
                  style={{ marginRight: 6 }}
                  icon="delete"
                />
              </Popconfirm>

            </Tooltip>

            {(user && user.userType === 'RO' && record && record.approved !== true) ? <div></div> :
              <Tooltip title="Download Quote Sheet">
                <Button
                  type="warning"
                  size="small"
                  shape="circle"
                  style={{ marginTop: '6px' }}
                  loading={this.state.fileDownloadLoader[key]}

                  onClick={() => {
                    this.downloadQuoteSheet(record._id, key)
                  }}
                  icon="download"
                />
              </Tooltip>}


          </React.Fragment>

        }
      }


    ]
    if (user && user.userType == 'RO') {
      columns.splice(2, 1)
    }


    return (
      <PageHeaderWrapper
        title={this.state.count ? `All USD Quotes: ${this.state.count}` : `All USD Quotes: 0`}>

        <Card bordered={true}>

          {/*<Row>*/}
          {/*  <Col span={8}>*/}
          {/*    <h4 style={{ display: 'inline-block', marginRight: '10px' }}>Select Quote Owner</h4>*/}
          {/*    <Select*/}
          {/*      showSearch*/}
          {/*      allowClear*/}
          {/*      optionFilterProp="children"*/}
          {/*      style={{*/}
          {/*        width: 225*/}

          {/*      }}*/}
          {/*      disabled={isRO}*/}
          {/*      value={isRO ? roOfficer : this.state.ownerId}*/}
          {/*      placeholder="Select Quote Owner"*/}
          {/*      onChange={id => {*/}
          {/*        this.setState({ ownerId: id }, () => {*/}
          {/*          this.table.current.reload()*/}
          {/*        })*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      {userList &&*/}
          {/*      userList.map((val, idn) => {*/}
          {/*        return (*/}
          {/*          <Option key={idn} value={val._id}>*/}
          {/*            {val.name} ({val.mobile})*/}
          {/*          </Option>*/}
          {/*        )*/}
          {/*      })}*/}
          {/*    </Select>*/}
          {/*  </Col>*/}
          {/*  <Col span={8}>*/}
          {/*    <h4 style={{ display: 'inline-block', marginRight: '10px' }}>Select Contact Name</h4>*/}
          {/*    <AutoComplete*/}
          {/*      value={this.state.selectedContact}*/}
          {/*      style={{*/}
          {/*        width: 225*/}
          {/*      }}*/}
          {/*      notFoundContent={this.state.showSpin && <Spin />}*/}
          {/*      autoComplete={'none'}*/}
          {/*      onSelect={this.onContactSelect}*/}
          {/*      onSearch={this.handleContactSearch}*/}
          {/*      placeholder={'Search Contact'}*/}
          {/*    >{allContacts && allContacts.map((val, item) => (*/}
          {/*      <Option value={val._id}>*/}
          {/*        <>*/}
          {/*          <div><b>{`${val.firstName} (${val.mobile})`}</b></div>*/}
          {/*        </>*/}
          {/*      </Option>*/}
          {/*    ))}*/}

          {/*    </AutoComplete>*/}
          {/*  </Col>*/}
          {/*</Row>*/}
          <TableComp ref={this.table}
                     columns={columns}
                     apiRequest={this.apiRequest}
                     extraProps={{ scroll: { x: 1500 } }}
                     pagination={{
                       ...this.state.pagination,
                       defaultPageSize: 10,
                       pageSizeOptions: ['10', '25', '50', '100'],
                       showSizeChanger: true,
                       ...this.props.pagination
                     }} />


          <Drawer
            title="Invoice"
            placement="right"
            closable={true}
            onClose={async () => {
              this.setState({
                visible: false
              })
            }}
            visible={visible}
            width={800}>
            <InvoiceForm quoteId={quoteId}
                         quoteData={quoteData}
                         key={this.state.key}
                         handleInvoiceLead={() => {
                           this.setState({
                             visible: false

                           })
                           this.table.current.reload()

                         }} />
          </Drawer>

        </Card>

      </PageHeaderWrapper>)

  }
}


const mapStateToProps = ({ global }) => ({
  categories: global.categories,
  currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllQuote)
