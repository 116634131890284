import React, {PureComponent} from 'react'
import PageHeaderWrapper from '../../components/PageHeaderWrapper'
import {
    Form,
    Input, List,
    Divider,
    Button,
    Card,
    Select,
    Modal,
    Checkbox,
    Icon,
    Typography
} from 'antd'
import ReactQuill from 'react-quill'
import QuillMention from 'quill-mention'
import 'react-quill/dist/quill.snow.css'
import {notification} from 'antd/lib/index'
import Request from '../../request'
import {connect} from 'react-redux'
import {addLeadActivity} from '../lead/all/helpers'
import styles from './styles.less'

const striptags = require('striptags')
const {Paragraph} = Typography
const Option = {Select}
const atValues = [
    {id: 1, value: 'contactName'},
    {id: 2, value: 'ROOfficer'}
]


class Email extends PureComponent {
    saveAndShareEmails = async () => {
        let user = JSON.parse(localStorage.getItem('user'))
        let {subject, emailId, message} = this.state
        let {handleAddEmail, leadId, leadData} = this.props
        if (!emailId) {
            return notification.error({message: 'Enter emailId!'})
        }
        if (!subject) {
            return notification.error({message: 'Enter subject!'})
        }
        if (!message) {
            return notification.error({message: 'Enter message!'})
        }
        this.setState({btnLoader: true})
        var element = document.getElementsByClassName('ql-editor')
        element[1].innerHTML = ''
        this.setState({loading: true, emailLoader: true})
        let x = await Request.saveAndSendEmail({message, leadId, subject, emailId, leadData})

        this.setState({loading: false})
        if (!x.error) {
            notification.success({
                message: x.message
            })
            this.setState({message: '', subject: '', emailId: '', templateTitle: null, emailLoader: false})
            await addLeadActivity({
                values: {
                    message: `Email sent to user "${emailId}" with subject "${subject}"`,
                    leadId: leadId,
                    user: user && user.name,
                    type: 'email'
                }
            })

            if (handleAddEmail) {
                handleAddEmail()
            }
        } else {
            notification.error({
                message: 'Error Saving',
                description: x.message
            })
            this.setState({emailLoader: false})
        }
    }

    extractText = (message) => {
        message = message.replace(/{%=ROOfficer%}/g, this.state.currentUser.name)
        message = message.replace(/{%=contactName%}/g, this.state.contactName)
        return message

    }

    leadUpdatedAsContacted = async () => {
        let user = JSON.parse(localStorage.getItem('user'))
        let {handleAddEmail, leadId, leadData} = this.props
        if (leadData && (leadData.productList && !leadData.productList.length && !leadData.otherProduct)) {
            return notification.error({
                message: 'Please add Product'
            })
        }

        let condition = leadData && ((leadData.stage === 'New (Untouched)' || leadData.stage === 'Followup (New Untouched)') && (leadData.productList && leadData.productList.length || leadData.otherProduct))
        if (condition) {
            await Request.updateLead({_id: leadId, new: false, stage: 'Contacted'})
            await addLeadActivity({
                values: {
                    message: `Lead staged as "Contacted"`,
                    leadId: leadData._id,
                    user: user && user.name,
                    type: 'stage'
                }
            })
        }

    }


    sendSms = async () => {

        let message = striptags(this.state.message)
        let mobile = this.state.mobile
        let {handleAddEmail, leadId, leadData} = this.props
        let user = JSON.parse(localStorage.getItem('user'))
        this.setState({smsLoader: true})
        let xx = await Request.checkLeadsCall({leadId: leadId})
        if (xx.error) {
            return notification.error({
                message: 'Error Saving',
                description: xx.message
            })
        }

        let x = await Request.sendSms({message, mobile, leadId, leadData})
        if (!x.error) {
            notification.success({
                message: 'Sms sent'
            })
        }
        var element = document.getElementsByClassName('ql-editor')
        element[1].innerHTML = ''
        this.setState({smsLoader: false})
        await addLeadActivity({
            values: {
                message: `Sms sent to "${mobile}" `,
                leadId: leadId,
                user: user && user.name,
                type: 'message',
                textMessage: message,
                textNumber: mobile
            }
        })
        if (handleAddEmail) {
            handleAddEmail()
        }

    }

    constructor(props) {
        super(props)
        this.state = {
            templateList: [], addTemplate: false, emailChecked: false,
            whatsAppChecked: false, smsChecked: false, emailLoader: false, smsLoader: false
        }
    }

    async componentDidMount() {
        let user = JSON.parse(localStorage.getItem('user'))
        let {leadData} = this.props
        this.setState({
            emailId: (leadData.contact) ? leadData.contact.email : '',
//      mobile: (leadData.contact) ? leadData.contact.mobile : '',
            mobile: leadData && leadData.mobile ? leadData.mobile : (leadData.contact) ? leadData.contact.mobile : '',
            contactName: leadData.contact ? leadData.contact.firstName || '' : '',
            currentUser: user
        })
        this.getAllTemplates()
    }

    getAllTemplates = async () => {
        let x = await Request.getAllEmailTemplates({results: 100000})
        if (!x.error) {
            this.setState({templateList: x.data})
        } else {
            notification.error({
                message: 'Error Saving',
                description: x.message
            })
        }

    }

    mentionModule = {
        allowedChars: /^[A-Za-z\s]*$/,
        mentionDenotationChars: ['@'],
        source: function (searchTerm, renderList, mentionChar) {
            let values
            if (mentionChar === '@') {
                values = atValues
            }
            if (searchTerm.length === 0) {
                renderList(values, searchTerm)

            } else {
                const matches = []
                for (let i = 0; i < values.length; i++)
                    if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())) matches.push(values[i])
                renderList(matches, searchTerm)
            }
        },
        mentionContainerClass: styles.mentionContainer,
        onSelect: (item, insertItem) => {
            insertItem({...item, value: `{%=${item.value}%}`})
        },
        showDenotationChar: false
    }

    render() {
        const onTemplateCancel = () => {
            this.setState({addTemplate: false})
        }
        const onTemplateCreate = async () => {
            //empty template
            let x = await Request.addTemplate({title: this.state.templateTitle, content: this.state.templateContent})
            var element = document.getElementsByClassName('ql-editor')
            element[2].innerHTML = ''
            this.setState({addTemplate: false, templateContent: '', templateTitle: ''})

            if (!x.error) {
                await this.getAllTemplates()
                notification.success({
                    message: x.message
                })
            } else {
                notification.error({
                    message: 'Error Saving',
                    description: x.message
                })
            }

        }
        let user = JSON.parse(localStorage.getItem('user'))
        // let { subject, emailId, message } = this.state
        let {handleAddEmail, leadId, leadData} = this.props
        const {hideTitle, emailsData} = this.props
        const {templateList} = this.state
        let form = <div>
            {hideTitle && <React.Fragment>
                {/* <Card bordered={true}> */}
                <div style={{marginBottom: '15px'}}>
                    <Checkbox onChange={() => {
                        this.setState({emailChecked: !this.state.emailChecked})
                    }}>Email</Checkbox>
                    <Checkbox onChange={() => {
                        this.setState({whatsAppChecked: !this.state.whatsAppChecked})
                    }}>WhatsApp</Checkbox>
                    <Checkbox onChange={() => {
                        this.setState({smsChecked: !this.state.smsChecked})
                    }}>SMS</Checkbox>
                </div>
                <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    style={{
                        width: 225,
                        marginBottom: '10px'
                    }}
                    placeholder="Select Template"
                    onChange={content => {
                        var element = document.getElementsByClassName('ql-editor')
                        if (content == undefined) {
                            element[1].innerHTML = ''
                            return
                        }
                        content = `${content}`
                        this.setState({templateContent: ''}, () => {
                            element[1].innerHTML = content
                        })
                    }}
                >
                    {templateList &&
                        templateList.map((val, idn) => {
                            return (
                                <Option key={idn} value={val.content}>
                                    {val.title}
                                </Option>
                            )
                        })}
                </Select>
                <Button style={{marginLeft: '10px'}} type="primary" icon="plus" size="medium" onClick={() => {
                    this.setState({addTemplate: true})
                }}/>

                <Paragraph
                    style={{backgroundColor: 'white', padding: '10px'}}
                    editable={{
                        onChange: async val => {
                            this.setState({mobile: val}, async () => {
                                await Request.updateLead({
                                    _id: leadId,
                                    new: false,
                                    mobile: this.state.mobile
                                }).then((x) => {
                                    console.log(x, 'hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh')
                                })

                            })
                        }
                    }}>
                    {this.state.mobile
                        ? this.state.mobile
                        : 'Click to add Mobile Number'}
                </Paragraph>


                <Input style={{marginBottom: '10px'}} disabled={!this.state.emailChecked} value={this.state.emailId}
                       placeholder={'Enter Email'} onChange={(v) => {
                    this.setState({emailId: v.target.value})
                }}/>

                <Input style={{marginBottom: '10px'}} value={this.state.subject} disabled={!this.state.emailChecked}
                       placeholder={'Enter subject'} onChange={(v) => {
                    this.setState({subject: v.target.value})
                }}/>
                <ReactQuill placeholder={'Type message...'} onChange={(text) => {
                    this.setState({message: text})
                }}
                            modules={{mention: this.mentionModule}}
                />

                <div className={styles.quoteAdd}><Button type="primary"
                                                         loading={this.state.emailLoader || this.state.smsLoader}
                                                         disabled={!(this.state.emailChecked || this.state.whatsAppChecked || this.state.smsChecked)}
                                                         style={{marginTop: '5px'}} onClick={async () => {
                    let message = this.state.message
                    if (!message) {
                        return notification.error({message: 'Enter message!'})
                    }
                    let whatsappMessage = striptags(this.state.message)

                    if (this.state.emailChecked) {
                        this.saveAndShareEmails()
                    }
                    if (this.state.smsChecked) {
                        this.sendSms()
                    }
                    if (this.state.whatsAppChecked) {

                        let x = await Request.checkLeadsCall({leadId: leadId})
                        if (!x.error) {
                            whatsappMessage = this.extractText(whatsappMessage)
                            window.open(`http://api.whatsapp.com/send?phone=${this.state.mobile}&text=${whatsappMessage}&source=&data=`)
                            await addLeadActivity({
                                values: {
                                    message: `Whatsapp sent to "${this.state.mobile}"`,
                                    whatsAppMessage: whatsappMessage,
                                    leadId: leadId,
                                    user: user && user.name,
                                    type: 'whatsApp'
                                }
                            })
                            var element = document.getElementsByClassName('ql-editor')
                            element[1].innerHTML = ''
                            this.leadUpdatedAsContacted()
                        } else {
                            notification.error({
                                message: 'Error Saving',
                                description: x.message
                            })
                        }

                    }


                }}>Send</Button>
                    <a href={`/quote/add?leadId=${leadId}`} target={'_blank'}>
                        <Button type="primary"
                                style={{marginTop: '5px'}}>Add Quote</Button>
                    </a>
                </div>

                {/* </Card> */}
                {/* {emailsData && emailsData.length > 0 && <Card>
          <List
            bordered
            itemLayout="horizontal"
            dataSource={emailsData || []}
            size="small"
            header={<div>Total : {emailsData.length}</div>}
            renderItem={item => (
              <List.Item>
                <List.Item.Meta
                  title={item.subject}
                  description={S(item.message).stripTags().s}
                />
                <div>{item.emailId}</div>
              </List.Item>
            )}
          />
        </Card>} */}
                <Modal
                    visible={this.state.addTemplate}
                    title="Create a new Email Template"
                    okText="Create"
                    onCancel={onTemplateCancel}
                    onOk={onTemplateCreate}
                >

                    <Input style={{marginBottom: '10px'}} value={this.state.templateTitle}
                           placeholder={'Please input the title of Template!'} onChange={(v) => {
                        this.setState({templateTitle: v.target.value})
                    }}/>
                    <ReactQuill
                        placeholder={'Type Content...'}
                        onChange={(text) => {
                            this.setState({templateContent: text})
                        }}
                        modules={{mention: this.mentionModule}}
                    />

                </Modal>
            </React.Fragment>}
        </div>
        return (
            <React.Fragment>{!hideTitle ? <PageHeaderWrapper>{form}</PageHeaderWrapper> : form}</React.Fragment>
        )
    }
}

const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    pathname: router.location.pathname
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Email)
