import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Input, Icon, AutoComplete, Select, Button, Modal} from 'antd';
import classNames from 'classnames';
import Debounce from 'lodash-decorators/debounce';
import Bind from 'lodash-decorators/bind';
import styles from './index.less';
import Request from '../../request'
import _ from 'lodash'
import {push} from 'connected-react-router';
import {connect} from 'react-redux';
import {getPushPathWrapper} from '../../routes';

const {OptGroup, Option} = Select

class HeaderSearch extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        placeholder: PropTypes.string,
        onSearch: PropTypes.func,
        onPressEnter: PropTypes.func,
        defaultActiveFirstOption: PropTypes.bool,
        dataSource: PropTypes.array,
        defaultOpen: PropTypes.bool,
        onVisibleChange: PropTypes.func,
    };

    static defaultProps = {
        defaultActiveFirstOption: false,
        onPressEnter: () => {
        },
        onSearch: () => {
        },
        className: '',
        placeholder: '',
        dataSource: [],
        defaultOpen: false,
        onVisibleChange: () => {
        },
    };

    static getDerivedStateFromProps(props) {
        if ('open' in props) {
            return {
                searchMode: props.open,
            };
        }
        return null;
    }

    constructor(props) {
        super(props);
        this.state = {
            searchMode: props.defaultOpen,
            value: '',
            dataSource: {},
            searchValue: undefined
        };

        this.onSearch = _.debounce(this.onSearchData, 1000, true)

    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    onKeyDown = e => {
        if (e.key === 'Enter') {
            const {onPressEnter} = this.props;
            const {value} = this.state;
            this.timeout = setTimeout(() => {
                onPressEnter(value); // Fix duplicate onPressEnter
            }, 0);
        }
    };

    onSelect = (val) => {
        const {dispatch} = this.props
        let value = JSON.parse(val)
        if (value.type === 'lead') {
            dispatch(push(`/lead/detail?id=${value._id}`))
        }
        else if (value.type === 'contact') {
            dispatch(push(`/contact/history?id=${value._id}`))
        }
        this.setState({searchValue: value.val})
    }

    enterSearchMode = () => {
        const {onVisibleChange} = this.props;
        console.log(this.state.dataSource, this.state.value)
        onVisibleChange(true);
        this.setState({searchMode: true}, () => {
            const {searchMode} = this.state;
            if (searchMode) {
                this.input.focus();
            }
        });
    };

    leaveSearchMode = () => {
        this.setState({
            searchMode: false,
            value: '',
        });
    };

    onSearchData = async (searchText) => {
        if (searchText === '') {
            console.log(searchText, "lllllllllllllllllllllllllllllllllllllll")
            return false
        }
        Request.globalSearch(searchText, this.state.type)
            .then(data => {
                if (!data.error) {
                    this.setState({dataSource: data.data || {}})
                }
            })

    };

    onChange = (value) => {
        this.setState({searchValue: value})
    }
    showModal = () => {
        this.setState({
            key: Date.now(),
            visible: true
        })
    }
    handleOk = e => {
        console.log(e);
        this.setState({
            visible: false,
        });
    };


    // NOTE: 不能小于500，如果长按某键，第一次触发auto repeat的间隔是500ms，小于500会导致触发2次
    @Bind()
    @Debounce(500, {
        leading: true,
        trailing: false,
    })
    debouncePressEnter() {
        const {onPressEnter} = this.props;
        const {value} = this.state;
        onPressEnter(value);
    }


    render() {
        const {className, placeholder, open, ...restProps} = this.props;
        const {searchMode, searchValue, visible} = this.state;
        delete restProps.defaultOpen; // for rc-select not affected
        const inputClass = classNames(styles.input, {
            [styles.show]: searchMode,
        });
        const options = _.map(this.state.dataSource, (val, key) => (
            <OptGroup key={key} label={<span>
                {key.toUpperCase()}
      </span>}>
                {val.map(opt => (
                    <Option key={opt._id} value={JSON.stringify({
                        _id: opt._id,
                        type: key === 'leads' ? 'lead' : 'contact',
                        val: opt.firstName || opt.title
                    })}>
                        {key === 'leads' ? `(${opt.id}) ${opt.title}` : `${opt.firstName || ''} ${opt.lastName || ''}`}
                        <span style={{marginLeft: '10px'}}>{opt.mobile}</span>
                    </Option>
                ))}
            </OptGroup>
        ))


        return (
            <>
                <Button onClick={this.showModal} style={{marginRight: '10px'}}>
                    Code of Conduct
                </Button>

                <Select
                    style={{
                        width: 125,
                        marginRight: '3px'
                    }}
                    allowClear
                    placeholder="Select"
                    onChange={type => {
                        this.setState({type: type})
                    }}
                >
                    <Option value={'Lead'}>
                        Lead
                    </Option>
                    <Option value={'Contact'}>
                        Contact
                    </Option>
                </Select>
                <span
                    className={classNames(className, styles.headerSearch)}
                    onClick={this.enterSearchMode}
                    onTransitionEnd={({propertyName}) => {
                        if (propertyName === 'width' && !searchMode) {
                            const {onVisibleChange} = this.props;
                            onVisibleChange(searchMode);
                        }
                    }}
                >
                    {this.state.type && <Icon type="search" key="Icon"/>}
                    <AutoComplete
                        onSearch={this.onSearch}
                        onChange={this.onChange}
                        onSelect={this.onSelect}
                        dropdownMatchSelectWidth={false}
                        dataSource={options}
                        placeholder={`Search ${this.state.type}`}
                        key="AutoComplete"
                        // {...restProps}
                        className={inputClass}
                        value={searchValue}
                    >
          <Input
              ref={node => {
                  this.input = node;
              }}
              aria-label={placeholder}
              placeholder={placeholder}
              onKeyDown={this.onKeyDown}
              onBlur={this.leaveSearchMode}
          />
        </AutoComplete>
      </span>
                <Modal
                    width={800}
                    visible={visible}
                    title={'Guidelines'}
                    onCancel={() => this.setState({visible: false})}
                    onOk={this.handleOk}


                >

                    <div>
                        <ol className={styles.guideLines}>
                            <li>
                                Each and Every mail should be replied within next 24 hours.
                            </li>
                            <li>
                                All open leads should have atleast one upcoming followup.
                            </li>
                        </ol>
                        <p></p>

                    </div>


                </Modal>
            </>
        );
    }
}

const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HeaderSearch)
