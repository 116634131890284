import React, {Component} from 'react'
import Board from 'react-trello'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {TableComp} from 'sz-react-utils'
import {ExclamationCircleOutlined} from '@ant-design/icons'
import {LinkOutlined, FileImageOutlined} from '@ant-design/icons'

import moment from 'moment'
import {
    Card,
    Tooltip,
    Popconfirm,
    notification, Modal,
    Switch, Avatar,
    Row, Col, Popover, AutoComplete, Spin, Tag,
    Input, Button, Icon, Select, DatePicker
} from 'antd'
import Request from '../../../request'
import styles from './styles.less'
import {connect} from 'react-redux'
import {hideLoader, showLoader} from "../../../modules/actions";


const {Option} = Select


class ReportLeads extends Component {


    constructor(props) {
        super(props)
        this.table = React.createRef()
        const {dispatch} = props
        this.state = {
            leadStatuseArr: ['Open', 'won', 'Unqualified', 'Lost'],
            currentPage: 1,
            leadStage: ['New (Untouched)', 'Contacted', 'Proposal Presented', 'Followup (New Untouched)', 'Contacted Followup', 'Overdue Followup', 'Today Followup'],
            startRange: moment().startOf('day').toDate(),
            lastRange: moment().endOf('day').toDate()

        }

    }


    apiRequest = (params) => {
        const {dispatch} = this.props

        if (params.page) {
            this.state.currentPage = params.page
        }
        if (this.state.selectedLeadStage) {
            params.stage = this.state.selectedLeadStage
        }
        if (this.state.selectedStatus) {
            params.status = this.state.selectedStatus
        }
        if (this.state.groupField) {
            params.groupField = this.state.groupField
        }
        if (!params.sortOrder) {
            params.sortField = 'createdAt'
            params.sortOrder = 'desc'
        }


        return new Promise(async (resolve) => {

            this.setState({filters: {...params}})
            dispatch(showLoader())

            let data = await Request.getLeadsReport({
                ...params,
                leadDate: {$gte: this.state.startRange, $lte: this.state.lastRange},
                regExFilters: ['title', 'company', 'contact']
            })
            dispatch(hideLoader())

            console.log(data)
            resolve(data)
            this.setState({count: data.total})
        })
    }

    async componentDidMount() {

    }

    getStageColor = (stage) => {

        let returnColor = ''

        switch (stage) {
            case 'New (Untouched)':
                returnColor = '#FF3E4D'
                break
            case 'Contacted':
                returnColor = "#ff8c00"
                break
            case 'Today Followup':
                returnColor = "#26ae60"
                break
            case 'Proposal Presented':
                returnColor = "#00CCCD";
                break
            case 'Overdue Followup':
                returnColor = "#8B78E6";
                break
            case 'Contacted Followup':
                returnColor = "#9c27b0";
                break
            default:
                returnColor = 'green'

        }

        return returnColor

    }

    exportData = async () => {
        console.log(this.state.filters)
        let {contactNameId} = this.state

        let stageArr = []
        if (this.state.selectedLeadStage == undefined || this.state.selectedLeadStage == '') {
            stageArr = ['New (Untouched)', 'Contacted', 'Proposal Presented', 'Followup (New Untouched)', 'Contacted Followup', 'Overdue Followup', 'Today Followup', 'In Negotiation']
        } else {
            stageArr = this.state.selectedLeadStage
        }
        this.setState({startLoading: true})
        let data = await Request.getAllLeads({
            ...this.state.filters,
            export: true,
            customQuery: {
                stage: {$in: stageArr},
                contact: contactNameId ? contactNameId : undefined,
                officer: this.state.officerId ? this.state.officerId : undefined,

            },
        })
        this.setState({startLoading: false})
        if (!data.error) {
            return notification.success({message: 'Mail Sent'})

        } else {
            return notification.error({message: 'Error'})

        }


    }


    render() {

        const {submitting, currentUser, dispatch,} = this.props
        const {leadStatuseArr, leadStage, currentPage, selectedStatus, groupField} = this.state

        const columns = [
            {
                title: 'S.No',
                dataIndex: 'sno',
                key: 'sno',
                width: 20,
                render: (value, item, index) => (currentPage - 1) * 10 + index + 1
            },
            {
                title: 'Name',
                dataIndex: '_id',
                key: '_id',
                width: 80
            }, {
                title: 'Count',
                dataIndex: 'count',
                key: 'count',
                width: 80
            },


        ]
        const handleChangeDebut = (range) => {
            console.log('range is', range)
            if (!range.length) {
                this.setState({
                    startRange: moment().startOf('month').toDate(),
                    lastRange: moment().endOf('month').toDate()
                })
            } else {
                const valueOfInput1 = moment(range[0]).startOf('day').format()
                const valueOfInput2 = moment(range[1]).endOf('day').format()
                this.setState({...this.state, startRange: valueOfInput1, lastRange: valueOfInput2})
            }
            console.log('state changed')
            setTimeout(() => {

            }, 500)

        }


        return (<PageHeaderWrapper title={this.state.count ? `Records : ${this.state.count}` : `Record: 0`}>
            <div style={{margin: '-24px', padding: '0px'}}>
                <Card>
                    <Row>
                        <Col span={6} style={{marginRight: '15px'}}>
                            <DatePicker.RangePicker
                                style={{width: '100%'}}
                                format="DD-MM-YYYY"
                                value={[moment(this.state.startRange), moment(this.state.lastRange)]}
                                getPopupContainer={trigger => trigger.parentNode}
                                onChange={(value, dateString) => handleChangeDebut(value)}
                            />
                        </Col>

                        <Col span={5}><Select
                            showSearch
                            allowClear
                            optionFilterProp="children"
                            style={{width: 200}}
                            value={this.state.selectedLeadStage}
                            placeholder='Select Stage'
                            onChange={id => {
                                if (id == undefined) {
                                    this.setState({selectedLeadStage: ''}, () => {

                                    })
                                } else {
                                    this.setState({selectedLeadStage: id}, () => {

                                    })
                                }
                            }}
                        >
                            {leadStage && leadStage.map((val, idn) => {
                                return (
                                    <Option key={idn} value={val}>{val}</Option>
                                )
                            })}
                        </Select></Col>
                        <Col span={5}><Select
                            showSearch
                            // allowClear
                            optionFilterProp="children"
                            style={{width: 200}}
                            value={selectedStatus}
                            onChange={selectedStatus => {
                                this.setState({selectedStatus}, () => {
                                })
                            }}
                            placeholder='Select Status'
                        >
                            {leadStatuseArr && leadStatuseArr.map((val, id) => {
                                return (
                                    <Option key={val} value={val}>{val}</Option>
                                )
                            })}
                        </Select></Col>
                        <Col span={5}><Select
                            showSearch
                            // allowClear
                            optionFilterProp="children"
                            style={{width: 200}}
                            value={groupField}
                            onChange={groupField => {
                                this.setState({groupField}, () => {
                                    // this.apiRequest({page: 1})
                                    // this.table.current.reload()
                                })
                            }}
                            placeholder='Select Group By Field'
                        >
                            <Option key={'productNAme'} value={'productName'}>Product Name</Option>
                            <Option key={'state'} value={'state'}>State</Option>

                        </Select></Col>


                    </Row>
                    <div style={{display: 'inline-block'}}>
                        <Button loading={this.props.loading} style={{marginTop: '10px', marginRight: '10px'}}
                                onClick={() => {
                                    this.table.current.reload()

                                }} type="primary">Search</Button>


                        <Button loading={this.props.loading} style={{marginTop: '10px'}} onClick={() => {
                            this.setState({
                                startRange: moment().startOf('month').toDate(),
                                lastRange: moment().endOf('month').toDate(),
                                selectedLeadStage: undefined,
                                selectedStatus: undefined,
                                groupField: undefined
                            }, () => {
                                this.table.current.reload()
                            })
                        }}>Reset</Button>
                    </div>

                </Card>

                <Card className={styles.outerLeadCard}>
                    <div style={{backgroundColor: 'white'}}>
                        <TableComp ref={this.table}
                                   columns={columns}
                                   key={this.state.key}
                                   apiRequest={this.apiRequest}
                                   id='leadList'
                                   pagination={{
                                       ...this.state.pagination,
                                       defaultPageSize: 10,
                                       pageSizeOptions: ['10', '25', '50', '100'],
                                       showSizeChanger: true,
                                       ...this.props.pagination
                                   }}/>
                    </div>

                </Card>
            </div>


        </PageHeaderWrapper>)

    }
}

const mapStateToProps = ({global, router}) => ({
    categories: global.categories,
    currentUser: global.currentUser,
    search: router.location.search,
    loading: global.buttonLoading,

})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ReportLeads)
