import React, {Component} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {TableComp} from 'sz-react-utils'
import moment from 'moment'
import {
    Card,
    Tooltip,
    Row, Col, Button, Table
} from 'antd'
import Request from '../../../request'
import {connect} from 'react-redux'
import {getUrlPushWrapper, getUrlParams} from '../../../routes'

class ViewLedger extends Component {


    constructor(props) {
        super(props)
        this.table = React.createRef()
        this.state = {currentPage: 1}

    }


    async componentDidMount() {

        let searchParams = new URLSearchParams(this.props.search);
        let productId = searchParams.get('id')

        let {data: ledger, stockData: stockData} = await Request.ledgerByProductId({productId: productId})
        this.setState({
            ledger: ledger,
            stockData: stockData,
        })


    }

    render() {

        const {submitting, currentUser, dispatch,} = this.props
        const {ledger, stockData, currentPage} = this.state
        const column = [
            {
                title: 'S.No',
                dataIndex: 'sno',
                width: 50,
                key: 'sno',
                render: (value, item, index) => (currentPage - 1) * 10 + index + 1
            },
            {
                title: 'Inward',
                width: 50,
                key: 'Inward',
                render: (val, record) => {
                    if (record && record.type === 'Inward') {
                        return <div>
                            {record && record.quantity}
                            {record && record.dispatchId && <Tooltip title={'View Inward'}>
                                <a href={`/dispatch/inward/edit?id=${record.dispatchId}`}
                                   target={'_blank'}>
                                    <Button style={{marginLeft: '5px'}} size="small" icon={'eye'}/>
                                </a>
                            </Tooltip>}

                        </div>

                    }


                }


            },
            {
                title: 'Outward',
                width: 50,
                key: 'Outward',
                render: (val, record) => {
                    if (record && record.type === 'Outward')
                        return <div>
                            <Tooltip title={'View Outward'}>
                                {record && record.quantity}
                                <a href={`/dispatch/outward/edit?id=${record.dispatchId}`} target={'_blank'}>
                                    <Button style={{marginLeft: '5px'}} size="small" icon={'eye'}/>
                                </a>
                            </Tooltip>
                        </div>


                }


            },
            {
                title: 'Description',
                width: 100,
                key: 'description',
                dataIndex: 'description',
            },


            {
                title: 'Date',
                width: 100,
                key: 'date',
                dataIndex: 'date',
                render: (text, record) => {
                    return (
                        <div>
                            {text ? moment(text).format('lll') : ''}
                        </div>
                    )
                }
            }

        ]


        return (<PageHeaderWrapper title={"Product Ledger"}>
            <Card>

                <div style={{paddingBottom: '20px'}}>
                    <Row>
                        <Col span={6}>
                            <b>Product Name : </b>{ledger && ledger[0] ? ledger[0].productName : ''}

                        </Col>

                        <Col span={6}>
                            <b>Total Inward
                                : </b>{stockData ? stockData.inwardCount : 0}

                        </Col>
                        <Col span={6}>
                            <b>Total Outward
                                : </b>{stockData ? stockData.outwardCount : 0}

                        </Col>
                        <Col span={6}>
                            <b>Net Stock : </b>{stockData ? stockData.netStock : 0}

                        </Col>
                    </Row>
                </div>

                <Table
                    bordered={true}
                    rowKey={x => x._id}
                    columns={column}
                    dataSource={ledger || []}
                    pagination={true}
                />
            </Card>


        </PageHeaderWrapper>)

    }
}

const mapStateToProps = ({global, router}) => ({
    categories: global.categories,
    currentUser: global.currentUser,
    search: router.location.search

})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewLedger)
