import React, { Component } from 'react'
import {
  Card,
  Col,
  Row,
  Icon,
  Table,
  List,
  Radio,
  DatePicker,
  Spin,
  Tag,
  Switch,
  Tooltip,
  Divider,
} from 'antd'
import styles from './style.less'
import moment from 'moment'
import socketio, { io } from 'socket.io-client'
import _ from 'lodash'
import { Tooltip as BarTooltip, Pie, PieChart, Cell } from 'recharts'

import Request, { getCallColor } from '../../request'
import Emitter from '../../emitter'
import { TableComp, FormUtils as GetAllFormFields } from 'sz-react-utils'

import { getUrlPushWrapper } from '../../routes'
import { notification } from 'antd/lib/index'
import OrderStats from '../../components/DashComp/orderStats'
import ComplaintStats from '../../components/DashComp/complaintStats'

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  return (
    <text
      x={x}
      y={y}
      style={{ fontSize: '8px' }}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  )
}
const RADIAN = Math.PI / 180

class Dashboard extends Component {
  loadApisRequest = () => {
    this.setState({ startLoading: true })

    Request.getAllLeads({
      wonDate: {
        $gte: this.state.startRange,
        $lte: this.state.lastRange,
      },
      status: 'won',
      sortField: 'createdAt',
      sortOrder: 'desc',
      results: '10000',
      stage: {
        $in: [
          'New (Untouched)',
          'Contacted',
          'Proposal Presented',
          'Followup (New Untouched)',
          'Contacted Followup',
          'Overdue Followup',
          'Today Followup',
        ],
      },
    }).then((data) => {
      console.log(data, 'leads data')
      this.setState({ closingLeadsData: data.data })
    })

    Request.getLeadsCountForAdmin({
      leadDate: { $gte: this.state.startRange, $lte: this.state.lastRange },
    }).then((data) => {
      this.setState({
        leadCount: data.allLeads,
        unqualifiedLeads: data.unqualifiedLeads,
        wonLeads: data.wonLeads,
        lostLeads: data.lostLeads,
      })
    })

    Request.getAllProducts({ results: '10000', count: '10000' }).then(
      (data) => {
        this.setState({ productCount: data.total })
      }
    )
    Request.getTaskCountForDashboard({
      leadDate: {
        $gte: this.state.startRange,
        $lte: this.state.lastRange,
      },
    }).then(({ data: taskCount }) => {
      this.setState({ taskCount })
    })
    Request.leadsStageCount({
      leadDate: { $lte: moment().endOf('day').toDate() },
      status: ['Open'],
    }).then(({ data }) => {
      this.setState({ leadStageCount: data })
    })
    Request.dateWiseFollowupCount({
      followUpDate: {
        $gte: this.state.startRange,
        $lte: this.state.lastRange,
      },
    }).then(({ data }) => {
      this.setState({ followupsCount: data })
    })
    Request.dateWiseActivitiesCount({}).then(({ data }) => {
      this.setState({ activitiesCount: data })
    })

    Request.leadTypeChart({
      leadDate: { $gte: this.state.startRange, $lte: this.state.lastRange },
    }).then(({ data: leadTypeChart }) => {
      console.log(leadTypeChart)
      this.setState({ leadTypeChart })
    })

    this.setState({ startLoading: false })
  }

  constructor(props) {
    super(props)
    this.table = React.createRef()
    this.state = {
      startRange: moment().startOf('day').toDate(),
      lastRange: moment().endOf('day').toDate(),
      currentPage: 1,
      userType: ['Admin', 'RO', 'Dispatch', 'Technician'],
      changeStatus: [],
      key: null,
      newData: ['render'],
    }
  }

  apiRequest = (params) => {
    if (params.page) {
      this.state.currentPage = params.page
    }
    params.userType = 'RO'
    if (params.results && params.page) {
      this.setState({ changeStatus: [] })
    }
    return new Promise(async (resolve) => {
      let data = await Request.loadUser({
        ...params,
        regExFilters: ['mobile', 'name', 'email'],
      })
      _.each(data.data, (item, i) => {
        if (item.isLoggedIn) {
          this.changeLoginStatus(i, item.isLoggedIn)
        }
      })
      resolve(data)
    })
  }

  componentDidMount() {
    this.loadApisRequest()
    Emitter.on('reloadUsers', () => {
      this.table.current.reload()
    })
  }

  componentWillUnmount() {
    Emitter.off('reloadUsers', () => {})
  }

  updateUser = async (values) => {
    let x = await Request.updateUserDetails(values)
    if (!x.error) {
      notification.success({
        message: x.message,
      })
    } else {
      console.log('err is', x.error)
      notification.error({
        message: 'Error Saving',
        description: x.message,
      })
    }
  }

  changeLoginStatus = (key, value, userId) => {
    let { changeStatus } = this.state
    let newValue = _.clone(changeStatus)
    newValue[key] = value
    this.setState({ changeStatus: newValue }, () => {
      if (userId) this.updateUser({ _id: userId, isLoggedIn: value })
    })
  }

  render() {
    const {
      leadTypeChart,
      lostLeads,
      wonLeads,
      closingLeadsData,
      productCount,
      leadCount,
      taskCount,
      leadStageCount,
      activitiesCount,
      followupsCount,
      unqualifiedLeads,
      currentPage,
      userType,
    } = this.state
    const columns = [
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        width: 250,
      },
      {
        title: 'Company',
        dataIndex: 'company',
        key: 'company',
        width: 180,
      },
      {
        title: 'Relationship Officer',
        dataIndex: 'officer',
        key: 'officer',
        width: 180,
        render: (val, record) => {
          return (
            <div>
              <div>{val && val.name}</div>
              <div>{val && val.email}</div>
              <div>{val && val.mobile}</div>
            </div>
          )
        },
      },
      {
        title: 'Lead Source',
        dataIndex: 'source',
        key: 'source',
        width: 150,
      },
      {
        title: 'Lead Stage',
        dataIndex: 'stage',
        key: 'stage',
        width: 150,
      },

      {
        title: 'Lead Status',
        key: 'status',
        render: (text, record) => {
          return (
            <div
              style={{
                backgroundColor: '#43BE31',
                textAlign: 'center',
                color: 'white',
                padding: '2px 2px',
                borderRadius: '2px',
                minWidth: '30px',
              }}>
              {record.status ? record.status : ''}
            </div>
          )
        },
      },
      /* {
               title: 'Priority',
               key: 'priority',
               render: (text, record) => {
                 return (
                   <div>{record.priority ? record.priority : ''}</div>
                 )
               }
             }*/
    ]
    const handleChangeDebut = (range) => {
      console.log('range is', range)
      if (!range.length) {
        this.setState({
          startRange: moment().startOf('month').toDate(),
          lastRange: moment().endOf('month').toDate(),
        })
      } else {
        const valueOfInput1 = moment(range[0]).startOf('day').format()
        const valueOfInput2 = moment(range[1]).endOf('day').format()
        this.setState({
          ...this.state,
          startRange: valueOfInput1,
          lastRange: valueOfInput2,
        })
      }
      this.setState({ key: Date.now() })
      console.log('state changed')
      setTimeout(() => {
        this.loadApisRequest()
      }, 500)
    }

    const ROColumns = [
      {
        title: 'S.No',
        dataIndex: 'sno',
        width: 50,
        key: 'sno',
        //  fixed: 'left',
        render: (value, item, index) => (currentPage - 1) * 10 + index + 1,
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        searchTextName: 'name',
      },

      {
        title: 'Mobile',
        dataIndex: 'mobile',
        key: 'mobile',
        searchTextName: 'mobile',
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        searchTextName: 'email',
      },
      {
        title: 'Logged In',
        dataIndex: 'isLoggedIn',
        key: 'isLoggedIn',
        render: (val, record, key) => {
          return (
            <Switch
              checked={this.state.changeStatus[key]}
              onChange={(val) => {
                this.changeLoginStatus(key, val, record && record._id)
              }}></Switch>
          )
        },
        filters: [
          { value: true, text: 'Online' },
          { value: false, text: 'Offline' },
        ],
      },

      {
        title: 'Last Call',
        dataIndex: 'lastCallLogDetails',
        key: 'lastCallLogDetails',
        sorter: true,
        render: (ii, record) => {
          return (
            <div>
              <small>
                {ii && ii.callingTime
                  ? moment(ii.callingTime).format('lll')
                  : ''}
              </small>
              {ii && ii.duration ? (
                <small>
                  <br />
                  Duration: {moment.utc(ii.duration * 1000).format('HH:mm:ss')}
                </small>
              ) : (
                ''
              )}
              <br />
              {ii && ii.callType ? (
                <small>
                  {
                    <Tag
                      style={getCallColor(
                        ii.callType.toLowerCase(),
                        ii && ii.duration
                      )}>
                      {ii.callType}
                    </Tag>
                  }
                </small>
              ) : (
                ''
              )}
            </div>
          )
        },
        // width:100
      },
      {
        title: 'Inactive Since',
        dataIndex: 'inActiveTime',
        key: 'inActiveTime',
        sorter: true,
        searchDateName: 'inActiveTime',
        render: (val, record) => {
          return (
            record &&
            record.inActiveTime && (
              <Tooltip title={moment(record.inActiveTime).format('lll')}>
                {moment(record.inActiveTime).fromNow()}
              </Tooltip>
            )
          )
        },
        // width:100
      },
    ]

    return (
      <div>
        <div className="site-card-wrapper">
          <Card style={{ height: '180px' }}>
            <Row>
              <Col span={17}>
                <h4 style={{ display: 'inline-block', marginRight: '10px' }}>
                  Select Date Range
                </h4>
                <DatePicker.RangePicker
                  style={{ width: '59%' }}
                  format="DD-MM-YYYY"
                  value={[
                    moment(this.state.startRange),
                    moment(this.state.lastRange),
                  ]}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  onChange={(value, dateString) => handleChangeDebut(value)}
                />
                <Card
                  // title={<span>Follow Ups</span>}
                  bordered={true}
                  // headStyle={{
                  //   backgroundColor: '#6ab04c',
                  //   textAlign: 'center',
                  //   color: 'white',
                  //   fontSize: '15px',
                  // }}
                  bodyStyle={{ padding: '5px' }}
                  style={{
                    width: '77%',
                    marginTop: '10px',
                  }}>
                  <List
                    size="small"
                    bordered={false}
                    dataSource={['sjjss']}
                    renderItem={() => (
                      <>
                        <Row gutter={24}>
                          <Col span={12}>
                            <List.Item>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-around',
                                  width: '100%',
                                  color: '#FF362E',
                                }}>
                                <div
                                  style={{
                                    width: '60%',
                                    textAlign: 'center',
                                    color: '#FF362E',
                                  }}>
                                  New (Untouched)
                                </div>
                                {leadStageCount &&
                                leadStageCount.untouchedCount > 0 ? (
                                  <a
                                    target={'_blank'}
                                    href={`/openStage/${moment(
                                      this.state.lastRange
                                    ).format(
                                      'YYYY-MM-DD HH:mm'
                                    )}/NewUntouched`}>
                                    {leadStageCount.untouchedCount}
                                  </a>
                                ) : (
                                  0
                                )}
                              </div>
                            </List.Item>
                            <List.Item>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-around',
                                  width: '100%',
                                  color: '#ff8c00',
                                }}>
                                <div
                                  style={{
                                    color: '#ff8c00',
                                    width: '60%',
                                    textAlign: 'center',
                                  }}>
                                  Contacted
                                </div>
                                {leadStageCount &&
                                leadStageCount.contactedCount > 0 ? (
                                  <a
                                    target={'_blank'}
                                    href={`/openStage/${moment(
                                      this.state.lastRange
                                    ).format('YYYY-MM-DD HH:mm')}/Contacted`}>
                                    {leadStageCount.contactedCount}
                                  </a>
                                ) : (
                                  0
                                )}
                              </div>
                            </List.Item>
                          </Col>
                          <Col span={12}>
                            <List.Item>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-around',
                                  width: '100%',
                                  color: '#8B78E6',
                                }}>
                                <div
                                  style={{ width: '60%', textAlign: 'center' }}>
                                  In Negotiation
                                </div>
                                {leadStageCount &&
                                leadStageCount.negotiationCount > 0 ? (
                                  <a
                                    target={'_blank'}
                                    href={`/openStage/${moment(
                                      this.state.lastRange
                                    ).format(
                                      'YYYY-MM-DD HH:mm'
                                    )}/In Negotiation`}>
                                    {leadStageCount.negotiationCount}
                                  </a>
                                ) : (
                                  0
                                )}
                              </div>
                            </List.Item>
                            <List.Item>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-around',
                                  width: '100%',
                                  color: '#00CCCD',
                                }}>
                                <div
                                  style={{
                                    width: '60%',
                                    textAlign: 'center',
                                    color: '#00CCCD',
                                  }}>
                                  Proposal
                                </div>
                                {leadStageCount &&
                                leadStageCount.proposalCount > 0 ? (
                                  <a
                                    target={'_blank'}
                                    href={`/openStage/${moment(
                                      this.state.lastRange
                                    ).format(
                                      'YYYY-MM-DD HH:mm'
                                    )}/Proposal Presented`}>
                                    {leadStageCount.proposalCount}
                                  </a>
                                ) : (
                                  0
                                )}
                              </div>
                            </List.Item>
                          </Col>
                        </Row>
                      </>
                    )}></List>
                </Card>
              </Col>

              <Col span={7}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <PieChart
                    width={200}
                    height={150}
                    onMouseEnter={this.onPieEnter}>
                    <BarTooltip />
                    <Pie
                      data={leadTypeChart}
                      cx={70}
                      cy={70}
                      innerRadius={30}
                      outerRadius={70}
                      fill="#8884d8"
                      dataKey="value"
                      labelLine={false}
                      label={renderCustomizedLabel}>
                      {leadTypeChart &&
                        leadTypeChart.map((entry, index) => {
                          if (entry.name === 'IndiaMART Api') {
                            return (
                              <Cell key={`cell-${index}`} fill={'#1287A5'} />
                            )
                          } else if (entry.name === 'aajjo') {
                            return (
                              <Cell key={`cell-${index}`} fill={'#43BE31'} />
                            )
                          } else {
                            return (
                              <Cell key={`cell-${index}`} fill={'#F3B431'} />
                            )
                          }
                        })}
                    </Pie>
                  </PieChart>

                  <ul className={styles.dashboardList}>
                    <li className={styles.indiaMart}>IndiaMart</li>
                    <li className={styles.aajjo}>Aajjo</li>
                    <li className={styles.inbound}>Inbound</li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Card>

          <Spin spinning={this.state.startLoading}>
            <Row gutter={32}>
              <Col span={12}>
                <Row style={{ marginBottom: '15px' }} gutter={16}>
                  <Col>
                    <Card
                      title={<span>Leads Count</span>}
                      bordered={false}
                      headStyle={{
                        backgroundColor: '#6ab04c',
                        textAlign: 'center',
                        color: 'white',
                        fontSize: '15px',
                      }}
                      bodyStyle={{ padding: '7px' }}
                      style={{
                        marginRight: '10px',
                        height: '220px',
                        padding: '10px !important',
                        marginTop: '10px',
                      }}>
                      <List
                        size="small"
                        bordered={false}
                        dataSource={this.state.newData || []}
                        renderItem={(val) => (
                          <>
                            <List.Item>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-around',
                                  width: '100%',
                                }}>
                                <div
                                  style={{ width: '60%', textAlign: 'center' }}>
                                  All Leads
                                </div>
                                <a
                                  target={'_blank'}
                                  style={{ width: '40%', textAlign: 'center' }}>
                                  {leadCount ? leadCount : 0}
                                </a>
                              </div>
                            </List.Item>
                            <List.Item>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-around',
                                  width: '100%',
                                }}>
                                <div
                                  style={{ width: '60%', textAlign: 'center' }}>
                                  Unq Leads
                                </div>
                                <a
                                  target={'_blank'}
                                  href={`/status/${moment(
                                    this.state.lastRange
                                  ).format('YYYY-MM-DD HH:mm')}/${moment(
                                    this.state.startRange
                                  ).format('YYYY-MM-DD HH:mm')}/Unqualified`}
                                  style={{ width: '40%', textAlign: 'center' }}>
                                  {unqualifiedLeads ? unqualifiedLeads : 0}
                                </a>
                              </div>
                            </List.Item>
                            <List.Item>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-around',
                                  width: '100%',
                                }}>
                                <div
                                  style={{ width: '60%', textAlign: 'center' }}>
                                  Lost Leads
                                </div>
                                <a
                                  target={'_blank'}
                                  href={`/status/${moment(
                                    this.state.lastRange
                                  ).format('YYYY-MM-DD HH:mm')}/${moment(
                                    this.state.startRange
                                  ).format('YYYY-MM-DD HH:mm')}/Lost`}
                                  style={{ width: '40%', textAlign: 'center' }}>
                                  {lostLeads ? lostLeads : 0}
                                </a>
                              </div>
                            </List.Item>
                            <List.Item>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-around',
                                  width: '100%',
                                }}>
                                <div
                                  style={{ width: '60%', textAlign: 'center' }}>
                                  Won Leads
                                </div>
                                <a
                                  target={'_blank'}
                                  href={`/status/${moment(
                                    this.state.lastRange
                                  ).format('YYYY-MM-DD HH:mm')}/${moment(
                                    this.state.startRange
                                  ).format('YYYY-MM-DD HH:mm')}/won`}
                                  style={{ width: '40%', textAlign: 'center' }}>
                                  {wonLeads ? wonLeads : 0}
                                </a>
                              </div>
                            </List.Item>
                          </>
                        )}></List>
                    </Card>
                  </Col>
                </Row>
                <ComplaintStats
                  startRange={this.state.startRange}
                  lastRange={this.state.lastRange}
                  key={this.state.key}
                />
              </Col>
              {/* <Col span={4}></Col> */}
              <Col span={12}>
                <OrderStats
                  startRange={this.state.startRange}
                  lastRange={this.state.lastRange}
                  key={this.state.key}
                />
              </Col>
            </Row>

            <Row style={{ marginBottom: '15px' }} gutter={32}>
              <Col span={12}>
                <Card
                  title={<span>Follow Ups</span>}
                  bordered={false}
                  headStyle={{
                    backgroundColor: '#6ab04c',
                    textAlign: 'center',
                    color: 'white',
                    fontSize: '15px',
                  }}
                  bodyStyle={{ padding: '7px' }}
                  style={{
                    marginRight: '10px',
                    height: '175px',
                    padding: '10px !important',
                    marginTop: '10px',
                  }}>
                  <List
                    size="small"
                    bordered={false}
                    dataSource={followupsCount || []}
                    renderItem={(val) => (
                      <List.Item>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            width: '100%',
                          }}>
                          <div style={{ width: '60%', textAlign: 'center' }}>
                            {val.type}
                          </div>
                          <a
                            target={'_blank'}
                            href={`/followups/${moment(
                              this.state.lastRange
                            ).format('YYYY-MM-DD HH:mm')}/${moment(
                              this.state.startRange
                            ).format('YYYY-MM-DD HH:mm')}/${val.type}`}
                            style={{ width: '40%', textAlign: 'center' }}>
                            {val.count}
                          </a>
                        </div>
                      </List.Item>
                    )}></List>
                </Card>
              </Col>
              <Col span={12}>
                <Card
                  title={<span>Tasks</span>}
                  bordered={false}
                  headStyle={{
                    backgroundColor: '#03a9f4',
                    textAlign: 'center',
                    color: 'white',
                    fontSize: '15px',
                  }}
                  bodyStyle={{ padding: '7px' }}
                  style={{
                    marginRight: '10px',
                    height: '175px',
                    padding: '10px !important',
                    marginTop: '10px',
                  }}>
                  <Row>
                    <Col span={12}>
                      <div
                        style={{
                          fontSize: '18px',
                          textAlign: 'center',
                          fontWeight: 'bold',
                          color: 'red',
                          padding: '15px 0px',
                        }}>
                        {taskCount && taskCount[0].type}
                      </div>

                      <div
                        style={{
                          fontSize: '18px',
                          textAlign: 'center',
                        }}>
                        {taskCount && taskCount[0].count > 0 ? (
                          <a
                            target={'_blank'}
                            style={{ color: 'red' }}
                            href={`/taskManagment/${moment(
                              this.state.lastRange
                            ).format('YYYY-MM-DD HH:mm')}/${moment(
                              this.state.startRange
                            ).format('YYYY-MM-DD HH:mm')}/${
                              taskCount[0].type
                            }`}>
                            {taskCount[0].count}
                          </a>
                        ) : (
                          0
                        )}
                      </div>
                    </Col>
                    <Col span={12}>
                      <div
                        style={{
                          fontSize: '18px',
                          textAlign: 'center',
                          fontWeight: 'bold',
                          color: 'green',
                          padding: '15px 0px',
                        }}>
                        {taskCount && taskCount[1].type}
                      </div>

                      <div
                        style={{
                          fontSize: '18px',
                          textAlign: 'center',
                        }}>
                        {' '}
                        {taskCount && taskCount[1].count > 0 ? (
                          <a
                            target={'_blank'}
                            href={`/taskManagment/${moment(
                              this.state.lastRange
                            ).format('YYYY-MM-DD HH:mm')}/${moment(
                              this.state.startRange
                            ).format('YYYY-MM-DD HH:mm')}/${
                              taskCount[1].type
                            }`}>
                            {taskCount[1].count}
                          </a>
                        ) : (
                          0
                        )}
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>

            <Row gutter={16}>
              <Card>
                <TableComp
                  ref={this.table}
                  columns={ROColumns}
                  apiRequest={this.apiRequest}
                  // extraProps={}

                  pagination={{
                    ...this.state.pagination,
                    defaultPageSize: 10,
                    pageSizeOptions: ['10', '25', '50', '100'],
                    showSizeChanger: true,
                    ...this.props.pagination,
                  }}
                />
              </Card>
            </Row>

            <Row>
              <Col span={24}>
                <Card style={{ marginTop: '10px' }}>
                  <Table
                    columns={columns}
                    dataSource={closingLeadsData}
                    bordered
                    pagination={false}
                    size="small"
                    title={() => <b>Won Leads</b>}
                    scroll={{ y: 300, x: 1000 }}
                  />
                </Card>
              </Col>
            </Row>
          </Spin>
        </div>
      </div>
    )
  }
}

export default Dashboard
