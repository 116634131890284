import React, {PureComponent} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
    Form,
    Input,
    DatePicker,
    Collapse,
    Spin,
    Select,
    Transfer,
    Button,
    Table,
    Card,
    Row,
    Col,
    Date,
    Tag,
    InputNumber,
    Icon, Tooltip, Popconfirm, Avatar, Checkbox, Timeline, Popover
} from 'antd'
import {ChromePicker} from 'react-color'
import _ from 'lodash'
import moment from 'moment'
import {FormUtils as GetAllFormFields} from 'sz-react-utils'
// import GetAllFormFields from '../../../components/_utils/formUtils'

import {API_URL} from '../../../request'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {notification} from 'antd/lib/index'
import {hideLoader, showLoader} from '../../../modules/actions'
import Request from '../../../request'
import styles from '../../callLogs/styles.less'
import timeLineStyles from '../../lead/all/styles.less'

import {connect} from 'react-redux'
import {createMatchSelector, goBack} from 'connected-react-router'
import StateData from '../../../states'
import {getUrlPushWrapper} from '../../../routes'
import {apiUrl} from "../../../settings";
import {
    WinModal,
    LostModal,
    addLeadActivity,
    NoteActivityModal,
    EmailActivityModal,
    DealActivityModal,
    FollowUpActivityModal,
    WhatsAppActivityModal,
    TextActivityModal
} from '../../lead/all/helpers'
import ReactPlayer from 'react-player/lazy'
import S from "string";

const FormItem = Form.Item
const {Option} = Select
const {TextArea} = Input;

@Form.create()
class ContactHistory extends PureComponent {


    constructor(props) {
        super(props)
        this.state = {
            loader: true,
            noteModal: false,
            win: false,
            lost: false,
            popOver: false,
            emailModal: false,
            followUpModal: false,
            textActivityModal: false,
            whatsAppModal: false,
            activities: [],
            showPlayer: []
        }

    }

    apiRequest = async () => {
        let searchParams = new URLSearchParams(this.props.search);
        let contactId = searchParams.get('id')
        console.log(contactId, "[[[[[[[[[")
        // let contactId = '5f0ebaec7ed513148c5e65c7'
        //'5f688eb9867b9a189602235a'
        if (this.state.id === contactId) {
            return false;
        } else {
            this.state.id = contactId
        }

        if (contactId) {
            Request.getContactById({_id: contactId}).then(({data, error, message}) => {
                if (!error) {
                    this.setState({
                        id: contactId,
                        loader: false,
                        singleContact: data
                    })


                } else {
                    notification.error({
                        message: 'Error Getting Data'
                    })
                }
            })
            //  let { data: leadsTable } = await Request.getAllLeads({ contact: contactId, results: 100000000000000 })
            let {data: leadsTable, totalCount: totalCount} = await Request.getContactLeads({contactId: contactId})


            console.log(totalCount, "4444444444444444444444444", leadsTable)


            this.setState({

                leadsTable: leadsTable,
                totalActivity: totalCount
            })

        }
        else {
            this.setState({

                loader: false

            })
        }

    }

    componentDidMount() {
    }

    handleVisibleChange = (key, value) => {
        let newData = _.clone(this.state.showPlayer)
        newData[key] = value
        this.setState({showPlayer: newData})
    }

    render() {
        this.apiRequest()
        const {submitting, currentUser} = this.props
        const {
            form: {getFieldDecorator, getFieldValue}
        } = this.props

        const {singleContact, totalActivity, leadsTable, showPlayer} = this.state

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
                md: {span: 8}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
                md: {span: 12}
            }
        }
        const formItemLayout1 = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
                md: {span: 4}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
                md: {span: 12}
            }
        }
        const onCancel = () => {
            this.setState({
                ...this.state, lost: false, win: false
            })
        }
        const cancelActivityModal = () => {
            this.setState({
                noteModal: false,
                emailModal: false,
                followUpModal: false,
                dealActivityModal: false,
                whatsAppModal: false,
                textActivityModal: false,
            })
        }


        const submitFormLayout = {
            wrapperCol: {
                xs: {span: 24, offset: 0},
                sm: {span: 10, offset: 7},
                md: {span: 12, offset: 8}
            }
        }
        const getCallColor = (callType, duration) => {
            if (callType === 'outgoing' && duration == 0) {
                return {

                    color: "#FBB917",
                    fontWeight: 'bold'

                }
            }
            else if (callType === 'outgoing' && duration > 0) {
                return {
                    color: "#43BE31",
                    fontWeight: 'bold'

                }
            }
            else if (callType === 'incoming') {
                return {

                    color: "#1287A5",
                    fontWeight: 'bold'

                }
            }
            else {
                return {
                    color: "red",
                    fontWeight: 'bold'

                }
            }
        }
        const setActivity = (activity, newKey, key) => {
            if (activity.type === "value" || activity.type === "status" || activity.type === "stage" || activity.type === "closeDate")
                return <Timeline.Item
                    key={newKey}>{activity.message} by <b>{activity.user}</b> on <b>{moment(activity.createdAt).format("lll")}</b></Timeline.Item>
            else if (activity.type === "notes")
                return <Timeline.Item key={newKey}>Note : "{S(activity.noteMessage).stripTags().s}" added
                    at <b>{moment(activity.createdAt).format('lll')}</b></Timeline.Item>
            else if (activity.type === "email")
                return <Timeline.Item key={newKey}><a onClick={() => {
                    this.setState({emailModal: true, emailActivity: activity});
                }}>{activity.message} by <b>{activity.user}</b> on <b>{moment(activity.createdAt).format("lll")}</b></a>
                </Timeline.Item>
            // else if (activity.type == "file")
            else if (activity.type === "whatsApp")
                return <Timeline.Item key={newKey}><a onClick={() => {
                    this.setState({whatsAppModal: true, whatsAppActivity: activity});
                }}>{activity.message} by <b>{activity.user}</b> on <b>{moment(activity.createdAt).format("lll")}</b></a>
                </Timeline.Item>
            else if (activity.type === "file")
                return <Timeline.Item
                    key={newKey}>{activity.message} by <b>{activity.user}</b> on <b>{moment(activity.createdAt).format("lll")}</b><a
                    style={{marginLeft: "10px"}} href={apiUrl + `${activity.fileUrl}`}
                    target="_blank">
                    <mark>{activity.fileName}</mark>
                </a></Timeline.Item>
            else if (activity.type === "followUp")
                return <Timeline.Item key={newKey}><a onClick={() => {
                    this.setState({followUpModal: true, followupActivity: activity});
                }}>{activity.message} by <b>{activity.user}</b> on <b>{moment(activity.createdAt).format("lll")}</b></a>
                </Timeline.Item>

            else if (activity.type === "message")
                return <Timeline.Item key={newKey}><a onClick={() => {
                    this.setState({textActivityModal: true, textActivity: activity});
                }}>{activity.message} by <b>{activity.user}</b> on <b>{moment(activity.createdAt).format("lll")}</b></a>
                </Timeline.Item>

            else if (activity.type === "activity")
                return <Timeline.Item key={newKey}><a onClick={() => {
                    this.setState({dealActivityModal: true, dealActivity: activity});
                }}>{activity.message} by <b>{activity.user}</b> on <b>{moment(activity.createdAt).format("lll")}</b></a>
                </Timeline.Item>
            else if (activity.type === "recording")
                return <Timeline.Item key={newKey}>
                    <span>{activity.message}</span> by <b>{activity.user}</b> on <b>{moment(activity.createdAt).format("lll")}</b>
                    <Popover
                        content={<ReactPlayer
                            url={`${apiUrl}/recordingStream?path=${activity.filePath}&isPath=true`}
                            controls
                            height={50}
                            className={styles.player}
                            width={400}
                        />}
                        placement={'bottomRight'}
                        trigger="click"
                        destroyTooltipOnHide
                        visible={showPlayer[key]}
                        onVisibleChange={(val) => this.handleVisibleChange(key, val)}
                    >
                        <Icon className={styles.playBtn} size='small' type='play-circle'/>
                    </Popover>

                    <Icon className={styles.playBtn} size='small' type='download'
                          onClick={() => {
                              Request.downloadWithPath(activity.filePath)
                          }}/>
                </Timeline.Item>

            else if (activity.type === "callLog") {
                let recordId = activity && activity.callLogId && activity.callLogId.recording
                return <Timeline.Item key={newKey}>
                                            <span>{activity && activity.callLogId ? <Tag
                                                style={getCallColor(activity.callLogId.callType, activity.callDuration)}>{_.startCase(activity.callLogId.callType)}</Tag> : ''}</span> Call
                    of duration <span>{moment.utc(activity.callDuration * 1000).format('HH:mm:ss')} </span>
                    of <b>{activity.user}</b> on <b>{moment(activity.followUpDate).format("lll")}</b>
                    {recordId && <>
                        <Popover
                            content={<ReactPlayer
                                url={`${apiUrl}/recordingStream?id=${recordId}`}
                                controls
                                height={50}
                                className={styles.player}
                                width={400}
                            />}
                            placement={'bottomRight'}
                            trigger="click"
                            destroyTooltipOnHide
                            visible={showPlayer[key]}
                            onVisibleChange={(val) => this.handleVisibleChange(key, val)}
                        >
                            <Icon className={styles.playBtn} size='small' type='play-circle'/>
                        </Popover>
                        <Icon className={styles.playBtn} size='small' type='download'
                              onClick={() => {
                                  Request.downloadWithPath(`recordings/${recordId}.mp3`)
                              }}/>

                    </>}

                </Timeline.Item>
            }
            else if (activity.type === "info")
                return <Timeline.Item
                    key={newKey}><a>{activity.message} by <b>{activity.user}</b> on <b>{moment(activity.createdAt).format("lll")}</b></a>
                </Timeline.Item>
        }

        return (
            <PageHeaderWrapper
                title={'Contact History'}
            >
                <Card bordered={true} style={{margin: '10px'}}>
                    <Card bordered={true} style={{margin: '10px'}}>

                        <Row>
                            <Col span={10}>

                                <h2 style={{marginBottom: '0px'}}> {singleContact && singleContact.firstName} {singleContact && singleContact.lastName ? singleContact.lastName : ''}
                                </h2>
                                <span> {singleContact && singleContact.mobile}</span> <br/>
                                <span>{singleContact && singleContact.email}</span>
                            </Col>

                        </Row>

                    </Card>
                    <div style={{margin: '10px'}}><h3>Activities ({totalActivity ? totalActivity : 0})</h3></div>

                    <div style={{margin: '10px'}}>  {_.map(leadsTable, (ii) => {
                        return <Card title={<div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <span>
                                <a href={`/lead/detail?id=${ii && ii._id}`} target={'_blank'}>{ii.title} ({ii.id})</a>

                            </span> <span>{ii && moment(ii.createdAt).format("lll")}</span>
                        </div>} style={{marginBottom: '10px'}}>

                            {ii.activities && ii.activities.length && ii.activities.map((activity, key) => {
                                return <div className={timeLineStyles.timeLineDiv}>
                                    <Tag color={'lightgreen'}
                                         style={{marginBottom: '10px'}}>{activity && activity.length && moment(activity[0].createdAt).format('YYYY-MM-DD')}</Tag>

                                    <Timeline mode={'left'}>
                                        {_.map(activity, (iii, kk) => {
                                            let newKey = iii._id
                                            if (ii && ii.leadAssignedDate && currentUser && currentUser.userType === 'RO') {
                                                if (moment(activity.createdAt).isAfter(ii.leadAssignedDate)) {
                                                    return setActivity(iii, newKey, kk)
                                                }


                                            } else {
                                                return setActivity(iii, newKey, kk)
                                            }

                                        })}
                                    </Timeline>
                                </div>


                            })}


                        </Card>
                    })}
                    </div>
                </Card>
                {this.state.win && WinModal(
                    {onCancel, state: {...this.state}, form: {...this.props.form}})}
                {this.state.lost && LostModal(
                    {onCancel, state: {...this.state}, form: {...this.props.form}})}
                {this.state.noteModal && NoteActivityModal(
                    {cancelActivityModal, state: {...this.state},})}
                {this.state.emailModal && EmailActivityModal(
                    {cancelActivityModal, state: {...this.state}})}
                {this.state.followUpModal && FollowUpActivityModal(
                    {cancelActivityModal, state: {...this.state},})}
                {this.state.dealActivityModal && DealActivityModal(
                    {cancelActivityModal, state: {...this.state},})}
                {this.state.whatsAppModal && WhatsAppActivityModal(
                    {cancelActivityModal, state: {...this.state},})}
                {this.state.textActivityModal && TextActivityModal(
                    {cancelActivityModal, state: {...this.state},})}

            </PageHeaderWrapper>
        )
    }
}

const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search,
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ContactHistory)
