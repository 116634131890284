import React, { PureComponent } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
  Form,
  Input,
  notification,
  Anchor,
  Spin,
  Select,
  Tag,
  Button,
  Table,
  Card,
  Typography,
  Row,
  Col,
  InputNumber,
  Descriptions,
  Icon, Tooltip, Popconfirm, Avatar, Checkbox, AutoComplete
} from 'antd'
import _ from 'lodash'
import { FormUtils as GetAllFormFields } from 'sz-react-utils'
import moment from 'moment'
import FormatNumber from 'indian-number-format'
import { API_URL } from '../../../request'
import 'react-quill/dist/quill.snow.css'
import { hideLoader, showLoader } from '../../../modules/actions'
import Request from '../../../request'
import styles from './styles.less'
import { connect } from 'react-redux'
import { createMatchSelector, goBack } from 'connected-react-router'
import { apiUrl, pdfUrl } from '../../../settings'
import { addLeadActivity } from '../../lead/all/helpers'

const { Text, Paragraph } = Typography
const { Option } = Select

@Form.create()
class AddQuote extends PureComponent {

  handleSubmit = e => {
    const { dispatch, form, currentUser } = this.props
    let { sendNotification, notifications, totalData, freightCharge, bankCharge } = this.state
    e.preventDefault()

    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        let values = _.clone(valData)


        dispatch(showLoader())
        let x = null

        this.setState({ sendLoading: true })

        if (totalData && totalData.length == 0) {
          dispatch(hideLoader())
          this.setState({ sendLoading: false })
          return notification.error({
            message: 'Atleast add one product'
          })
        }
        if (this.state.showError || this.state.grandTotal == 0) {
          dispatch(hideLoader())
          this.setState({ sendLoading: false })
          return notification.error({
            message: 'Amount cannot be zero'
          })
        }


        _.each(totalData, (ii) => {
          ii.totalAmount = parseFloat((ii.totalAmount).toFixed(2))
          ii.amount = parseFloat((ii.amount).toFixed(2))
        })

        values.productList = totalData
        values.approved = this.state.approved
        if (this.state.approved) {
          values.status = 'Approved'
        } else {
          values.status = 'Unapproved'
        }
        values.leadId = this.state.leadId
        values.tradeTerms = this.state.tradeTerms
        values.grandTotal = parseFloat((this.state.grandTotal).toFixed(2))
        values.user = this.props.currentUser.name
        values.subTotal = parseFloat((this.state.subTotal || 0).toFixed(2))
        values.bankCharge = bankCharge
        values.freightCharge = freightCharge
        if (!notifications.mail && !notifications.whatsapp && !notifications.message && sendNotification) {
          dispatch(hideLoader())
          this.setState({ sendLoading: false })
          return notification.error({
            message: 'Atleast select one notification'
          })
        }
        if (sendNotification)
          values.notifications = { ...notifications }

        if (this.state.id) {
          values._id = this.state.id
          values.approved = this.state.approved
          if (this.state.approved) {
            values.status = 'Approved'
          } else {
            values.status = 'Unapproved'
          }
          if (this.state.adminApproved) {
            values.notifications = { 'mail': false, 'text': false, 'whatsapp': false }
            values.status = 'Approved'
            values.approved = true
          }

          x = await Request.updateOutsideQuote(values)
          if (this.state.notifications.whatsapp) {
            let pdfId = x && x.data && x.data._id
            let mobileNumber = x && x.mobile ? x.mobile : this.state.contactMobile
            console.log(mobileNumber, 'lllllllllllllllllllllllllll')
            let msg = `Please Find Attached your quote from Hindustan Thermostatics. ${API_URL}/media/${pdfId}.pdf \n\n\n
                                                 Connect with us on Whatsapp: https://signupforservices.com/whatsapp/optin/?bId=a73133cc-6fc1-4fba-b696-28a96e2e733f&bName=hindustanthermostatics&s=URL&lang=en_US"\`
`
            setTimeout(() => {
              addLeadActivity({
                values: {
                  message: `Whatsapp sent to ${mobileNumber}`,
                  whatsAppMessage: msg,
                  leadId: this.state.leadId,
                  user: this.props.currentUser.name,
                  type: 'whatsApp'
                }
              })
              window.open(`http://api.whatsapp.com/send?phone=${mobileNumber}&text=${msg} `)
            }, 1000)
          }
        } else {
          values.leadId = this.state.leadId
          x = await Request.addOutsideQuote(values)
          let pdfId = x && x.data && x.data._id
          let mobileNumber = x && x.mobile ? x.mobile : this.state.contactMobile

          let msg = `Please Find Attached your quote from Hindustan Thermostatics. ${API_URL}/media/${pdfId}.pdf \n\n\n
                              Connect with us on Whatsapp: https://signupforservices.com/whatsapp/optin/?bId=a73133cc-6fc1-4fba-b696-28a96e2e733f&bName=hindustanthermostatics&s=URL&lang=en_US"\`
`
          if (!x.error) {
            if (this.state.notifications.whatsapp) {
              setTimeout(() => {
                addLeadActivity({
                  values: {
                    message: `Whatsapp sent to ${mobileNumber}`,
                    whatsAppMessage: msg,
                    leadId: this.state.leadId,
                    user: this.props.currentUser.name,
                    type: 'whatsApp'
                  }
                })
                window.open(`http://api.whatsapp.com/send?phone=${mobileNumber}&text=${msg}`)

              }, 1000)
            }
          }
        }

        this.setState({ sendLoading: false })
        dispatch(hideLoader())
        if (!x.error) {
          if (!this.state.id) {
            this.props.form.resetFields()
            this.props.form.setFieldsValue({ date: moment(), code: '63916513400825', iecNumber: '3399001347' })
            this.setState({
              totalData: [],
              subTotal: 0,
              showEditButton: false,
              isEdited: false,
              freightCharge: 0,
              bankCharge: 0,
              grandTotal: 0,
              approved: true,
              contactMobile: ''


            })

            if (currentUser && currentUser.userType === 'RO') {
              this.props.form.setFieldsValue({
                quoteOwner: currentUser._id
              })
              this.setState({ isRO: true })
            }
          }
          notification.success({
            message: this.state.id
              ? x.message
              : x.message
          })
          if (this.state.id) {
            dispatch(goBack())
          }

        } else {
          notification.error({
            message: 'Error Saving',
            description: x.message
          })
        }

      }
    })
  }

  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      loader: false,
      userList: [],
      allContacts: [],
      productList: [],
      totalData: [],
      popVisible: false,
      time: Date.now(),
      disableSend: false,
      notifications: { 'mail': true, 'text': false, 'whatsapp': false },
      leadNames: [],
      contact: {},
      leadId: '',
      leadNamesFiltered: [],
      approved: true,
      showEditButton: false,
      carriersData: ['Fedex', 'DHL', 'UPS', 'USPS', 'BlueDart', 'DTDC'],
      contactSearchText: '',
      freightCharge: 0,
      bankCharge: 0
    }
    this.AddData = _.debounce(this.addData, 1000, true)
    this.searchLead = _.debounce(this.handleDealsSearch, 100, true)

  }

  handleOk = async e => {
    let {
      quantity, productName, amount, hsnCode
    } = this.state
    if (!productName) {
      return notification.error({
        message: 'Enter Product Name'
      })
    }

    if (!hsnCode) {
      return notification.error({
        message: 'Enter HSN Code'
      })
    }

    if (quantity < 0 || quantity == 0 || !quantity) {
      return notification.error({
        message: 'Incorrect Quantity'
      })
    }
    if (amount < 0 || amount == 0 || !amount) {
      return notification.error({
        message: 'Incorrect Amount'
      })
    }


    if (productName && quantity && amount && hsnCode) {
      this.addData()

    }


  }


  addData = () => {


    let tt = _.cloneDeep(this.state.totalData)
    let found = _.find(tt, (i) => {
      return (i && i.productName && i.productName.toLowerCase() == this.state.productName.toLowerCase())
    })

    if (found) {
      this.setState({ showText: false })
      return notification.error({
        message: 'Product Already Exists.'
      })
    }
    tt.push({
      quantity: this.state.quantity,
      amount: this.state.amount,
      productName: this.state.productName,
      totalAmount: this.state.amount * this.state.quantity,
      hsnCode: this.state.hsnCode
    })

    this.setState({
      totalData: tt,
      productName: null,
      quantity: null,
      amount: null,
      totalAmount: null,
      hsnCode: null


    }, () => {

      this.totalCalculate()
    })

  }

  totalCalculate = async () => {
    let { bankCharge, freightCharge } = this.state
    let label1 = 0
    let grandTotal = 0
    let showError = false
    _.each(this.state.totalData, (i) => {
      label1 = i.totalAmount + label1
      grandTotal = grandTotal + i.totalAmount
      if (i.totalAmount == 0) {
        if (!showError) {
          showError = true
        }

      }


    })

    if (showError) {
      this.setState({ showError: true })
    } else {
      this.setState({ showError: false })
    }


    this.setState({
      subTotal: label1,
      grandTotal: grandTotal + freightCharge + bankCharge
    }, () => {
    })


  }

  getOptionsData = async (lead) => {
    // const { currentUser } = this.props
    let currentUser = JSON.parse(window.localStorage.getItem('user'))
    let filter = { userType: 'RO', results: 10000000000000000 }
    if (currentUser && currentUser.userType === 'RO') {
      filter._id = currentUser._id
      this.props.form.setFieldsValue({
        quoteOwner: currentUser._id
      })
      this.setState({ isRO: true })
    }

    Request.loadUser(filter).then(({ data: userList }) => {
      this.setState({ userList: userList || [] })
    })
    Request.getAllLeads(currentUser && currentUser.userType === 'RO' ? {
      officer: currentUser._id,
      status: 'Open', sortField: 'createdAt',
      sortOrder: 'desc',
      _id: lead
    } : {
      status: 'Open', sortField: 'createdAt',
      sortOrder: 'desc',
      _id: lead
    }).then(({ data: leadNames }) => {
      this.setState({ leadNames: leadNames || [], leadNamesFiltered: leadNames || [] }, () => {
        if (lead) {
          this.onDealSelect(lead)
        }
      })
    })

  }

  handleDealsSearch = async (test) => {
    let obj = {}
    this.setState({ showSpin: true })

    let currentUser = JSON.parse(window.localStorage.getItem('user'))

    if (currentUser && currentUser.userType === 'RO') {
      obj.officer = currentUser._id
    }
    if (test === '') {
      this.setState({ leadNamesFiltered: this.state.leadNames }, () => {
        this.setState({
          showSpin: false,
          isEdited: false,
          showEditButton: false,
          billingData: null,
          shippingData: null
        })
        this.props.form.setFieldsValue({
          contactName: undefined,
          companyName: undefined
        })

      })
      return false

    }
    obj.text = test
    let { data, error, message } = await Request.leadsSearch(obj)
    if (!error) {

      this.setState({ leadNamesFiltered: data }, () => {
        this.setState({ showSpin: false })

      })
    }
  }

  onDealSelect = async (val) => {
    let selectedQuote = this.state.leadNamesFiltered.find((obj) => obj._id === val)
    if (selectedQuote) {
      this.props.form.setFieldsValue({
        dealName: selectedQuote.title
      })
      this.state.leadId = selectedQuote._id
      this.state.contactMobile = selectedQuote.contact.mobile
    }
  }

  async componentDidMount() {
    let searchParams = new URLSearchParams(this.props.search)
    let currentUser = JSON.parse(window.localStorage.getItem('user'))
    let quoteId = searchParams.get('id')
    this.getOptionsData()

    if (quoteId) {
      this.setState({ loader: true })
      Request.getOutsideQuoteById({ _id: quoteId }).then(({ data: quoteData, error, message }) => {

        if (quoteData && quoteData.approved == undefined) {
          this.setState({ approved: true })
        } else {
          if (currentUser && currentUser.userType == 'RO') {
            this.setState({ approved: quoteData && quoteData.approved })
          } else {
            this.setState({ approved: quoteData && quoteData.approved, disableSend: true })
          }
        }

        if (!error) {
          this.setState({
            id: quoteId,
            loader: false,
            totalData: quoteData.productList,
            bankCharge: quoteData && quoteData.bankCharge,
            freightCharge: quoteData && quoteData.freightCharge,
            leadId: quoteData && quoteData.leadId ? quoteData.leadId : ''

          }, () => {

            this.totalCalculate()

          })
          this.props.form.setFieldsValue({

            invoiceNumber: quoteData && quoteData.invoiceNumber,
            iecNumber: quoteData && quoteData.iecNumber,
            code: quoteData && quoteData.code,
            consignee: quoteData && quoteData.consignee,
            receiptPlace: quoteData && quoteData.receiptPlace,
            dischargePort: quoteData && quoteData.dischargePort,
            netWeight: quoteData && quoteData.netWeight,
            date: quoteData && moment(quoteData.date),
            shipTo: quoteData && quoteData.shipTo,
            destination: quoteData && quoteData.destination,
            tradeTerms: quoteData && quoteData.tradeTerms,
            finalDestination: quoteData && quoteData.finalDestination,
            noOfBoxes: quoteData && quoteData.noOfBoxes,
            payment: quoteData && quoteData.payment,
            dealName: quoteData && quoteData.dealName

          })
        } else {
          notification.error({
            message: 'Error Getting Data'
          })
        }
      })


    } else {
      this.props.form.setFieldsValue({ date: moment(), code: '63916513400825', iecNumber: '3399001347' })
      this.setState({

        loader: false

      })

    }


  }


  setSocial = (field, val) => {
    let newData = _.clone(this.state.notifications)
    newData[field] = val
    this.setState({ notifications: newData })
  }

  calculateRoundOff = (value) => {
    value = parseFloat(value)
    let splitValue = value.toFixed(2).split('.')
    if (splitValue[1] && parseInt(splitValue[1])) {

      let newValue = FormatNumber.formatFixed(value - parseInt(splitValue[0]), 2)
      this.setState({ roundValue: newValue })
      return <div>- {`₹${newValue}`}</div>

      //  return <div> {`₹${FormatNumber.formatFixed((Math.round((value || 0))), 0)}`}</div>

    } else {
      this.setState({ roundValue: 0 })

      return '-'
    }
  }

  render() {
    const {
      form,
      form: { getFieldDecorator, getFieldValue }
    } = this.props

    const { freightCharge, hsnCode, quantity, amount, id, sendLoading, productName, bankCharge, leadNamesFiltered, showSpin } = this.state

    const contactDetails = {
      fields: [

        {
          label: 'Invoice Number',
          key: 'invoiceNumber',
          required: true


        },
        {
          label: 'IEC Number',
          key: 'iecNumber',
          required: true


        },
        {
          label: 'AD Code',
          key: 'code',
          required: true


        },
        {
          label: 'Consignee',
          key: 'consignee',
          required: true


        },
        {
          label: 'Place of Receipt',
          key: 'receiptPlace'
        },
        {
          label: 'Port of Discharge',
          key: 'dischargePort'
        },
        {
          label: 'Net Weight',
          key: 'netWeight',
          required: true,
          type: 'number'


        }


      ]
    }

    const contactDetails1 = {
      fields: [

        {
          label: 'Date',
          key: 'date',
          type: 'date',
          required: true

        },
        // {
        //   label: 'Title',
        //   key: 'title',
        //   type: 'select',
        //   options: ['Mr.', 'Mrs.'],
        //   onChange: (title) => {
        //     this.props.form.setFieldsValue({
        //       title: title
        //     })
        //
        //   }
        //
        // },
        {
          label: 'Ship To',
          key: 'shipTo',
          type: 'textArea',
          required: true,
          rows: 5

        },

        {
          label: 'Destination',
          key: 'destination'
        },

        {
          label: 'Final Destination',
          key: 'finalDestination'
        },
        {
          label: 'No of Boxes',
          key: 'noOfBoxes',
          required: true,
          type: 'number'


        }
        , {
          label: 'Payment',
          key: 'payment',
          required: true

        }
      ]
    }


    const refColumns = [


      {
        title: 'Product',
        dataIndex: 'productName',
        key: 'productName',
        width: 130
      },
      {
        title: 'HSN Code',
        dataIndex: 'hsnCode',
        key: 'hsnCode',
        width: 130
      },

      {
        title: 'Quantity',
        key: 'quantity',
        width: 100,
        dataIndex: 'quantity',
        render: (y, item, index) => {
          return <div><Text editable={{
            onChange: (val) => {

              if (val === '' || val == 0) {
                return false
              }
              if (!isNaN(parseInt(val))) {
                let tt = this.state.totalData
                item.quantity = val ? Math.abs(val) : tt[index].quantity

                item.totalAmount = item.amount * item.quantity

                this.setState({ totalData: tt }, () => {
                  this.totalCalculate()
                })

              }


            }
          }}> {y}</Text>


          </div>


        }

      },


      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        width: 150,
        render: (val, item, index) => {
          return (<div><InputNumber min={0}
                                    value={item && item.amount ? `$${FormatNumber.formatFixed(parseFloat(item.amount), 2)}` : 0}
                                    onBlur={(e) => {
                                      let currentUser = JSON.parse(window.localStorage.getItem('user'))

                                      let vall = e.target.value
                                      if (vall === '' || vall == 0) {
                                        return false
                                      }
                                      let tt = this.state.totalData
                                      tt[index].amount = parseFloat(vall) || 0
                                      tt[index].totalAmount = item.amount * item.quantity

                                      if (currentUser && currentUser.userType === 'RO') {
                                        this.state.approved = false
                                      }
                                      this.setState({ totalData: tt }, () => {
                                        this.totalCalculate()
                                      })
                                    }}>

          </InputNumber></div>)


        }


      },

      {
        title: 'Total Amount',
        dataIndex: 'totalAmount',
        width: 150,
        key: 'totalAmount',
        render: (y, item) => {
          return `$${FormatNumber.formatFixed(y, 2)}`

        }


      },
      {
        title: 'Action',
        key: 'operation',
        width: 80,
        render: (item, record) => {
          return <React.Fragment>

            <Tooltip title="Delete Product">
              <Popconfirm
                title="Are you sure delete this Product?"
                onConfirm={() => {
                  let tt = this.state.totalData
                  tt = _.reject(tt, item)
                  this.setState({ totalData: tt }, () => {
                    this.totalCalculate()
                  })


                }}
                onCancel={() => {
                  console.log()
                }}
                okText="Yes"
                cancelText="No">
                <Button
                  shape="circle"
                  size="small"
                  type={'danger'}
                  icon="delete"
                />
              </Popconfirm>

            </Tooltip>

          </React.Fragment>

        }
      }
    ]


    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 12 }
      }
    }


    return (
      <PageHeaderWrapper
        title={this.state.id ? 'Update USD Quote' : 'Add USD Quote'}
      >

        <Spin spinning={this.state.loader}>
          <div>
            <Form onSubmit={this.handleSubmit} hideRequiredMark={false} style={{ marginTop: 8 }}>

              <Card bordered={true} style={{ marginBottom: '15px' }} title='Quote Information'>

                <Row>
                  <Col span={12}>
                    <GetAllFormFields apiurl={API_URL}
                                      inputSchema={contactDetails}
                                      formItemLayout={formItemLayout}
                                      getFieldDecorator={getFieldDecorator} />
                    <Form.Item label={'Terms of Trade'} {...formItemLayout}>
                      {getFieldDecorator('tradeTerms', {
                        rules: [{
                          required: true,
                          message: 'This is a Mandatory Field'
                        }]
                      })(
                        <Checkbox.Group
                          checked={true}
                          className={styles.checkbox}
                          options={['Freight additional as actual',
                            'Payment 100% advance',
                            'Local duty & taxes as applicable',
                            'warranty as per the product',
                            'Delivery as per requirement',
                            'Installation and training extra']}
                          onChange={(checkedValues) => {
                            this.setState({ tradeTerms: checkedValues })

                          }}
                        />)}
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      label='Lead Name'
                      {...formItemLayout}
                    >{getFieldDecorator('dealName', {
                      rules: [{
                        required: true,
                        message: 'This is a Mandatory Field'
                      }]
                    })(
                      <AutoComplete
                        notFoundContent={showSpin && <Spin />}

                        autoComplete={'none'}
                        onSelect={this.onDealSelect}
                        onSearch={this.searchLead}
                        placeholder={'Lead Name'}

                      >
                        {leadNamesFiltered.map((val, item) => (
                          <Option value={val._id}>
                            <>
                              <div><b>{`${val.title} (${val.id})`}</b></div>
                              <div className={styles.autoCompleteList}
                                   style={{ marginTop: '0px' }}>{`${val.contact && val.contact.email ? val.contact.email : ''}`}</div>
                              <div
                                className={styles.autoCompleteList}>{`${val.contact && val.contact.firstName} (${val.contact && val.contact.mobile})`}</div>
                              <div
                                className={styles.autoCompleteList}>{`Company: ${val.company || ''}`}</div>
                              <Tag>{`${moment(val.createdAt).format('DD-MM-YYYY HH:mm')}`}</Tag>
                            </>
                          </Option>
                        ))}
                      </AutoComplete>
                    )}
                    </Form.Item>
                    <GetAllFormFields apiurl={API_URL}
                                      inputSchema={contactDetails1}
                                      formItemLayout={formItemLayout}
                                      getFieldDecorator={getFieldDecorator} />
                  </Col>
                </Row>
              </Card>


              <Card bordered={true} style={{ marginBottom: '15px' }}
                    title={<div style={{
                      display: 'flex', justifyContent: 'space-between'
                    }}><h4>Product Details</h4>

                      <div>
                        <Button onClick={() => {
                          this.setState({ totalData: [] }, () => {
                          })
                          let currentUser = JSON.parse(window.localStorage.getItem('user'))

                          if (currentUser && currentUser.userType === 'RO') {
                            this.setState({ approved: true })
                          }

                        }}>Reset</Button>
                      </div>
                    </div>}>

                <div>
                  <Card style={{ marginTop: '10px', marginBottom: '10px' }}>
                    <Row gutter={6}>
                      <Col span={6}>
                        <h4 style={{ marginRight: '10px' }}>Product Name</h4>

                        <Input value={productName} onChange={(val) => {
                          let vall = val.target.value
                          if (vall === '') {
                            return false
                          }
                          this.setState({ productName: vall })
                        }}>

                        </Input>
                      </Col>
                      <Col span={6}>
                        <h4 style={{ marginRight: '10px' }}>HSN Code</h4>

                        <Input value={hsnCode} onChange={(val) => {
                          let vall = val.target.value
                          if (vall === '') {
                            return false
                          }
                          this.setState({ hsnCode: vall })
                        }}>

                        </Input>
                      </Col>
                      <Col span={4}>
                        <h4 style={{ marginRight: '10px' }}>Quantity</h4>
                        <InputNumber
                          value={quantity}
                          onBlur={(val) => {

                            let vall = val.target.value
                            if (vall === '') {
                              return false
                            }
                            this.setState({ quantity: parseInt(vall) })


                          }}

                        >

                        </InputNumber>


                      </Col>
                      <Col span={4}>
                        <h4 style={{ marginRight: '10px' }}>Amount</h4>
                        <InputNumber
                          value={amount}
                          onBlur={(val) => {

                            let vall = val.target.value
                            if (vall === '') {
                              return false
                            }
                            this.setState({ amount: parseInt(vall) })


                          }}

                        >

                        </InputNumber>


                      </Col>
                      <Col span={4} style={{ marginTop: '30px' }}>
                        <Tooltip title={'Add'}>
                          <Button
                            type="primary"
                            size="small"
                            onClick={() => {
                              this.handleOk()

                            }}
                            icon={'plus'}
                          />


                        </Tooltip>

                      </Col>

                    </Row>

                  </Card>


                </div>

                <div className={styles.addItem}>

                  {this.state.totalData && this.state.totalData.length ? <>
                    <Row> <Col span={24}>
                      {<Table
                        bordered={true}
                        rowKey={x => x.productName}
                        columns={refColumns}
                        dataSource={this.state.totalData || []}
                        pagination={false}
                        scroll={{ x: true }}
                      />}

                    </Col>
                    </Row>
                    <div style={{
                      display: 'flex',
                      justifyContent: 'flex-end'
                    }}>
                      <div className={styles.subTotal}>
                        <Descriptions column={1} size={'small'}
                                      bordered={false}>
                          <Descriptions.Item
                            label={<b>Sub
                              Total</b>}> {`$${FormatNumber.formatFixed((this.state.subTotal || 0), 2)}`}

                          </Descriptions.Item>

                          <Descriptions.Item
                            label={
                              <b>Freight Charges</b>}> <InputNumber
                            value={`$${FormatNumber.formatFixed(parseFloat(freightCharge), 2)}`}
                            onBlur={(e) => {
                              let vall = e.target.value

                              this.setState({ freightCharge: parseInt(vall) || 0 }, () => {
                                this.totalCalculate()
                              })
                            }}>

                          </InputNumber>

                          </Descriptions.Item>
                          <Descriptions.Item
                            label={
                              <b>Bank Charges</b>}> <InputNumber
                            value={`$${FormatNumber.formatFixed(parseFloat(bankCharge), 2)}`}
                            onBlur={(e) => {
                              let vall = e.target.value
                              this.setState({ bankCharge: parseInt(vall) || 0 }, () => {
                                this.totalCalculate()
                              })
                            }}>

                          </InputNumber>

                          </Descriptions.Item>
                          <Descriptions.Item
                            label={<b>Grand
                              Total</b>}>{`$${FormatNumber.formatFixed(((this.state.grandTotal || 0)), 2)}`}
                          </Descriptions.Item>
                        </Descriptions>
                      </div>
                    </div>
                  </> : ''}

                </div>
              </Card>
              <Card bordered={true} style={{ marginBottom: '15px' }}>
                <div className={styles.notification}>
                  <Checkbox
                    disabled={(this.props.currentUser.userType == 'RO') ? (!this.state.approved) : false}
                    defaultChecked={true} onChange={(e) => {
                    this.setSocial('mail', e.target.checked)
                  }}><Icon style={{ color: '#E8290B' }} type='mail' /> Mail</Checkbox>
                  <Checkbox
                    disabled={(this.props.currentUser.userType == 'RO') ? (!this.state.approved) : false}
                    defaultChecked={false} onChange={(e) => {
                    this.setSocial('whatsapp', e.target.checked)
                  }}><Icon style={{ color: '#45CE30' }} type='message' /> Whatsapp</Checkbox>
                  <Checkbox
                    disabled={(this.props.currentUser.userType == 'RO') ? (!this.state.approved) : false}
                    defaultChecked={false} onChange={(e) => {
                    this.setSocial('message', e.target.checked)
                  }}><Icon style={{ color: '#0A79DF' }} type='message' /> Text</Checkbox>
                </div>
              </Card>

              <Form.Item style={{ marginTop: 32, textAlign: 'center' }}>
                <Button size="large" type="primary" htmlType="submit" onClick={() => {
                  this.setState({
                    sendNotification: false
                  })
                }}
                        loading={this.props.loading}
                        style={{ width: '15%' }}>
                  {id ? `Update` : `Save`}

                </Button>
                <Button size="large" type='dashed'
                        loading={sendLoading}
                        htmlType='submit'
                        disabled={this.props.currentUser.userType == 'RO' ? !this.state.approved : false}
                        onClick={() => {
                          this.setState({
                            sendNotification: true,
                            approved: true,
                            adminApproved: false
                          })
                        }}
                        style={{ width: '15%', marginLeft: '1em' }}>
                  {(this.state.approved == false) ? 'Approve and Send' : 'Save and Send'}
                </Button>


                <Button size="large" type='dashed'
                        hidden={((this.props.currentUser.userType == 'Admin' || this.props.currentUser.userType == 'Manager') && !this.state.approved) ? false : true}
                        loading={sendLoading}
                        htmlType='submit'
                        onClick={async () => {
                          this.setState({ adminApproved: true })

                        }}
                        style={{ width: '15%', marginLeft: '1em' }}>Approve
                </Button>


              </Form.Item>


            </Form>
          </div>


        </Spin>

      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  categories: global.categories,
  search: router.location.search,
  currentUser: global.currentUser

})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddQuote)
