import React, { PureComponent } from 'react'
// import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import PageHeaderWrapper from '../PageHeaderWrapper'
import {
  Form,
  Input,
  Spin,
  Button,
  Card,
  List,
  Row,
  Col,
  Checkbox,
  Upload,
  Icon,
  Select,
  AutoComplete,
  Tag,
  Radio,
  Descriptions,
} from 'antd'
import _ from 'lodash'
import moment from 'moment'

import { getUrlPushWrapper } from '../../routes'
import { API_URL } from '../../request'

import 'react-quill/dist/quill.snow.css'
import { notification } from 'antd/lib/index'
// import { hideLoader, showLoader } from '../../../modules/actions'
import Request from '../../request'
import { connect } from 'react-redux'
import { createMatchSelector, goBack } from 'connected-react-router'

import styles from './styles.less'

const { Option } = Select

class ComplaintStats extends PureComponent {

 

  constructor(props) {
    super(props)
    this.state = {
      total: null,
      newData: ["one"],
    }
  }

  async componentDidMount() {
    let params = {
      startRange: this.props.startRange,
      lastRange: this.props.lastRange,
    }
  
    const { data, error } = await Request.getComplaintCount({ ...params })
    if (!error) {
      if (data.length > 0) {
      
        this.setState({ total: data[0], newData: data })
      }
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.key !== this.props.key) {
      this.componentDidMount()
    }
  } 

  render() {
    const { dispatch } = this.props
    const { total } = this.state

    return (
      <>
        <Row style={{ marginBottom: '15px' }} gutter={16}>
          <Col>
            <Card
              title={<span>Complaint Count</span>}
              bordered={false}
              headStyle={{
                backgroundColor: '#FF6347 ',
                textAlign: 'center',
                color: 'white',
                fontSize: '15px',
              }}
              bodyStyle={{ padding: '7px' }}
              style={{
                marginRight: '10px',
                height: '250px',
                padding: '10px !important',
                marginTop: '10px',
              }}>
              <List
                size="small"
                bordered={false}
                dataSource={this.state.newData || []}
                renderItem={(val) => (
                  <>
                    <List.Item>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-around',
                          width: '100%',
                        }}>
                        <div style={{ width: '60%', textAlign: 'center' }}>
                          Open
                        </div>
                        <a
                          target={'_blank'}
                          href={`/complaint/closeComplaintForAdmin?type=Open`}
                          
                          style={{ width: '40%', textAlign: 'center' }}>
                          {total !== null ? total.open : 0}
                        </a>
                      </div>
                    </List.Item>
                    <List.Item>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-around',
                          width: '100%',
                        }}>
                        <div style={{ width: '60%', textAlign: 'center' }}>
                          Fixed By Technician
                        </div>
                        <a
                          target={'_blank'}
                          href={`/complaint/closeComplaintForAdmin?type=Fixed By Technician`}

                          // onClick={() => {
                          //   dispatch(
                          //     getUrlPushWrapper('Complaint.list', {
                          //       type: 'Fixed By Technician',
                          //     })
                          //   )
                          // }}
                          style={{ width: '40%', textAlign: 'center' }}>
                          {total !== null ? total.fixedByTech : 0}
                        </a>
                      </div>
                    </List.Item>
                    <List.Item>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-around',
                          width: '100%',
                        }}>
                        <div style={{ width: '60%', textAlign: 'center' }}>
                         Complaint To Head Office
                        </div>
                        <a
                          target={'_blank'}
                          href={`/complaint/closeComplaintForAdmin?type=Complaint To Head Office`}

                          // onClick={() => {
                          //   dispatch(
                          //     getUrlPushWrapper('Complaint.list', {
                          //       type: 'Fixed By Technician',
                          //     })
                          //   )
                          // }}
                          style={{ width: '40%', textAlign: 'center' }}>
                          {total !== null ? total.complaintToHeadOffice : 0}
                        </a>
                      </div>
                    </List.Item>
                    <List.Item>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-around',
                          width: '100%',
                        }}>
                        <div style={{ width: '60%', textAlign: 'center' }}>
                          Transfer To ASC
                        </div>
                        <a
                          target={'_blank'}
                          href={`/complaint/closeComplaintForAdmin?type=Transfer To ASC`}

                          // onClick={() => {
                          //   dispatch(
                          //     getUrlPushWrapper('Complaint.list', {
                          //       type: 'Fixed By Technician',
                          //     })
                          //   )
                          // }}
                          style={{ width: '40%', textAlign: 'center' }}>
                          {total !== null ? total.transferToAsc : 0}
                        </a>
                      </div>
                    </List.Item>
                    <List.Item>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-around',
                          width: '100%',
                        }}>
                        <div style={{ width: '60%', textAlign: 'center' }}>
                          Close
                        </div>
                        <a
                          target={'_blank'}
                          href={`/complaint/closeComplaintForAdmin?type=Close`}

                          // onClick={() => {
                          //   dispatch(
                          //     getUrlPushWrapper('Complaint.list', {
                          //       type: 'Close',
                          //     })
                          //   )
                          // }}
                          style={{ width: '40%', textAlign: 'center' }}>
                          {total !== null ? total.close : 0}
                        </a>
                      </div>
                    </List.Item>
                  </>
                )}></List>
            </Card>
          </Col>
        </Row>
      </>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  categories: global.categories,
  search: router.location.search,
})
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ComplaintStats)
