import React, {Component} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {TableComp} from 'sz-react-utils'
import moment from 'moment'
import {
    Table,
    Badge,
    Card,
    Tooltip,
    Popconfirm,
    notification,
    Row,
    Col,
    Select,
    Drawer,
    Switch,
    AutoComplete,
    Spin,
    Input,
    Button,
    Icon,
    Tag,
} from 'antd'
import Request from '../../../request'
import _ from 'lodash'
//import styles from './styles.less'
import {connect} from 'react-redux'
import {getUrlPushWrapper} from '../../../routes'

const {Option} = Select

class AllVoucher extends Component {
    setDrawerData(record) {
        this.setState({productList: record.products, drawerVisible: true})
    }

    deleteVoucher = async (data) => {
        this.setState({loading: true})
        let x = await Request.deleteVoucher(data)
        this.setState({loading: false})

        this.table.current.reload()
        if (!x.error) {
            notification.success({
                message: x.message,
                duration: 20,
                key: `${data._id}-close`,
            })
        } else {
            notification.error({
                message: x.message || 'Try Again',
                duration: 20,
                key: `${data._id}-close`,
            })
        }
    }

    apiRequest = (params) => {
        if (params.page) {
            this.state.currentPage = params.page
        }
        return new Promise(async (resolve) => {
            if (!params.sortOrder) {
                params.sortField = 'voucherNumber'
                params.sortOrder = 'desc'
            }
            let user = {...JSON.parse(localStorage.getItem('user'))}
            if (user.userType === 'RO') {
                params.createdByUserId = user._id
            }
            let tt = await Request.AllVoucher({
                ...params,
                type: 'Dispatch',
                regExFilters: [],
            })
            this.setState({count: tt.total})
            resolve(tt)
        })
    }
    downloadInvoiceSheet = async (item, key) => {
        this.setLoaderForFile(key, true)

        let resp = await Request.downloadInvoiceSheet(item)
        this.setLoaderForFile(key, false)
    }

    constructor(props) {
        super(props)
        this.table = React.createRef()
        this.state = {
            visible: false,
            currentPage: 1,
            key: Date.now(),
            totalData: [],
            productList: [],
            drawerVisible: false,
            fileDownloadLoader: [],
        }
    }

    setLoaderForFile = (key, value) => {
        let {fileDownloadLoader} = this.state
        let newLoading = _.clone(fileDownloadLoader)
        newLoading[key] = value
        this.setState({fileDownloadLoader: newLoading})
    }

    async componentDidMount() {
        let {data: userList} = await Request.loadUser({
            customQuery: {userType: ['RO', 'Admin', 'Dispatch', 'Technician']},
            results: 1000000000,
        })
        console.log(userList.length)
        this.setState({userList: userList})
    }

    render() {
        const {submitting, dispatch, currentUser} = this.props
        const {currentPage} = this.state
        const userListArray =
            this.state.userList &&
            this.state.userList.map((user) => {
                return {
                    text: user.name,
                    value: user._id,
                }
            })

        const columns = [
            {
                title: 'S.No',
                dataIndex: 'sno',
                width: 100,
                key: 'sno',
                render: (value, item, index) => (currentPage - 1) * 10 + index + 1,
            },
            {
                title: 'Voucher No',
                dataIndex: 'voucherNumber',
                width: 150,
                key: 'voucherNumber',
                searchTextName: 'voucherNumber',
            },
            {
                title: 'Created By',
                width: 150,
                dataIndex: 'createdByUserId',
                key: 'createdByUserId',
                filters: userListArray,
                render: (val, record) => {
                    return (
                        <div>
                            <div>
                                {record &&
                                record.createdByUserId &&
                                record.createdByUserId.name}
                            </div>
                            <div>
                                {record &&
                                record.createdByUserId &&
                                record.createdByUserId.mobile}
                            </div>
                            <div>
                                {record &&
                                record.createdByUserId &&
                                record.createdByUserId.email}
                            </div>
                        </div>
                    )
                },
            },
            {
                title: 'Created At',
                key: 'voucherDate',
                width: 150,
                dataIndex: 'voucherDate',
                searchDateName: 'voucherDate',
                render: (text, record) => {
                    return <Tag>{text ? moment(text).format('lll') : ''}</Tag>
                },
                sorter: true,
            },
            {
                key: 'actions',
                title: 'Actions',
                width: 120,
                render: (text, record, key) => {
                    return (
                        <React.Fragment>
                            <Tooltip title="Edit Voucher">
                                <Button
                                    shape="circle"
                                    style={{marginRight: 6}}
                                    size="small"
                                    onClick={() => {
                                        dispatch(
                                            getUrlPushWrapper('voucher.editDispatch', {
                                                id: record._id,
                                            })
                                        )
                                    }}
                                    icon="edit"
                                />
                            </Tooltip>
                            <Tooltip title="Delete Voucher">
                                <Popconfirm
                                    title="Are you sure delete this Voucher?"
                                    onConfirm={() => {
                                        this.deleteVoucher({_id: record._id})
                                    }}
                                    onCancel={() => {
                                    }}
                                    okText="Yes"
                                    cancelText="No">
                                    <Button
                                        shape="circle"
                                        size="small"
                                        style={{marginRight: 6}}
                                        icon="delete"
                                    />
                                </Popconfirm>
                            </Tooltip>

                            <Tooltip title="View Products">
                                <Button
                                    type="warning"
                                    size="small"
                                    shape="circle"
                                    style={{marginTop: '6px'}}
                                    onClick={() => {
                                        this.setDrawerData(record)
                                    }}
                                    icon="eye"
                                />
                            </Tooltip>
                        </React.Fragment>
                    )
                },
            },
        ]

        const drawerCol = [
            {
                title: 'S.No',
                dataIndex: 'sno',
                key: 'sno',
                width: 80,
                render: (value, item, index) => (currentPage - 1) * 10 + index + 1,
            },
            {
                title: 'Product',
                width: 150,
                dataIndex: 'productId.name',
                key: 'name',
            },

            {
                title: 'Quantity',
                dataIndex: 'qty',
                key: 'qty',
                render: (text) => Math.abs(text),
            },
            {
                title: 'Serial No.',
                width: 120,
                dataIndex: 'serialNumbers',
                key: 'serialNumbers',
                render: (text) => text.toString(),
            },
        ]

        return (
            <PageHeaderWrapper
                title={
                    this.state.count
                        ? `All Dispatch Vouchers: ${this.state.count}`
                        : `All Dispatch Voucher: 0`
                }>
                <Card bordered={true}>
                    <Row>
                        <Col>
                            <TableComp
                                bordered={true}
                                ref={this.table}
                                columns={columns}
                                apiRequest={this.apiRequest}
                                rowKey={(x) => x.voucherNumber}
                                pagination={{
                                    ...this.state.pagination,
                                    defaultPageSize: 10,
                                    pageSizeOptions: ['10', '25', '50', '100'],
                                    showSizeChanger: true,
                                    ...this.props.pagination,
                                }}
                            />
                        </Col>
                    </Row>
                </Card>
                <Drawer
                    title="Product list"
                    placement="right"
                    width={'50%'}
                    closable={false}
                    onClose={() => {
                        this.setState({drawerVisible: false})
                    }}
                    visible={this.state.drawerVisible}>
                    <Card bordered={true}>
                        <Table
                            bordered={true}
                            rowKey={(x) => x.voucherNumber}
                            columns={drawerCol}
                            dataSource={this.state.productList || []}
                            pagination={false}
                        />
                    </Card>
                </Drawer>
            </PageHeaderWrapper>
        )
    }
}

const mapStateToProps = ({global}) => ({
    categories: global.categories,
    currentUser: global.currentUser,
})
const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AllVoucher)
