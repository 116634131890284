import React, {PureComponent} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
    Form,
    Input,
    DatePicker,
    Select,
    Button, Table,
    Card,
    Icon
} from 'antd'
import {ChromePicker} from 'react-color'
import _ from 'lodash'
import async from 'async'

import moment from 'moment'
import {FormUtils as GetAllFormFields} from 'sz-react-utils'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {notification} from 'antd/lib/index'
import {hideLoader, showLoader} from '../../../modules/actions'
import Request from '../../../request'
import {API_URL} from '../../../request'

import {connect} from 'react-redux'
import {createMatchSelector} from 'connected-react-router'
import {getUrlParams} from '../../../routes'
import {addLeadActivity} from '../../lead/all/helpers'
import {followUpTime} from '../helpers';

const FormItem = Form.Item
const {Option} = Select

@Form.create()
class AddFollowup extends PureComponent {

    handleSubmit = e => {
        const {dispatch, form, handleAddFollowup, leadId, user, stage} = this.props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let values = _.clone(valData)

                dispatch(showLoader())
                values.leadId = leadId;
                values.followUpTime = this.state.followUpTime
                let x = null
                values.isAllow = true
                x = await Request.addfollowup(values);
                console.log("values inside follow up is", values);
                dispatch(hideLoader())

                if (!x.error) {

                    notification.success({
                        message: x.message
                    })

                    this.loadFollowups();

                    if (handleAddFollowup) {
                        handleAddFollowup()
                    }
                    if (stage && stage === 'New (Untouched)') {
                        await Request.updateLead({_id: leadId, stage: 'Followup (New Untouched)', dontCheckLead: true})
                        await addLeadActivity({
                            values: {
                                message: `Lead staged as "Followup (New Untouched)"`,
                                leadId: leadId,
                                user: user && user.name,
                                type: 'stage'
                            }
                        })
                    }
                    if (stage && stage === 'Today Followup' || stage && stage === 'Overdue Followup') {
                        let {data} = await Request.getLeadById({_id: leadId})
                        await Request.updateLead({_id: leadId, stage: data && data.existingStage, dontCheckLead: true})
                        await addLeadActivity({
                            values: {
                                message: `Lead staged as "${data && data.existingStage}"`,
                                leadId: leadId,
                                user: user && user.name,
                                type: 'stage'
                            }
                        })
                    }
                    this.props.form.resetFields();


                    await addLeadActivity({
                        values: {
                            message: `Followup Added for date ${moment(values.followUpDate).format('YYYY-MM-DD')}`,
                            leadId: leadId,
                            user: user && user.name,
                            type: 'followUp'
                        }
                    });

                } else {
                    notification.error({
                        message: 'Error Saving',
                        description: x.message
                    })
                }

            }
        })
    }
    loadFollowups = async () => {
        let {leadId} = this.props
        let params = {results: 20, count: 20}
        if (leadId) {
            params.leadId = leadId
        }
        let data = await Request.getAllFollowupsByLeadId({
            ...params
        })
        this.setState({count: data.total, followsData: data.data})

    }
    getTimeOptions = (date) => {
        let afterDate = date || moment()
        console.log(afterDate, "afafafafafaf", date)
        let timeOptionsArr = []
        if (afterDate.format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')) {
            async.forEach(followUpTime, (doc, cb) => {

                let compareTime = moment(doc, ['h:mma'])

                if (moment().add('hour', 1).isBefore(compareTime)) {
                    timeOptionsArr.push(doc)
                }
                cb()
            }, () => {
                this.setState({
                    timeOptionsArr
                })
            })
        } else {
            this.setState({
                timeOptionsArr: followUpTime
            })
        }
    }


    constructor(props) {
        super(props)

        this.state = {followsData: [], followUpTime: ''}

    }

    async componentDidMount() {
        this.loadFollowups()
        this.getTimeOptions()
    }


    render() {
        let itemNo = 1;

        const {submitting, dispatch, hideTitle} = this.props
        const {
            form: {getFieldDecorator, getFieldValue}
        } = this.props

        const {editorState, followsData, timeOptionsArr} = this.state


        const inputTypes = {
            fields: [
                {
                    label: 'FollowUp Date',
                    key: 'followUpDate',
                    type: 'date',
                    required: true,
                    rightComp: <Select
                        style={{
                            width: 125,
                            marginLeft: '3px', marginBottom: "13px"
                        }}
                        allowClear
                        placeholder="Select Time"
                        onChange={time => {
                            this.setState({followUpTime: time}, () => {
                                // this.table.current.reload()
                            })
                        }}
                    >
                        {timeOptionsArr &&
                            timeOptionsArr.map((val, idn) => {
                                return (
                                    <Option key={idn} value={val}>
                                        {val}
                                    </Option>
                                )
                            })}
                    </Select>,

                    customProps: {
                        onChange: (val) => {
                            this.getTimeOptions(val)
                        },
                        disabledDate: (d) => !d || d.isBefore(moment().add(-1, 'day'))

                    },

                },
                {
                    label: 'Description',
                    key: 'description',
                    type: 'textArea',
                    rows: 10,
                    required: true
                },
                // {
                //   label: 'Status',
                //   key: 'status',
                //   type: 'select',
                //   required: true,
                //   options: ['Started', 'Not Started', 'In Progress'],
                //   placeholder: 'Select status',
                //   onChange: v => {
                //     this.props.form.setFieldsValue({
                //       status: v
                //     })
                //   }
                // },


                // {
                //   label: 'Follow Ups Type',
                //   key: 'followuptype',
                //   type: 'select',
                //   required: true,
                //   options: ['Cold Sales Follow Up', 'Product Sale', 'Warm Sales Follow Up', 'Free Trial Follow Up'],
                //   placeholder: 'Select followUp Type',
                //   onChange: v => {
                //     this.props.form.setFieldsValue({
                //       followuptype: v
                //     })
                //   }
                // }

            ]
        }


        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
                md: {span: 6}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
                md: {span: 18}
            }
        }

        const submitFormLayout = {
            wrapperCol: {
                xs: {span: 24, offset: 0},
                sm: {span: 10, offset: 7},
                md: {span: 12, offset: 8}
            }
        }
        const followupsColumn = [
            {
                title: 'Id',
                dataIndex: 'id',
                key: 'id',
                render: (value, item, index) => (itemNo - 1) * 10 + index + 1

            },
            {
                title: 'Followup Type',
                dataIndex: 'followuptype',
                key: 'followuptype'
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status'
            },
            {
                title: 'Description',
                key: 'description',
                dataIndex: 'description'
            },
            {
                title: 'FollowUp Date',
                key: 'followUpDate',
                render: ((item, record) => {
                    return <div>{record && record.followUpDate ? `${moment(record.followUpDate).format('ll')} ${record.followUpTime ? record.followUpTime : ''}` : ''}</div>
                })

            },

        ]


        let form = <div>
            {hideTitle && <React.Fragment>
                {/* <Card bordered={true}> */}
                <Form onSubmit={this.handleSubmit} hideRequiredMark style={{marginTop: 8}}>

                    <GetAllFormFields apiurl={API_URL} inputSchema={inputTypes} formItemLayout={formItemLayout}
                                      getFieldDecorator={getFieldDecorator}/>

                    <Form.Item {...submitFormLayout} style={{marginTop: 32}}>
                        <Button type="primary" htmlType="submit" loading={this.props.loading}>
                            SAVE
                        </Button>
                    </Form.Item>
                </Form>
                {/* </Card> */}
                {/* {followsData && followsData.length > 0 && <Card>
          <Table dataSource={followsData} columns={followupsColumn} bordered pagination={false}/>
        </Card>} */}
            </React.Fragment>}
        </div>

        return (
            <React.Fragment>{!hideTitle ? <PageHeaderWrapper>{form}</PageHeaderWrapper> : form}</React.Fragment>
            // <React.Fragment>{form}</React.Fragment>
        )
    }
}

const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    pathname: router.location.pathname

})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddFollowup)
