import React, { PureComponent } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
  Form,
  Input,
  Spin,
  Button,
  Card,
  Row, Col, Checkbox, Upload, Icon,
  Select, AutoComplete, Tag, Radio, Descriptions
} from 'antd'
import _ from 'lodash'
import moment from 'moment'

import { FormUtils as GetAllFormFields } from 'sz-react-utils'

import { API_URL } from '../../../request'

import 'react-quill/dist/quill.snow.css'
import { notification } from 'antd/lib/index'
import { hideLoader, showLoader } from '../../../modules/actions'
import Request, { getWarrantyStatusColor, warrantyStatusList } from '../../../request'
import { connect } from 'react-redux'
import { createMatchSelector, goBack } from 'connected-react-router'
import styles from '../../quote/add/styles.less'

const { Option } = Select

@Form.create()
class AddComplain extends PureComponent {

  handleSubmit = e => {
    const { dispatch, form } = this.props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        let values = _.clone(valData)

        dispatch(showLoader())

        let x = null
        values.leadId = this.state.leadId
        values.productId = this.state.productId

        if (this.state.id) {
          values._id = this.state.id
          //  x = await Request.updateCity(values)
        } else {
          x = await Request.addComplaint(values)

        }

        dispatch(hideLoader())

        if (!x.error) {

          notification.success({
            message: this.state.id
              ? x.message
              : x.message
          })

          form.resetFields()

          this.props.form.setFieldsValue({ date: moment(), type: 'Complaint' })
          this.setState({ leadNamesFiltered: [], machineId: '', productInitial: '', warrantyCheckData: '' })
          if (this.state.id) {
            dispatch(goBack())
          }

        } else {
          notification.error({
            message: 'Error Saving',
            description: x.message
          })
        }

      }
    })
  }

  constructor(props) {
    super(props)
    this.state = {
      leadNamesFiltered: [],
      leadNames: [],
      productList: []
    }
    this.searchLead = _.debounce(this.handleDealsSearch, 1000, true)


  }

  handleDealsSearch = async (test) => {
    let obj = {}
    this.setState({ showSpin: true })

    if (test === '') {
      this.setState({ leadNamesFiltered: this.state.leadNames }, () => {
        this.setState({
          showSpin: false,
          isEdited: false,
          showEditButton: false,
          billingData: null,
          shippingData: null
        })
      })
      return false

    }
    obj.text = test
    obj.dontCheckStatus = true
    let { data, error, message } = await Request.leadsSearch(obj)
    if (!error) {

      this.setState({ leadNamesFiltered: data }, () => {
        this.setState({ showSpin: false })

      })
    }
  }

  onProductSelect = async (val) => {
    let selectedProduct = this.state.productList.find((obj) => obj._id === val)
    if (selectedProduct) {
      this.props.form.setFieldsValue({
        productName: selectedProduct.name
      })
      this.state.productId = selectedProduct && selectedProduct._id
      this.state.productInitial = selectedProduct && selectedProduct.productInitial

    }
  }

  onDealSelect = async (val) => {
    let selectedQuote = this.state.leadNamesFiltered.find((obj) => obj._id === val)
    if (selectedQuote) {
      this.props.form.setFieldsValue({
        leadName: selectedQuote.title,
        contactName: selectedQuote && selectedQuote.contact && selectedQuote.contact.firstName,
        mobile: selectedQuote && selectedQuote.contact && selectedQuote.contact.mobile,
        email: selectedQuote && selectedQuote.contact && selectedQuote.contact.email,
        state: selectedQuote && selectedQuote.state

      })
      this.state.leadId = selectedQuote._id

    }
  }

  getClaimHistory = async (machineId) => {
    this.setState({ searchLoader: true })
    let x = await Request.getClaimHistoryArrByMachineId({ machineId })
    this.setState({ searchLoader: false })

    if (!x.error) {
      if (x.data && x.data.length) {
        this.setState({ warrantyCheckData: x.data[0] })
        let warrantyCheck = moment(x.data[0].warranty)
        if (moment().isSame(warrantyCheck) || moment().isBefore(warrantyCheck)) {
          this.props.form.setFieldsValue({ warranty: 'In Warranty' })
          this.setState({ warrantyStatus: 'In Warranty' })

        } else {

          this.props.form.setFieldsValue({ warranty: 'Out Of Warranty' })
          this.setState({ warrantyStatus: 'Out Of Warranty' })

        }
      }


    } else {
      this.props.form.setFieldsValue({ warranty: 'Unknown Warranty' })
      this.setState({
        warrantyCheckData: {
          saleDate: '',
          warranty: '',
          productName: this.props.form.getFieldValue('productName')
        }
      })

      this.setState({ warrantyStatus: 'Unknown Warranty' })

      // return notification.error({
      //   message: `Cannot found history with this Machine Id-${machineId}`
      // })
    }
  }

  async componentDidMount() {
    this.props.form.setFieldsValue({ date: moment(), type: 'Complaint' })

    Request.getAllProducts({ results: 10000 }).then(({ data: productList }) => {

      this.setState({ productList: productList || [] }, () => {

      })
    })

  }

  render() {
    const {
      form: { getFieldDecorator, getFieldValue }
    } = this.props

    let { showSpin, leadNamesFiltered, productList, machineId, productInitial, warrantyCheckData, warrantyStatus, searchLoader } = this.state
    const inputType = {
      fields: [
        {
          label: 'Contact Name',
          key: 'contactName'
        },
        {
          label: 'Mobile No',
          key: 'mobile',
          customProps: { disabled: true }

        },
        {
          label: 'Email',
          key: 'email'

        },
        {
          label: 'State',
          key: 'state'

        }
      ]
    }
    const inputType1 = {
      fields: [


        {
          label: 'Warranty',
          key: 'warranty',
          required: true,
          type: 'select',
          disabled: true,
          options: warrantyStatusList,
          onChange: (val) => {
            this.props.form.setFieldsValue({ warranty: val })
          }
        },
        {
          label: 'Complaint Date',
          key: 'date',
          required: true,
          type: 'date'
        },
        {
          label: 'Complaint Details',
          key: 'complaintDetails',
          required: true,
          type: 'textarea',
          rows: 8
        },
        {
          label: 'Photos',
          key: 'images',
          type: 'file',
          limit: 10
        }

      ]
    }


    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 12 }
      }
    }


    return (
      <PageHeaderWrapper
        title={this.state.id ? 'Update Complaint' : 'Add Complaint'}
      >
        <Card bordered={true}>
          <Form onSubmit={this.handleSubmit} hideRequiredMark={false} style={{ marginTop: 8 }}>

            <Row>

              <Col span={16}>

                <Form.Item
                  label='Lead Name'
                  {...formItemLayout}
                >{getFieldDecorator('leadName', {
                  rules: [{
                    required: true,
                    message: 'This is a Mandatory Field'
                  }]
                })(
                  <AutoComplete
                    notFoundContent={showSpin && <Spin />}

                    autoComplete={'none'}
                    onSelect={this.onDealSelect}
                    onSearch={this.searchLead}
                    placeholder={'Lead Name'}

                  >
                    {leadNamesFiltered && leadNamesFiltered.map((val, item) => (
                      <Option value={val._id}>
                        <>
                          <div><b>{`${val.title} (${val.id})`}</b></div>
                          <div className={styles.autoCompleteList}
                               style={{ marginTop: '0px' }}>{`${val.contact && val.contact.email ? val.contact.email : ''}`}</div>
                          <div
                            className={styles.autoCompleteList}>{`${val.contact && val.contact.firstName} (${val.contact && val.contact.mobile})`}</div>
                          <div
                            className={styles.autoCompleteList}>{`Company: ${val.company || ''}`}</div>
                          <Tag>{`${moment(val.createdAt).format('DD-MM-YYYY HH:mm')}`}</Tag>
                        </>
                      </Option>
                    ))}
                  </AutoComplete>
                )}
                </Form.Item>

                <Form.Item {...formItemLayout} label={'Select Product'}>

                  <Input.Group compact>
                    {getFieldDecorator('productName', {
                      rules: [{
                        required: true,
                        message: 'This is a Mandatory Field'
                      }]
                    })(
                      <AutoComplete
                        style={{ width: '45%' }}
                        notFoundContent={this.state.showSpin && <Spin />}
                        autoComplete={'none'}
                        onChange={() => {
                          this.setState({ machineId: '', warrantyCheckData: '', warrantyStatus: '' }, () => {
                            this.props.form.setFieldsValue({ warranty: undefined })
                          })

                        }}
                        dropdownMatchSelectWidth={false}
                        onSelect={this.onProductSelect}
                        placeholder={'Product Name'}
                      >{productList && productList.map((val, item) => (
                        <Option value={val._id}>
                          <>
                            <div><b>{val.name}</b></div>
                          </>
                        </Option>
                      ))}

                      </AutoComplete>
                    )}
                    <Input placeholder={'Enter Machine Id'} value={machineId} style={{ width: '35%' }}
                           onChange={(val) => {
                             this.setState({ machineId: val.target.value })
                           }} />

                    <Button style={{ marginLeft: '3px' }} loading={searchLoader} type="primary" onClick={() => {
                      let machine = ''
                      if (!this.props.form.getFieldValue('productName')) {
                        return notification.error({ message: 'Select Product.' })
                      }
                      if (!machineId) {
                        return notification.error({ message: 'Enter machine Id.' })
                      }
                      machine = `${productInitial}-${machineId}`

                      this.getClaimHistory(machine)
                    }}>{<Icon type="search" />}</Button>
                  </Input.Group>

                </Form.Item>


                <GetAllFormFields apiurl={API_URL}
                                  inputSchema={inputType}
                                  formItemLayout={formItemLayout}
                                  getFieldDecorator={getFieldDecorator} />

                <Form.Item label={'Type'} {...formItemLayout} >
                  {getFieldDecorator('type', {
                    rules: [{ required: true }]

                  })(
                    <Radio.Group name="type" onChange={(val) => {
                      if (val.target.value === 'Installation') {
                        this.props.form.setFieldsValue({ warranty: 'Brand New' })
                      } else {
                        this.props.form.setFieldsValue({ warranty: undefined })

                      }
                    }}>
                      <Radio value={'Complaint'}>Complaint</Radio>
                      <Radio value={'Installation'}>Installation</Radio>

                    </Radio.Group>)}
                </Form.Item>


                <GetAllFormFields apiurl={API_URL}
                                  inputSchema={inputType1}
                                  formItemLayout={formItemLayout}
                                  getFieldDecorator={getFieldDecorator} />
              </Col>

              <Col span={8}>
                {warrantyCheckData ? <Card bordered={true} style={{ margin: '10px' }}>
                  <Descriptions bordered={false} size={'middle'} column={1}>
                    <Descriptions.Item
                      label={<b>Sale
                        Date</b>}>{warrantyCheckData && warrantyCheckData.saleDate ? moment(warrantyCheckData.saleDate).format('DD/MM/YYYY') : ''}</Descriptions.Item>

                    <Descriptions.Item
                      label={
                        <b>Product Name</b>}>{warrantyCheckData && warrantyCheckData.productName}</Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <b>Warranty
                          Upto</b>}>{warrantyCheckData && warrantyCheckData.warranty ? moment(warrantyCheckData.warranty).format('DD/MM/YYYY') : ''}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <b>Warranty Status</b>}>{<Tag
                      color={getWarrantyStatusColor(warrantyStatus)}>{warrantyStatus}</Tag>}
                    </Descriptions.Item>
                  </Descriptions>
                </Card> : ''}


              </Col>


            </Row>


            <Form.Item style={{ marginTop: 32, textAlign: 'center' }}>
              <Button size="large" type="primary" htmlType="submit" loading={this.props.loading}
                      style={{ width: '15%' }}>
                {this.state.id ? `UPDATE` : `SAVE`}

              </Button>
            </Form.Item>

          </Form>
        </Card>
      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  categories: global.categories,
  search: router.location.search
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddComplain)
