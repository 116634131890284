import React, { PureComponent } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
  Form,
  Input,
  notification,
  Spin,
  Select,
  Button,
  Table,
  Card,
  Typography,
  Row,
  Col,
  Tooltip,
  Popconfirm,
} from 'antd'
import _ from 'lodash'
import { FormUtils as GetAllFormFields } from 'sz-react-utils'
import moment from 'moment'

import { API_URL } from '../../../request'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { hideLoader, showLoader } from '../../../modules/actions'
import Request from '../../../request'
//import styles from './styles.less'
import { connect } from 'react-redux'
import { createMatchSelector, goBack } from 'connected-react-router'
import { getUrlPushWrapper } from '../../../routes'
import { FaIgloo } from 'react-icons/fa'

const FormItem = Form.Item
const { Option } = Select
const { Text, Paragraph } = Typography
const { TextArea } = Input

@Form.create()
class VoucherForm extends PureComponent {
  handleSubmit = (e) => {
    const { dispatch, form, currentUser } = this.props
    let { totalData } = this.state

    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        let values = _.clone(valData)

        if (totalData && totalData.length == 1) {
          this.setState({ sendLoading: false })
          return notification.error({
            message: 'Atleast add one product',
          })
        }

        let tempArr = []
        let errorIds = []

        _.each(this.state.totalData, (item) => {
          _.each(item.serialNumbers, (val) => {
            if (!tempArr.includes(val)) {
              tempArr.push(val)
            } else {
              errorIds.push(val)
            }
          })
        })

        if (errorIds.length) {
          return notification.error({
            message: `Duplicate Product Ids ${errorIds}.`,
          })
        }

        totalData = _.reject(this.state.totalData, (x) => x.isLast == true)

        var voucherData = {}
        voucherData.voucherNotes = values.notes
        voucherData.type = this.state.type
        voucherData.createdByUserId = values.userId
        voucherData.products = totalData
        voucherData.voucherDate = values.voucherDate
        voucherData.voucherNumber = values.vID.split('-')[1]
        voucherData.voucherId = values.vID

        dispatch(showLoader())
        let x
        if (this.state.id) {
          voucherData._id = this.state.id

          x = await Request.updateVoucher(voucherData)
        } else {
          x = await Request.createVoucher(voucherData)
        }

        dispatch(hideLoader())

        if (!x.error) {
          notification.success({
            message: this.state._id ? x.message : x.message,
          })

          // if (!this.state._id) {
          this.props.form.resetFields()
          this.getOptionsData()

          this.setState({ totalData: [{ isLast: true }] })
        } else {
          notification.error({
            message: 'Error Saving',
            description: x.message,
          })
        }
      }
    })
  }

  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      loader: true,
      productList: [],
      totalData: [],
      type: 'Stock Adjustment',
    }
    this.AddData = _.debounce(this.addData, 1000, true)
  }

  handleCancel = (e) => {
    this.props.form.setFieldsValue({
      quantity: null,
      productName: undefined,
    })
    this.setState({
      modalVisible: false,
      productName: '',
      quantity: '',
    })
  }

  addData = () => {
    let tt = _.cloneDeep(this.state.totalData)
    let found = _.find(tt, (i) => {
      if (i && i.isLast != true) {
        return (
          i.productId == this.state.productId ||
          i.productId._id == this.state.productId
        )
      }
    })

    if (found) {
      this.setState({ showText: false })
      this.props.form.setFieldsValue({
        productName: undefined,
        qty: null,
        productId: undefined,
      })

      return notification.error({
        message: 'Product Already Exists.',
      })
    }
    let comp = tt.pop()
    tt.push({
      qty: this.state.qty,
      productName: this.state.productName,
      isLast: false,
      productId: this.state.productId,
      serialNumbers: [],
    })
    tt.push(comp)

    this.props.form.setFieldsValue({
      totalData: tt,
      productName: undefined,
      qty: null,
    })
    this.setState(
      {
        totalData: tt,
        productName: undefined,
        qty: null,
        productId: undefined,
        serialNumbers: [],
      },
      () => {}
    )
  }

  onProductSelect = (val) => {
    let selectedQuote = this.state.productList.find((obj) => obj._id === val)
    if (selectedQuote) {
      this.props.form.setFieldsValue({
        productName: selectedQuote.name,
      })
      this.setState(
        {
          productName: selectedQuote.name,
          productId: selectedQuote._id,
          qty: 1,
          
        },
        () => {
          this.addData()
        }
      )
    }
  }

  getOptionsData = async () => {
    let currentUser = JSON.parse(window.localStorage.getItem('user'))

    let filter = {
      userType: { $in: ['RO', 'Admin', 'Dispatch', 'Technician'] },
      results: 1000,
    }
    this.props.form.setFieldsValue({
      userId: currentUser._id,
    })

    Request.getAllProducts({ results: 200 }).then(({ data: productList }) => {
      this.setState({ productList: productList || [] }, () => {})
    })
    Request.loadUser(filter).then(({ data: userList }) => {
      this.setState({ userList: userList || [] })
    })

    this.props.form.setFieldsValue({ voucherDate: moment() })
    let x = await Request.nextVoucherId({ type: this.state.type })
    this.props.form.setFieldsValue({ vID: 'ADJ-' + x.message })
  }

  async componentDidMount() {
    let searchParams = new URLSearchParams(this.props.search)
    let voucherId = searchParams.get('id')

    this.getOptionsData()
    if (voucherId) {
      this.setState({ loader: true })
{}
      Request.getVoucherById({ _id: voucherId }).then(
        ({ data: voucherData, error, message }) => {
          if (!error) {
            this.setState({
              id: voucherId,
              loader: false,
              totalData: [...voucherData.products, { isLast: true }],
              userId: [],
              notes: voucherData.voucherNotes,
            })
            this.props.form.setFieldsValue({
              vID: voucherData && voucherData.voucherId,
            })
          } else {
            notification.error({
              message: 'Error Getting Data',
            })
          }
        }
      )
    } else {
      this.props.form.setFieldsValue({ scheduledDate: moment() })
      this.state.totalData.push({ isLast: true })
    }

    this.setState({ loader: false })
  }

  render() {
    const {
      form,
      form: { getFieldDecorator, getFieldValue },
    } = this.props

    const { productList, _id, showPurchase, userList } = this.state

    const inputTypes = {
      fields: [
        {
          label: 'Adjusted By',
          key: 'userId',
          showSearch: true,
          disabled: true,
          required: true,

          keyAccessor: (x) => x._id,
          valueAccessor: (x) => `${x.name} ${x.mobile} (${x.userType})`,
          type: 'select',
          options: userList || [],
          onChange: (filledBy) => {
            this.props.form.setFieldsValue({
              userId: filledBy,
            })
          },
        },
        {
          label: 'Voucher Id',
          key: 'vID',
          type: 'text',
          customProps: {
            disabled: true,
          },
        },
        {
          label: 'Adjustment date',
          key: 'voucherDate',
          required: true,
          type: 'date',
          customProps: {
            disabledDate: (d) => !d || d.isBefore(moment().add(-1, 'day')),
          },
        },
      ],
    }

    const refColumns = [
      {
        title: 'S.No',
        dataIndex: 'sno',
        key: 'sno',
        width: 50,
        render: (value, item, index) => {
          if (item && item.isLast != true) {
            return index + 1
          }
        },
      },
      {
        title: 'Product',
        dataIndex: 'productName',
        key: 'productName',
        width: 130,
        render: (x, item, index) => {
          if (item && item.isLast == true) {
            return (
              <div>
                {getFieldDecorator(
                  'productName',
                  {}
                )(
                  <Select
                    showSearch
                    dropdownMatchSelectWidth={false}
                    optionFilterProp="children"
                    style={{
                      width: 200,
                    }}
                    placeholder="Select Product"
                    onSelect={(id) => {
                      this.onProductSelect(id)
                    }}>
                    {productList &&
                      productList.map((val, idn) => {
                        return (
                          <Option key={idn} value={val._id}>
                            {val.name}{' '}
                            {val && val.quoteDisplayedName
                              ? `(${val.quoteDisplayedName})`
                              : ''}
                          </Option>
                        )
                      })}
                  </Select>
                )}
              </div>
            )
          } else {
            if (item && item.productId && item && item.productId._id) {
              return (
                <div style={{ marginBottom: '5px' }}>
                  {' '}
                  {item.productId.name}
                </div>
              )
            }
            return <div style={{ marginBottom: '5px' }}> {x}</div>
          }
        },
      },
      {
        title: 'Quantity adjusted',
        key: 'qty',
        dataIndex: 'qty',
        render: (y, item, index) => {
          if (item && item.isLast != true) {
            return (
              <div>
                <Text
                  editable={{
                    onChange: async (val) => {
                      if (!isNaN(parseInt(val))) {
                        let tt = this.state.totalData
                        item.qty = val ? val : tt[index].qty

                        this.props.form.setFieldsValue({
                          qty: val,
                        })

                        this.setState({ totalData: tt, tableKey: Date.now() })
                      }
                    },
                  }}>
                  {y}
                </Text>
              </div>
            )
          }
        },
      },
      {
        title: 'Product Serial No.',
        key: 'serialNumbers',
        dataIndex: 'serialNumbers',
        render: (x, item, key) => {
          if (item && item.isLast !== true) {
            return (
              <div>
                <Select
                  defaultValue={item && item.serialNumbers}
                  style={{ width: 200 }}
                  placeholder={'Enter Product Id'}
                  mode={'tags'}
                  onChange={(e) => {
                    let array = []
                    _.each(e, (i) => {
                      array.push(i)
                    })
                    item.serialNumbers = array
                  }}></Select>
              </div>
            )
          }
        },
      },

      {
        title: 'Action',
        key: 'operation',
        width: 100,
        render: (item, record, key) => {
          if (record && record.isLast != true) {
            return (
              <React.Fragment>
                <Tooltip title="Delete Product">
                  <Popconfirm
                    title="Are you sure delete this Product?"
                    onConfirm={() => {
                      let tt = this.state.totalData
                      tt = _.reject(tt, item)
                      this.setState({ totalData: tt }, () => {})
                    }}
                    onCancel={() => {
                      console.log()
                    }}
                    okText="Yes"
                    cancelText="No">
                    <Button
                      shape="circle"
                      size="small"
                      type={'danger'}
                      icon="delete"
                    />
                  </Popconfirm>
                </Tooltip>
              </React.Fragment>
            )
          }
        },
      },
    ]

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 12 },
      },
    }

    return (
      <PageHeaderWrapper title={'Create Voucher'}>
        <Spin spinning={this.state.loader}>
          <Form
            onSubmit={this.handleSubmit}
            hideRequiredMark={false}
            style={{ marginTop: 8 }}>
            <Card bordered={true}>
              <Row>
                <Col span={14}>
                  <GetAllFormFields
                    apiurl={API_URL}
                    inputSchema={inputTypes}
                    formItemLayout={formItemLayout}
                    getFieldDecorator={getFieldDecorator}
                  />
                </Col>
                <Col span={10}>
                  <Form.Item label={'Notes/Description'} {...formItemLayout}>
                    {getFieldDecorator('notes')(
                      <TextArea
                        rows={4}
                        onChange={(ei) => {
                          this.setState({ notes: ei.target.value })
                        }}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Table
                    key={this.state.tableKey}
                    bordered={true}
                    rowKey={(x) => x.productName}
                    columns={refColumns}
                    dataSource={this.state.totalData || []}
                    pagination={false}
                  />
                </Col>
              </Row>
              <Form.Item style={{ marginTop: 32, textAlign: 'center' }}>
                <Button
                  size="large"
                  type="primary"
                  loading={this.props.loading}
                  htmlType="submit"
                  style={{ width: '15%', marginLeft: '1em' }}>
                  {_id ? `Update` : `Save`}
                </Button>
              </Form.Item>
            </Card>
          </Form>
        </Spin>
      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  categories: global.categories,
  search: router.location.search,
  currentUser: global.currentUser,
})
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VoucherForm)
