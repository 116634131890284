import React, {PureComponent} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
    Form,
    Input,
    Spin,
    Button,
    Card,
    Row, Col, Checkbox, Upload, Icon,
    Select
} from 'antd'

import ReactQuill from 'react-quill'

import 'react-quill/dist/quill.snow.css'
import {notification} from 'antd/lib/index'
import styles from '../styles.less'
import {hideLoader, showLoader} from '../../../modules/actions'
import Request from '../../../request'
import {connect} from 'react-redux'
import {createMatchSelector, goBack} from 'connected-react-router'

const atValues = [
    {id: 1, value: 'contactName'},
    {id: 2, value: 'ROOfficer'}
];

@Form.create()
class AddTemplate extends PureComponent {

    handleUpdate = async () => {
        console.log(this.state.templateContent, this.state.templateTitle)
        if ((!this.state.templateContent) || (!this.state.templateTitle)) {
            return notification.error({
                message: 'Please Fill All Required Fields'
            })
        }
        this.setState({loading: true})
        let x = await Request.addTemplate({title: this.state.templateTitle, content: this.state.templateContent});
        this.setState({loading: false})
        this.setState({templateContent: undefined, templateTitle: undefined});
        if (!x.error) {

            notification.success({
                message: x.message
            })
        } else {
            notification.error({
                message: 'Error Saving',
                description: x.message
            })
        }
    }

    constructor(props) {
        super(props)
        this.state = {templateTitle: '', loading: false}

    }

    mentionModule = {
        allowedChars: /^[A-Za-z\s]*$/,
        mentionDenotationChars: ["@"],
        source: function (searchTerm, renderList, mentionChar) {
            let values;
            if (mentionChar === "@") {
                values = atValues;
            }
            if (searchTerm.length === 0) {
                renderList(values, searchTerm);

            } else {
                const matches = [];
                for (let i = 0; i < values.length; i++)
                    if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())) matches.push(values[i]);
                renderList(matches, searchTerm);
            }
        },
        mentionContainerClass: styles.mentionContainer,
        onSelect: (item, insertItem) => {
            insertItem({...item, value: `{%=${item.value}%}`})
        },
        showDenotationChar: false
    }

    async componentDidMount() {
        let searchParams = new URLSearchParams(this.props.search)


    }


    render() {

        const {} = this.props
        const {
            form: {getFieldDecorator, getFieldValue}
        } = this.props


        return (
            <PageHeaderWrapper
                title={'Add Template'}
            >
                <Card bordered={true} style={{textAlign: 'center'}}>
                    <Input style={{marginBottom: '10px', margin: '30px', width: '80%'}}
                           value={this.state.templateTitle}
                           placeholder={'Please input the title of Template!'} onChange={(v) => {
                        this.setState({templateTitle: v.target.value})
                        if (v.target.value === '') {
                            this.setState({templateTitle: undefined})
                        }

                    }}/>
                    <ReactQuill
                        style={{margin: 'auto', marginBottom: '70px', width: '80%', height: '100px', textAlign: 'left'}}
                        value={this.state.templateContent || ''}
                        modules={{mention: this.mentionModule}}
                        placeholder={'Type Content...'} onChange={(text) => {
                        this.setState({templateContent: text});
                        if (text === '<p><br></p>') {
                            this.setState({templateContent: undefined});
                        }


                    }
                    }/>

                    <Button loading={this.state.loading} onClick={this.handleUpdate} type="primary">Add</Button>
                </Card>
            </PageHeaderWrapper>
        )
    }
}

const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddTemplate)
