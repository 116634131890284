import React, {PureComponent} from 'react'
import {
    Divider,
    Button, Input, InputNumber,
    Card, Form, Select
} from 'antd'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {notification} from 'antd/lib/index'
import Request from '../../../request'
import {connect} from 'react-redux'

class SendOtp extends PureComponent {

    changeStatus = async () => {
        const {complaintId, handleOtp} = this.props
        let {changedMobile, showMobileModal, otp} = this.state

        if (showMobileModal) {
            if (!changedMobile) {
                return notification.error({message: 'Mobile Number Required'})

            }
        } else {
            if (!otp) {
                return notification.error({message: 'Enter Your Happy Code'})

            }
        }

        this.setState({statusLoader: true})
        let x = null
        if (showMobileModal) {
            x = await Request.sendComplaintOtp({
                complaintId: complaintId,
                mobile: changedMobile
            })
        } else {
            x = await Request.verifyComplaintOtp({
                complaintId: complaintId,
                mobile: changedMobile,
                otp: otp
            })
        }


        this.setState({statusLoader: false})


        if (!x.error) {
            notification.success({message: x.message})
            if (!showMobileModal) {
                if (handleOtp) {
                    handleOtp()
                }
            }
            let message = `We hope You are satisfied with our service.\n\n\n kindly share Happy code "${x && x.data && x.data.otp}" to your concerned RO to close the complaint`
            if (showMobileModal) {
                window.open(`http://api.whatsapp.com/send?phone=${x && x.data.mobile}&text=${message}&source=&data=`)

            }
            this.setState({showMobileModal: false})


        } else {
            return notification.error({message: x.message})
        }

    }


    constructor(props) {
        super(props)
        this.state = {statusLoader: false}
    }

    async componentDidMount() {
        this.setState({showMobileModal: true, changedMobile: this.props.mobile})
    }

    render() {

        const {isCommentModal, onCancel, mobile} = this.props
        let {showMobileModal, otp, statusLoader, changedMobile} = this.state

        return (
            <React.Fragment>
                <Form layout='inline'>
                    {showMobileModal ? <div>
                        <Form.Item label='Mobile Number'>
                            <Input value={changedMobile} style={{width: '200px'}} onChange={(val) => {
                                this.setState({changedMobile: val.target.value})
                            }}/>

                        </Form.Item>

                    </div> : <div>
                        <Form.Item label='Happy Code'>
                            <InputNumber value={otp} style={{width: '200px'}} onChange={(val) => {
                                this.setState({otp: val})
                            }}/>

                        </Form.Item>
                    </div>}

                    <div style={{textAlign: 'right', marginTop: '20px', width: '90%'}}>

                        <Button onClick={() => {
                            onCancel()
                            this.setState({comment: '', selectedStatus: null})
                        }}>
                            CANCEL
                        </Button>
                        <Button style={{marginLeft: '10px'}} type="primary" onClick={() => {
                            this.changeStatus()
                        }} loading={statusLoader}>
                            {showMobileModal ? 'Send Happy Code' : 'Verify'}
                        </Button>

                    </div>


                </Form>


            </React.Fragment>
        )
    }
}

const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    pathname: router.location.pathname

})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SendOtp)
