import React, {Component} from 'react'
import Board from 'react-trello'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {TableComp} from 'sz-react-utils'
import {ExclamationCircleOutlined} from '@ant-design/icons'
import {LinkOutlined, FileImageOutlined} from '@ant-design/icons';
import _ from 'lodash'

import moment from 'moment'
import {
    Table,
    Badge,
    Card,
    Tooltip,
    Popconfirm,
    notification, Modal,
    Switch, Avatar,
    Row, Col, Popover,
    Input, Button, Icon, Select, Drawer, Form, AutoComplete, Spin
} from 'antd'
import Request, {getLeadStageColor, getLeadTypeColor} from '../../../request'
import styles from './styles.less'
import {connect} from 'react-redux'
import UpdateLead from '../add/editLead';
import {Typography} from 'antd';
import {stateFilter} from '../../../states'


const {Paragraph} = Typography;


const {Option} = Select

class WonLeads extends Component {


    constructor(props) {
        super(props);
        this.table = React.createRef()
        const {dispatch} = props;
        this.state = {
            allContacts: [],
            showBoard: true,
            showTable: false,
            leadStage: ['New (Untouched)', 'Contacted', 'Proposal Presented', 'Followup (New Untouched)', 'Contacted Followup', 'Overdue Followup', 'Today Followup', 'In Negotiation', 'Qualified'],
            boardData: {lanes: []},
            key: Math.random(),
            RoList: [],
            selectedRowKeys: [],
            selectedRO: undefined,
            currentPage: 1
        }
        this.searchContact = _.debounce(this.handleContactSearch, 1000, true)


    }


    apiRequest = (params) => {
        let {contactNameId} = this.state

        if (params.page) {
            this.state.currentPage = params.page
        }
        if (!params.sortOrder) {
            params.sortField = 'createdAt';
            params.sortOrder = 'desc';
        }
        if (!params.stage || !params.stage.length) {
            params.stage = this.state.leadStage

        }

        let user = {...JSON.parse(localStorage.getItem('user'))};
        return new Promise(async (resolve) => {
            let data = await Request.getAllLeads({
                ...params,
                customQuery: {
                    status: 'won',
                    //  officer: user._id,
                    contact: contactNameId ? contactNameId : undefined
                },
                regExFilters: ['title', 'company', 'contact'],
            })
            resolve(data)
            this.setState({leadData: data, count: data.total})
        })
    }

    showModal = () => {
        this.setState({
            visible: true, btnLoader: false
        })
    }


    async componentDidMount() {
        let {data: allContacts} = await Request.getAllContacts({results: 10});
        this.setState({allContacts, sale: 'Sales'});
        let {data: userList} = await Request.getAllROList({customQuery: {userType: 'RO'}});
        this.setState({RoList: userList});

    }

    handleContactSearch = async (test) => {
        console.log(test)
        // this.setState({​​​​​​​​showSpin: true}​​​​​​​​)
        this.setState({selectedContact: test})
        if (test == '') {
            console.log("empty");
            this.setState({
                allContacts: this.state.allContacts,
                selectedContact: test,
                contactNameId: null
            }, () => this.table.current.reload())
            return false
        }
        let {data, error, message} = await Request.contactSearch({text: test, type: 'list'})
        if (!error) {
            this.setState({allContacts: data}, () => {
                this.setState({showSpin: false})
            })
        }
    }

    onContactSelect = (val) => {
        let selectedContact = this.state.allContacts.find((obj) => obj._id === val)
        if (selectedContact) {
            this.setState({
                contactNameId: selectedContact._id,
                selectedContact: `${selectedContact.firstName} (${selectedContact.mobile})`
            }, () => {
                this.table.current.reload()
            })
        }
    }

    render() {

        const {submitting, currentUser, dispatch} = this.props
        const {
            currentPage,
            leadStatuseArr,
            allContacts,
        } = this.state
        const columns = [
            {
                title: 'S.No',
                dataIndex: 'sno',
                width: 50,
                key: 'sno',
                fixed: 'left',
                render: (value, item, index) => (currentPage - 1) * 10 + index + 1
            },

            {
                key: 'actions',
                title: 'View',
                fixed: 'left',
                width: 80,
                render: (text, record) => {
                    return <React.Fragment>
                        <Tooltip title="View">
                            <a href={`/lead/detail?id=${record && record._id}`} target={'_blank'}>
                                <Button style={{marginLeft: '5px'}} size="small" icon={'eye'} shape={'circle'}/>
                            </a>


                        </Tooltip>
                    </React.Fragment>
                }
            },
            {
                title: 'Id',
                dataIndex: 'id',
                key: 'id',
                width: 100,
                searchTextName: 'id',
                fixed: 'left',
            },
            {
                title: 'Lead',
                dataIndex: 'title',
                key: 'title',
                searchTextName: 'title',
                render: (text, record) => <span><a onClick={() => {
                    // this.setState({...this.state, cardId: record._id, updatelead: true})
                }}>{record.title}</a></span>,
            },
            {
                title: 'Contact',
                dataIndex: 'contact.firstName',
                key: 'contact.firstName',
                render: (text, record) => <span><span>{(record.contact) ? record.contact.firstName : ''}</span><br/>
          <span>{(record.contact) ? record.contact.mobile : ''}</span></span>,
            },
            {
                title: 'Company',
                dataIndex: 'company',
                key: 'company',
                searchTextName: 'company',
                // width: 100
            },

            {
                title: 'Stage',
                dataIndex: 'stage',
                key: 'stage',
                filters: _.map(this.state.leadStage, (ii) => ({text: ii, value: ii})),
                render: (text, record) => {
                    return (
                        <div>{record && record.stage ?
                            <div className={styles.stageColor} style={{
                                backgroundColor: getLeadStageColor(record.stage)
                            }}>{record.stage}</div> : ''}
                        </div>
                    )
                },
            },
            {
                title: 'Lead Type',
                dataIndex: 'leadType',
                key: 'leadType',
                filters: [
                    {text: 'IndiaMART Api', value: 'IndiaMART Api'},
                    {text: 'Inbound', value: 'Inbound'}, {text: 'Aajjo', value: 'aajjo'}
                ],
                render: (val, record) => {

                    return (<div>{record.leadType ?
                        <div style={getLeadTypeColor(record.leadType)}>{record.leadType}</div> : ''}</div>)

                }
            },
            {
                title: 'State',
                dataIndex: 'state',
                key: 'state',
                filters: stateFilter,
            },

            {
                title: 'Status',
                key: 'status',
                render: (text, record) => {
                    return (
                        <div style={{
                            backgroundColor: "#43BE31",
                            color: "white",
                            textAlign: "center",
                            padding: "2px 2px",
                            borderRadius: "2px",
                            minWidth: "30px"
                        }}>{record && record.status}</div>
                    )
                }
            },
            {
                title: 'Created At',
                dataIndex: 'createdAt',
                key: 'createdAt',
                sorter: true,
                searchDateName: 'createdAt',
                render: (val, record) => {
                    return <div>
                        <div>{moment(record.createdAt).format('lll')}</div>
                    </div>
                },
                // width:100

            },
            {
                title: 'Lead Received Date',
                dataIndex: 'leadReceivedDate',
                key: 'leadReceivedDate',
                searchDateName: 'leadReceivedDate',
                sorter: true,
                fixed: 'right',
                render: (val, record) => {
                    return <div>
                        <div>  {record && record.leadReceivedDate ? moment(record.leadReceivedDate).format('lll') : ''} </div>
                    </div>
                },
                width: 150

            }
        ]
        if (currentUser && currentUser.userType == 'Admin') {
            let dataItem = {
                title: 'Total Call Logs',
                dataIndex: 'callLogs',
                key: 'callLogs',
                render: (val, record) => {
                    return <div>
                        <small>Outgoing: {val && ((val.outgoingCalls || 0) + (val.outgoingRejectCalls || 0))}</small>
                        <div><small>Incoming: {val && val.incomingCalls || 0}</small></div>
                        <div><small>Missed: {val && val.missedCalls || 0}</small></div>
                        <div><small>Attended: {val && val.totalAttended || 0}</small></div>
                    </div>
                }
                // width: 100
            }
            columns.splice(9, 0, dataItem)
        }


        return (<PageHeaderWrapper
            title={this.state.count ? `All Won Leads: ${this.state.count}` : `All Won Leads: 0`}>
            <div style={{margin: '-24px', padding: '0px'}}>
                <Card>
                    <Row>
                        <Col span={8}>
                            <AutoComplete
                                value={this.state.selectedContact}
                                notFoundContent={this.state.showSpin && <Spin/>}
                                autoComplete={'none'}
                                onSelect={this.onContactSelect}
                                onSearch={this.searchContact}
                                onChange={(val) => {
                                    this.setState({selectedContact: val})
                                }}
                                placeholder={'Search Contact'}
                                style={{width: 300}}
                            >{allContacts && allContacts.map((val, item) => (
                                <Option value={val._id}>
                                    <>
                                        <div><b>{`${val.firstName} (${val.mobile})`}</b></div>
                                    </>
                                </Option>
                            ))}

                            </AutoComplete>

                        </Col>
                    </Row>
                </Card>

                <Card className={styles.greyCard}>
                    <TableComp ref={this.table}
                               columns={columns}
                               apiRequest={this.apiRequest}
                               extraProps={{
                                   scroll: {x: 1700},
                               }}
                               id='leadList'
                               pagination={{
                                   ...this.state.pagination,
                                   defaultPageSize: 10,
                                   pageSizeOptions: ['10', '25', '50', '100'],
                                   showSizeChanger: true,
                                   ...this.props.pagination
                               }}/>
                </Card>
            </div>

            <Drawer
                title=""
                placement="right"
                closable={true}
                onClose={async () => {
                    this.setState({
                        ...this.state,
                        updatelead: false,
                        cardId: ''
                    })
                }}
                visible={this.state.updatelead}
                width={800}>
                {/* cardId={cardId} */}
                <UpdateLead cardId={this.state.cardId} allContacts={allContacts} leadOwner={currentUser}
                            key={(this.state.drawerKey) ? this.state.drawerKey : Date.now()}
                            handleUpdateLead={() => {
                                this.setState({updatelead: false, cardId: ''});
                                this.table.current.reload();
                            }}/>
            </Drawer>

        </PageHeaderWrapper>)

    }
}


const mapStateToProps = ({global, router}) => ({
    categories: global.categories,
    currentUser: global.currentUser,
    search: router.location.search

})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WonLeads)
