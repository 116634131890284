import React, {Component} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {TableComp, FormUtils as GetAllFormFields} from 'sz-react-utils'
import moment from 'moment'
import {
    Table,
    Badge,
    Card,
    Tooltip,
    Popconfirm,
    notification,
    Switch,
    Input, Button, Icon,
    Form,
    Drawer, Row, Col, Tag, Select, List, Avatar, Modal
} from 'antd'
import Request, {orderStatus, getStatusColor} from '../../../request'
import _ from 'lodash'
import {connect} from 'react-redux'
import {getUrlPushWrapper} from "../../../routes";

const Option = {Select}

@Form.create()
class dispatchOrderList extends Component {

    apiRequest = (params) => {
       if(!params.status){
        let searchParams = new URLSearchParams(window.location.search)
        let statusType = searchParams.get('type')
        if(statusType){
            console.log(statusType)
            params.status = statusType
        }
       }
       
        if (params.page) {
            this.state.currentPage = params.page
        }
        let currentUser = JSON.parse(window.localStorage.getItem('user'))

        if (currentUser && currentUser.userType == 'RO') {
            params.orderDispatchById = currentUser._id

        }

        if (!params.sortOrder) {
            params.sortField = 'createdAt'
            params.sortOrder = 'desc'
        }
        
        this.setState({filters: {...params}})

        return new Promise(async (resolve) => {
            let data = await Request.orderDispatchList({
                ...params,
                regExFilters: ['mobile', 'name', 'email', 'leadName', 'invoiceNumber', 'remarks', 'technicianName']
            })
            this.setState({count: data.total})

            resolve(data)
        })
    }

    constructor(props) {
        super(props)
        this.table = React.createRef()
        this.state = {
            currentPage: 1,
            visible: false,
            carriersData: [],
            productShow: [],
            fileDownloadLoader: [],
            arrayLoader: [],
            statusType:null
        }

    }

    componentDidMount() {
       
        Request.getAllCarriers().then(({data, error, message}) => {

            if (!error) {
                this.setState({carriersData: data && data[0] && data[0].carriers})


            }
        })
    }

    updateOrderStatus = async data => {
        this.setState({loader: true})
        let x = await Request.updateOrderDispatchStatusAction(data)
        this.setState({loader: false})

        this.table.current.reload()
        if (!x.error) {
            notification.success({
                message: x.message,
                duration: 20,
                key: `${data._id}-close`
            })

        } else {
            notification.error({
                message: x.message,
                duration: 20,
                key: `${data._id}-close`
            })
        }

    }

    updateOrder = async data => {
        let {dispatchNumber, orderId} = this.state
        if (!dispatchNumber) {
            notification.error({
                message: 'Enter Dispatch Number'
            })
            return
        }

        data._id = orderId
        data.dispatchNumber = dispatchNumber

        this.setState({modalLoader: true})
        let x = await Request.updateOrderAction(data)
        this.setState({modalLoader: false})

        this.table.current.reload()
        if (!x.error) {
            notification.success({
                message: x.message,
                duration: 20,
                key: `${data._id}-close`
            })
            this.setState({modalVisible: false})

        } else {
            notification.error({
                message: x.message,
                duration: 20,
                key: `${data._id}-close`
            })
        }

    }
    setLoaderAction = (key, value) => {
        let {arrayLoader} = this.state
        let newLoading = _.clone(arrayLoader)
        newLoading[key] = value
        this.setState({arrayLoader: newLoading})
    }

    render() {
        const {dispatch} = this.props
        let currentUser = JSON.parse(window.localStorage.getItem('user'))

        let {
            currentPage,
            modalLoader,
            modalVisible,
            carriersData,
            loader,
            key

        } = this.state

        const columns = [
            {
                title: 'S.No',
                dataIndex: 'sno',
                width: 50,
                key: 'sno',
                fixed: 'left',
                render: (value, item, index) => (currentPage - 1) * 10 + index + 1
            },
            {
                title: 'Lead Name',
                key: 'leadName',
                width: 200,
                fixed: 'left',
                searchTextName: 'lead'
            },
            {
                title: 'Contact Details',
                key: 'contactDetails',
                searchTextName: 'contactDetails',
                render: (text, record) => <span><span>{record.contactName || ''}</span><br/>
          <span>{record.mobile || ''}</span><br/>
          <span>{record.email || ''}</span><br/>
          <span>{record.state || ''}</span><br/>

        </span>,
                width: 200
            },
            {
                title: 'Products',
                dataIndex: 'products',
                key: 'products',
                width: 200,
                render: (val, record, index) => {
                    return <React.Fragment>
                        <div>
                            <ul style={{paddingLeft: '0px', listStyle: 'none'}}>
                                {val && val.length && val.map((item, key) => {

                                    return <li key={key}>{item.productName}</li>
                                })}

                            </ul>
                        </div>
                    </React.Fragment>

                }

            },
            {
                title: 'Status',
                dataIndex: 'status',
                width: 180,
                key: 'status',
                filters: orderStatus && orderStatus.map(x => ({value: x, text: x})),
                render: (val) => {
                    return <Tag color={getStatusColor(val)}>{val}</Tag>
                }

            },
            {
                title: 'carrier',
                dataIndex: 'carrier',
                width: 100,
                key: 'carrier',
                filters: carriersData && carriersData.map(x => ({value: x, text: x}))

            },

            {
                title: 'Invoice No',
                dataIndex: 'invoiceNumber',
                width: 150,
                key: 'invoiceNumber',
                searchTextName: 'invoiceNumber'
            },
            {
                title: 'Technician Name',
                dataIndex: 'technicianName',
                width: 150,
                key: 'technicianName',
                searchTextName: 'technicianName'
            },
            {
                title: 'Remarks',
                dataIndex: 'remarks',
                width: 150,
                key: 'remarks',
                searchTextName: 'remarks'
            },
            {
                title: 'Docket Number',
                dataIndex: 'dispatchNumber',
                width: 150,
                key: 'dispatchNumber',
                searchTextName: 'dispatchNumber'
            },
            {
                title: 'Estimated Date',
                dataIndex: 'estimatedDate',
                key: 'estimatedDate',
                width: 180,
                searchDateName: 'estimatedDate',
                render: (text, record) => {
                    return (
                        <Tag>
                            {text ? moment(text).format('lll') : ''}
                        </Tag>
                    )
                },
                sorter: true
            },
            {
                title: 'Order Date',
                dataIndex: 'orderDate',
                key: 'orderDate',
                width: 180,
                searchDateName: 'orderDate',
                render: (text, record) => {
                    return (
                        <Tag>
                            {text ? moment(text).format('lll') : ''}
                        </Tag>
                    )
                },
                sorter: true
            },
            {
                title: 'Order Accepted Date',
                dataIndex: 'orderAcceptedDate',
                key: 'orderAcceptedDate',
                width: 180,
                searchDateName: 'orderAcceptedDate',
                render: (text, record) => {
                    return (
                        <Tag>
                            {text ? moment(text).format('lll') : ''}
                        </Tag>
                    )
                },
                sorter: true
            },
            {
                title: 'Order Preparing Date',
                dataIndex: 'orderPreparingDate',
                key: 'orderPreparingDate',
                width: 180,
                searchDateName: 'orderPreparingDate',
                render: (text, record) => {
                    return (
                        <Tag>
                            {text ? moment(text).format('lll') : ''}
                        </Tag>
                    )
                },
                sorter: true
            },
            {
                title: 'Order Prepared Date',
                dataIndex: 'orderPreparedDate',
                key: 'orderPreparedDate',
                width: 180,
                searchDateName: 'orderPreparedDate',
                render: (text, record) => {
                    return (
                        <Tag>
                            {text ? moment(text).format('lll') : ''}
                        </Tag>
                    )
                },
                sorter: true
            },
            {
                title: 'Quality Check Date',
                dataIndex: 'qualityCheckDate',
                key: 'qualityCheckDate',
                width: 180,
                searchDateName: 'qualityCheckDate',
                render: (text, record) => {
                    return (
                        <Tag>
                            {text ? moment(text).format('lll') : ''}
                        </Tag>
                    )
                },
                sorter: true
            },
            {
                title: 'Quality Approved Date',
                dataIndex: 'qualityApprovedDate',
                key: 'qualityApprovedDate',
                width: 180,
                searchDateName: 'qualityApprovedDate',
                render: (text, record) => {
                    return (
                        <Tag>
                            {text ? moment(text).format('lll') : ''}
                        </Tag>
                    )
                },
                sorter: true
            },
            {
                title: 'Ready To Dispatched Date',
                dataIndex: 'readyToDispatchedDate',
                key: 'readyToDispatchedDate',
                width: 180,
                searchDateName: 'readyToDispatchedDate',
                render: (text, record) => {
                    return (
                        <Tag>
                            {text ? moment(text).format('lll') : ''}
                        </Tag>
                    )
                },
                sorter: true
            },
            {
                title: 'Dispatched Date',
                dataIndex: 'dispatched',
                key: 'dispatched',
                width: 180,
                searchDateName: 'dispatched',
                render: (text, record) => {
                    return (
                        <Tag>
                            {text ? moment(text).format('lll') : ''}
                        </Tag>
                    )
                },
                sorter: true
            },
            {
                key: 'actions',
                title: 'Actions',
                fixed: 'right',
                width: 100,
                render: (val, record, key) => {
                    return (<React.Fragment>

                        {currentUser && (currentUser.userType == 'RO' || currentUser.userType == 'Admin') && <Popconfirm
                            title="Are you sure to Approved this Order?"
                            onConfirm={async () => {
                                this.setLoaderAction(key, true)

                                await this.updateOrderStatus({_id: record._id, status: 'Quality Approved'})

                                this.setLoaderAction(key, false)

                            }}
                            onCancel={() => {

                            }}
                            okText="Yes"
                            cancelText="No">
                            <Tooltip title="Approved Order">
                                <Button
                                    hidden={record && record.status != 'Quality Check'}
                                    shape="circle"
                                    style={{marginRight: 6}}
                                    loading={this.state.arrayLoader[key]}
                                    type={'primary'}
                                    size="small"
                                    onClick={() => {
                                    }}
                                    icon={'check'}
                                />
                            </Tooltip>

                            <Tooltip title="Edit Order">
                                <Button
                                    shape="circle"
                                    style={{marginRight: 6}}
                                    size="small"
                                    onClick={() => {
                                        dispatch(
                                            getUrlPushWrapper('orderDispatch.editOrderDispatch', {
                                                id: record._id
                                            })
                                        )
                                    }}
                                    icon="edit"
                                />
                            </Tooltip>
                        </Popconfirm>}

                        {currentUser && (currentUser.userType == 'RO' || currentUser.userType == 'Admin') &&
                            <Tooltip title="Update Docket Number">
                                <Button
                                    shape="circle"
                                    size="small"
                                    type={'primary'}
                                    hidden={record.status !== 'Dispatched'}
                                    style={{marginRight: '6px'}}
                                    onClick={async () => {
                                        this.setState({orderId: record._id, key: Date.now()}, () => {
                                            this.setState({modalVisible: true})
                                        })
                                    }}
                                    icon={'check'}
                                />
                            </Tooltip>}


                        <Tooltip title={'Order Details'}>
                            <a href={`/orderDispatch/orderDetails?id=${record && record._id}`} target={'_blank'}>
                                <Button size="small" icon={'eye'} shape={'circle'}/>
                            </a>
                        </Tooltip>

                    </React.Fragment>)
                }
            }

        ]
        if (currentUser && currentUser.userType === 'Admin') {

            let dataItem = {
                title: 'Order Dispatch By',
                key: 'orderDispatchByName',
                width: 180

            }
            columns.splice(6, 0, dataItem)
        }
        return (
            <PageHeaderWrapper
                title={`Dispatch Order List: ${this.state.count || 0}`}>

                <Card bordered={true}>

                    <TableComp ref={this.table}
                               columns={columns}
                               apiRequest={this.apiRequest}
                               extraProps={{scroll: {x: 1000}}}
                               pagination={{
                                   ...this.state.pagination,
                                   defaultPageSize: 10,
                                   pageSizeOptions: ['10', '25', '50', '100'],
                                   showSizeChanger: true,
                                   ...this.props.pagination
                               }}/>


                    <Modal key={key} confirmLoading={modalLoader} onOk={this.updateOrder} visible={modalVisible}
                           title={'Update Docket Number'}
                           onCancel={() => {
                               this.setState({modalVisible: false, orderName: ''})
                           }}>
                        <Form layout={'inline'}>
                            <Form.Item label={'Docket Number'}>

                                <Input style={{width: 200}} onChange={(val) => {
                                    this.setState({dispatchNumber: val.target.value})
                                }}/>
                            </Form.Item>
                        </Form>

                    </Modal>
                </Card>

            </PageHeaderWrapper>)

    }
}

const mapStateToProps = ({global}) => ({
    categories: global.categories,
    loading: global.buttonLoading

})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(dispatchOrderList)
