import React, {PureComponent} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
    Form,
    Button,
    Card,
    Input,
    Tooltip,
    Popconfirm,
    Tag,
    Drawer
} from 'antd'
import _ from 'lodash'
import { TableComp} from 'sz-react-utils'
import 'react-quill/dist/quill.snow.css'
import {notification} from 'antd/lib/index'
import {hideLoader, showLoader} from '../../../modules/actions'
import Request from '../../../request'
import {connect} from 'react-redux'
import moment from "moment/moment";
import styles from "../../dispatchDept/all/styles.less";


@Form.create()
class AddCategory extends PureComponent {


    handleSubmit = e => {
        const {dispatch, form} = this.props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let values = _.clone(valData)


                dispatch(showLoader())

                let x = null
                if (this.state.id) {
                    values._id = this.state.id

                    x = await Request.updateIP(values)
                } else {
                    x = await Request.addCategory(values)
                }

                dispatch(hideLoader())


                if (!x.error) {

                    notification.success({
                        message: this.state.id
                            ? x.message
                            : x.message
                    })

                    if (!this.state.id) {
                        this.props.form.resetFields()
                        this.table.current.reload()

                    }


                } else {
                    notification.error({
                        message: 'Error Saving',
                        description: x.message
                    })
                }

            }
        })
    }


    constructor(props) {
        super(props)
        this.table = React.createRef()

        this.state = {visible:false,stockProduct:[]}

    }

    async componentDidMount() {
        let searchParams = new URLSearchParams(this.props.search);
        let userId = searchParams.get('id')


    }
    showDrawer = () => {
        this.setState({
            visible: true
        })
    }

    apiRequest = (params) => {

        return new Promise(async (resolve) => {

            if (!params.sortField) {
                params.sortField = 'createdAt'
                params.sortOrder = 'descend'
            }

            let data = await Request.getAllCategories({
                ...params, regExFilters: []
            })

            console.log(data)
            this.setState({count: data.total})
            resolve(data);
        })
    }
    deleteCategory = async data => {
        this.setState({loading: true})
        let x = await Request.deleteCategory(data)
        this.setState({loading: false})

        this.table.current.reload()
        if (!x.error) {
            notification.success({
                message: x.message,
                duration: 20,
                key: `${data._id}-close`
            })

        }
        else {
            notification.error({
                message: x.message,
                duration: 20,
                key: `${data._id}-close`
            })
        }


    }

    render() {


        const {
            form: {getFieldDecorator, getFieldValue}
        } = this.props;


        const columns = [
            {
                title: 'Category',
                key: 'category',
                dataIndex: 'category',
                searchTextName: 'category'


            },
            {
                title: 'Total Product',
                dataIndex: 'stockProduct',
                key: 'stockProduct',
                render: (val, record) => {
                    return <div><a onClick={() => {
                        this.setState({ stockProduct: record.stockProduct }, () => {
                            this.showDrawer()
                        })
                    }}>{val && val.length}</a></div>
                }


            },

            {
                title: 'Created At',
                key: 'createdAt',
                dataIndex: 'createdAt',
                sorter: true,
                render: (text, record) => {
                    return (
                        <Tag> {text ? moment(text).format('lll') : ''}</Tag>
                    )
                }
            },
            {
                key: 'actions',
                title: 'Actions',
                render: (text, record) => {
                    return <React.Fragment>

                        <Tooltip title="Delete Category">
                            <Popconfirm
                                title="Are you sure to delete this Category?"
                                onConfirm={() => {
                                    this.deleteCategory({_id: record._id})
                                }}
                                onCancel={() => {
                                    console.log()
                                }}
                                okText="Yes"
                                cancelText="No">
                                <Button
                                    shape="circle"
                                    size="small"

                                    icon="delete"
                                />
                            </Popconfirm>

                        </Tooltip>
                    </React.Fragment>
                }
            }


        ]


        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
                md: {span: 8}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
                md: {span: 12}
            }
        }

        const submitFormLayout = {
            wrapperCol: {
                xs: {span: 24, offset: 0},
                sm: {span: 10, offset: 7},
                md: {span: 12, offset: 8}
            }
        }


        return (
            <PageHeaderWrapper
                title={this.state.id ? 'Update Category' : 'Add Category'}
            >

                <Card bordered={true}>
                    <Form onSubmit={this.handleSubmit} hideRequiredMark>

                        <div>
                            <Form.Item label={'Category'} {...formItemLayout}>
                                {getFieldDecorator('category', {
                                    rules: [
                                        {
                                            required: true,
                                            message: `Please input category`
                                        }
                                    ]
                                })(<Input>

                                </Input>)}
                            </Form.Item>

                            <Form.Item {...submitFormLayout}>
                                <Button type="primary" htmlType="submit" loading={this.props.loading}>
                                    ADD
                                </Button>
                            </Form.Item>
                        </div>


                    </Form>


                </Card>
                <Card bordered={true}>
                    <h3>{this.state.count ? `All Categories: ${this.state.count}` : `All Category: 0`}</h3>

                    <TableComp ref={this.table}
                               columns={columns}
                               apiRequest={this.apiRequest}
                               pagination={{
                                   ...this.state.pagination,
                                   defaultPageSize: 10,
                                   pageSizeOptions: ['10', '25', '50', '100'],
                                   showSizeChanger: true,
                                   ...this.props.pagination
                               }}/>
                </Card>
                <Drawer
                    title="Product Details"
                    placement="right"
                    closable={true}
                    onClose={() => {
                        this.setState({
                            visible: false,
                            stockProduct: null
                        })
                    }}
                    visible={this.state.visible}
                    width={800}>
                    <Card className={styles.drawerCard}>

                        <table style={{ width: '100%' }} className={styles.ProcessTable}>
                            <thead>
                            <tr>
                                <th>Product Name</th>
                                <th>Quantity</th>
                                <th>Minimum Critical Value</th>
                            </tr>
                            </thead>
                            <tbody> {_.map(this.state.stockProduct, (item) => {
                                return <tr>
                                    <td colSpan="1">{item && item.name}</td>
                                    <td colSpan="1">{item && item.quantity}</td>
                                    <td colSpan="1"> {item && item.criticalValue}</td>

                                </tr>
                            })}</tbody>


                        </table>

                    </Card>


                </Drawer>

            </PageHeaderWrapper>
        )
    }
}

const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddCategory)
