import React, {Component} from 'react'
import PageHeaderWrapper from '../../components/PageHeaderWrapper'
import moment from 'moment'
import {TableComp} from 'sz-react-utils'
import {
    Card,
    Tooltip,
    Popover,
    Button,
    Tag,
    Modal,
    Form,
    Select,
    notification
} from 'antd'
import Request from '../../request'
import _ from 'lodash'
import styles from './styles.less'
import {connect} from 'react-redux'
import {push} from 'connected-react-router'
import ReactPlayer from 'react-player/lazy'
import {apiUrl} from '../../settings'
import {getUrlParams} from "../../routes";


@Form.create()
class Demo extends Component {

    constructor(props) {
        super(props)
        this.state = {}


    }


    async componentDidMount() {
        let searchParams = new URLSearchParams(this.props.search)
        let recordId = searchParams.get('recordId')
        this.setState({recordId})
    }


    render() {

        const {} = this.props
        let {recordId} = this.state
        const {
            form: {getFieldDecorator, getFieldValue}
        } = this.props

        return <div style={{textAlign:'-webkit-center'}}>
            <ReactPlayer
                url={`${apiUrl}/recordingStream?path=recordings/${recordId}.mp3&isPath=true`}
                controls
                //height={50}
                className={styles.player}
               // width={400}
            />
        </div>

    }
}

const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search,
    currentUser: global.currentUser

})

export default connect(
    mapStateToProps)(Demo)

