import React, {PureComponent} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
    Form,
    Input,
    Spin,
    Button,
    Card,
    Row, Col, Checkbox, Upload, Icon,
    Select,
    Table,
    AutoComplete,
    Popconfirm,
    Typography,
    Tooltip
} from 'antd'
import _ from 'lodash'
import moment from 'moment'
import styles from './styles.less'

import {FormUtils as GetAllFormFields} from 'sz-react-utils'

import {API_URL} from '../../../request'

import 'react-quill/dist/quill.snow.css'
import {notification} from 'antd/lib/index'
import {hideLoader, showLoader} from '../../../modules/actions'
import Request from '../../../request'
import {connect} from 'react-redux'
import {createMatchSelector, goBack} from 'connected-react-router'

const {TextArea} = Input
const {Option} = Select
const {Text, Paragraph} = Typography;
const Categories = ['Milk Testing Equipment', 'Glassware', 'IOT']


@Form.create()
class AddProduct extends PureComponent {

    handleSubmit = e => {
        const {dispatch, form, handleProductSubmit} = this.props
        let {totalData} = this.state

        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let values = _.clone(valData)

                // if (totalData && totalData.length == 1) {
                //     this.setState({sendLoading: false})
                //     return notification.error({
                //         message: 'Atleast add one product'
                //     })
                // }

                dispatch(showLoader())

                let x = null

                let priceInformation = {
                    unitPrice: values.unitPrice,
                    gstTax: values.gstTax
                }


                values.priceInformation = priceInformation
                values.warrantyValue = values.warranty
                values.warrantyType = this.state.warranty ? `${this.state.warranty}` : 'Years'
                values.productImage = values.productImage && values.productImage.fileList
                values.productPdf = values.productPdf && values.productPdf.fileList
                values.productInitial = this.state.productInitial
                values.generateSerialNumber = this.state.generateSerialNumber

                totalData = _.reject(this.state.totalData, (x) => x.isLast == true)
                console.log(totalData)
                values.bom = totalData
                if (this.state.id) {
                    values._id = this.state.id
                    values.activeProduct = values.activeProduct ? values.activeProduct : this.state.checkActive
                    x = await Request.updateProduct(values)
                }
                else {
                    x = await Request.addProduct(values)
                    form.resetFields()
                    this.props.form.setFieldsValue({manufacturer: 'Hindustan Thermostatic'})

                    this.setState({checkActive: false, files: [], pdfFiles: []})
                }

                dispatch(hideLoader())

                if (!x.error) {

                    notification.success({
                        message: this.state.id
                            ? x.message
                            : x.message
                    })

                    this.props.form.setFieldsValue({})

                    if (handleProductSubmit) {
                        console.log('handleProductSubmit is', handleProductSubmit)
                        handleProductSubmit(x.data)
                    }

                    if (this.state.id) {
                        dispatch(goBack())
                    }

                } else {
                    notification.error({
                        message: 'Error Saving',
                        description: x.message
                    })
                }

            }
        })
    }
    handlePreview = file => {
        let url
        if (file && file.response && file.response.path) {
            file.response.path = file.response.path.replace(/^public/, '')
            url = `${API_URL}/${file.response.path}`

        } else {
            url = `${API_URL}/${file.url}`

        }

        window.open(url, '_blank')
        this.setState({
            previewImage: url,
            previewVisible: true
        })
    }
    handleChange = (value) => {

        this.setState({warranty: value ? value : 'Years'})

    }
    handleImageChange = v => {
        if (v.fileList) {
            this.setState({files: v.fileList})
        }
    }
    onRemove = v => {
        console.log(v)
    }

    constructor(props) {
        super(props)
        this.state = {
            loader: true, uploading: false, pdfUploading: false, previewVisible: false,
            previewImage: '',
            totalData: [],
            generateSerialNumber: false
        }
        this.AddData = _.debounce(this.addData, 1000, true)

    }


    async componentDidMount() {
        let searchParams = new URLSearchParams(this.props.search)
        let {handleProductSubmit} = this.props
        Request.getAllProducts({results: 1000}).then(({data: productList}) => {
            this.setState({productList: productList || []}, () => {

            })
        })
        let productId = searchParams.get('id')
        if (productId && !handleProductSubmit) {
            Request.getProductById({_id: productId}).then(({data, error, message}) => {
                if (!error) {
                    this.setState({
                        id: productId,
                        loader: false,
                        checkActive: data && data.activeProduct,
                        generateSerialNumber: data && data.generateSerialNumber ? true : false,
                        warranty: data.warrantyType,
                        files: data && data.productImage,
                        pdfFiles: data && data.productPdf,
                        productInitial: data && data.productInitial,
                        totalData: [...data.bom, {isLast: true}],

                    })

                    this.props.form.setFieldsValue({
                        name: data && data.name,
                        productCode: data && data.productCode,
                        productCategory: data && data.productCategory,
                        manufacturer: data && data.manufacturer,
                        unitPrice: data && data.priceInformation && data.priceInformation.unitPrice,
                        gstTax: data && data.priceInformation && data.priceInformation.gstTax,
                        notes: data && data.notes,
                        warranty: data.warrantyValue,
                        youtubeLink: data && data.youtubeLink ? data.youtubeLink : '',
                        facebookLink: data && data.facebookLink ? data.facebookLink : '',
                        quoteDisplayedName: data && data.quoteDisplayedName,
                        unit: data && data.unit
                    })
                } else {
                    console.log(error)
                    notification.error({
                        message: 'Error Getting Data'
                    })
                }
            })
        }
        else {
            this.props.form.setFieldsValue({manufacturer: 'Hindustan Thermostatic'})
            this.setState({
                loader: false,
                generateSerialNumber: true
            })
            this.state.totalData.push({isLast: true})

        }


    }


    addData = () => {


        let tt = _.cloneDeep(this.state.totalData)
        let found = _.find(tt, (i) => {
            if (i && i.isLast != true) {
                return (i.productId == this.state.productId) || (i.productId._id == this.state.productId)

            }
        })

        if (found) {
            this.setState({showText: false})
            this.props.form.setFieldsValue({
                productName: undefined,
                qty: null,
                productId: undefined

            })

            return notification.error({
                message: 'Product Already Exists.'
            })
        }
        let comp = tt.pop()
        tt.push({

            qty: this.state.qty,
            productName: this.state.productName,
            isLast: false,
            productId: this.state.productId


        })
        tt.push(comp)

        this.props.form.setFieldsValue({
            totalData: tt,
            productName: undefined,
            qty: null,

        })
        this.setState({
            totalData: tt,
            productName: undefined,
            qty: null,
            productId: undefined


        }, () => {

        })

    }


    onProductSelect = (val) => {
        let selectedQuote = this.state.productList.find((obj) => obj._id === val)
        if (selectedQuote) {
            this.props.form.setFieldsValue({
                productName: selectedQuote.name,

            })
            this.setState({
                productName: selectedQuote.name,
                productId: selectedQuote._id,
                qty: 1

            }, () => {
                this.addData()
            })
        }

    }

    render() {

        const {productList} = this.state
        const {
            form: {getFieldDecorator, getFieldValue}
        } = this.props
        const leftInputTypes = {
            fields: [
                {
                    label: 'Product Code',
                    key: 'productCode',
                    required: true
                    // onChange: v => {
                    //   this.props.form.setFieldsValue({productCode: v.target.value.toUpperCase()})
                    // }
                },
                {
                    label: 'Product Category',
                    key: 'productCategory',
                    required: true,
                    type: 'select',
                    options: Categories,
                    onChange: v => {
                        this.props.form.setFieldsValue({productCategory: v})
                    }
                },
                {
                    label: 'Unit',
                    key: 'unit',
                    type: 'select',
                    options: ['Kg', ' Box', 'Piece'],
                    onChange: v => {
                        this.props.form.setFieldsValue({unit: v})
                    }
                },
            ]
        }
        const rightInputTypes = {
            fields: [
                {
                    label: 'Product Name',
                    key: 'name',
                    required: true
                },

                {
                    label: 'Manufacturer',
                    key: 'manufacturer',
                    type: 'select',
                    disabled: true,
                    options: ['Hindustan Thermostatic'],
                    onChange: v => {
                        this.props.form.setFieldsValue({manufacturer: v})
                    }
                },
                {
                    label: 'Quote Displayed Name',
                    key: 'quoteDisplayedName',
                    required: true
                }


            ]
        }

        const refColumns = [

            {
                title: 'S.No',
                dataIndex: 'sno',
                key: 'sno',
                width: 50,
                render: (value, item, index) => {
                    if (item && item.isLast != true) {
                        return index + 1
                    }
                }
            },
            {
                title: 'Product',
                dataIndex: 'productName',
                key: 'productName',
                width: 130,
                render: (x, item, index) => {
                    if (item && item.isLast == true) {

                        return (<div>

                            {getFieldDecorator('productName', {})(
                                <Select
                                    showSearch
                                    dropdownMatchSelectWidth={false}
                                    optionFilterProp="children"
                                    style={{
                                        width: 200
                                    }}
                                    placeholder="Select Product"
                                    onSelect={id => {
                                        this.onProductSelect(id)

                                    }}
                                >
                                    {productList &&
                                    productList.map((val, idn) => {
                                        return (
                                            <Option key={idn} value={val._id}>
                                                {val.name} {val && val.quoteDisplayedName ? `(${val.quoteDisplayedName})` : ''}
                                            </Option>
                                        )
                                    })}
                                </Select>)}

                        </div>)

                    }
                    else {

                        if (item && item.productId && item && item.productId._id) {
                            return <div style={{marginBottom: '5px'}}> {item.productId.name}</div>
                        }
                        return <div style={{marginBottom: '5px'}}> {x}</div>


                    }


                }


            },


            {
                title: 'Quantity',
                key: 'qty',
                width: 50,
                dataIndex: 'qty',
                render: (y, item, index) => {
                    if (item && item.isLast != true) {
                        return <div><Text editable={{
                            onChange: (val) => {
                                if (!isNaN(parseInt(val))) {
                                    let tt = this.state.totalData
                                    item.qty = val ? Math.abs(val) : tt[index].qty
                                    if (val == 0) {
                                        item.qty = 1

                                    }

                                    this.setState({totalData: tt})
                                    this.props.form.setFieldsValue({
                                        qty: val
                                    })
                                }


                            }
                        }}> {y}</Text>


                        </div>
                    }


                }

            },
            {
                title: 'Action',
                key: 'operation',
                width: 50,
                //  fixed: 'right',
                render: (item, record) => {
                    if (record && record.isLast != true) {
                        return <React.Fragment>

                            <Tooltip title="Delete Product">
                                <Popconfirm
                                    title="Are you sure delete this Product?"
                                    onConfirm={() => {
                                        let tt = this.state.totalData
                                        tt = _.reject(tt, item)
                                        this.setState({totalData: tt}, () => {
                                        })


                                    }}
                                    onCancel={() => {
                                        console.log()
                                    }}
                                    okText="Yes"
                                    cancelText="No">
                                    <Button
                                        shape="circle"
                                        size="small"
                                        type={'danger'}
                                        icon="delete"
                                    />
                                </Popconfirm>

                            </Tooltip>

                        </React.Fragment>

                    }
                }
            }
        ]

        const priceLeftInputTypes = {
            fields: [{
                label: 'Unit Price',
                key: 'unitPrice',
                required: true,
                type: 'number'
            },
                {
                    label: 'GST',
                    key: 'gstTax',
                    type: 'number',
                    required: true
                }]
        }
        const warranty = {
            fields: [{
                label: 'Warranty',
                key: 'warranty',
                type: 'number',
                required: true,
                customProps: {
                    style: {
                        width: '30%',
                        marginRight: '10px'
                    }
                },
                rightComp: <Select defaultValue={warranty ? warranty : 'Years'} key={warranty ? 'new' : 'old'}
                                   style={{width: 120}} onChange={this.handleChange}>

                    <Option value="Years">Years</Option>
                    <Option value="Months">Months</Option>
                    <Option value="Days">Days</Option>
                </Select>
            }]
        }

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
                md: {span: 8}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
                md: {span: 12}
            }
        }
        const formItemLayout1 = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
                md: {span: 5}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
                md: {span: 12}
            }
        }


        return (
            <PageHeaderWrapper
                title={this.state.id ? 'Update Product' : 'Add Product'}
            >
                <Spin spinning={this.state.loader}>
                    <Card bordered={true}>
                        <Form onSubmit={this.handleSubmit} hideRequiredMark={false} style={{marginTop: 8}}>
                            <Card bordered={true} style={{marginBottom: '15px'}}>
                                <div className={styles.heading}>
                                    <h3>Product Information</h3></div>
                                <Row>
                                    <Col span={12}>
                                        <GetAllFormFields apiurl={API_URL}
                                                          inputSchema={leftInputTypes} formItemLayout={formItemLayout}
                                                          getFieldDecorator={getFieldDecorator}/>
                                        <Form.Item label={'Product Active'} {...formItemLayout}>
                                            {getFieldDecorator('activeProduct')(<Checkbox
                                                checked={this.state.checkActive}
                                                onChange={(val) => {
                                                    if (val.target.checked) {
                                                        this.setState({checkActive: true})
                                                    }
                                                    else {
                                                        this.setState({checkActive: false})
                                                    }
                                                }}>
                                            </Checkbox>)}
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <GetAllFormFields apiurl={API_URL}
                                                          inputSchema={rightInputTypes} formItemLayout={formItemLayout}
                                                          getFieldDecorator={getFieldDecorator}/>

                                        <Form.Item label={'Initial'} {...formItemLayout}>
                                            <Input
                                                maxLength={3}
                                                required={true}
                                                value={this.state.productInitial}
                                                onChange={(val) => {
                                                    this.setState({productInitial: val.target.value.toUpperCase()})

                                                }}>
                                            </Input>
                                        </Form.Item>


                                        <Form.Item label={'Generate Serial Number'} {...formItemLayout}>
                                            {getFieldDecorator('generateSerialNumber')(<Checkbox
                                                checked={this.state.generateSerialNumber}
                                                onChange={(val) => {
                                                    if (val.target.checked) {
                                                        this.setState({generateSerialNumber: true})
                                                    }
                                                    else {
                                                        this.setState({generateSerialNumber: false})
                                                    }
                                                }}>
                                            </Checkbox>)}
                                        </Form.Item>

                                    </Col>
                                </Row>

                            </Card>
                            <Card bordered={true} style={{marginBottom: '15px'}}>
                                <div className={styles.heading}>
                                    <h3>Product Image</h3></div>
                                <Row>
                                    <Col span={12}>
                                        <Form.Item label={'Upload Image'} {...formItemLayout}>

                                            {getFieldDecorator('productImage')(<Upload
                                                // name={'file'}
                                                fileList={this.state.files}
                                                loading={this.state.uploading}
                                                action={`${API_URL}/filesUploader`}
                                                onChange={this.handleImageChange}
                                                // listType="picture-card"
                                                onPreview={this.handlePreview}
                                                onRemove={this.onRemove}
                                            >
                                                <Button>
                                                    <Icon type="upload"/> Select File
                                                </Button>
                                            </Upload>)}
                                        </Form.Item>
                                    </Col>
                                </Row></Card>

                            <Card bordered={true} style={{marginBottom: '15px'}}>
                                <div className={styles.heading}><h3>Price Information</h3></div>
                                <Row>

                                    <Col span={12}>
                                        <GetAllFormFields apiurl={API_URL}
                                                          inputSchema={priceLeftInputTypes}
                                                          formItemLayout={formItemLayout}
                                                          getFieldDecorator={getFieldDecorator}/>
                                    </Col>
                                    <Col span={12}>
                                        <GetAllFormFields apiurl={API_URL}
                                                          inputSchema={warranty} formItemLayout={formItemLayout}
                                                          getFieldDecorator={getFieldDecorator}/>
                                    </Col>

                                </Row>
                            </Card>

                            <Card bordered={true} style={{marginBottom: '15px'}}>
                                <div className={styles.heading}><h3>Bill of Materials</h3>
                                </div>

                                <Row>
                                    <Col span={24}>
                                        {<Table
                                            bordered={true}
                                            rowKey={x => x.productName}
                                            columns={refColumns}
                                            dataSource={this.state.totalData || []}
                                            pagination={false}
                                        />}
                                    </Col>

                                </Row>
                            </Card>


                            <Card bordered={true} style={{marginBottom: '15px'}}>
                                <div className={styles.heading}><h3>Description Information</h3>
                                </div>

                                <Row>
                                    <Col span={24}>
                                        <Form.Item label={'Facebook Link'} {...formItemLayout1}>
                                            {getFieldDecorator('facebookLink')(<Input/>)}
                                        </Form.Item>

                                        <Form.Item label={'Youtube Link'} {...formItemLayout1}>
                                            {getFieldDecorator('youtubeLink')(<Input/>)}
                                        </Form.Item>

                                        <Form.Item label={'Upload Pdf'} {...formItemLayout1}>

                                            {getFieldDecorator('productPdf')(<Upload
                                                fileList={this.state.pdfFiles}
                                                loading={this.state.pdfUploading}
                                                onPreview={this.handlePreview}
                                                action={`${API_URL}/filesUploader`}
                                                onChange={v => {
                                                    if (v.fileList) {
                                                        this.setState({pdfFiles: v.fileList})
                                                    }
                                                }}
                                                onRemove={this.onRemove}
                                            >
                                                <Button>
                                                    <Icon type="upload"/> Select Pdf
                                                </Button>
                                            </Upload>)}
                                        </Form.Item>


                                        <Form.Item label={'Notes/Description'} {...formItemLayout1}>
                                            {getFieldDecorator('notes')(<TextArea rows={4}
                                                                                  onChange={ei => {
                                                                                      this.setState({notes: ei.target.value})
                                                                                  }}/>)}
                                        </Form.Item>
                                    </Col>

                                </Row>
                            </Card>


                            <Form.Item style={{marginTop: 32, textAlign: 'center'}}>
                                <Button size="large" type="primary" htmlType="submit" loading={this.props.loading}
                                        style={{width: '15%'}}>
                                    {this.state.id ? `UPDATE` : `SAVE`}

                                </Button>
                            </Form.Item>

                        </Form>
                    </Card>
                </Spin>
            </PageHeaderWrapper>
        )
    }
}

const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddProduct)
