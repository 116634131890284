import React, {Component} from 'react'
import PageHeaderWrapper from '../../components/PageHeaderWrapper'
import {TableComp} from 'sz-react-utils'
import moment from 'moment'
import {
    Table,
    Badge,
    Card,
    Spin,
    Tooltip,
    Input, Button, Icon, Row, Col, Select
} from 'antd'
import Request, {API_URL} from '../../request'
import _ from 'lodash'
import styles from './styles.less'
import {connect} from 'react-redux'
import {hideLoader, showLoader} from '../../modules/actions';
import {Calendar} from 'antd';
import CalendarComponent from './calendarComponent';

import {addFollowUpModal, followUpModal} from './helpers'

const {Option} = Select

class FollowupCalendar extends Component {


    constructor(props) {
        super(props)
        this.table = React.createRef()
        this.state = {
            visible: false,
            startRange: moment().startOf('day').format('YYYY-MM-DD'),
            lastRange: moment().endOf('day').format('YYYY-MM-DDTHH:MM:ss'),
            leadId: {},
            currentFollowup: {},
            loader: true,
            addFollowup: false,
            listClicked: false

        }
    }

    async componentDidMount() {
        this.getCalendarData();

        Request.getAllROList({userType: 'RO', results: '10000000000000'}).then(({data: userList}) => {
            this.setState({userList: userList || []})
        })

    }

    getCalendarData = async (data1) => {
        console.log("poooannananan", data1)
        let params = {};
        this.setState({loader: true})
        let user = {...JSON.parse(localStorage.getItem('user'))};
        if (user && user.userType === 'Admin') {
            params.addedBy = this.state.roId

        } else {
            params.addedBy = user && user._id
        }
        // params.calendarView = true;
        if (data1) {
            console.log("inside data 1")
            this.setState({startRange: data1.startRange, lastRange: data1.lastRange})
            this.state.startRange = data1.startRange
            this.state.lastRange = data1.lastRange
            // params.followUpDate = {$gte: data1.startRange, $lte: data1.lastRange}
        }
        params.active = true
        params.followUpDate = {$gte: this.state.startRange, $lte: this.state.lastRange}

        console.log(params)
        let {data} = await Request.followupCalendarList({
            ...params
        });
        console.log("followup data is", data)
        this.setState({count: data && data.length || 0, followUps: data, loader: false})


    }


    render() {

        const {submitting, dispatch, currentUser} = this.props;
        const {followUps, userList} = this.state;
        const setFollowupModal = (item) => {
            this.setState({
                visible: true,
                currentFollowup: item
            })
        }


        return (
            <Spin spinning={this.state.loader}><PageHeaderWrapper
                title={this.state.count ? `All FollowUp : ${this.state.count}` : `All FollowUp: 0`}>

                <Card bordered={true}>
                    <Row>
                        <Col span={12}>
                            <h4 style={{display: 'inline-block', marginRight: '10px'}}>Select Relationship Officer</h4>
                            <Select
                                showSearch
                                allowClear
                                optionFilterProp="children"
                                disabled={currentUser && currentUser.userType !== 'Admin'}
                                style={{
                                    width: 225

                                }}
                                placeholder="Select Relationship Officer"
                                onChange={id => {
                                    this.setState({roId: id}, () => {
                                        this.getCalendarData()
                                    })
                                }}
                            >
                                {userList &&
                                userList.map((val, idn) => {
                                    return (
                                        <Option key={idn} value={val._id}>
                                            {val.name} ({val.mobile})
                                        </Option>
                                    )
                                })}
                            </Select>


                        </Col>
                    </Row>
                </Card>


                <CalendarComponent events={followUps} handleCancel={this.getCalendarData.bind(this)}/>

            </PageHeaderWrapper> </Spin>)

    }
}


const mapStateToProps = ({global}) => ({
    categories: global.categories,
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FollowupCalendar)
