import React, {PureComponent} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
    Form,
    Input,
    Spin,
    Button,
    Card,
    Row, Col, Checkbox, Upload, Icon,
    Select, Radio,
    Switch
} from 'antd'
import _ from 'lodash'
import moment from 'moment'
import styles from '../all/styles.less'

import {FormUtils as GetAllFormFields} from 'sz-react-utils'

import {API_URL} from '../../../request'

import 'react-quill/dist/quill.snow.css'
import {notification} from 'antd/lib/index'
import {hideLoader, showLoader} from '../../../modules/actions'
import Request from '../../../request'
import {connect} from 'react-redux'
import {createMatchSelector, goBack} from 'connected-react-router'

const {TextArea} = Input
const {Option} = Select


@Form.create()
class AddDairyContact extends PureComponent {

    handleSubmit = e => {
        const {dispatch, form} = this.props
        let {otherMilkPlant, source, selectedFarmerAcknowledgement, selectedFarmerAcknowledgementInput, selectedMilkPlantCapacity, milkProcurementChallengesInput, milkProcurementChallenges, mcpNumberInput, milkProcurementTechnologyInput, sourceInput, manageSalesInput, farmerFootfallsNumberInput, practiceMilkProcurementInput, milkCollectionProcuredFromInput} = this.state
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let values = _.clone(valData)
                let user = {...JSON.parse(localStorage.getItem('user'))}

                dispatch(showLoader())

                let x = null
                console.log(milkProcurementChallenges, "llllllll")

                if (milkProcurementChallenges.includes('Other')) {

                    let arr = _.reject(milkProcurementChallenges, (item) => item == 'Other')

                    arr.push(milkProcurementChallengesInput)

                    values.milkProcurementChallenges = arr
                } else {
                    values.milkProcurementChallenges = milkProcurementChallenges
                }

                if (selectedMilkPlantCapacity.includes('Other')) {

                    let arr = _.reject(selectedMilkPlantCapacity, (item) => item == 'Other')

                    arr.push(otherMilkPlant)

                    values.milkPlantCapacity = arr
                } else {
                    values.milkPlantCapacity = selectedMilkPlantCapacity
                }

                if (selectedFarmerAcknowledgement.includes('Other')) {

                    let arr = _.reject(selectedFarmerAcknowledgement, (item) => item == 'Other')

                    arr.push(selectedFarmerAcknowledgementInput)

                    values.farmerAcknowledgement = arr
                } else {
                    values.farmerAcknowledgement = selectedFarmerAcknowledgement
                }

                if (source.includes('Other')) {
                    let arr = _.reject(source, (item) => item == 'Other')

                    arr.push(sourceInput)

                    values.source = arr
                } else {
                    values.source = source
                }

                values.mcpNumber = values.mcpNumber === 'Other' ? mcpNumberInput : values.mcpNumber
                values.milkProcurementTechnology = values.milkProcurementTechnology === 'Other' ? milkProcurementTechnologyInput : values.milkProcurementTechnology
                values.milkCollectionProcuredFrom = values.milkCollectionProcuredFrom === 'Other' ? milkCollectionProcuredFromInput : values.milkCollectionProcuredFrom
                values.practiceMilkProcurement = values.practiceMilkProcurement === 'Other' ? practiceMilkProcurementInput : values.practiceMilkProcurement
                values.farmerFootfallsNumber = values.farmerFootfallsNumber === 'Other' ? farmerFootfallsNumberInput : values.farmerFootfallsNumber
                values.manageSales = values.manageSales === 'Other' ? manageSalesInput : values.manageSales
                values.source = values.source === 'Other' ? sourceInput : values.source

                if (user && user.userType === "RO") {
                    values.officer = user && user._id
                }


                if (this.state.id) {
                    values._id = this.state.id
                    x = await Request.updateDairyContact(values)
                }
                else {
                    x = await Request.addDairyContact(values)

                }

                dispatch(hideLoader())

                if (!x.error) {

                    notification.success({
                        message: this.state.id
                            ? x.message
                            : x.message
                    })

                    form.resetFields()
                    this.props.form.setFieldsValue({})
                    this.setState({

                        milkProcurementChallengesInput: '',
                        mcpNumberInput: '',
                        milkProcurementTechnologyInput: '',
                        sourceInput: '',
                        manageSalesInput: '',
                        farmerFootfallsNumberInput: '',
                        practiceMilkProcurementInput: '',
                        milkCollectionProcuredFromInput: '',
                        selectedFarmerAcknowledgementInput: '',
                        otherMilkPlant: '',
                        showMcpNumber: false,
                        showMilkProcurementTechnology: false,
                        showMilkCollectionProcuredFrom: false,
                        showMilkProcurementChallenges: false,
                        checkedOther: false,
                        showPracticeMilkProcurement: false,
                        showFarmerFootfallsNumber: false,
                        showFarmerAcknowledgement: false,
                        showManageSales: false,
                        showSource: false,
                        toggle: false

                    })
                    if (this.state.id) {
                        dispatch(goBack())
                    }

                } else {
                    notification.error({
                        message: 'Error Saving',
                        description: x.message
                    })
                }

            }
        })
    }

    constructor(props) {
        super(props)
        this.state = {
            loader: true,
            milkProcurementChallenges: [],
            selectedMilkPlantCapacity: [],
            selectedFarmerAcknowledgement: [],
            source: [],
            sourceOption: ['Social Media', 'Dairy Exibitions', 'From our existing valuable customer', 'Other'],

            farmerAcknowledgementOption: ['Through paper receipt', 'Via SMS', 'Through Farmer Mobile Applications', 'Other'],
            milkPlantCapacity: ['Less then ten thousand', '10000-50000', '50000-100000', '100000-500000', 'Above five lakhs', 'Other'],
            milkProcurementChallengesOption: [
                'Transparency in Milk procurement', 'Hardware issues', 'Data Syncing Issues', 'Issue in rate chart updation', 'After sales service and Maintenance',
                'Issues in farmer payments', 'Misuse of hardware by society incharge for personal benefits', 'Other'
            ],
            mcpNumberOptions: ['less than 100', '100-500', '500-1000', '1000-2000', '2000 or Above', 'Other'],

            milkProcurementTechnologyOptions: [
                'Automatic Milk Collection System (AMCU)',
                'Data Processing Milk Collection (DPMCU)',
                'Mobile Automatic milk Collection Unit (M-AMCU)',
                'GPRS Enabled DPU',
                'Other',
            ],
            milkCollectionProcuredFromOptions: ['Benny Impex', 'Everest Instruments Pvt Ltd', 'Prompt Dairy tech', 'Rajasthan Electronics limited(REL)', 'Stellapps technologies Pvt Ltd', 'Other'],
            practiceMilkProcurementOptions: [
                'Company owned and company operated', 'Company Owned butOperated by third party', 'Owned and Operated by third party', 'Bulk vendors', 'Other'
            ],
            farmerFootfallsNumberOptions: ['Less then 50', '50-100', '100-500', 'Other'],
            manageSalesOptions: ['Manually by Supervisor', 'Through Automatic collection unit software', 'separate system for sales', 'Other']

        }

    }

    setData = (data) => {
        let {sourceOption, manageSalesOptions, farmerAcknowledgementOption, farmerFootfallsNumberOptions, practiceMilkProcurementOptions, milkPlantCapacity, mcpNumberOptions, milkProcurementChallengesOption, milkProcurementTechnologyOptions, milkCollectionProcuredFromOptions} = this.state
        let temp = []
        let temp2 = []
        let temp3 = []
        let temp4 = []


        if (!mcpNumberOptions.includes(data && data.mcpNumber)) {
            this.setState({mcpNumberInput: data.mcpNumber, showMcpNumber: true})
            this.props.form.setFieldsValue({mcpNumber: 'Other'})
        }
        else {
            console.log("else part ", data.mcpNumber)
            this.props.form.setFieldsValue({mcpNumber: data && data.mcpNumber})

            this.setState({mcpNumberInput: '', showMcpNumber: false})
        }
        if (data && data.milkProcurementTechnology && !milkProcurementTechnologyOptions.includes(data.milkProcurementTechnology)) {
            this.setState({
                milkProcurementTechnologyInput: data.milkProcurementTechnology,
                showMilkProcurementTechnology: true
            })
            this.props.form.setFieldsValue({milkProcurementTechnology: 'Other'})

        }
        else {
            this.props.form.setFieldsValue({milkProcurementTechnology: data && data.milkProcurementTechnology})

            this.setState({milkProcurementTechnologyInput: '', showMilkProcurementTechnology: false})
        }
        if (data && data.milkCollectionProcuredFrom && !milkCollectionProcuredFromOptions.includes(data.milkCollectionProcuredFrom)) {
            this.setState({
                milkCollectionProcuredFromInput: data.milkCollectionProcuredFrom,
                showMilkCollectionProcuredFrom: true
            })
            this.props.form.setFieldsValue({milkCollectionProcuredFrom: 'Other'})

        }
        else {
            this.props.form.setFieldsValue({milkCollectionProcuredFrom: data && data.milkCollectionProcuredFrom})

            this.setState({milkCollectionProcuredFromInput: '', showMilkCollectionProcuredFrom: false})
        }


        if (data && data.practiceMilkProcurement && !practiceMilkProcurementOptions.includes(data.practiceMilkProcurement)) {
            this.setState({
                practiceMilkProcurementInput: data.practiceMilkProcurement,
                showPracticeMilkProcurement: true
            })
            this.props.form.setFieldsValue({practiceMilkProcurement: 'Other'})

        }
        else {
            this.props.form.setFieldsValue({practiceMilkProcurement: data && data.practiceMilkProcurement})
            this.setState({practiceMilkProcurementInput: '', showPracticeMilkProcurement: false})
        }

        if (data && data.farmerFootfallsNumber && !farmerFootfallsNumberOptions.includes(data.farmerFootfallsNumber)) {
            this.setState({
                farmerFootfallsNumberInput: data.farmerFootfallsNumber,
                showFarmerFootfallsNumber: true
            })
            this.props.form.setFieldsValue({farmerFootfallsNumber: 'Other'})

        }
        else {
            this.props.form.setFieldsValue({farmerFootfallsNumber: data && data.farmerFootfallsNumber})
            this.setState({farmerFootfallsNumberInput: '', showFarmerFootfallsNumber: false})
        }

        if (data && data.manageSales && !manageSalesOptions.includes(data.manageSales)) {
            this.setState({
                manageSalesInput: data.manageSales,
                showManageSales: true
            })
            this.props.form.setFieldsValue({manageSales: 'Other'})

        }
        else {
            this.props.form.setFieldsValue({manageSales: data && data.manageSales})
            this.setState({manageSalesInput: '', showManageSales: false})
        }


        _.each(data.milkProcurementChallenges, (item) => {
            if (!milkProcurementChallengesOption.includes(item)) {
                this.setState({milkProcurementChallengesInput: item, showMilkProcurementChallenges: true})
                temp.push('Other')
            }
        })
        if (temp && temp.length) {

            this.props.form.setFieldsValue({milkProcurementChallenges: [...data.milkProcurementChallenges, ...temp]})

        } else {
            this.props.form.setFieldsValue({milkProcurementChallenges: data && data.milkProcurementChallenges})

            this.setState({milkProcurementChallengesInput: '', showMilkProcurementChallenges: false})

        }


        _.each(data.milkPlantCapacity, (item) => {
            if (!milkPlantCapacity.includes(item)) {
                this.setState({otherMilkPlant: item, checkedOther: true})
                temp2.push('Other')
            }
        })
        if (temp2.length) {

            this.props.form.setFieldsValue({milkPlantCapacity: [...data.milkPlantCapacity, ...temp2]})

        } else {
            this.props.form.setFieldsValue({milkPlantCapacity: data && data.milkPlantCapacity})

            this.setState({otherMilkPlant: '', checkedOther: false})

        }


        _.each(data.farmerAcknowledgement, (item) => {
            if (!farmerAcknowledgementOption.includes(item)) {
                this.setState({selectedFarmerAcknowledgementInput: item, showFarmerAcknowledgement: true})
                temp3.push('Other')
            }
        })
        if (temp3.length) {
            this.props.form.setFieldsValue({farmerAcknowledgement: [...data.farmerAcknowledgement, ...temp3]})

        } else {
            this.props.form.setFieldsValue({farmerAcknowledgement: data && data.farmerAcknowledgement})

            this.setState({selectedFarmerAcknowledgementInput: '', showFarmerAcknowledgement: false})

        }


        _.each(data.source, (item) => {
            if (!sourceOption.includes(item)) {
                this.setState({sourceInput: item, showSource: true})
                temp4.push('Other')
            }
        })
        if (temp4.length) {

            this.props.form.setFieldsValue({source: [...data.source, ...temp4]})

        } else {
            this.props.form.setFieldsValue({source: data && data.source})

            this.setState({sourceInput: '', showSource: false})

        }

    }


    async componentDidMount() {
        let searchParams = new URLSearchParams(this.props.search)
        Request.loadUser({userType: ['RO', 'Admin']}, {results: 1000000000000}).then(({data: userList}) => {
            this.setState({userList: userList})
        })
        let dairyId = searchParams.get('id')
        if (dairyId) {
            Request.getDairyContactById({_id: dairyId}).then(({data, error, message}) => {
                console.log(data)
                if (!error) {
                    this.setState({
                        id: dairyId,
                        loader: false,
                        milkProcurementChallenges: data.milkProcurementChallenges,
                        selectedMilkPlantCapacity: data && data.milkPlantCapacity,
                        selectedFarmerAcknowledgement: data && data.farmerAcknowledgement,
                        source: data && data.source,
                        toggle: data && data.response

                    })

                    this.setData(data)

                    this.props.form.setFieldsValue({

                        name: data && data.name,
                        email: data && data.email,
                        contactNumber: data && data.contactNumber,
                        organizationName: data && data.organizationName,
                        designation: data && data.designation,
                        milkPlantsLocation: data && data.milkPlantsLocation,
                        exactMilkPlantCapacity: data && data.exactMilkPlantCapacity,
                        aboutHT: data && data.aboutHT,
                        status: data && data.status,
                        farmerSale: data && data.farmerSale,
                        futureUpdation: data && data.futureUpdation,
                        //  response: data && data.response,
                        upGradationTechnology: data && data.upGradationTechnology,
                        officer: data && data.officer

                    })
                } else {
                    console.log(error)
                    notification.error({
                        message: 'Error Getting Data'
                    })
                }
            })
        }
        else {
            this.setState({
                loader: false,
                toggle: false

            })
        }


    }

    render() {

        const {currentUser} = this.props
        const {
            form: {getFieldDecorator, getFieldValue}
        } = this.props

        let {userList, manageSalesOptions, farmerFootfallsNumberOptions, practiceMilkProcurementOptions, mcpNumberOptions, milkCollectionProcuredFromOptions, milkPlantCapacity, milkProcurementTechnologyOptions, sourceOption, showSource, milkProcurementChallengesOption, showMilkProcurementChallenges, showManageSales, farmerAcknowledgementOption, showMilkCollectionProcuredFrom, checkedOther, showPracticeMilkProcurement, showMcpNumber, showFarmerFootfallsNumber, showMilkProcurementTechnology, showFarmerAcknowledgement} = this.state
        const inputType = {
            fields: [
                {
                    title: 'Email',
                    key: 'email',
                    searchTextName: 'email',
                    required: true,

                },
                {
                    label: 'Name',
                    key: 'name',
                    required: true

                },
                {
                    title: 'Contact Number',
                    key: 'contactNumber',
                    searchTextName: 'contactNumber',
                    required: true
                },
                {
                    label: 'Contact Owner',
                    key: 'officer',
                    required: true,
                    showSearch: true,
                    hidden: currentUser && currentUser.userType === 'RO',
                    keyAccessor: x => x._id,
                    valueAccessor: x => `${x.name} (${x && x.id}) (${x.mobile}) `,
                    type: 'select',
                    options: userList || [],
                    onChange: officer => {
                        this.props.form.setFieldsValue({
                            officer: officer
                        })


                    }

                },

                {
                    title: 'Organization Name',
                    key: 'organizationName',
                    searchTextName: 'organizationName',
                    required: true
                },
                {
                    title: 'Designation',
                    key: 'designation',
                    searchTextName: 'designation'
                },
                {
                    title: 'Location of Milk Plants',
                    key: 'milkPlantsLocation',
                    searchTextName: 'milkPlantsLocation'
                },
                {
                    title: 'Exact Milk Plant Capacity',
                    key: 'exactMilkPlantCapacity',
                    searchTextName: 'exactMilkPlantCapacity',
                    type: 'number'
                }
            ]
        }


        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
                md: {span: 8}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
                md: {span: 12}
            }
        }
        const formItemLayout1 = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
                md: {span: 5}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
                md: {span: 12}
            }
        }


        return (
            <PageHeaderWrapper
                title={this.state.id ? 'Milk Procurement' : 'Milk Procurement'}
            >
                <Card bordered={true} loading={this.state.loader}>
                    <Form onSubmit={this.handleSubmit} hideRequiredMark={false} style={{marginTop: 8}}>

                        <Row>

                            <Col span={12}>
                                <GetAllFormFields apiurl={API_URL}
                                                  inputSchema={inputType}
                                                  formItemLayout={formItemLayout}
                                                  getFieldDecorator={getFieldDecorator}/>

                                <Form.Item label={'Number of Milk Collection Point (MCP)'}>
                                    {getFieldDecorator('mcpNumber', {
                                        rules: [{
                                            required: true,
                                        }],
                                    })(
                                        <Radio.Group options={mcpNumberOptions} className={styles.radioButton}
                                                     onChange={(checkedValues) => {

                                                         if (checkedValues.target.value === 'Other') {
                                                             this.setState({
                                                                 showMcpNumber: true,
                                                                 mcpNumber: checkedValues.target.value
                                                             })

                                                         } else if (checkedValues.target.value === 'Clear') {
                                                             this.setState({
                                                                 showMcpNumber: false,
                                                                 mcpNumber: undefined,
                                                                 mcpNumberInput: ''
                                                             })
                                                         } else {
                                                             this.setState({
                                                                 showMcpNumber: false,
                                                                 mcpNumber: checkedValues.target.value,
                                                                 mcpNumberInput: ''
                                                             })
                                                         }
                                                     }} value={this.state.mcpNumber}>


                                        </Radio.Group>
                                    )}
                                </Form.Item>

                                {showMcpNumber &&
                                <Input required={true} value={this.state.mcpNumberInput}
                                       style={{width: '50%', marginRight: '5px'}}
                                       onChange={(v) => {
                                           this.setState({mcpNumberInput: v.target.value})
                                       }}/>}


                                <Form.Item label={'Technology used for Milk procurement'}>
                                    {getFieldDecorator('milkProcurementTechnology', {
                                        rules: [{
                                            required: false,
                                        }],
                                    })(
                                        <Radio.Group className={styles.radioButton} onChange={(checkedValues) => {
                                            if (checkedValues.target.value === 'Other') {
                                                this.setState({
                                                    showMilkProcurementTechnology: true,
                                                    milkProcurementTechnology: checkedValues.target.value
                                                })

                                            } else if (checkedValues.target.value === 'Clear') {
                                                this.setState({
                                                    showMilkProcurementTechnology: false,
                                                    milkProcurementTechnology: undefined,
                                                    milkProcurementTechnologyInput: ''
                                                })
                                            } else {
                                                this.setState({
                                                    showMilkProcurementTechnology: false,
                                                    milkProcurementTechnology: checkedValues.target.value,
                                                    milkProcurementTechnologyInput: ''
                                                })
                                            }
                                        }} options={milkProcurementTechnologyOptions}
                                                     value={this.state.milkProcurementTechnology}>

                                        </Radio.Group>
                                    )}
                                </Form.Item>

                                {showMilkProcurementTechnology &&
                                <Input value={this.state.milkProcurementTechnologyInput}
                                       style={{width: '50%', marginRight: '5px'}}
                                       onChange={(v) => {
                                           this.setState({milkProcurementTechnologyInput: v.target.value})
                                       }}/>}


                                <Form.Item label={'Currently milk collection unit is procured from?'}>
                                    {getFieldDecorator('milkCollectionProcuredFrom', {
                                        rules: [{
                                            required: true,
                                        }],
                                    })(
                                        <Radio.Group className={styles.radioButton}
                                                     options={milkCollectionProcuredFromOptions}
                                                     onChange={(checkedValues) => {

                                                         if (checkedValues.target.value === 'Other') {
                                                             this.setState({
                                                                 showMilkCollectionProcuredFrom: true,
                                                                 milkCollectionProcuredFrom: checkedValues.target.value
                                                             })

                                                         } else if (checkedValues.target.value === 'Clear') {
                                                             this.setState({
                                                                 showMilkCollectionProcuredFrom: false,
                                                                 milkCollectionProcuredFrom: undefined,
                                                                 milkCollectionProcuredFromInput: ''
                                                             })
                                                         } else {
                                                             this.setState({
                                                                 showMilkCollectionProcuredFrom: false,
                                                                 milkCollectionProcuredFrom: checkedValues.target.value,
                                                                 milkCollectionProcuredFromInput: ''
                                                             })
                                                         }
                                                     }} value={this.state.practiceMilkProcurement}>

                                        </Radio.Group>
                                    )}
                                </Form.Item>

                                {showMilkCollectionProcuredFrom &&
                                <Input required={true} value={this.state.milkCollectionProcuredFromInput}
                                       style={{width: '50%', marginRight: '5px'}}
                                       onChange={(v) => {
                                           this.setState({milkCollectionProcuredFromInput: v.target.value})
                                       }}/>}

                                <Form.Item label={'Challenges you face during milk procurement'}>
                                    {getFieldDecorator('milkProcurementChallenges', {
                                        rules: [{
                                            required: false,
                                        }],
                                    })(
                                        <Checkbox.Group
                                            className={styles.checkBox}
                                            options={milkProcurementChallengesOption}
                                            onChange={(checkedValues) => {
                                                if (checkedValues.includes('Other')) {
                                                    this.setState({
                                                        showMilkProcurementChallenges: true,
                                                        milkProcurementChallenges: checkedValues
                                                    })

                                                } else {
                                                    this.setState({
                                                        showMilkProcurementChallenges: false,
                                                        milkProcurementChallenges: checkedValues,
                                                        milkProcurementChallengesInput: ''
                                                    })
                                                }
                                            }}

                                        />
                                    )}
                                </Form.Item>
                                {showMilkProcurementChallenges &&
                                <Input value={this.state.milkProcurementChallengesInput}
                                       style={{width: '50%', marginRight: '5px'}}
                                       onChange={(v) => {
                                           this.setState({milkProcurementChallengesInput: v.target.value})
                                       }}/>}


                                <Form.Item
                                    label={'Status'}>
                                    {getFieldDecorator('status', {
                                        rules: [{
                                            required: true,
                                        }],
                                    })(
                                        <Radio.Group className={styles.radioButton} onChange={(checkedValues) => {
                                            this.setState({
                                                status: checkedValues.target.value
                                            })


                                        }} value={this.state.status}>
                                            <Radio value={'Current running Plant'}>Current running Plant</Radio>
                                            <Radio value={'Future'}>Future</Radio>


                                        </Radio.Group>
                                    )}
                                </Form.Item>


                                <Form.Item
                                    label={'Send me a copy of my responses.'} {...formItemLayout1}>
                                    {getFieldDecorator('response', {
                                        rules: [{
                                            required: false,
                                        }],
                                    })(
                                        <Switch size={'small'} onChange={(val) => {
                                            this.setState({toggle: val})
                                        }} checked={this.state.toggle}/>
                                    )}
                                </Form.Item>

                            </Col>

                            <Col span={12}>
                                <Form.Item label={'The capacity of Milk Plant (Litres per day)'}>
                                    {getFieldDecorator('milkPlantCapacity', {
                                        rules: [{
                                            required: true,
                                        }],
                                    })(
                                        <Checkbox.Group
                                            className={styles.checkBox}
                                            options={milkPlantCapacity}
                                            onChange={(checkedValues) => {
                                                if (checkedValues.includes('Other')) {
                                                    this.setState({
                                                        checkedOther: true,
                                                        selectedMilkPlantCapacity: checkedValues
                                                    })

                                                } else {
                                                    this.setState({
                                                        checkedOther: false,
                                                        selectedMilkPlantCapacity: checkedValues,
                                                        otherMilkPlant: ''
                                                    })
                                                }
                                            }}

                                        />
                                    )}
                                </Form.Item>
                                {checkedOther &&
                                <Input required={true} value={this.state.otherMilkPlant}
                                       style={{width: '50%', marginRight: '5px'}}
                                       onChange={(v) => {
                                           this.setState({otherMilkPlant: v.target.value})
                                       }}/>}

                                <Form.Item label={'The current practice of milk procurement'}>
                                    {getFieldDecorator('practiceMilkProcurement', {
                                        rules: [{
                                            required: false,
                                        }],
                                    })(
                                        <Radio.Group className={styles.radioButton}
                                                     options={practiceMilkProcurementOptions}
                                                     onChange={(checkedValues) => {

                                                         if (checkedValues.target.value === 'Other') {
                                                             this.setState({
                                                                 showPracticeMilkProcurement: true,
                                                                 practiceMilkProcurement: checkedValues.target.value
                                                             })

                                                         } else if (checkedValues.target.value === 'Clear') {
                                                             this.setState({
                                                                 showPracticeMilkProcurement: false,
                                                                 practiceMilkProcurement: undefined,
                                                                 practiceMilkProcurementInput: ''
                                                             })
                                                         } else {
                                                             this.setState({
                                                                 showPracticeMilkProcurement: false,
                                                                 practiceMilkProcurement: checkedValues.target.value,
                                                                 practiceMilkProcurementInput: ''
                                                             })
                                                         }
                                                     }} value={this.state.practiceMilkProcurement}>

                                        </Radio.Group>
                                    )}
                                </Form.Item>

                                {showPracticeMilkProcurement &&
                                <Input value={this.state.practiceMilkProcurementInput}
                                       style={{width: '50%', marginRight: '5px'}}
                                       onChange={(v) => {
                                           this.setState({practiceMilkProcurementInput: v.target.value})
                                       }}/>}


                                <Form.Item label={'Average number of farmer footfalls at a particular MCP'}>
                                    {getFieldDecorator('farmerFootfallsNumber', {
                                        rules: [{
                                            required: false,
                                        }],
                                    })(
                                        <Radio.Group options={farmerFootfallsNumberOptions}
                                                     className={styles.radioButton} onChange={(checkedValues) => {

                                            if (checkedValues.target.value === 'Other') {
                                                this.setState({
                                                    showFarmerFootfallsNumber: true,
                                                    farmerFootfallsNumber: checkedValues.target.value
                                                })

                                            } else if (checkedValues.target.value === 'Clear') {
                                                this.setState({
                                                    showMcpNumber: false,
                                                    farmerFootfallsNumber: undefined,
                                                    farmerFootfallsNumberInput: ''
                                                })
                                            } else {
                                                this.setState({
                                                    showFarmerFootfallsNumber: false,
                                                    farmerFootfallsNumber: checkedValues.target.value,
                                                    farmerFootfallsNumberInput: ''
                                                })
                                            }
                                        }} value={this.state.farmerFootfallsNumber}>

                                        </Radio.Group>
                                    )}
                                </Form.Item>

                                {showFarmerFootfallsNumber &&
                                <Input value={this.state.farmerFootfallsNumberInput}
                                       style={{width: '50%', marginRight: '5px'}}
                                       onChange={(v) => {
                                           this.setState({farmerFootfallsNumberInput: v.target.value})
                                       }}/>}


                                <Form.Item label={'How acknowledgement to the farmers is given'}>
                                    {getFieldDecorator('farmerAcknowledgement', {
                                        rules: [{
                                            required: true,
                                        }],
                                    })(
                                        <Checkbox.Group
                                            className={styles.checkBox}
                                            options={farmerAcknowledgementOption}
                                            onChange={(checkedValues) => {
                                                if (checkedValues.includes('Other')) {
                                                    this.setState({
                                                        showFarmerAcknowledgement: true,
                                                        selectedFarmerAcknowledgement: checkedValues
                                                    })

                                                } else {
                                                    this.setState({
                                                        showFarmerAcknowledgement: false,
                                                        selectedFarmerAcknowledgement: checkedValues,
                                                        selectedFarmerAcknowledgementInput: ''
                                                    })
                                                }
                                            }}

                                        />
                                    )}
                                </Form.Item>
                                {showFarmerAcknowledgement &&
                                <Input required={true} value={this.state.selectedFarmerAcknowledgementInput}
                                       style={{width: '50%', marginRight: '5px'}}
                                       onChange={(v) => {
                                           this.setState({selectedFarmerAcknowledgementInput: v.target.value})
                                       }}/>}


                                <Form.Item
                                    label={'Is there any sale to farmers, veterinary facility or animal feed is given'}>
                                    {getFieldDecorator('farmerSale', {
                                        rules: [{
                                            required: true,
                                        }],
                                    })(
                                        <Radio.Group className={styles.radioButton} onChange={(checkedValues) => {
                                            this.setState({
                                                farmerSale: checkedValues.target.value
                                            })


                                        }} value={this.state.farmerSale}>
                                            <Radio value={true}>Yes</Radio>
                                            <Radio value={false}>No</Radio>


                                        </Radio.Group>
                                    )}
                                </Form.Item>


                                <Form.Item label={'If yes, how you manage sales and inventory in such scenario'}>
                                    {getFieldDecorator('manageSales', {
                                        rules: [{
                                            required: false,
                                        }],
                                    })(
                                        <Radio.Group options={manageSalesOptions} className={styles.radioButton}
                                                     onChange={(checkedValues) => {

                                                         if (checkedValues.target.value === 'Other') {
                                                             this.setState({
                                                                 showManageSales: true,
                                                                 manageSales: checkedValues.target.value
                                                             })

                                                         } else if (checkedValues.target.value === 'Clear') {
                                                             this.setState({
                                                                 showManageSales: false,
                                                                 manageSales: undefined,
                                                                 manageSalesInput: ''
                                                             })
                                                         } else {
                                                             this.setState({
                                                                 showManageSales: false,
                                                                 manageSales: checkedValues.target.value,
                                                                 manageSalesInput: ''
                                                             })
                                                         }
                                                     }} value={this.state.manageSales}>

                                        </Radio.Group>
                                    )}
                                </Form.Item>

                                {showManageSales &&
                                <Input value={this.state.manageSalesInput}
                                       style={{width: '50%', marginRight: '5px'}}
                                       onChange={(v) => {
                                           this.setState({manageSalesInput: v.target.value})
                                       }}/>}


                                <Form.Item
                                    label={'Looking for any updation in future year'}>
                                    {getFieldDecorator('futureUpdation', {
                                        rules: [{
                                            required: true,
                                        }],
                                    })(
                                        <Radio.Group className={styles.radioButton} onChange={(checkedValues) => {
                                            this.setState({
                                                futureUpdation: checkedValues.target.value
                                            })


                                        }} value={this.state.futureUpdation}>
                                            <Radio value={true}>Yes</Radio>
                                            <Radio value={false}>No</Radio>


                                        </Radio.Group>
                                    )}
                                </Form.Item>


                                <Form.Item
                                    label={'If Yes, Kindly share which technology up gradation you are looking for your system.'}>
                                    {getFieldDecorator('upGradationTechnology', {
                                        rules: [{
                                            required: false,
                                        }],
                                    })(
                                        <Input></Input>
                                    )}
                                </Form.Item>


                                <Form.Item
                                    label={'Have you ever heard about Hindustan Thermostatics'}>
                                    {getFieldDecorator('aboutHT', {
                                        rules: [{
                                            required: true,
                                        }],
                                    })(
                                        <Radio.Group className={styles.radioButton} onChange={(checkedValues) => {
                                            this.setState({
                                                aboutHT: checkedValues.target.value
                                            })


                                        }} value={this.state.aboutHT}>
                                            <Radio value={'Yes'}>Yes</Radio>
                                            <Radio value={'No'}>No</Radio>
                                            <Radio value={'Maybe'}>Maybe</Radio>


                                        </Radio.Group>
                                    )}
                                </Form.Item>


                                <Form.Item label={'If yes, kindly tell us the source.'}>
                                    {getFieldDecorator('source', {
                                        rules: [{
                                            required: true,
                                        }],
                                    })(
                                        <Checkbox.Group
                                            className={styles.checkBox}
                                            options={sourceOption}
                                            onChange={(checkedValues) => {
                                                if (checkedValues.includes('Other')) {
                                                    this.setState({
                                                        showSource: true,
                                                        source: checkedValues
                                                    })

                                                } else {
                                                    this.setState({
                                                        showSource: false,
                                                        source: checkedValues,
                                                        sourceInput: ''
                                                    })
                                                }
                                            }}

                                        />
                                    )}
                                </Form.Item>
                                {showSource &&
                                <Input required={true} value={this.state.sourceInput}
                                       style={{width: '50%', marginRight: '5px'}}
                                       onChange={(v) => {
                                           this.setState({sourceInput: v.target.value})
                                       }}/>}


                            </Col>


                        </Row>


                        <Form.Item style={{marginTop: 32, textAlign: 'center'}}>
                            <Button size="large" type="primary" htmlType="submit" loading={this.props.loading}
                                    style={{width: '15%'}}>
                                {this.state.id ? `UPDATE` : `SAVE`}

                            </Button>
                        </Form.Item>

                    </Form>
                </Card>
            </PageHeaderWrapper>
        )
    }
}

const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search,
    currentUser: global.currentUser,

})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddDairyContact)
