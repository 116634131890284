import React, {PureComponent} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
    Form,
    Button,
    Card,
    Radio, Checkbox, Icon,
    DatePicker, Input,
    InputNumber,
    Popover, Tooltip,
    Select, Table
} from 'antd'
import _ from 'lodash'
import {FormUtils as GetAllFormFields} from 'sz-react-utils'
import {API_URL} from '../../../request'
import styles from './styles.less'
import 'react-quill/dist/quill.snow.css'
import {notification} from 'antd/lib/index'
import {hideLoader, showLoader} from '../../../modules/actions'
import Request from '../../../request'
import {connect} from 'react-redux'
import {createMatchSelector, goBack} from 'connected-react-router'
import moment from "moment/moment";
import {addLeadActivity} from "../../lead/all/helpers";
import FormatNumber from "indian-number-format";

const {Option} = Select

@Form.create()
class InvoiceForm extends PureComponent {


    handleSubmit = e => {
        const {dispatch, form, handleInvoiceLead, quoteId, quoteData} = this.props
        let {notifications} = this.state
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let values = _.clone(valData)

                let foundFlag = false

                let tempArr = []
                let errorIds = []
                _.each(this.state.totalData, (item) => {

                    if (item.productIds && item.productIds.length != item.quantity) {
                        foundFlag = true
                    }
                    _.each(item.productIds, (val) => {
                        if (!tempArr.includes(val)) {
                            tempArr.push(val)

                        } else {
                            errorIds.push(val)

                        }
                    })


                })
                if (foundFlag) {
                    return notification.error({message: 'Product Ids must be equivalent to quantity.'})
                }

                if (errorIds.length) {
                    return notification.error({message: `Duplicate Product Ids ${errorIds}.`})

                }

                dispatch(showLoader())
                if (parseInt(quoteData.grandTotal) < parseInt(values.paidAmount)) {
                    dispatch(hideLoader())
                    return notification.error({
                        message: 'Paid Amount should be less than Total Amount'
                    })
                }

                console.log(values, "values")
                quoteData.invoiceNo = values.invoiceNo
                quoteData.invoiceDate = values.invoiceDate
                quoteData.paidAmount = values.paidAmount
                quoteData.quoteId = quoteId
                delete quoteData._id
                quoteData.paymentMode = values.paymentMode
                quoteData.addedManually = true
                quoteData.notifications = {...notifications}
                quoteData.poNumber = values.poNumber
                if (values.paymentMode == 'Partial') {
                    quoteData.remainingAmount = parseInt(quoteData.grandTotal) - parseInt(values.paidAmount);
                }
                console.log(quoteData, "quoteDatatatat")
                let x = await  Request.addManualInvoice(quoteData)

                dispatch(hideLoader())


                if (!x.error) {

                    notification.success({
                        message: this.state.id
                            ? x.message
                            : x.message
                    })
                    if (handleInvoiceLead) {
                        handleInvoiceLead()
                    }
                    let msg = `Please Find Attached your invoice from Hindustan Thermostatics. ${API_URL}${x.pdfName} `

                    if (this.state.notifications.whatsapp) {
                        setTimeout(() => {
                            addLeadActivity({
                                values: {
                                    message: `Whatsapp sent to 91${quoteData && quoteData.contactName && quoteData.contactName.mobile}`,
                                    whatsAppMessage: msg,
                                    leadId: quoteData && quoteData.leadId,
                                    user: this.props.currentUser.name,
                                    type: 'whatsApp'
                                }
                            });
                            window.open(`http://api.whatsapp.com/send?phone=91${quoteData && quoteData.contactName && quoteData.contactName.mobile}&text=${msg}`)

                        }, 1000);
                    }

                    this.props.form.resetFields()


                } else {
                    notification.error({
                        message: 'Error Saving',
                        description: x.message
                    })
                }

            }
        })
    }


    constructor(props) {
        super(props)
        this.state = {
            notifications: {'mail': true, 'text': false, 'whatsapp': false}, paymentMode: 'Full', totalData: []
        }

    }

    async componentDidMount() {
        let searchParams = new URLSearchParams(this.props.search);
        let {quoteData} = this.props
        this.props.form.setFieldsValue({
            invoiceDate: moment(), paymentMode: this.state.paymentMode,
            quoteAmount: ((quoteData && quoteData.grandTotal) - (quoteData && quoteData.roundOff))

        });

        _.each(quoteData && quoteData.productList, (item) => {
            item.productIds = []
        })

        this.setState({totalData: quoteData && quoteData.productList})
        Request.getNextInvoiceNo().then((data) => {
            if (!data.error) {
                this.setState({invoiceNo: data.data});
                this.props.form.setFieldsValue({invoiceNo: data.data})
            }
        })

        Request.getAllProducts({results: 1000}).then(({data: productList}) => {
            this.setState({productList: productList || []}, () => {

            })
        })

    }

    setSocial = (field, val) => {
        let newData = _.clone(this.state.notifications)
        newData[field] = val
        this.setState({notifications: newData})
    }


    render() {
        let {productList} = this.state

        const onModeChange = (e) => {
            console.log("val", e.target.value);
            if (e.target.value == 'Partial') {
                this.setState({paymentMode: 'Partial'})
            }
            if (e.target.value == 'Full') {
                this.setState({paymentMode: 'Full'})
            }
        }
        const {
            form: {getFieldDecorator, getFieldValue}
        } = this.props;


        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
                md: {span: 8}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
                md: {span: 12}
            }
        }

        const submitFormLayout = {
            wrapperCol: {
                xs: {span: 24, offset: 0},
                sm: {span: 10, offset: 7},
                md: {span: 12, offset: 8}
            }
        }

        const refColumns = [


            {
                title: 'Product',
                dataIndex: 'productName',
                key: 'productName',
                width: 150,
                render: (x, item, index) => {

                    let found = _.find(productList, (ii) => {
                        return ii._id === x
                    })
                    if (found) {
                        return <div>
                            <div style={{marginBottom: '5px'}}>{found.quoteDisplayedName || ''}</div>
                        </div>
                    }


                }


            },
            {
                title: 'ProductIds',
                key: 'productIds',
                dataIndex: 'productIds',
                width: 150,
                render: (x, item, key) => {
                    return <div>
                        <Select
                            placeholder={'Enter Product Id'}
                            mode={'tags'}
                            onChange={(e) => {
                                let array = []
                                _.each(e, (i) => {
                                    array.push(i.toLowerCase())
                                })
                                item.productIds = array
                            }}
                        >
                        </Select>
                    </div>

                }


            },
            {
                title: 'Quantity',
                key: 'quantity',
                dataIndex: 'quantity',
                width: 80
            },


        ]

        return (
            <Card bordered={true}>


                <Form onSubmit={this.handleSubmit} hideRequiredMark={false} style={{marginTop: 8}}>
                    <Form.Item name="quoteAmount" label="Total Amount" {...formItemLayout}>
                        {getFieldDecorator('quoteAmount')(<Input type='number' disabled/>)}
                    </Form.Item>
                    <Table
                        style={{marginBottom: '10px'}}
                        bordered={true}
                        rowKey={x => x.productName}
                        columns={refColumns}
                        dataSource={this.state.totalData || []}
                        pagination={false}
                    />

                    <Form.Item name="invoiceNo" label="Invoice Number" {...formItemLayout}>
                        {getFieldDecorator('invoiceNo', {
                            rules: [{required: true}]

                        })(<Input type='number' disabled/>)}
                    </Form.Item>

                    <Form.Item name="poNumber" label="P.O Number" {...formItemLayout}>
                        {getFieldDecorator('poNumber', {
                            rules: [{required: true}]

                        })(<Input/>)}
                    </Form.Item>
                    <Form.Item name="invoiceDate" label="Invoice Date" {...formItemLayout}>
                        {getFieldDecorator('invoiceDate', {
                            rules: [{required: true}]
                        })(<DatePicker/>)}
                    </Form.Item>
                    <Form.Item label={'Mode'} style={{flex: 1}}  {...formItemLayout}>
                        {getFieldDecorator('paymentMode', {rules: [{required: true}]})(
                            <Radio.Group onChange={onModeChange} value={this.state.paymentMode}>
                                <Radio value={'Full'} defaultChecked>Full Payment</Radio>
                                <Radio value={'Partial'}>Partial Payment</Radio>
                            </Radio.Group>)}
                    </Form.Item>
                    {(this.state.paymentMode == "Partial") &&
                    <Form.Item label={'Amount Paid'} style={{flex: 1}}  {...formItemLayout}>
                        {getFieldDecorator('paidAmount', {rules: [{required: true}]})(<InputNumber
                            onChange={ei => {
                            }}/>)}
                    </Form.Item>}
                    <div className={styles.notification}>
                        <Checkbox
                            defaultChecked={true} onChange={(e) => {
                            this.setSocial('mail', e.target.checked)
                        }}><Icon style={{color: "#E8290B"}} type='mail'/> Mail</Checkbox>
                        <Checkbox
                            defaultChecked={false} onChange={(e) => {
                            this.setSocial('whatsapp', e.target.checked)
                        }}><Icon style={{color: "#45CE30"}} type='message'/> Whatsapp</Checkbox>
                        <Checkbox
                            defaultChecked={false} onChange={(e) => {
                            this.setSocial('message', e.target.checked)
                        }}><Icon style={{color: "#0A79DF"}} type='message'/> Text</Checkbox>
                    </div>

                    <Form.Item {...submitFormLayout} style={{marginTop: 32}}>
                        <Button type="primary" htmlType="submit" loading={this.props.loading}>
                            Generate Invoice
                        </Button>
                    </Form.Item>

                </Form>
            </Card>
        )
    }
}

const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search,
    currentUser: global.currentUser

})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InvoiceForm)
