import React, {Component} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {TableComp, FormUtils as GetAllFormFields} from 'sz-react-utils'
import moment from 'moment'
import {
    Tag,
    Badge,
    Card,
    Tooltip,
    Popconfirm,
    notification,
    Switch,
    Input, Button, Icon,
    Form,
    Drawer, Row, Col
} from 'antd'
import Request from '../../../request'
import Color from 'color'
import _ from 'lodash'
import Highlighter from 'react-highlight-words'
import styles from './styles.less'
import {connect} from 'react-redux'
import memoizeOne from 'memoize-one'
import update from 'immutability-helper'
import {getUrlPushWrapper} from '../../../routes'
import {hideLoader, showLoader} from '../../../modules/actions'

@Form.create()
class ChangePassword extends Component {
    handleSubmit = e => {
        const {dispatch, form} = this.props

        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                valData.form = form
                this.props.handleSubmit(valData)
                // form.resetFields()

            }
        })

    }

    componentDidMount() {
        let {hideField} = this.props

        if (hideField) {
            this.props.form.resetFields()
        }
    }

    render() {
        const {submitting, dispatch} = this.props

        const {
            form: {getFieldDecorator, getFieldValue, setFieldsValue}
        } = this.props

        let inputTypes = {
            fields: [
                {
                    label: 'New Password',
                    key: 'password',
                    type: 'password',
                    required: true
                },
                {
                    label: 'Confirm Password',
                    key: 'confirmPassword',
                    type: 'password',
                    required: true
                }
            ]
        }

        return (
            <div>
                <Form onSubmit={this.handleSubmit} style={{marginTop: 8}}>
                    <GetAllFormFields
                        inputSchema={inputTypes}
                        getFieldDecorator={getFieldDecorator}

                    />
                    <div style={{textAlign: 'center'}}>
                        <Button
                            type="primary"
                            size="medium"
                            htmlType="submit"
                            style={{marginBottom: 10}}
                            loading={this.props.loading}>
                            SAVE
                        </Button>
                    </div>
                </Form>
            </div>
        )
    }
}

@Form.create()
class AllUsers extends Component {

    deleteRO = async data => {
        this.setState({loading: true})
        let x = await Request.deleteUser(data)
        this.setState({loading: false})

        this.table.current.reload()
        if (!x.error) {
            notification.success({
                message: x.message,
                duration: 20,
                key: `${data._id}-close`
            })

        } else {
            notification.error({
                message: x.message,
                duration: 20,
                key: `${data._id}-close`
            })
        }


    }

    inActiveUser = async data => {
        this.setState({loading: true})
        let x = await Request.updateUserDetails(data)
        this.setState({loading: false})

        this.table.current.reload()
        if (!x.error) {
            notification.success({
                message: x.message,
                duration: 20,
                key: `${data._id}-close`
            })

        } else {
            notification.error({
                message: x.message,
                duration: 20,
                key: `${data._id}-close`
            })
        }


    }

    passwordDrawer = () => {
        this.setState({
            visible: true,
            key: Date.now(),
            hideField: false
        })
    }
    apiRequest = (params) => {
        if (params.page) {
            this.state.currentPage = params.page
        }

        return new Promise(async (resolve) => {
            let data = await Request.loadUser({
                ...params, regExFilters: ['mobile', 'name', 'email']
            })
            resolve(data)
        })
    }

    constructor(props) {
        super(props)
        this.table = React.createRef()
        this.state = {
            currentPage: 1,
            visible: false, userType: ['Admin', 'RO', 'Dispatch', 'Technician', 'Lead Executive', 'Caller', 'Manager'],
            lockUnlockLead: []
        }

    }

    componentDidMount() {
    }

    render() {
        const {dispatch} = this.props

        let {changePasswordRecord, userType, currentPage} = this.state
        const columns = [
            {
                title: 'S.No',
                dataIndex: 'sno',
                width: 50,
                key: 'sno',
                fixed: 'left',
                render: (value, item, index) => (currentPage - 1) * 10 + index + 1
            },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                searchTextName: 'name'
            },

            {
                title: 'Mobile',
                dataIndex: 'mobile',
                key: 'mobile',
                searchTextName: 'mobile'
            },
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
                searchTextName: 'email'
            },
            {
                title: 'User Type',
                dataIndex: 'userType',
                key: 'userType',
                filters: userType && userType.map(x => ({value: x, text: x}))

            },
            {
                title: 'Inactive Alert Time',
                dataIndex: 'leadAlertTime',
                key: 'leadAlertTime',
                // filters: userType && userType.map(x => ({value: x, text: x}))

            },
            {
                title: 'Check Lead Call',
                dataIndex: 'checkLeadCall',
                key: 'checkLeadCall',
                filters: [{value: true, text: 'Yes'}, {value: false, text: 'No'}],
                render: (val) => {

                    return  val ? <Tag color={'green'}>Yes</Tag> : <Tag color={'red'}>No</Tag>

                }

            },
            {
                title: 'Disable User',
                dataIndex: 'disableLogin',
                key: 'disableLogin',
                filters: [
                    {text: 'Yes', value: true},
                    {text: 'No', value: false}],

                render: (val, record, key) => {
                    return <Switch checked={val ? val : false} onChange={(vall) => {
                        this.inActiveUser({_id: record._id, disableLogin: vall})

                    }}/>
                },
                width: 150

            },

            {
                key: 'actions',
                title: 'Actions', fixed: 'right',
                width: 150,
                render: (text, record) => {


                    return <React.Fragment>
                        <Tooltip title="Edit user">
                            <Button
                                shape="circle"
                                style={{marginRight: 6}}
                                size="small"
                                onClick={() => {
                                    dispatch(
                                        getUrlPushWrapper('salesStaff.editSalesStaff', {
                                            id: record._id
                                        })
                                    )
                                }}
                                icon="edit"
                            />
                        </Tooltip>

                        <Tooltip title="Change Password">
                            <Button
                                style={{marginRight: '6px'}}
                                size="small"
                                shape="circle"
                                onClick={() => {
                                    this.setState({
                                        changePasswordRecord: record
                                    }, () => {
                                        this.passwordDrawer()

                                    })

                                }}
                                icon="unlock"
                            />
                        </Tooltip>

                        <Tooltip title="Delete user">
                            <Popconfirm
                                title="Are you sure to delete this user?"
                                onConfirm={() => {
                                    this.deleteRO({_id: record._id})
                                }}
                                onCancel={() => {
                                    console.log()
                                }}
                                okText="Yes"
                                cancelText="No">
                                <Button
                                    style={{marginRight: '6px'}}

                                    shape="circle"
                                    size="small"
                                    icon="delete"
                                />
                            </Popconfirm>

                        </Tooltip>

                        {record.userType === 'RO' && <Tooltip title="Call Logs">
                            <Button
                                shape="circle"
                                style={{marginRight: '6px'}}
                                size="small"
                                onClick={() => {
                                    dispatch(
                                        getUrlPushWrapper('communication.callLogs', {
                                            RONumber: btoa(record.mobile)
                                        })
                                    )
                                }}
                                icon="phone"
                            />
                        </Tooltip>}


                        {record.userType === 'RO' && <Tooltip title="View RO Leads">

                            <a href={`/roleads/allList?ROIdAsAdmin=${record && record._id}`}
                               target={'_blank'}>
                                <Button
                                    shape="circle"
                                    style={{marginRight: '6px', marginTop: '6px'}}
                                    size="small"
                                    icon="eye"
                                /></a>


                        </Tooltip>}


                    </React.Fragment>

                }
            }

        ]
        return (
            <PageHeaderWrapper
                title={'All Users '}>

                <Card bordered={true}>
                    <TableComp ref={this.table}
                               columns={columns}
                               apiRequest={this.apiRequest}
                               pagination={{
                                   ...this.state.pagination,
                                   defaultPageSize: 10,
                                   pageSizeOptions: ['10', '25', '50', '100'],
                                   showSizeChanger: true,
                                   ...this.props.pagination
                               }}/>
                </Card>
                <Drawer
                    title=""
                    placement="right"
                    closable={true}
                    onClose={() => {

                        this.setState({
                            visible: false,
                            hideField: true
                        })
                    }}
                    visible={this.state.visible}
                    width={600}>
                    <div className={styles.drawerDiv}>
                        <Card bordered={true} className={styles.drawerCard}>
                            <Row>
                                <Col span={12}>
                                    <b>Name : </b>{changePasswordRecord ? changePasswordRecord.name : null}
                                </Col>
                                <Col span={12}>
                                    <b>Mobile No : </b>{changePasswordRecord ? changePasswordRecord.mobile : null}
                                </Col>
                            </Row>
                        </Card>
                        <Card className={styles.drawerCard} bordered={true} style={{marginTop: 10}}>
                            <div style={{textAlign: 'center', padding: 20, fontSize: 18}}>
                                Change Password for
                                {changePasswordRecord && <b> {changePasswordRecord.name}</b>}
                            </div>
                            <ChangePassword
                                hideField={this.state.hideField ? true : false}
                                loading={this.props.loading}
                                key={this.state.key}
                                handleSubmit={async values => {
                                    let {form} = values
                                    dispatch(showLoader())
                                    if (values.password !== values.confirmPassword) {
                                        notification.error({
                                            message: 'Password does not match.'
                                        })
                                        dispatch(hideLoader())
                                    } else {
                                        let x = await Request.changePassword({
                                            _id: changePasswordRecord._id,
                                            password: values.password
                                        })

                                        if (x.error) {
                                            notification.error({
                                                message: x.message
                                            })
                                        } else {
                                            notification.success({
                                                message: x.message
                                            })

                                            form.resetFields()
                                            this.setState({visible: false})

                                        }
                                    }


                                    dispatch(hideLoader())
                                }}
                            />
                        </Card>

                    </div>


                </Drawer>

            </PageHeaderWrapper>)

    }
}


const mapStateToProps = ({global}) => ({
    categories: global.categories,
    loading: global.buttonLoading

})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllUsers)
