import React, {Component} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {TableComp} from 'sz-react-utils'
import moment from 'moment'
import {
    Table,
    Badge,
    Card,
    Tooltip,
    Popconfirm,
    Row,
    Col,
    Select,
    notification,
    Drawer,
    Switch, Upload,
    Input, Button, Icon,
    Timeline
} from 'antd'
import Request from '../../../request'
import _ from 'lodash'
//import styles from './styles.less'
import {connect} from 'react-redux'
import {getUrlPushWrapper} from '../../../routes'
import {apiUrl} from "../../../settings";
import {API_URL} from '../../../request'


const {Option} = Select

class AllContacts extends Component {

    constructor(props) {
        super(props)
        this.table = React.createRef()
        this.state = {visible: false, key: Date.now(), leadsTable: [], currentPage: 1}

    }

    deleteContact = async data => {
        this.setState({loading: true})
        let x = await Request.deleteContact(data)
        this.setState({loading: false})

        this.table.current.reload()
        if (!x.error) {
            notification.success({
                message: x.message,
                duration: 20,
                key: `${data._id}-close`
            })

        } else {
            notification.error({
                message: x.message,
                duration: 20,
                key: `${data._id}-close`
            })
        }
    }


    apiRequest = (params) => {
        if (params.page) {
            this.state.currentPage = params.page
        }
        let user = {...JSON.parse(localStorage.getItem('user'))}
        if (user.userType == 'RO') {
            this.state.ownerId = user._id
        }
        if (user && user.userType == 'Manager') {
            params.contactOwner = user && user.roList
        }
        return new Promise(async (resolve) => {
            if (this.state.ownerId) {
                params.contactOwner = this.state.ownerId
            }

            let data = await Request.getAllContacts({
                ...params, regExFilters: ['mobile', 'remarks', 'email']
            })
            this.setState({count: data.total})
            resolve(data);
        })
    }


    async componentDidMount() {

        let user = {...JSON.parse(localStorage.getItem('user'))}

        if (user && user.userType == 'Manager') {
            Request.loadUser({_id: {$in: user.roList}}, {results: 1000000000000}).then(({data: userList}) => {
                this.setState({userList: userList})
            })

        } else {
            let {data: userList} = await Request.getAllROList({customQuery: {userType: {$eq: "RO"}}});
            this.setState({userList: userList})
        }

    }

    render() {
        const {submitting, dispatch} = this.props
        const {userList, singleContact, totalActivity, leadsTable, currentPage} = this.state;
        let user = {...JSON.parse(localStorage.getItem('user'))}
        const handleFileChange = async (v) => {
            checkStatus(v.file);
        }

        const checkStatus = (file) => {
            if (!file) return;
            if (file.status == 'done' || file.status == 'success') {
                notification.success({message: "Contacts uploaded"});
                this.setState({files: []})
            }
            if (file.status == 'error') {
                notification.danger({message: "Something went wrong"});
                this.setState({files: []})
            }
        }

        const checkMimeType = (file) => {
            if (!file) return;
            if (file.name.toLowerCase().includes('.csv')) {
                return true
            } else {
                notification.error({message: "Please upload .csv file."})
            }
            return false
        }


        const columns = [
            {
                title: 'S.No',
                dataIndex: 'sno',
                width: 50,
                key: 'sno',
                render: (value, item, index) => (currentPage - 1) * 10 + index + 1
            },
            {
                title: 'Id',
                dataIndex: 'id',
                width: 100,
                key: 'id',
                searchTextName: 'id',

            },

            {
                title: 'Contact Owner',
                key: 'contactOwner',
                width: 150,
                render: (val, record) => {
                    return (<div>
                        <div>{record.contactOwner ? record.contactOwner.name : ''}</div>
                        <div>{record.contactOwner ? record.contactOwner.email : ''}</div>
                        <div>{record.contactOwner ? record.contactOwner.mobile : ''}</div>
                    </div>)
                }

            },
            {
                title: 'Name',
                dataIndex: 'firstName',
                width: 180,
                searchTextName: 'firstName',
                render: (val, record) => {
                    return (<div>
                        <b>{record.firstName} {record.lastName}</b>
                    </div>)
                }
            },
            {
                title: 'Mobile',
                dataIndex: 'mobile',
                width: 180,
                key: 'mobile',
                searchTextName: 'mobile',

            },
            {
                title: 'Email',
                dataIndex: 'email',
                width: 180,
                key: 'email',
                searchTextName: 'email'
            },

            {
                title: 'Date of Birth',
                dataIndex: 'dob',
                width: 120,
                key: 'dob',
                render: (val, record) => {
                    return (
                        <div>{record && record.dob ? moment(record.dob).format('DD-MM-YYYY') : ''}</div>)
                }
            },
            {
                title: 'Remarks',
                dataIndex: 'remarks',
                key: 'remarks',
                searchTextName: 'remarks',
                width: 150,
            },

            {
                title: 'Billing Address',
                dataIndex: 'billingAddress',
                searchTextName: 'state/city/pincode/address',
                key: 'billingAddress',
                render: (val, record) => {
                    let arr = []
                    if (record) {
                        if (record.billingAddress && record.billingAddress.addressLine1) {
                            arr.push(record.billingAddress.addressLine1)
                        }
                        if (record.billingAddress && record.billingAddress.addressLine2) {
                            arr.push(record.billingAddress.addressLine2)
                        }
                        if (record.billingAddress && record.billingAddress.state) {
                            arr.push(record.billingAddress.state)
                        }
                        if (record.billingAddress && record.billingAddress.city) {
                            arr.push(record.billingAddress.city)
                        }
                        if (record.billingAddress && record.billingAddress.zipcode) {
                            arr.push(record.billingAddress.zipcode)
                        }
                        if (record.billingAddress && record.billingAddress.country) {
                            arr.push(record.billingAddress.country)
                        }
                    }

                    return (<div>
                        {arr.join(', ')}
                    </div>)
                }
            },
            {
                title: 'Shipping Address',
                dataIndex: 'shippingAddress',
                searchTextName: 'state/city/pincode/address',
                key: 'shippingAddress',
                render: (val, record) => {
                    let arr = []
                    if (record && record.shippingAddress) {
                        if (record.shippingAddress && record.shippingAddress.addressLine1) {
                            arr.push(record.shippingAddress.addressLine1)
                        }
                        if (record.shippingAddress && record.shippingAddress.addressLine2) {
                            arr.push(record.shippingAddress.addressLine2)
                        }
                        if (record.shippingAddress && record.shippingAddress.state) {
                            arr.push(record.shippingAddress.state)
                        }
                        if (record.shippingAddress && record.shippingAddress.city) {
                            arr.push(record.shippingAddress.city)
                        }
                        if (record.shippingAddress && record.shippingAddress.zipcode) {
                            arr.push(record.shippingAddress.zipcode)
                        }
                        if (record.shippingAddress && record.shippingAddress.country) {
                            arr.push(record.shippingAddress.country)
                        }
                    }

                    return (<div>
                        {arr.join(', ')}
                    </div>)

                }
            },
            {
                title: 'Created At',
                width: 120,
                key: 'createdAt',
                dataIndex: 'createdAt',
                sorter: true,
                render: (text, record) => {
                    return (
                        <div>
                            {text ? moment(text).format('lll') : ''}
                        </div>
                    )
                }
            },

            {
                title: 'Updated At',
                width: 120,
                key: 'updatedAt',
                dataIndex: 'updatedAt',
                sorter: true,
                render: (text, record) => {
                    return (
                        <div>
                            {text ? moment(text).format('lll') : ''}
                        </div>
                    )
                }
            },

            {
                key: 'actions',
                title: 'Actions',
                fixed: 'right',
                width: 150,
                render: (text, record) => {

                    return <React.Fragment>
                        <Tooltip title="Edit Contact">
                            <Button
                                shape="circle"
                                style={{marginRight: 6}}
                                size="small"
                                onClick={() => {
                                    dispatch(
                                        getUrlPushWrapper('contact.editContact', {
                                            id: record._id
                                        })
                                    )
                                }}
                                icon="edit"
                            />
                        </Tooltip>

                        {(user.userType != "RO") && <Tooltip title="Delete Contact">
                            <Popconfirm
                                title="Are you sure delete this Contact?"
                                onConfirm={() => {
                                    this.deleteContact({_id: record._id})
                                }}
                                onCancel={() => {
                                    console.log()
                                }}
                                okText="Yes"
                                cancelText="No">
                                <Button
                                    shape="circle"
                                    size="small"
                                    icon="delete"
                                />
                            </Popconfirm>

                        </Tooltip>
                        }

                        <Tooltip title="View History">
                            <a href={`/contact/history?id=${record._id}`} target={'_blank'}>
                                <Button style={{marginLeft: '5px'}} size="small" icon={'eye'} shape={'circle'}/>
                            </a>
                        </Tooltip>

                    </React.Fragment>

                }
            }


        ]

        if (user.userType == "RO") {
            columns.splice(1, 1);
        }


        return (
            <PageHeaderWrapper
                title={this.state.count ? `All Contacts: ${this.state.count}` : `All Contacts: 0`}>

                <Card bordered={true}>

                    <Row>
                        <Col span={15}>
                            {(user.userType != 'RO') && <> <h4
                                style={{display: 'inline-block', marginRight: '10px'}}>Select Contact Owner</h4>

                                <Select
                                    showSearch
                                    allowClear
                                    optionFilterProp="children"
                                    style={{
                                        width: 225

                                    }}
                                    placeholder="Select Contact Owner"
                                    onChange={id => {
                                        this.setState({ownerId: id}, () => {
                                            this.table.current.reload()
                                        })
                                    }}
                                >
                                    {userList &&
                                    userList.map((val, idn) => {
                                        return (
                                            <Option key={idn} value={val._id}>
                                                {val.name} ({val.mobile})
                                            </Option>
                                        )
                                    })}
                                </Select></>}
                        </Col>
                        <Col span={4}>
                            <Upload
                                name={'file'}
                                action={`${API_URL}/uploadContactCsv`}
                                multiple={false}
                                onChange={handleFileChange}
                                beforeUpload={checkMimeType}
                                showUploadList={false}
                                maxCount={1}
                            >
                                <Button icon="upload">
                                    Upload Contact
                                </Button>
                            </Upload>
                        </Col>
                        <Col span={5}>
                            <Button type="primary" onClick={() => window.open(`${apiUrl}/auth/google`, '_blank')}>Import
                                Google Contacts</Button>
                        </Col>
                    </Row>
                    <TableComp ref={this.table}
                               columns={columns}
                               apiRequest={this.apiRequest}
                               extraProps={{scroll: {x: 1800}}}
                               pagination={{
                                   ...this.state.pagination,
                                   defaultPageSize: 10,
                                   pageSizeOptions: ['10', '25', '50', '100'],
                                   showSizeChanger: true,
                                   ...this.props.pagination
                               }}/>
                </Card>

            </PageHeaderWrapper>)

    }
}


const mapStateToProps = ({global}) => ({
    categories: global.categories,
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllContacts)
