import React, { PureComponent } from 'react'
import PageHeaderWrapper from '../../components/PageHeaderWrapper'
import {
  Form,
  Input,
  DatePicker, List,
  Select, Upload, Divider,
  Button, Table,
  Card,
  Icon
} from 'antd'
import { ChromePicker } from 'react-color'
import _ from 'lodash'
import moment from 'moment'
import { FormUtils as GetAllFormFields } from 'sz-react-utils'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { notification } from 'antd/lib/index'
import { hideLoader, showLoader } from '../../modules/actions'
import Request from '../../request'
import { API_URL } from '../../request'

import { connect } from 'react-redux'
import { createMatchSelector } from 'connected-react-router'
import { getUrlParams } from '../../routes'

const FormItem = Form.Item
const { Option } = Select

@Form.create()
class AttachFiles extends PureComponent {

  constructor (props) {
    super(props)
    this.state = { filesArr: [] }

  }

  handleFileChange = v => {
    if (v.fileList) {
      this.setState({ files: v.fileList })
    }
  }

  saveMediaFiles = async () => {
    let { files } = this.state
    let { handleAttachFile, leadId } = this.props

    this.setState({ loading: true })
    console.log(files, leadId, '-------------')
    let x = await Request.saveMediaFile({ files, leadId })
    this.setState({ loading: false })
    if (!x.error) {
      notification.success({
        message: x.message
      })
      console.log('files inside save b444444', files)

      if (handleAttachFile) {
        handleAttachFile()
      }
      this.setState({ files: files.splice(0, files.length) }, () => {
        console.log('files inside save', files)
      })
      this.setLeadData()
    } else {
      notification.error({
        message: 'Error Saving',
        description: x.message
      })
    }

  }

  onRemove = v => {
    console.log(v)
  }

  deleteFile = async (fileId) => {
    console.log('inside deleete file fileIf is', fileId)
    let { leadId } = this.props
    let x = await Request.deleteMediaFile({ leadId, fileId })
    if (!x.error) {
      notification.success({
        message: x.message
      })
      this.setLeadData()
    } else {
      notification.error({
        message: 'Error deleting',
        description: x.message
      })
    }
  }

  async componentDidMount () {
    let { filesData } = this.props
    this.setFilesArr(filesData)
  }

  setFilesArr = (filesData) => {
    console.log('files data is', filesData)
    if (filesData) {
      _.each(filesData, (item) => {
        item.link = API_URL + '/' + item.url
      })
      this.setState({ filesArr: filesData }, () => {
      })
    }
  }

  setLeadData = async () => {
    await Request.getLeadById({ _id: this.props.leadId }).then(({ data, error, message }) => {
      if (!error) {
        this.setState({
          leadData: data
        })
        let filesData = data && data.files ? data.files : []
        this.setFilesArr(filesData)

      } else {
        notification.error({
          message: 'Error Getting Data'
        })
      }
    })
  }

  render () {

    const { hideTitle } = this.props
    const { filesArr } = this.state

    let form = <div>
      {hideTitle && <React.Fragment>
        {/* <Card bordered={true}> */}
        <Upload
          name={'file'}
          // fileList={this.state.files}
          loading={this.state.uploading}
          action={`${API_URL}/filesUploader`}
          onChange={this.handleFileChange}
          onRemove={this.onRemove}
        >
          <Button>
            <Icon type="upload"/> Select File
          </Button>
        </Upload>
        <Divider/>
        <Button type="primary" onClick={async () => {
          if (this.state.files && this.state.files.length > 0) {
            await this.saveMediaFiles()
            // this.onRemove();
          } else {
            return notification.error({ message: 'Select 1 file.' })
          }
        }}>Save</Button>
        {/* </Card> */}

        {filesArr && filesArr.length > 0 && <Card>
          <List
            bordered
            itemLayout="horizontal"
            dataSource={this.state.filesArr || []}
            size="small"
            header={<div>Total : {filesArr.length}</div>}
            renderItem={item => (
              <List.Item>
                <List.Item.Meta
                  title={<a
                    href={item.link}
                    style={{
                      color: 'orange',
                      textDecorationLine: 'underline'
                    }}
                    target={'_blank'}>
                    {item.name}
                  </a>}
                />
                <Icon onClick={() => this.deleteFile(item._id)} type="delete"/>
              </List.Item>
            )}
          />
        </Card>}
      </React.Fragment>}
    </div>

    return (
      <React.Fragment>{!hideTitle ? <PageHeaderWrapper>{form}</PageHeaderWrapper> : form}</React.Fragment>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  categories: global.categories,
  pathname: router.location.pathname

})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AttachFiles)
