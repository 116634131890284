import React, { PureComponent } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
  Form,
  TimePicker, Table,
  Button,
  Card
} from 'antd'
import _ from 'lodash'
import moment from 'moment'
import { FormUtils as GetAllFormFields } from 'sz-react-utils'

import 'react-quill/dist/quill.snow.css'
import { notification } from 'antd/lib/index'
import { hideLoader, showLoader } from '../../../modules/actions'
import Request from '../../../request'
import { API_URL } from '../../../request'
import { connect } from 'react-redux'
import { createMatchSelector } from 'connected-react-router'


@Form.create()
class AddActivity extends PureComponent {

  handleSubmit = e => {
    const { dispatch, form, handleAddActivity, leadId } = this.props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        let values = _.clone(valData)
        dispatch(showLoader())
        values.leadId = leadId
        values.time = this.state.time
        console.log("activity values are", values)

        let x = null
        x = await Request.addActivity(values);
        console.log("x is", x)
        

        dispatch(hideLoader())

        if (!x.error) {
          notification.success({
            message: x.message
          })
          this.loadActivities();
          if (handleAddActivity) {
            handleAddActivity()
          }

          this.props.form.resetFields()

        } else {
          notification.error({
            message: 'Error Saving',
            description: x.message
          })
        }

      }
    })
  }
  onTimeChange = (time) => {
    this.setState({ time: moment(time).format('HH:mm:ss') }, () => {
      console.log(this.state.time)
    })

  }
  loadActivities = async () => {
    let { leadId } = this.props
    let params = { results: 20, count: 20 }
    if (leadId) {
      params.leadId = leadId
    }
    let data = await Request.getAllActivitiesByLeadId({
      ...params
    })
    this.setState({ count: data.total, activitiesData: data.data })
  }

  constructor(props) {
    super(props)
    this.state = { activitiesData: [] }

  }

  async componentDidMount() {
    this.loadActivities()
  }

  render() {
    let itemNo = 1;


    const { hideTitle } = this.props
    const {
      form: { getFieldDecorator, getFieldValue }
    } = this.props

    const { activitiesData } = this.state

    const activityColumn = [
      {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
        render: (value, item, index) => (itemNo - 1) * 10 + index + 1


      },
      {
        title: 'Description',
        key: 'description',
        dataIndex: 'description'

      },
      {
        title: 'Title',
        key: 'title',
        dataIndex: 'title'

      },
      {
        title: 'Activity Type',
        dataIndex: 'activityType',
        key: 'activityType'

      },
      {
        title: 'Date',
        key: 'date',
        render: ((item, record) => {
          return <div>{record && record.date ? moment(record.date).format('lll') : ''}</div>
        })

      }]
    const inputTypes = {
      fields: [
        // {
        //   label: 'Activity Type',          
        //   key: 'activityType',
        //   type: 'select',
        //   required: true,
        //   options: ['Call', 'Meeting', 'Task', 'Demo', 'Email', 'Whatsapp Products'],
        //   placeholder: 'Select activity Type',
        //   onChange: v => {
        //     this.props.form.setFieldsValue({
        //       activityType: v
        //     })
        //   }
        // },
        {
          label: 'Date',
          key: 'date',
          type: 'date',
          required: true

        }
      ]
    }
    const inputTypes1 = {
      fields: [

        {
          label: 'Title',
          key: 'title',
          required: true
        },
        {
          label: 'Description',
          key: 'description',
          type: 'textArea',
          rows: 5,
          required: true
        }


      ]
    }


    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 4 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 20 }
      }
    }

    const submitFormLayout = {
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 10, offset: 7 },
        md: { span: 12, offset: 8 }
      }
    }


    let form = <div>
      {hideTitle && <React.Fragment>
        {/* <Card bordered={true}> */}
          <Form onSubmit={this.handleSubmit} hideRequiredMark style={{ marginTop: 8 }}>

            <GetAllFormFields apiurl={API_URL} inputSchema={inputTypes} formItemLayout={formItemLayout}
                              getFieldDecorator={getFieldDecorator}/>
            <Form.Item {...formItemLayout} label="Time"> {getFieldDecorator('time', {
              rules: [{ required: true }]
            })(
              <TimePicker
                value={this.state.time && moment(this.state.time, 'HH:mm:ss')}

                onChange={this.onTimeChange}/>
            )}</Form.Item>
            <GetAllFormFields apiurl={API_URL} inputSchema={inputTypes1} formItemLayout={formItemLayout}
                              getFieldDecorator={getFieldDecorator}/>

            <Form.Item {...submitFormLayout} style={{ marginTop: 32 }}>
              <Button type="primary" htmlType="submit" loading={this.props.loading}>
                SAVE
              </Button>
            </Form.Item>

          </Form>
        {/* </Card> */}
        {/* {activitiesData && activitiesData.length > 0 && <Card>
          <Table dataSource={activitiesData} columns={activityColumn} bordered pagination={false}/>
        </Card>} */}
      </React.Fragment>}
    </div>

    return (
      <React.Fragment>{!hideTitle ? <PageHeaderWrapper>{form}</PageHeaderWrapper> : form}</React.Fragment>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  categories: global.categories,
  pathname: router.location.pathname

})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddActivity)
