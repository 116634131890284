import React, {PureComponent} from 'react'
import {
    Steps,
    Card, Input, Select, DatePicker, Modal, Icon, Spin,
    Row, Col, Tabs, Collapse, Avatar, Tooltip, Divider, Popover, Button, Form, Tag, Alert, Badge
} from 'antd'
import {Timeline} from 'antd'
import 'react-quill/dist/quill.snow.css'
import _ from 'lodash'
import moment from 'moment'
import S from 'string'
import {LikeTwoTone, LikeOutlined} from '@ant-design/icons'
import styles from './styles.less'
import {notification} from 'antd/lib/index'
import Request, {setCountryName, getCallColor} from '../../../request'
import {connect} from 'react-redux'
import {createMatchSelector} from 'connected-react-router'
import {getColorClass} from '../../../colors'
import AddFollowup from '../../followup/add/index'
import AddSmartFollowup from '../../followup/add/smartFollowup'
import AddActivity from '../../activity/add/index'
import AddNote from '../../note/index'
import AttachFile from '../../emails/attachFiles'
import AddEmail from '../../emails/index'
import {FormUtils as GetAllFormFields} from 'sz-react-utils'
import {MdSnooze} from 'react-icons/md'

import LeadsProduct from '../../product/add/addProductByRO'

import {
    WinModal,
    LostModal,
    addLeadActivity,
    NoteActivityModal,
    EmailActivityModal,
    DealActivityModal,
    FollowUpActivityModal,
    WhatsAppActivityModal,
    SmartFollowUpModal,
    TextActivityModal
} from './helpers'
import {apiUrl} from '../../../settings'
import ReactPlayer from 'react-player/lazy'

const {TabPane} = Tabs
const {Step} = Steps
const {Panel} = Collapse
const {Option} = Select

@Form.create()
class ViewLead extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            leadStage: [
                {
                    value: 0,
                    text: 'New (Untouched)'
                }, {value: 1, text: 'Contacted'}, {value: 2, text: 'Qualified'}, {
                    value: 3,
                    text: 'Proposal Presented'
                }, {value: 4, text: 'In Negotiation'}],

            activeKey: '0',
            activeKey2: '0',
            currentStage: 0,
            win: false,
            lost: false,
            popOver: false,
            noteModal: false,
            emailModal: false,
            followUpModal: false,
            textActivityModal: false,
            whatsAppModal: false,
            activities: [],
            loader: true,
            showPlayer: [],
            smartLoader: false,
            callBtnLoader: false,
            alert: false,
            smartFollowupModal: false
        }
    }

    apiRequest = async () => {
        let searchParams = new URLSearchParams(this.props.search)
        let leadId = searchParams.get('id') || this.props.leadId
        if (this.state.leadId === leadId) {
            return false

        } else {
            this.state.leadId = leadId
        }

        if (leadId) {
            await Request.getLeadById({_id: leadId}).then(async ({data, error, message}) => {
                if (!error) {
                    let {data: ROData} = await Request.getUserById({_id: data.officer})
                    if (data && data.existingRO) {
                        let {data: existingROData} = await Request.getUserById({_id: data.existingRO})
                        this.setState({existingROData: existingROData})
                    }

                    this.setState({
                        leadId: leadId, leadData: data,
                        loader: false,
                        ROData: ROData
                    })

                    let found = _.find(this.state.leadStage, (item) => {
                        return item.text == data.stage
                    })
                    if (found) {
                        this.setState({currentStage: found.value})
                    } else {
                        this.setState({currentStage: 0})
                    }

                    this.props.form.setFieldsValue({
                        value: (data.value) ? data.value : '',
                        currency: (data.currency) ? data.currency : '',
                        closeDate: (data.closeDate) ? moment(data.closeDate) : ''
                    })
                } else {
                    this.setState({
                        loader: false
                    })
                    notification.error({
                        message: 'Error Getting Data'
                    })
                }
            })
            let {data} = await Request.getLeadByActivityType({leadId: leadId, type: 'all'})
            this.setState({
                activities: data
            })
            let {data: followupData} = await Request.getAllFollowupsByLeadId({
                // ...params,
                customQuery: {leadId: {$eq: leadId}, active: true},
                regExFilters: ['description']
            })
            this.setState({followupData: followupData})
        }

    }

    componentDidMount() {
    }

    handleVisibleChange = (key, value) => {
        let newData = _.clone(this.state.showPlayer)
        newData[key] = value
        this.setState({showPlayer: newData})
    }
    showDrawer = () => {
        this.setState({
            visible: true,
            key: Date.now()
        })
    }

    handleCancel = e => {
        this.setState({
            visible: false,
            smartFollowupModal: false
        })
    }

    render() {
        this.apiRequest()
        const styles1 = {
            mainDiv: {
                position: 'relative'
            },
            loadingBox: {
                // position: 'absolute',
                top: 10,
                left: '40%',
                height: '100%',
                width: '100%',
                backgroundColor: 'rgba(255, 255,255, 0.5)',
                textAlign: 'center',
                paddingTop: '10%'

            }
        }
        const {getTodos, currentUser} = this.props
        const {leadStage, leadData, currentStage, activities, ROData, showPlayer, newTitle, existingROData} = this.state

        const setActivity = (activity, newKey, key) => {
            if (activity.type == 'value' || activity.type == 'status' || activity.type == 'stage' || activity.type ==
                'closeDate')
                return <Timeline.Item
                    key={newKey}>{activity.message} by <b>{activity.user}</b> on <b>{moment(activity.createdAt).format('lll')}</b></Timeline.Item>
            else if (activity.type == 'notes')
                return <Timeline.Item key={newKey}>Note : "{S(activity.noteMessage).stripTags().s}" added
                    at <b>{moment(activity.createdAt).format('lll')}</b></Timeline.Item>
            else if (activity.type == 'email')
                return <Timeline.Item key={newKey}><a onClick={() => {
                    this.setState({emailModal: true, emailActivity: activity})
                }}>{activity.message} by <b>{activity.user}</b> on <b>{moment(activity.createdAt).format('lll')}</b></a>
                </Timeline.Item>
            // else if (activity.type == "file")
            else if (activity.type == 'whatsApp')
                return <Timeline.Item key={newKey}><a onClick={() => {
                    this.setState({whatsAppModal: true, whatsAppActivity: activity})
                }}>{activity.message} by <b>{activity.user}</b> on <b>{moment(activity.createdAt).format('lll')}</b></a>
                </Timeline.Item>
            else if (activity.type == 'file')
                return <Timeline.Item
                    key={newKey}>{activity.message} by <b>{activity.user}</b> on <b>{moment(activity.createdAt).format('lll')}</b><a
                    style={{marginLeft: '10px'}} href={apiUrl + `${activity.fileUrl}`} target="_blank">
                    <mark>{activity.fileName}</mark>
                </a></Timeline.Item>
            else if (activity.type == 'followUp')
                return <Timeline.Item key={newKey}><a onClick={() => {
                    this.setState({followUpModal: true, followupActivity: activity})
                }}>{activity.message} by <b>{activity.user}</b> on <b>{moment(activity.createdAt).format('lll')}</b></a>
                </Timeline.Item>

            else if (activity.type == 'message')
                return <Timeline.Item key={newKey}><a onClick={() => {
                    this.setState({textActivityModal: true, textActivity: activity})
                }}>{activity.message} by <b>{activity.user}</b> on <b>{moment(activity.createdAt).format('lll')}</b></a>
                </Timeline.Item>

            else if (activity.type == 'activity')
                return <Timeline.Item key={newKey}><a onClick={() => {
                    this.setState({dealActivityModal: true, dealActivity: activity})
                }}>{activity.message} by <b>{activity.user}</b> on <b>{moment(activity.createdAt).format('lll')}</b></a>
                </Timeline.Item>
            else if (activity.type === 'recording')
                return <Timeline.Item key={newKey}>
                    <span>{activity.message}</span> by <b>{activity.user}</b> on <b>{moment(activity.createdAt).format('lll')}</b>
                    <Popover
                        content={<ReactPlayer
                            url={`${apiUrl}/recordingStream?path=${activity.filePath}&isPath=true`}
                            controls
                            height={50}
                            className={styles.player}
                            width={400}
                        />}
                        placement={'bottomRight'}
                        trigger="click"
                        destroyTooltipOnHide
                        visible={showPlayer[key]}
                        onVisibleChange={(val) => this.handleVisibleChange(key, val)}
                    >
                        <Icon className={styles.playBtn} size="small" type="play-circle"/>
                    </Popover>
                    <Icon className={styles.playBtn} size="small" type="download" onClick={() => {
                        Request.downloadWithPath(activity.filePath)
                    }}/>

                </Timeline.Item>
            else if (activity.type === 'callLog' && leadData) {
                let currentActivity = _.find(leadData.activities, (e) => e._id === activity._id) || {}
                let recordId = currentActivity && currentActivity.callLogId && currentActivity.callLogId.recording
                return <Timeline.Item key={newKey}>
                            <span>{currentActivity && currentActivity.callLogId
                                ? <Tag
                                    style={getCallColor(currentActivity.callLogId.callType,
                                        activity.callDuration)}>{_.startCase(currentActivity.callLogId.callType)}</Tag>
                                : ''}</span> Call
                    of
                    duration <span>{moment.utc(activity.callDuration * 1000)
                    .format('HH:mm:ss')} </span> of <b>{activity.user}</b> on <b>{moment(activity.followUpDate).format('lll')}</b>
                    {recordId && <>
                        <Popover
                            content={<ReactPlayer
                                url={`${apiUrl}/recordingStream?id=${recordId}`}
                                controls
                                height={50}
                                className={styles.player}
                                width={400}
                            />}
                            placement={'bottomRight'}
                            trigger="click"
                            destroyTooltipOnHide
                            visible={showPlayer[key]}
                            onVisibleChange={(val) => this.handleVisibleChange(key, val)}
                        >
                            <Icon className={styles.playBtn} size="small" type="play-circle"/>
                        </Popover>
                        <Icon className={styles.playBtn} size="small" type="download" onClick={() => {
                            Request.downloadWithPath(`recordings/${recordId}.mp3`)
                        }}/>

                    </>}

                </Timeline.Item>
            } else if (activity.type === 'info')
                return <Timeline.Item
                    key={newKey}><a>{activity.message} by <b>{activity.user}</b> on <b>{moment(activity.createdAt)
                    .format('lll')}</b></a>
                </Timeline.Item>
        }

        const RenderActivities = (props) => {
            let {renderKey, data} = props
            let newData = _.groupBy(data, (x) => moment(x.createdAt).format('YYYY-MM-DD'))

            let findAArr = []
            _.each(newData, (val, k) => {
                findAArr.push({date: k, activities: val})
            })
            return _.map(findAArr, (val, key) => {
                return <div className={styles.timeLineDiv}>
                    <h3><Tag color={'lightgreen'}>{val.date}</Tag></h3>
                    <Timeline mode={'left'}>
                        {_.map(val.activities, (ii, k) => {

                            let newKey = `${renderKey} ${ii._id}`
                            if (leadData && leadData.leadAssignedDate && currentUser && currentUser.userType === 'RO') {
                                if (moment(ii.createdAt).isAfter(leadData.leadAssignedDate)) {
                                    return setActivity(ii, newKey, k)
                                }

                            } else {
                                return setActivity(ii, newKey, k)

                            }

                        })}

                    </Timeline>


                </div>
            })

        }

        let user = JSON.parse(localStorage.getItem('user'))
        const setNewData = async (leadId) => {
            await Request.getLeadById({_id: leadId}).then(({data, error, message}) => {
                if (!error) {
                    this.setState({
                        leadData: data
                    })

                    let found = _.find(this.state.leadStage, (item) => {
                        return item.text == data.stage
                    })
                    if (found) {
                        this.setState({currentStage: found.value})
                    } else {
                        this.setState({currentStage: 0})
                    }
                    this.props.form.setFieldsValue({
                        value: (data && data.value) ? data.value : '',
                        currency: (data && data.currency) ? data.currency : '',
                        closeDate: (data && data.closeDate) ? moment(data.closeDate) : ''
                    })
                } else {
                    notification.error({
                        message: 'Error Getting Data'
                    })
                }
                if (this.props.getTodos) {
                    getTodos(undefined, this.props.paginate, undefined, this.state.activeKey == 3)
                }
                if (this.props.handleAfterUpdate) {
                    this.props.handleAfterUpdate()
                }
            })
            let {data: followupData} = await Request.getAllFollowupsByLeadId({
                // ...params,
                customQuery: {leadId: {$eq: leadId}, active: true},
                regExFilters: ['description']
            })
            this.setState({followupData: followupData})

        }

        const getLeadByTypes = async (type, type1, type2) => {
            let selectType

            if (type === 'all' || type === 'updates') {
                selectType = type
            } else {
                if (type && type1 && !type2) {
                    selectType = [type, type1]
                } else if (type && type1 && type2) {
                    selectType = [type, type1, type2]
                } else {
                    selectType = [type]
                }

            }

            try {
                let {data} = await Request.getLeadByActivityType({leadId: leadData._id, type: selectType})
                this.setState({
                    activities: data
                })
            } catch (err) {
                console.log('error', err)
            }
        }

        const handleCallUnattended = async () => {
            try {
                //check lead Call

                let xx = await Request.checkLeadsCall({leadId: this.state.leadData._id, isAllow: true})

                if (xx.error) {
                    return notification.error({
                        message: 'Error Saving',
                        description: xx.message
                    })
                }


                //add note
                let note = await Request.saveTextNote({message: 'Call not picked', leadId: this.state.leadData._id})
                addLeadActivity({
                    values: {
                        message: `Note Added `,
                        leadId: this.state.leadData._id,
                        user: user && user.name,
                        type: 'notes'
                    }
                })

                let body = {_id: this.state.leadData._id}
                this.setState({...this.state, callBtnLoader: true})
                //set next followup
                let followup = await handleSmartFollowup(true)
                let x = await Request.callUnattended(body)
                this.setState({...this.state, callBtnLoader: false})
                if (!x.error) {
                    let msg = `Hello ${this.state.leadData && this.state.leadData.contact.firstName}, ${this.props.currentUser.name} from Hindustan Thermostatics this side. \n\n I tried to call you but was unable to reach you. Please call me back when you are free or \n\n I will try to call you again some after sometime`
                    setTimeout(async () => {
                        await addLeadActivity({
                            values: {
                                message: `Whatsapp sent to 91${this.state.leadData && this.state.leadData.contact.mobile}`,
                                whatsAppMessage: msg,
                                leadId: this.state.leadData._id,
                                user: this.props.currentUser.name,
                                type: 'whatsApp'
                            }
                        })
                        window.open(`http://api.whatsapp.com/send?phone=91${this.state.leadData && this.state.leadData.contact.mobile}&text=${msg} `)
                    }, 1000)
                    notification.success({message: 'Call record Updated'})
                } else {
                    notification.error({message: 'Something went wrong'})
                }
                await getLeadByTypes('all')
                setNewData(this.state.leadData._id)
            } catch (err) {
                notification.error('Something went wrong')
            }
        }

        const handleSmartFollowup = async (fromCallBtn) => {
            try {
                let body = {
                    leadId: this.state.leadData._id,
                    userId: user && user._id,
                    proposalPresented: true
                }
                if (fromCallBtn) {
                    body.proposalPresented = false
                }

                this.setState({...this.state, smartLoader: true})
                let x = await Request.addSmartFollowup(body)
                this.setState({...this.state, smartLoader: false})
                if (!x.error) {
                    if (!fromCallBtn) {
                        notification.success({message: 'Follow up Added'})
                    }
                    await addLeadActivity({
                        values: {
                            message: `Smart Follow up added`,
                            leadId: this.state.leadData._id,
                            user: user && user.name,
                            type: 'followUp'
                        }
                    })
                } else if (x.type == 'Booked') {
                    this.setState({
                        ...this.state,
                        smartFollowupModal: true
                    })
                } else {
                    return notification.error({
                        message: x.message
                    })
                }
                await getLeadByTypes('all')
                setNewData(this.state.leadData._id)
            } catch (err) {
                notification.error('something went wrong')
            }
        }

        let closeDateValue = (leadData) ? leadData.closeDate : undefined
        const {
            form: {getFieldDecorator, getFieldValue}
        } = this.props

        const onDateChange = async (date, dateString) => {
            let user = JSON.parse(localStorage.getItem('user'))
            let x = await updateTheLead({closeDate: date})
            if (!x.error) {
                addLeadActivity({
                    values: {
                        message: `Date changed from ${moment(leadData.closeDate).format('YYYY-MM-DD')} to ${moment(date)
                            .format('YYYY-MM-DD')}`, leadId: leadData._id, user: user && user.name, type: 'closeDate'
                    }
                })
            }
        }

        const onFinishValueForm = async (e) => {
            e.preventDefault()
            this.props.form.validateFields(async (err, values) => {
                if (!err) {
                    if (!(values.value || values.currency) || (values.value == '' || values.currency == '')) {
                        notification.error({
                            message: 'Please input both Value and Currency before submitting'
                        })
                        return
                    }
                    let x = await updateTheLead(values)
                    if (!x.error) {
                        if (leadData.value !== values.value)
                            addLeadActivity({
                                values: {
                                    message: `Value changed from ${leadData.value ? leadData.value : 'empty'} to ${values.value}`,
                                    leadId: leadData._id,
                                    user: user && user.name,
                                    type: 'value'
                                }
                            })
                        else addLeadActivity({
                            values: {
                                message: `Currency changed from ${leadData.currency} to ${values.currency}`,
                                leadId: leadData._id,
                                user: user && user.name,
                                type: 'currency'
                            }
                        })
                    }

                }
            })
        }

        const content = (
            <Form
                layout="vertical"
                onSubmit={onFinishValueForm}
            >
                <Form.Item
                    name="value"
                    label="Value"
                >{getFieldDecorator('value', {
                    // rules: [{ required: true }]

                })(<Input/>)}
                </Form.Item>
                <Form.Item name="currency" label="Currency">
                    {getFieldDecorator('currency', {
                        // rules: [{ required: true }]
                    })(<Select
                        placeholder="Select Currency"
                        allowClear
                    >
                        <Option value="DOLLAR-$">DOLLAR-$</Option>
                        <Option value="INR-₹">INR-₹</Option>
                    </Select>)}
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        )
        const leftInputTypes = {
            fields: [

                {
                    label: 'Value',
                    key: 'value',
                    required: true
                },
                {
                    label: 'Currency',
                    key: 'currency',
                    required: true,
                    type: 'select', options: ['DOLLAR-$', 'INR-₹'],
                    onChange: v => {
                        this.props.form.setFieldsValue({currency: v})
                    }
                }
            ]
        }

        const showWonModal = () => {
            this.setState({
                ...this.state, win: true
            })
        }
        const showLostModal = () => {
            this.setState({
                ...this.state, lost: true
            })
        }
        const onCancel = () => {
            this.setState({
                ...this.state, lost: false, win: false
            })
        }

        const updateTheLead = async (values) => {
            values._id = this.state.leadData._id
            values.new = false
            let x = await Request.updateLead(values)
            if (!x.error) {
                notification.success({
                    message: x.message
                })
                this.props.form.resetFields()
                this.setState({...this.state, popOver: false})
                setNewData(this.state.leadData._id)
                return (x)
                // this.setState({ activeKey2: "0" });
            } else {
                console.log('err is', x.error)
                notification.error({
                    message: 'Error Saving',
                    description: x.message
                })
                return (x)
            }
        }
        let rightSide

        if (leadData && leadData.status == 'Open' || leadData && (!leadData.status)) {
            rightSide = <div className={styles.headerRight}>
                <Button onClick={() => {
                    showWonModal()
                }} style={{margin: '4px', background: 'white', color: 'green'}}
                        icon="like" type="primary" size="large">Won</Button>
                <Button hidden={leadData && leadData.isTransferred} onClick={() => {
                    showLostModal()
                }} style={{margin: '4px', background: 'white', color: 'red'}}
                        icon="dislike" type="danger" size="large">Lost</Button>
            </div>
        } else {
            rightSide = <div className={styles.headerRight}></div>
        }
        const stageHandler = async (step) => {
            switch (step) {
                case 0:
                    let {error} = await updateTheLead({stage: 'New (Untouched)'})
                    if (!error) {
                        await addLeadActivity({
                            values: {
                                message: `Lead staged as "New (Untouched)"`,
                                leadId: leadData._id,
                                user: user && user.name,
                                type: 'stage'
                            }
                        })
                    }
                    break
                case 1:
                    let x = await updateTheLead({stage: 'Contacted'})
                    if (!x.error) {
                        await addLeadActivity({
                            values: {
                                message: `Lead staged as "Contacted"`,
                                leadId: leadData._id,
                                user: user && user.name,
                                type: 'stage'
                            }
                        })
                    }
                    break
                case 2:
                    let xx = await updateTheLead({stage: 'Qualified', status: 'Open'})
                    if (!xx.error) {
                        await addLeadActivity({
                            values: {
                                message: `Lead staged as "Qualified"`,
                                leadId: leadData._id,
                                user: user && user.name,
                                type: 'stage'
                            }
                        })

                        await addLeadActivity({
                            values: {
                                message: `Lead declared as "Open"`,
                                leadId: leadData._id,
                                user: user && user.name,
                                type: 'status'
                            }
                        })
                    }
                    break
                case 3:
                    let y = await updateTheLead({stage: 'Proposal Presented'})
                    if (!y.error) {
                        await addLeadActivity({
                            values: {
                                message: `Lead staged as "Proposal Presented"`,
                                leadId: leadData._id,
                                user: user && user.name,
                                type: 'stage'
                            }
                        })
                    }
                    break
                case 4:
                    let yy = await updateTheLead({stage: 'In Negotiation'})
                    if (!yy.error) {
                        await addLeadActivity({
                            values: {
                                message: `Lead staged as "In Negotiation"`,
                                leadId: leadData._id,
                                user: user && user.name,
                                type: 'stage'
                            }
                        })
                    }
                    break
            }
            getLeadByTypes('all')

        }

        const cancelActivityModal = () => {
            this.setState({
                noteModal: false,
                emailModal: false,
                followUpModal: false,
                dealActivityModal: false,
                whatsAppModal: false,
                textActivityModal: false
            })
        }

        function truncate(str, n) {
            if (str && str.length) {
                return (str.length > n) ? str.substr(0, n - 1) + '...' : str
            }
            return ''
        }

        const formattedValue = (value) => {
            let formatted = (value) ? value.replace(/(\d)(?=(\d\d)+\d$)/g, '$1,') : ''
            return formatted

        }

        return (<Spin size="large" spinning={this.state.loader}>
                <React.Fragment>
                    <div className="abc" style={{margin: '10px'}}>
                        <div className={styles.headerButtonWrapper}>
                            <h2 style={{
                                marginLeft: '20px',
                                fontWeight: 800,
                                fontSize: '24px'
                            }}>{leadData && leadData.title
                                ? (<span><b style={{
                                    color: '#99AAAB',
                                    fontWeight: 600,
                                    fontSize: '20px'
                                }}>#{leadData.id} </b>{leadData.title} {currentUser && currentUser.userType === 'Admin'
                                    ? ROData && ROData.name
                                        ? `( ${ROData.name} )`
                                        : ''
                                    : ''}  </span>)
                                : ''}</h2>
                            {rightSide}
                        </div>
                        <div style={{width: leadData && leadData.tags && leadData.tags.length ? '100%' : '60%'}}>
                            <table style={{margin: '20px 10px'}} className={styles.dataTable}>
                                <thead>
                                <tr>
                                    <th style={{width: '10%'}}>Value</th>
                                    <th style={{width: '25%'}}>Estimated Close Date</th>
                                    <th style={{width: '25%'}}> Next Followup <Button size="small"
                                                                                      style={{marginLeft: '5px'}}
                                                                                      type="primary"
                                                                                      onClick={() => {
                                                                                          this.setState({activeKey: '3'})
                                                                                      }}>{<Icon type="plus"/>}</Button>
                                    </th>


                                    {leadData && leadData.tags && leadData.tags.length ? _.map(leadData.tags, (x) => {
                                        return <th style={{
                                            width: '25%', backgroundColor: 'white',
                                            border: '1px #d0d0d0 solid',
                                            padding: '0 10px'
                                        }}><Tag color="red">{x}</Tag></th>
                                    }) : ''}


                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td><Popover content={content} title="Update Value" trigger="click">
                                        <a onClick={() => {
                                            this.setState({...this.state, popOver: true})
                                        }}>{leadData && leadData.currency
                                            ? `${leadData.currency[leadData.currency.length -
                                            1] + formattedValue(leadData.value)}`
                                            : '____'}</a>
                                    </Popover></td>
                                    <td><DatePicker key={closeDateValue ? 'abc' : 'xyz'}
                                                    defaultValue={closeDateValue ? moment(closeDateValue) : moment()}
                                                    onChange={onDateChange}/></td>
                                    <td><a onClick={() => {
                                        this.setState({activeKey: '3'})
                                    }}>
                    <span>{(this.state.followupData) ? (this.state.followupData.length ? truncate(
                        this.state.followupData[0].description, 24) : '') : ''}</span><br/>
                                        <span><small>{(this.state.followupData && this.state.followupData.length) ? moment(
                                            this.state.followupData[0].followUpDate).format('ll') : ''}</small></span>
                                        <br/>
                                        <span
                                            style={{color: 'red'}}>{this.state.followupData && this.state.followupData[0] && this.state.followupData[0].overdueCount ?
                                            <Badge
                                                count={`OD (${this.state.followupData[0].overdueCount})`}
                                                style={{backgroundColor: 'red'}}
                                            /> : ''}</span>
                                    </a>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>


                        <div style={{margin: '40px 10px', marginTop: '0px'}}>
                            <Steps size="small" current={currentStage} onChange={(e) => {
                                stageHandler(e)
                                if ((e !== 1 && e !== 0) && (leadData && !leadData.productList.length)) {
                                    this.setState({visible: true})

                                }

                            }}>
                                {leadStage && leadStage.map((item, cc) => {

                                    if (user && user.userType && user.userType !== 'Admin') {
                                        return (<Step disabled={true} title={item.text} key={cc}/>)
                                    } else {
                                        return (<Step title={item.text} key={cc}/>)
                                    }

                                    // if (currentStage == 0) {
                                    //     return (<Step disabled={true} title={item.text} key={cc}/>)
                                    //
                                    // } else {
                                    //     return (<Step title={item.text} key={cc}/>)
                                    //
                                    // }
                                })}
                            </Steps>
                        </div>
                    </div>
                    <div>
                        <Row gutter={14}>
                            <Col span={8} className="gutter-row">

                                <Collapse bordered={false}
                                          defaultActiveKey={['4', '1', '6']}
                                >

                                    {leadData && leadData.isTransferred &&
                                        <Panel header={<span style={{color: 'blue'}}>Transferred Lead</span>} key="6">
                                            {existingROData &&
                                                <div className={styles.description} style={{color: 'blue'}}>
                                            <span>
                                                {existingROData && existingROData.name ? existingROData.name : ''}

                                            </span> <br/>
                                                    <span>
                                                {existingROData && existingROData.email ? existingROData.email : ''}

                                            </span> <br/>
                                                    <span>
                                                {existingROData && existingROData.mobile ? existingROData.mobile : ''}
                                            </span>
                                                </div>}
                                        </Panel>}

                                    <Panel header="Description" key="4">
                                        {leadData && leadData.description ?
                                            <div className={styles.description}>
                                                {leadData && leadData.description ? leadData.description : ''}</div>
                                            : null}
                                    </Panel>
                                    <Panel header="Contact" key="1">
                                        {leadData && leadData.contact && leadData.contact.firstName ?
                                            <div>
                                                <Tooltip title={leadData.contact.firstName}>
                                                    <Avatar style={{
                                                        backgroundColor: getColorClass(leadData.contact.firstName)
                                                    }}>{leadData && leadData.contact.firstName
                                                        ? leadData.contact.firstName.charAt(0).toUpperCase()
                                                        : ''}</Avatar>
                                                </Tooltip> <span
                                                style={{paddingLeft: '10px'}}> {leadData.contact.firstName}</span>
                                                <div
                                                    style={{paddingLeft: '45px'}}> {leadData && leadData.contact &&
                                                    leadData.contact.mobile}</div>
                                                <div
                                                    style={{paddingLeft: '45px'}}> {leadData && leadData.contact && leadData.contact.phone}</div>
                                                <div
                                                    style={{paddingLeft: '45px'}}> {leadData && leadData.contact && leadData.contact.email
                                                    ? leadData.contact.email
                                                    :
                                                    <span style={{color: 'red'}}>NO EMAIL</span>}</div>
                                            </div> : null}


                                        {leadData && leadData.addressInfo && leadData.addressInfo.addressLine1 ? <div
                                            style={{paddingLeft: '45px'}}>{`${leadData.addressInfo.addressLine1} (${setCountryName(leadData.addressInfo.countryCode || '')})`}</div> : ''}
                                    </Panel>

                                    <Panel header="Company" key="2">
                                        {leadData && leadData.company ? <div>
                                                <Tooltip title={leadData.company}>
                                                    <Avatar style={{
                                                        backgroundColor: getColorClass(leadData.company)
                                                    }}>{leadData && leadData.company ? leadData.company.charAt(0).toUpperCase() : ''}</Avatar>
                                                </Tooltip> <span style={{paddingLeft: '10px'}}> {leadData.company}</span>
                                            </div>
                                            : null}
                                    </Panel>
                                    <Panel header="Deal Details" key="3">
                                        <div className={styles.description}>
                                            <span>Source :</span> {leadData && leadData.source ? leadData.source : ''}
                                        </div>
                                        <Divider/>
                                        <div className={styles.description}>
                                            <span>Priority :</span> {leadData && leadData.priority ? leadData.priority : ''}
                                        </div>
                                        <Divider/>
                                        <div className={styles.description}>
                                            <span>Status :</span> {leadData && leadData.status ? leadData.status : ''}
                                        </div>
                                        <Divider/>
                                    </Panel>
                                    {(leadData && leadData.status == 'Open' || leadData && (!leadData.status)) &&
                                        <Panel header="Unqualify Lead?" key="5">
                                            {leadData && <div>
                                                <Button style={{marginLeft: '5px'}} type="danger" size={'small'}
                                                        onClick={async () => {

                                                            let x = await updateTheLead({
                                                                status: 'Unqualified',
                                                                stage: 'Contacted'
                                                            })

                                                            if (!x.error) {
                                                                await addLeadActivity({
                                                                    values: {
                                                                        message: `Lead declared as "unqualified"`,
                                                                        leadId: this.state.leadData._id,
                                                                        user: user && user.name,
                                                                        type: 'status'
                                                                    }
                                                                })
                                                                await addLeadActivity({
                                                                    values: {
                                                                        message: `Lead staged as "Contacted"`,
                                                                        leadId: this.state.leadData._id,
                                                                        user: user && user.name,
                                                                        type: 'stage'
                                                                    }
                                                                })
                                                                Request.setFollowupsToFalse({leadId: this.state.leadData._id})
                                                            }

                                                        }}>Unqualify lead</Button>
                                            </div>
                                            }
                                        </Panel>}

                                </Collapse>
                            </Col>
                            <Col span={16} className="gutter-row">
                                <Row>
                                    <Col>


                                        <Card bordered="false" style={{backgroundColor: '#e8fae6'}}>
                                            <div style={{marginBottom: '10px'}}><Collapse
                                                activeKey={this.state.productActiveKey} accordion
                                                onChange={() => {
                                                    this.setState({productActiveKey: '1'})
                                                    console.log(this.state.productActiveKey)
                                                    if (this.state.productActiveKey === '1') {
                                                        this.setState({productActiveKey: '2'})
                                                    }
                                                }}>
                                                <Panel key="1" header={'Add Product'}>
                                                    <LeadsProduct leadId={this.state.leadId}
                                                                  otherProduct={leadData && leadData.otherProduct}
                                                                  selectedProducts={leadData && leadData.productList}
                                                                  formResponse={async () => {
                                                                      this.setState({
                                                                          visible: false,
                                                                          productActiveKey: '2'
                                                                      }, () => {
                                                                          setNewData(leadData && leadData._id)
                                                                      })
                                                                  }}/>


                                                </Panel>

                                            </Collapse></div>

                                            <div>
                                                <Button type="primary" size={'small'}
                                                        icon="book"
                                                        loading={this.state.smartLoader}
                                                        style={{
                                                            backgroundColor: 'rgb(0, 204, 205)',
                                                            borderColor: 'rgb(0, 204, 205)'
                                                        }}
                                                        onClick={() => handleSmartFollowup(false)}>Proposal
                                                    Presented
                                                </Button>
                                                <Button type="primary" size={'small'}
                                                        icon="phone"
                                                        loading={this.state.callBtnLoader}
                                                        style={{
                                                            backgroundColor: '#ff5722',
                                                            borderColor: '#ff5722',
                                                            marginLeft: '10px'
                                                        }}
                                                        onClick={handleCallUnattended}>
                                                    Call Not Picked
                                                </Button>
                                                <Button type="primary" size={'small'}
                                                        loading={this.state.startSnoozeLoader}
                                                        style={{
                                                            marginLeft: '10px'
                                                        }}
                                                        onClick={async () => {

                                                            let date = moment().add(2, 'hours')
                                                            this.setState({startSnoozeLoader: true})
                                                            console.log(user._id, this.state.leadData._id, date)
                                                            let x = await Request.addfollowup({

                                                                leadId: this.state.leadData._id,
                                                                addedBy: user && user._id,
                                                                description: 'Call not picked',
                                                                followUpDate: date,
                                                                checkCallPicked: true,
                                                                followUpTime: '02:00 PM'
                                                            })
                                                            this.setState({startSnoozeLoader: false})

                                                            if (!x.error) {

                                                                notification.success({
                                                                    message: x.message
                                                                })
                                                                await updateTheLead({
                                                                    isFollowUp: true,
                                                                    snooze: true,
                                                                    snoozeTime: moment(),
                                                                    dontCheckLead: true

                                                                })

                                                                await addLeadActivity({
                                                                    values: {
                                                                        message: `Followup Added for date ${moment(date).format('YYYY-MM-DD')}`,
                                                                        leadId: this.state.leadData._id,
                                                                        user: user && user.name,
                                                                        type: 'followUp'
                                                                    }
                                                                })

                                                                await getLeadByTypes('all')


                                                            } else {
                                                                notification.error({
                                                                    message: 'Error Saving',
                                                                    description: x.message
                                                                })
                                                            }

                                                        }}>Snooze<MdSnooze
                                                    style={{marginLeft: '4px', verticalAlign: 'middle'}}/>
                                                </Button>
                                            </div>
                                            <Tabs
                                                style={{overflow: 'unset'}}
                                                type="line"
                                                activeKey={this.state.activeKey}
                                                defaultActiveKey="0"
                                                onTabClick={(key) => {
                                                    this.setState({activeKey: key}, () => {
                                                        if (key == '2') {
                                                            this.setState({renderActivity: Date.now()})
                                                        }
                                                        if (key == '3') {
                                                            this.setState({renderFollowup: Date.now()})
                                                        }
                                                    })
                                                }}
                                            >
                                                <TabPane tab={'Note'} key={0}>
                                                    <AddNote hideTitle={true} leadId={leadData && leadData._id}
                                                             notesData={leadData && leadData.notes ? leadData.notes : []}
                                                             handleAddNote={async () => {
                                                                 await addLeadActivity({
                                                                     values: {
                                                                         message: `Note Added`,
                                                                         leadId: leadData._id,
                                                                         user: user && user.name,
                                                                         type: 'notes'
                                                                     }
                                                                 })
                                                                 await getLeadByTypes('all')

                                                                 setNewData(leadData._id)
                                                                 this.setState({activeKey2: '0'})

                                                             }}/>
                                                </TabPane>
                                                <TabPane tab={'Email'} key={1}>
                                                    <AddEmail hideTitle={true} leadId={leadData && leadData._id}
                                                              emailsData={leadData && leadData.emails ? leadData.emails : []}
                                                              leadData={{...leadData, emails: null}}
                                                              handleAddEmail={async () => {
                                                                  await getLeadByTypes('all')
                                                                  setNewData(leadData._id)
                                                                  this.setState({activeKey2: '0'})

                                                              }}/>
                                                </TabPane>
                                                <TabPane tab={'Activity'} key={2}>
                                                    <AddActivity hideTitle={true} leadId={leadData && leadData._id}
                                                                 key={this.state.renderActivity}
                                                                 handleAddActivity={async () => {
                                                                     await addLeadActivity({
                                                                         values: {
                                                                             message: `Activity Added`,
                                                                             leadId: leadData._id,
                                                                             user: user && user.name,
                                                                             type: 'activity'
                                                                         }
                                                                     })
                                                                     await getLeadByTypes('all')
                                                                     setNewData(leadData._id)
                                                                     this.setState({activeKey2: '0'})
                                                                 }}/>
                                                </TabPane>
                                                <TabPane tab={'FollowUp'} key={3}>
                                                    <AddFollowup hideTitle={true} leadId={leadData && leadData._id}
                                                                 stage={leadData && leadData.stage}
                                                                 user={user}
                                                                 key={this.state.renderFollowup}
                                                                 handleAddFollowup={async () => {
                                                                     this.setState({activeKey2: '0'})
                                                                     await getLeadByTypes('all')
                                                                     setNewData(leadData._id)

                                                                 }}/>
                                                </TabPane>
                                                <TabPane tab={'File'} key={4}>
                                                    <AttachFile hideTitle={true} leadId={leadData && leadData._id}
                                                                filesData={leadData && leadData.files ? leadData.files : []}
                                                                handleAttachFile={async () => {
                                                                    this.setState({activeKey2: '0'})
                                                                    await addLeadActivity({
                                                                        values: {
                                                                            message: `File attached`,
                                                                            leadId: leadData._id,
                                                                            user: user && user.name,
                                                                            type: 'file'
                                                                        }
                                                                    })
                                                                    await getLeadByTypes('all')
                                                                    setNewData(leadData._id)
                                                                }}/>
                                                </TabPane>
                                            </Tabs>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Card style={{marginTop: '30px'}}>
                                            <Tabs
                                                type="line"
                                                activeKey={this.state.activeKey2}
                                                defaultActiveKey="0"
                                                onTabClick={(key) => {
                                                    this.setState({activeKey2: key}, async () => {
                                                        if (key == '0')
                                                            await getLeadByTypes('all')
                                                        if (key == '1') {
                                                            await getLeadByTypes('notes')
                                                        }
                                                        if (key == '2') {
                                                            await getLeadByTypes('email', 'message', 'whatsApp')
                                                        }
                                                        if (key == '3') {
                                                            await getLeadByTypes('followUp')
                                                        }
                                                        if (key == '4') {
                                                            await getLeadByTypes('file')
                                                        }
                                                        if (key == '5') {
                                                            await getLeadByTypes('updates')
                                                        }
                                                        if (key == '6') {
                                                            await getLeadByTypes('callLog', 'recording')
                                                        }

                                                    })
                                                }}
                                            >
                                                <TabPane tab={'All Activities'} key={0}>
                                                    <RenderActivities renderKey="0" data={activities}/>
                                                </TabPane>
                                                <TabPane tab={'Notes'} key={1}>
                                                    <RenderActivities renderKey="1" data={activities}/>
                                                </TabPane>
                                                <TabPane tab={'Communication'} key={2}>
                                                    <RenderActivities renderKey="2" data={activities}/>
                                                </TabPane>
                                                <TabPane tab={'FollowUp'} key={3}>
                                                    <RenderActivities renderKey="3" data={activities}/>
                                                </TabPane>
                                                <TabPane tab={'File'} key={4}>
                                                    <RenderActivities renderKey="4" data={activities}/>
                                                </TabPane>
                                                <TabPane tab={'Updates'} key={5}>
                                                    <RenderActivities renderKey="5" data={activities}/>
                                                </TabPane>
                                                <TabPane tab={'Call'} key={6}>
                                                    <RenderActivities renderKey="6" data={activities}/>
                                                </TabPane>

                                            </Tabs>
                                        </Card>
                                    </Col>
                                </Row>

                            </Col>
                        </Row>
                    </div>
                    {this.state.win && WinModal(
                        {
                            getFieldDecorator,
                            onCancel,
                            updateTheLead,
                            state: {...this.state},
                            form: {...this.props.form}
                        })}
                    {this.state.lost && LostModal(
                        {
                            getFieldDecorator,
                            onCancel,
                            updateTheLead,
                            state: {...this.state},
                            form: {...this.props.form}
                        })}
                    {this.state.noteModal && NoteActivityModal(
                        {getFieldDecorator, cancelActivityModal, updateTheLead, state: {...this.state}})}
                    {this.state.emailModal && EmailActivityModal(
                        {getFieldDecorator, cancelActivityModal, updateTheLead, state: {...this.state}})}
                    {this.state.followUpModal && FollowUpActivityModal(
                        {getFieldDecorator, cancelActivityModal, updateTheLead, state: {...this.state}})}
                    {this.state.dealActivityModal && DealActivityModal(
                        {getFieldDecorator, cancelActivityModal, updateTheLead, state: {...this.state}})}
                    {this.state.whatsAppModal && WhatsAppActivityModal(
                        {getFieldDecorator, cancelActivityModal, updateTheLead, state: {...this.state}})}
                    {this.state.textActivityModal && TextActivityModal(
                        {getFieldDecorator, cancelActivityModal, updateTheLead, state: {...this.state}})}

                    <Modal
                        visible={this.state.smartFollowupModal}
                        onCancel={this.handleCancel}
                        // key={this.state.key}
                        footer={false}

                    >
                        <div style={{marginLeft: 20, marginRight: 20}}>
                            <Row>
                                <Col>
                                    <h3>No Time slot available for the next day. Please choose manually</h3>
                                    <AddSmartFollowup hideTitle={true} leadId={leadData && leadData._id}
                                                      stage={leadData && leadData.stage}
                                                      user={user}
                                                      key={this.state.renderFollowup}
                                                      handleAddSmartFollowup={async () => {
                                                          await addLeadActivity({
                                                              values: {
                                                                  message: `Follow up added`,
                                                                  leadId: this.state.leadData._id,
                                                                  user: user && user.name,
                                                                  type: 'followup'
                                                              }
                                                          })
                                                          await getLeadByTypes('all')
                                                          this.setState({
                                                              ...this.state,
                                                              smartFollowupModal: false
                                                          })
                                                          setNewData(leadData._id)
                                                      }}/>

                                </Col>
                            </Row>


                        </div>
                    </Modal>

                </React.Fragment>

            </Spin>
        )
    }
}

const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search,
    currentUser: global.currentUser

})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewLead)
