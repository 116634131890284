import React, {Component} from 'react'
import moment from 'moment'
import {
    Button,
    Icon,
    Row,
    Mentions,
    Badge,
    notification,
    Col,
    Select,
    DatePicker,
    TimePicker,
    Spin,
    AutoComplete
} from 'antd'
import _ from 'lodash'
import Request from "../../request";

const chrono = require('chrono-node')

const Option = Select.Option


class MentionAddTask extends Component {
    state = {
        taskName: '',
        prefix: '@',
        priority: null,
        assignToId: null,
        searchTerm: '',
        date: null,
        reminderTime: null,
        allContacts: []
    }

    onSearch = (title, prefix) => {
        let searchTerm = `${prefix}${title}`
        console.log(searchTerm, ' search term')
        this.setState({prefix, searchTerm})
    }
    onSelect = (value, prefix, test) => {

        let {taskName, searchTerm} = this.state

        taskName = taskName.replace(searchTerm, '')

        if (prefix === '#') {
            this.setState({
                priority: value.value
            })
        }

        if (prefix === '@') {
            let assignId = value && value.value.split('%').shift()

            console.log('assignId', assignId)
            this.setState({
                assignToId: assignId
            })
        }

        this.setState({
            taskName
        })

    }


    parseDate = () => {
        const {title} = this.state
        let x = chrono.parseDate(title)
        if (x) {
            this.setState({
                date: moment(x)
            })
        }

    }
    save = async () => {
        let {taskName, date, priority, assignToId, reminderTime, contactId} = this.state
        if (!taskName) {
            return notification.error({
                message: 'Enter Task Name'
            })
        }

        if (!date) {
            date = new Date()
        }

        if (!assignToId) {
            assignToId = this.props.currentUser._id
        }
        if (!reminderTime) {
            reminderTime = moment().format('HH:mm:ss')
        }

        if (!priority) {
            priority = 'Low'
        }

        let obj = {
            taskName,
            dueDate: date,
            priority,
            assignToId,
            reminderTime,
            statusLog: [{status: 'Pending', time: new Date()}],
            contactId
        }


        let resp = await this.props.onsave(obj)
        if (resp) {
            this.setState({
                taskName: '',
                prefix: '@',
                priority: null,
                assignToId: null,
                searchTerm: '',
                date: null,
                reminderTime: null,
                contactId: null,
                selectedContact: null
            })
        }
    }

    handleContactSearch = async (test) => {
        console.log(test)
        this.setState({selectedContact: test})
        if (test == '') {
            console.log("empty");
            this.setState({
                allContacts: this.state.allContacts,
                selectedContact: test,
                contactId: null

            })

            return false
        } else {
            this.props.onChange(test)

        }

    }

    onContactSelect = (val) => {
        let selectedContact = this.props.allContacts.find((obj) => obj._id === val)
        if (selectedContact) {
            this.setState({
                contactId: selectedContact._id,
                selectedContact: `${selectedContact.firstName} (${selectedContact.mobile})`
            }, () => {
            })
        }
    }

    constructor(props) {
        super(props)
        this.searchContact = _.debounce(this.handleContactSearch, 1000, true)

    }

    componentDidMount() {

    }

    render() {

        const {allUsers, currentUser, allContacts} = this.props

        const {prefix, taskName} = this.state

        const data = {
            '@': allUsers
        }

        const priority = [{text: 'High', color: '#ff0a1c'},
            {text: 'Low', color: '#00a6ff'},
            {text: 'Normal', color: '#ffda0c'}]


        return <React.Fragment>
            <Row gutter={16}>
                <Col span={10}>
                    <div onKeyPress={(e, test) => {
                        if (e.key.toLowerCase() === 'enter') {
                            e.preventDefault()
                            this.parseDate()
                            this.save()
                        }
                    }}>
                        <Mentions
                            style={{
                                width: '100%',
                                height: '32px'
                            }}
                            value={taskName}
                            placeholder="user @ to assign task, # to give priority"
                            prefix={['@', '#', '%']}
                            onBlur={this.parseDate}
                            onSearch={this.onSearch}
                            onChange={(title) => {
                                this.setState({taskName: title})
                            }}
                            onSelect={this.onSelect}
                        >

                            {(data[prefix] || []).map(value => (
                                <Mentions.Option
                                    key={value._id}
                                    value={`${value._id}%${value.name}-${value.email}`}>
                                    {value.name} ({value.email})
                                </Mentions.Option>
                            ))}

                            {(prefix === '#' ? priority : []).map(value => (
                                <Mentions.Option key={value.text} value={value.text}>
                                    <Badge color={value.color} text={value.text}/>
                                </Mentions.Option>))}

                        </Mentions>
                    </div>
                </Col>
                <Col span={14} id={'visaDashboardCol'}>
                    <Row gutter={8}>
                        <Col span={4}>
                            <DatePicker
                                placeholder={'Due'}
                                format={'DD MMM'}
                                value={this.state.date}
                                disabledDate={(d) => !d || d.isBefore(moment().add(-1, 'day'))}
                                onChange={(date) => {
                                    this.setState({date})
                                }}/>

                        </Col>
                        <Col span={4}>
                            <TimePicker
                                placeholder={'Reminder'} style={{width: '100%'}}
                                value={this.state.reminderTime && moment(this.state.reminderTime, 'HH:mm:ss')}
                                onChange={(time) => {
                                    this.setState({reminderTime: moment(time).format('HH:mm:ss')}, () => {
                                        console.log(this.state.reminderTime)
                                    })
                                }}
                            />

                        </Col>
                        <Col span={10}>
                            <Select
                                showSearch
                                allowClear
                                style={{width: '100%'}}
                                value={this.state.assignToId}
                                optionFilterProp="children"
                                placeholder="Select User"
                                onChange={assignId => {
                                    console.log(assignId, '---------------------------')
                                    this.setState({assignToId: assignId})
                                }}>

                                {allUsers && allUsers.map((val, idn) => {
                                    return (
                                        <Option key={idn} value={val._id}>
                                            {val.name} ({val.email})
                                        </Option>
                                    )
                                })}

                            </Select>
                        </Col>
                        <Col span={4}>
                            <Select
                                allowClear
                                style={{width: '100%'}}
                                value={this.state.priority}
                                placeholder="Priority"
                                onChange={priority => {
                                    this.setState({priority})
                                }}
                            >
                                {priority.map((val, idn) => {
                                    return (
                                        <Option key={idn} value={val.text}>
                                            <Badge color={val.color} text={val.text}/>
                                        </Option>
                                    )
                                })}

                            </Select>
                        </Col>
                        <Col span={2}>
                            <Button
                                type={'primary'}
                                onClick={() => {
                                    this.save()
                                }}>
                                <Icon type={'plus'}/>
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col span={8}>
                    <AutoComplete
                        value={this.state.selectedContact}
                        notFoundContent={this.state.showSpin && <Spin/>}
                        dropdownMatchSelectWidth={false}
                        style={{
                            width: 380, marginTop: '10px'
                        }}
                        autoComplete={'none'}
                        onSelect={this.onContactSelect}
                        onSearch={this.searchContact}
                        onChange={(val) => {
                            this.setState({selectedContact: val})
                        }}
                        placeholder={'Search Contact'}
                    >
                        {allContacts && allContacts.map((val, item) => (
                            <Option value={val._id}>
                                <>
                                    <div><b>{`${val.firstName} (${val.mobile})`}</b></div>
                                </>
                            </Option>
                        ))}

                    </AutoComplete>

                </Col>
            </Row>
        </React.Fragment>
    }
}

export default MentionAddTask
