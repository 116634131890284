import React, {Component} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {TableComp} from 'sz-react-utils'
import moment from 'moment'
import {
    Modal,
    Card,
    Button, Tooltip,
    Select,
    Popconfirm

} from 'antd'
import Request from '../../../request'
import _ from 'lodash'
//import styles from './styles.less'
import {connect} from 'react-redux'
import {getUrlPushWrapper} from '../../../routes'
import {apiUrl} from "../../../settings";
import {notification} from "antd/lib/index";

const {Option} = Select

class AllCountry extends Component {

    constructor(props) {
        super(props)
        this.table = React.createRef()
        this.state = {visible: false, key: Date.now()}

    }


    apiRequest = (params) => {

        return new Promise(async (resolve) => {

            let data = await Request.getAllCountry({
                ...params, regExFilters: ['countryName']
            })

            console.log(data)
            this.setState({count: data.total})
            resolve(data);
        })


    }
    deleteCountry = async data => {
        this.setState({loading: true})
        let x = await Request.deleteCountry(data)
        this.setState({loading: false})

        this.table.current.reload()
        if (!x.error) {
            notification.success({
                message: x.message,
                duration: 20,
                key: `${data._id}-close`
            })

        }
        else {
            notification.error({
                message: x.message,
                duration: 20,
                key: `${data._id}-close`
            })
        }


    }


    async componentDidMount() {

    }

    render() {
        const {submitting, dispatch} = this.props
        const {visible, singleMail} = this.state;


        const columns = [
            {
                title: 'Country Name',
                key: 'countryName',
                dataIndex: 'countryName',
                searchTextName: 'countryName',
                render: (val) => {
                    return val && _.startCase(val)
                }


            },
            // {
            //     key: 'actions',
            //     title: 'Actions',
            //     render: (text, record) => {
            //         return <React.Fragment>
            //
            //             <Tooltip title="Delete Country">
            //                 <Popconfirm
            //                     title="Are you sure to delete this country?"
            //                     onConfirm={() => {
            //                         this.deleteCountry({_id: record._id})
            //                     }}
            //                     onCancel={() => {
            //                         console.log()
            //                     }}
            //                     okText="Yes"
            //                     cancelText="No">
            //                     <Button
            //                         shape="circle"
            //                         size="small"
            //
            //                         icon="delete"
            //                     />
            //                 </Popconfirm>
            //
            //             </Tooltip>
            //         </React.Fragment>
            //     }
            // }


        ]


        return (
            <PageHeaderWrapper
                title={this.state.count ? `All Country: ${this.state.count}` : `All Country: 0`}>

                <Card bordered={true}>

                    <TableComp ref={this.table}
                               columns={columns}
                               apiRequest={this.apiRequest}
                               pagination={{
                                   ...this.state.pagination,
                                   defaultPageSize: 10,
                                   pageSizeOptions: ['10', '25', '50', '100'],
                                   showSizeChanger: true,
                                   ...this.props.pagination
                               }}/>
                </Card>


            </PageHeaderWrapper>)

    }
}


const mapStateToProps = ({global}) => ({
    categories: global.categories,
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllCountry)
